import React from 'react';
import clsx from 'clsx';
import { Grid, FormControl } from '@mui/material';
import { formControlStyles } from '../../styles/materialStyles';
// import ColorPicker from 'material-ui-color-picker';
// import InputAdornment from '@mui/material/InputAdornment';
// import TextField from '@mui/material/TextField';
import { ChromePicker, Compact } from 'react-color';
import { Controller } from 'react-hook-form';

const MainColorInputController = ({
	control,
	label,
	md,
	identifier,
	hide,
}: any) => {
	const getErrors = (errors, keyToGet = 'message') => {
		if (errors[identifier] && errors[identifier][keyToGet]) {
			return errors[identifier][keyToGet];
		}
		return '';
	};

	if (hide) {
		return null;
	}

	return (
		<Controller
			control={control}
			name={identifier}
			render={({
				field: { onChange, onBlur, value, ref },
				fieldState: { invalid, isTouched, isDirty, error },
				formState: { errors },
			}) => {
				let errorMessage = getErrors(errors);

				return (
					<Grid item xs={12} md={md}>
						<FormControl
							fullWidth={true}
							// style={{ width: '100%' }}
							className={clsx(formControlStyles().root)}
						>
							<label
								style={{
									marginBottom: 5,
									fontWeight: 500,
									width: '200px !important',
								}}
							>
								{label}
							</label>

							<ChromePicker
								style={{ margin: 'auto', width: '50%' }}
								color={value}
								onChange={(colorObj) => {
									// let color = e.target.value;
									// if (color) {
									onChange(colorObj.hex);
									// setDef(color);
									// }
								}}
							/>
						</FormControl>
					</Grid>
				);
			}}
		/>
	);
};

export default MainColorInputController;
