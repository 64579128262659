import { sendAPI } from './helper';

export const getTapDeviceData = async (id) => {
	const result = await sendAPI('gpios/getStatus/' + id, {});
	if (result) {
		return result;
	} else {
		return {};
	}
};

export const getQueueDetails = async (id) => {
	const result = await sendAPI('gpios/getQueueDetails/' + id, {});
	if (result) {
		return result;
	} else {
		return {};
	}
};

export const sendInformationBack = async (tapId, state, window) => {
	const result = await sendAPI(
		'gpios/sendInformationBack/' + tapId,
		{},
		state.openModal,
		null,
		() => window.location.reload()
	);
	return result;
};

export const resetGPIOData = async (tapId, state, window) => {
	const result = await sendAPI(
		'gpios/resetGPIOData/' + tapId,
		{},
		state.openModal,
		null,
		() => window.location.reload()
	);
	return result;
};

export const checkDeviceConnection = async (tapId, state, window) => {
	const result = await sendAPI(
		'taps/checkDeviceConnection/' + tapId,
		{},
		state.openModal,
		null,
		() => window.location.reload()
	);
	return result;
};




