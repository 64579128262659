import React, { useContext, Fragment } from 'react';
import { Context } from '../../../store/modalContext/store';

// import bootstrap components
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { scrollToView } from './../frontPageScript';
import { onLogout } from '../../../api/helper';
import { useInvalidateMutation } from '../../../store/api/api';
import { resetLoginData } from '../../../store/login/loginSlice';
import { useDispatch } from 'react-redux';
import { scrollToView } from '../frontPageScript';

// import style
import './style.scss';

// import images
// import logo from '../../assets/logo.png';
// import toriologo from '../../../assets/torioblacksmaller.svg';
import toriologo from '../../../assets/toriologo.png';

const Header = ({ loggedIn }) => {
	const [modal] = useContext(Context);
	const [invalidate] = useInvalidateMutation();
	// const commonWidth = 136;
	const commonNavStyle = {
		marginLeft: 12, display: 'flex', width: 'auto', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap'
	}
	const dispatch = useDispatch();

	return (
		<Fragment key={1} style={{ zIndex: 1 }}>
			<div key={2} className="header-wrapper" style={{ zIndex: 1 }}>
				<Container style={{ zIndex: 1 }}>
					<div className="header-container" style={{ zIndex: 1 }}>
						<div id="main-logo" className="logo">
							<img src={toriologo} alt="logo" className="img-fluid" style={{ width: 70, borderRadius: 15 }} />
						</div>
						<div></div>
						<div className="main-menu">
							<Navbar >
								<Navbar.Toggle aria-controls="basic-navbar-nav" />
								<Navbar.Collapse id="basic-navbar-nav" expanded={true}>
									<Nav>

										{
											loggedIn ?
												<Nav.Link
													style={commonNavStyle}
													href='https://admin.torio.is/admin'
												>
													Admin Panel
												</Nav.Link>
												:
												null
										}
										{
											loggedIn ?
												<Nav.Link
													style={{ ...commonNavStyle, color: 'red' }}
													onClick={async () => {
														let res = await onLogout(modal.openModal, invalidate);
														dispatch(resetLoginData());

														// let inData = await invalidate();

														setTimeout(() => {
															window.location.reload()
														}, 500);

													}}
													href="#"
												>
													Logout
												</Nav.Link>
												:
												null
										}

									</Nav>
								</Navbar.Collapse>
							</Navbar>
						</div>
					</div>
				</Container>
			</div>
		</Fragment>
	);
};

// Wrap everything in <UseWalletProvider />
export default Header;
