import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, IconButton, Typography } from '@mui/material';
import { sendAPI } from '../../api/helper';

interface MontorHandleOpenInterface {
	tap: any;
	modalState: any;
	onClick?: any;
}

const MontorHandleOpen = ({
	tap,
	modalState,
	onClick,
}: MontorHandleOpenInterface) => {
	const handleOpenAndClose = async (
		isClose: boolean,
		cancelClosePending: boolean = false
	) => {
		await sendAPI(
			'taps/changeTap/' + tap._id,
			{
				action: cancelClosePending
					? 'CANCEL_CLOSE'
					: isClose
					? 'CLOSE'
					: 'OPEN',
			},
			modalState.openModal,
			null
		);
		if (onClick) {
			onClick();
		}
	};

	if (tap.flowStepEnum === 'EMPTY') {
		return (
			<Button
				style={{ marginTop: 24 }}
				variant={'outlined'}
				onClick={async () => {
					handleOpenAndClose(false);
				}}
			>
				Open tap again
			</Button>
		);
	}

	return (
		<div style={{ marginTop: 38 }}>
			{tap.isActive ? (
				tap.closePending ? (
					<IconButton
						color="inherit"
						style={{
							borderRadius: 5,
							backgroundColor: '#111',
						}}
						onCanPlay={async () => {
							handleOpenAndClose(false);
						}}
						aria-label="open tap"
						onClick={() => {
							// setIsOpen(!isOpen);
							handleOpenAndClose(false, true);
						}}
						edge="start"
					>
						<CheckCircleOutlineIcon
							style={{
								color: 'white',
							}}
						/>
						<Typography
							style={{ color: 'white', paddingLeft: 6, paddingRight: 6 }}
						>
							Tap will be closed after user is finished.
						</Typography>
					</IconButton>
				) : (
					<Button
						variant={'outlined'}
						onClick={async () => {
							if (tap && !tap.closePending) {
								handleOpenAndClose(true);
							}
						}}
					>
						Close tap when it's available
					</Button>
				)
			) : (
				<Button
					variant={'outlined'}
					onClick={async () => {
						if (tap && !tap.closePending) {
							handleOpenAndClose(false);
						}
					}}
				>
					Open tap
				</Button>
			)}
		</div>
	);
};

export default MontorHandleOpen;
