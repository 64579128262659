import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

// const Transition = React.forwardRef(function Transition(
// 	props: TransitionProps & {
// 		children: React.ReactElement<any, any>;
// 	},
// 	ref: React.Ref<unknown>
// ) {
// 	return <Slide direction="up" ref={ref} {...props} />;
// });

export default function TextDialog({ isOpen, setIsOpen, text, title }) {
	//   const handleClickOpen = () => {
	//     setOpen(true);
	//   };

	//   const handleClose = () => {
	//     setOpen(false);
	//   };

	return (
		<div>
			{isOpen ? (
				<Dialog
					open={isOpen}
					// TransitionComponent={Transition}
					keepMounted
					onClose={() => setIsOpen(false)}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							{text}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setIsOpen(false)}>Ok</Button>
					</DialogActions>
				</Dialog>
			) : null}
		</div>
	);
}
