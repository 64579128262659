import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import NoEvents from '../texts/NoEvents';
import EventItem from './EventItem';

const EventList = ({
	events,
	noEventsText,
	onClick,
	onRemove,
	showRemove,
	onRemovePermanently,
}) => {
	if (events && events.length === 1 && !events[0].name) {
		return <NoEvents noEventsText={noEventsText} />;
	}

	return (
		<Box
			style={{
				marginTop: 30,
				// width: '90%',
				margin: 'auto',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'center',
			}}
		>
			{events && events.length > 0 ? (
				events.map((event, index) => {
					if (!event || (event && !event.name)) {
						return null;
					}

					return (
						<EventItem
							key={index}
							onRemove={onRemove}
							showRemove={showRemove}
							id={event._id}
							name={event.name}
							image={event.imageLink ? event.imageLink.link : null}
							description={event.description}
							from={event.from}
							to={event.to}
							onClick={onClick}
							onRemovePermanently={onRemovePermanently}
						/>
					);
				})
			) : (
				<NoEvents noEventsText={noEventsText} />
			)}
		</Box>
	);
};

export default EventList;
