import { Button } from '@mui/material';

interface AuthButtonProps {
	onClick: any;
	text: string;
	disabled?: boolean;
}

// AuthButton

const LoginScreenButton = ({ onClick, text, disabled }: AuthButtonProps) => {
	return (
		<Button
			onClick={async () => await onClick()}
			variant="contained"
			// size="md"
			disabled={disabled}
			style={{
				color: 'white',
				width: 200,
				padding: 10,
				margin: 'auto',
			}}
		>
			{text}
		</Button>
	);
};

export default LoginScreenButton;
