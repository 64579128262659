import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

const BottomTabs = ({ activeTab }: any) => {
	return <div></div>;

	// const navigate = useNavigate();

	// const handleChange = (event: React.SyntheticEvent, newValue: number) => {
	// 	console.log('new val: ', newValue);
	// 	if (newValue === 0) {
	// 		navigate('/');
	// 	} else if (newValue === 1) {
	// 		navigate('/scan');
	// 	} else if (newValue === 2) {
	// 		navigate('/profile');
	// 	}
	// };

	// return (
	// 	<Tabs value={activeTab} onChange={handleChange} aria-label="tabs">
	// 		<Tab style={{ flex: 1 }} icon={<HomeIcon />} label="HOME" />
	// 		<Tab style={{ flex: 1 }} icon={<QrCodeScannerIcon />} label="SCAN" />
	// 		<Tab style={{ flex: 1 }} icon={<PersonIcon />} label="USER" />
	// 	</Tabs>
	// );
};

export default BottomTabs;
