import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import { sendAPI, onObjectChange, removeItem } from '../../api/helper';
import { useParams } from 'react-router-dom';
import MainInput from '../../components/inputs/MainInput';
import { Grid } from '@mui/material';
import MainButton from '../../components/buttons/MainButton';
import MainContainer from '../../components/containers/MainContainer';
import Header from '../../components/texts/Header';
import { goOutOfItem } from '../../business/routingHelper';
import { Panel } from '../../styles/styledComponents';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import PageContainer from '../../components/containers/PageContainer';
import { flowDropdownMaker } from '../../business/dropdownHelper';
import useItems from '../../hooks/useItems';
import MainDropdown from '../../components/dropdowns/MainDropdown';

function GPIO({ user }) {
	//@ts-ignore
	const [state] = useContext(Context);

	const [GPIO, setGPIO] = React.useState({});
	const [isNew, setIsNew] = React.useState(true);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const params = useParams<any>();
	const history = useHistoryNav();

	const [flows, flow, flowsDropdown] = useItems({
		id: '',
		dropdownFunction: flowDropdownMaker.bind(this),
		useDropdown: true,
		url: 'flowmeters/flows/',
	});

	React.useEffect(() => {
		getGPIO();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getGPIO() {
		// Get item
		if (params && params.id && params.id !== '0') {
			const result = await sendAPI('gpios/getGPIO/' + params.id, {});
			if (result && result.GPIO) {
				//Set GPIO if it exists
				const GPIO = result.GPIO;
				console.log('gpio: ', GPIO);
				setGPIO(GPIO);
				setIsNew(false);
				setIsLoaded(true);
			} else {
				//Exit
				goOutOfItem(history);
			}
		} else if (params.id === '0') {
			// Adding new item
			setIsLoaded(true);
		} else {
			// Somethings wrong
			goOutOfItem(history);
		}
	}

	async function onSend(isNewPara, isRemove = false) {
		sendAPI(
			'gpios/manageDevice/' + params.id,
			{ GPIO: GPIO, isNew: isNewPara, isRemove: isRemove },
			state.openModal
		);
	}

	const onValueChange = (key, value) => {
		onObjectChange(GPIO, key, value, setGPIO.bind(this));
	};

	return (
		<PageContainer header={'Device'}>
			<MainContainer isLoaded={isLoaded}>
				{GPIO ? (
					<Panel>
						<Header text={'Manage GPIO'} />
						<Grid spacing={3} container>
							<MainInput
								type={'number'}
								label={'Flow meter GPIO *'}
								options={{
									min: 0,
									max: 40,
								}}
								md={6}
								value={GPIO['flowMeterGPIO'] ? GPIO['flowMeterGPIO'] : ''}
								identifier={'flowMeterGPIO'}
								onChange={onValueChange}
							/>
							<MainInput
								type={'number'}
								label={'Solenoid valve GPIO *'}
								options={{
									min: 0,
									max: 40,
								}}
								value={
									GPIO['solenoidValveGPIO'] ? GPIO['solenoidValveGPIO'] : ''
								}
								identifier={'solenoidValveGPIO'}
								md={6}
								onChange={onValueChange}
							/>
							<MainInput
								type={'number'}
								label={'Flow sensor *'}
								options={{
									min: 0,
									max: 40,
								}}
								value={GPIO['flowSensor'] ? GPIO['flowSensor'] : ''}
								identifier={'flowSensor'}
								md={6}
								onChange={onValueChange}
							/>
							<MainInput
								type={'number'}
								label={'Flow meter earth PIN number'}
								options={{
									min: 0,
									max: 30,
								}}
								value={GPIO['flowMeterEarth'] ? GPIO['flowMeterEarth'] : ''}
								md={6}
								identifier={'flowMeterEarth'}
								onChange={onValueChange}
							/>

							<MainDropdown
								size={12}
								label={'Flow meter type'}
								isWithLink={false}
								options={flowsDropdown}
								optionsValueKey={'_id'}
								optionsTextKey={'text'}
								id={'flowmeter'}
								onChange={(e) => {
									// console.log('t.target: ', e.target.value);
									let newGPIO = { ...GPIO };
									newGPIO['flowmeter'] = e.target.value;
									setGPIO(newGPIO);
									// console.log('e.target.value: ', e.target.value);
									// if (e.target.value) {
									// 	setSelectedTap(e.target.value);
									// } else {
									// 	setSelectedTap(defaultSelection);
									// }
								}}
								value={GPIO['flowmeter'] ? GPIO['flowmeter'] : ''}
							/>

							<MainInput
								label={'Circles per ml'}
								value={GPIO['flowRate'] ? GPIO['flowRate'] : ''}
								md={6}
								identifier={'flowRate'}
								onChange={onValueChange}
							/>

							<MainInput
								type={'number'}
								label={'Flow meter power PIN number'}
								options={{
									min: 0,
									max: 30,
								}}
								value={GPIO['flowMeterPower'] ? GPIO['flowMeterPower'] : ''}
								md={6}
								identifier={'flowMeterPower'}
								onChange={onValueChange}
							/>

							<MainInput
								type={'number'}
								label={'Solenoid valve earth PIN number'}
								md={6}
								options={{
									min: 0,
									max: 30,
								}}
								value={
									GPIO['solenoidValveEarth'] ? GPIO['solenoidValveEarth'] : ''
								}
								identifier={'solenoidValveEarth'}
								onChange={onValueChange}
							/>
							<MainInput
								label={'Flow meter type'}
								md={6}
								maxlength={100}
								value={GPIO['flowMeterType'] ? GPIO['flowMeterType'] : ''}
								identifier={'flowMeterType'}
								onChange={onValueChange}
							/>
							<MainInput
								label={'Flow meter model'}
								maxlength={30}
								md={6}
								value={GPIO['flowMeterModel'] ? GPIO['flowMeterModel'] : ''}
								identifier={'flowMeterModel'}
								onChange={onValueChange}
							/>

							<MainInput
								label={'Device ID *'}
								md={6}
								maxlength={20}
								value={GPIO['deviceId'] ? GPIO['deviceId'] : ''}
								identifier={'deviceId'}
								onChange={onValueChange}
							/>

							{/* <MainInput
								label={'Device'}
								maxlength={100}
								value={GPIO['device'] ? GPIO['device'] : ''}
								md={12}
								identifier={'device'}
								onChange={onValueChange}
							/> */}

							<MainInput
								label={'Information'}
								maxlength={100}
								value={GPIO['information'] ? GPIO['information'] : ''}
								md={12}
								rows={'3'}
								identifier={'information'}
								onChange={onValueChange}
							/>

							<MainInput
								label={'SSHURL'}
								maxlength={100}
								md={12}
								value={GPIO['SSHURL'] ? GPIO['SSHURL'] : ''}
								identifier={'SSHURL'}
								onChange={onValueChange}
							/>

							<MainInput
								label={'URL'}
								maxlength={100}
								md={12}
								value={GPIO['URL'] ? GPIO['URL'] : ''}
								identifier={'URL'}
								onChange={onValueChange}
							/>
						</Grid>
						<Grid style={{ display: 'flex' }}>
							<MainButton
								onClick={() => onSend(isNew)}
								text={isNew ? 'Create' : 'Save'}
								color={'primary'}
							/>
							<MainButton
								onClick={() => {
									goOutOfItem(history);
								}}
								text={'Go back'}
								color={'default'}
							/>
							{!isNew ? (
								<MainButton
									color={'inherit'}
									onClick={() => {
										removeItem(
											'Do you want to remove this GPIO?',
											onSend.bind(this, isNew, true)
										);
									}}
									text={'Remove'}
								/>
							) : null}
						</Grid>
					</Panel>
				) : (
					<p>Not found</p>
				)}
			</MainContainer>
		</PageContainer>
	);
}

export default GPIO;
