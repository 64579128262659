import { sendAPI } from './helper';
import { goOutOfItem, goBack } from '../business/routingHelper';

export const getEvents = async (withImages) => {
	const result = await sendAPI('events/getEvents/', { withImages: withImages });
	return result;
	// if (result && result.items) {
	// 	return result.items;
	// } else {
	// 	return [];
	// }
};

export const manageEvent = async (
	event,
	isNewPara,
	isRemove,
	id,
	openModal,
	history
) => {
	let result = await sendAPI(
		'events/manageEvent/' + id,
		{ event: event, isNew: isNewPara, isRemove: isRemove },
		openModal
	);

	if (result && result.success && (isNewPara || isRemove)) {
		goBack(history);
	}
};

export const onSaveEvents = async (selectedDate, eventId, shouldRemove) => {
	const result = await sendAPI('events/saveDateEvent/', {
		date: selectedDate,
		eventId: eventId,
		shouldRemoveFromDate: shouldRemove,
	});
	return result;
};

export const getNewLanguage = async (name, description, languageChange) => {
	const result = await sendAPI('events/getNewLanguage/', {
		name: name,
		description: description,
		languageChange: languageChange,
	});
	return result;
};

// export const setEvent = async (info, openModal) => {
// 	const result = await sendAPI(
// 		'detailedPlace/saveDetailedPlace/',
// 		{ ...info },
// 		openModal,
// 		openModal
// 	);
// 	if (result) {
// 		return result;
// 	} else {
// 		return {};
// 	}
// };
