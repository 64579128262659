import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { isIS } from '@mui/material/locale';

// import green from '@mui/material/colors/green';

const theme = createTheme({
	// isIS,
	typography: {
		fontFamily: 'Inter',
		// fontFamily: ['DM Sans', 'sans-serif'].join(','),
		h1: {
			fontWeight: 600,
			fontSize: 25,
			// letterSpacing: '-1.5px',
		},
		h2: {
			fontWeight: 600,
			fontSize: 21,
			// letterSpacing: '-0.5px',
		},
		h3: {
			fontWeight: 500,
		},
		h4: {
			fontWeight: 500,
			letterSpacing: '0.25px',
		},
		h5: {
			fontWeight: 700,
		},
		h6: {
			fontWeight: 700,
			letterSpacing: '0.15px',
		},
		body1: {
			letterSpacing: '0.15px',
		},
		body2: {
			letterSpacing: '0.15px',
		},
		subtitle1: {
			letterSpacing: '0.15px',
		},
		subtitle2: {
			letterSpacing: '0.1px',
		},
		fontWeightLight: 400,
		fontWeightRegular: 500,
		fontWeightMedium: 600,
		fontWeightBold: 700,
	},
	palette: {
		primary: {
			main: grey[900], //'#222',
		},
		secondary: {
			main: '#ffffff',
		},
	},
	components: {
		MuiTabs: {
			styleOverrides: {
				root: {
					display: 'flex',
					position: 'fixed',
					bottom: 0,
					backgroundColor: 'white',
					width: '100vw',
					justifyContent: 'center',
				},
				flexContainer: {
					justifyContent: 'center',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					display: 'flex',
					flex: 1,
					maxWidth: '100%',
				},
			},
		},
	},
});

// palette: {
// 	primary: {
// 		main: '#111',
// 		// main: '#1d38bc', //purple[500],
// 	},
// 	secondary: {
// 		main: '#f66c2a',
// 	},
// },

// const theme = createTheme(
// 	adaptV4Theme({
// 		typography: {
// 			fontFamily: ['DM Sans', 'sans-serif'].join(','),
// 			h1: {
// 				fontWeight: 400,
// 				fontSize: 29,
// 				// letterSpacing: '-1.5px',
// 			},
// 			h2: {
// 				fontWeight: 400,
// 				// letterSpacing: '-0.5px',
// 			},
// 			h3: {
// 				fontWeight: 500,
// 			},
// 			h4: {
// 				fontWeight: 500,
// 				letterSpacing: '0.25px',
// 			},
// 			h5: {
// 				fontWeight: 700,
// 			},
// 			h6: {
// 				fontWeight: 700,
// 				letterSpacing: '0.15px',
// 			},
// 			body1: {
// 				letterSpacing: '0.15px',
// 			},
// 			body2: {
// 				letterSpacing: '0.15px',
// 			},
// 			subtitle1: {
// 				letterSpacing: '0.15px',
// 			},
// 			subtitle2: {
// 				letterSpacing: '0.1px',
// 			},
// 		},
// 		palette: {
// 			primary: {
// 				main: '#111',
// 				// main: '#1d38bc', //purple[500],
// 			},
// 			secondary: {
// 				main: '#f66c2a',
// 			},
// 		},
// 	})
// );

export default theme;
