// import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { DatePicker } from '@mui/lab';
import MomentUtils from '@date-io/moment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import MomentUtils from '@date-io/moment';
// import MomentUtils from "material-ui-pickers/utils/moment-utils";
import moment from 'moment';
import { TextField } from '@mui/material';

export default function DateTimePickerMain({ label, date, onChange }) {
	const handleDateChange = (date: any) => {
		onChange(date);
		// setSelectedDate(date);
	};

	console.log('label, date, change: ', label, date);

	return (
		<DesktopDatePicker
			// id="date-picker-dialog"
			label={label}
			maxDate={moment().add(1, 'days').toDate()}
			// format="DD/MM/yyyy HH:mm"
			// value={date}
			value={date}
			renderInput={(params) => <TextField {...params} />}
			// type={'outlined'}
			// ampm={false}
			// disableFuture={true}
			onChange={handleDateChange}
		/>
	);
}
