import React, { useContext } from 'react';
import MainContainer from './../../components/containers/MainContainer';
import { useLocation } from 'react-router-dom';
import PanelContainer from '../../components/containers/PanelContainer';
import CalendarMain from '../../components/calendar/CalendarMain';
import SubHeader from './../../components/texts/SubHeader';
import MainButton from '../../components/buttons/MainButton';
import { onSaveEvents } from './../../api/eventsData';
import moment from 'moment';
import EventList from './../../components/lists/EventList';
import { getEventByDateKey } from './../../business/eventsHelper';
import { useHistoryNav } from './../../hooks/useHistoryNav';
import PageContainer from './../../components/containers/PageContainer';
import { Grid, Snackbar } from '@mui/material';
import {
	useDeleteEventMutation,
	useGetEventsMutation,
} from '../../store/api/api';
import useManageHook from '../../components/hooks/useManageHook';
import { ROUTE_NAMES } from '@toriosoftware/torioshared/dist/config';
import { eventFromServer } from '@toriosoftware/torioshared/dist/event';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import NoEvents from '../../components/texts/NoEvents';
import ButtonGroup from '../../components/buttons/ButtonGroup';
import ConfirmHook from '../../components/dialogs/ConfirmHook';
import { Context } from '../../store/modalContext/store';
import ExpandLess from '@mui/icons-material/ExpandLess';
import HelpCard from '../../components/cards/HelpCard';
import MainInputController from '../../components/inputs/MainInputController';
import MainInput from '../../components/inputs/MainInput';
import AddEventFromFB from './AddEventFromFB';

const Events = ({ user }) => {
	const [getEvents] = useGetEventsMutation();
	const [eventToDelete, setEventToDelete] = React.useState<{
		id: string;
		name: string;
	}>({
		id: '',
		name: '',
	});
	const [deleteEvent] = useDeleteEventMutation();

	const [allEventEntries, setAllEventEntries] = React.useState<any>([]);
	const [onDelete, setOnDelete] = React.useState<boolean>(false);
	const [allEventKeys, setAllEventKeys] = React.useState<Array<string>>([]);
	const [allEvents, setAllEvents] = React.useState<Array<eventFromServer>>([]);
	const [selectedEvent, setSelectedEvent] = React.useState<
		eventFromServer | {}
	>({});
	const [selectedDate, setSelectedDate] = React.useState(
		moment(new Date()).format('DD-MM-YYYY')
	);
	const location = useLocation();
	let route = location.pathname;

	const {
		modelState,
		setIsLoaded,
		isLoaded,
		history,
		isNew,
		id,
		data,
		csrfToken,
	} = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.events, user)?.data?.get(getEvents, null),
		],
		user: user,
	});

	React.useEffect(() => {
		//@ts-ignore
		if (data && data?.items) {
			//@ts-ignore
			let dataToUse = data?.items;
			setAllEventEntries(dataToUse.eventEntries ? dataToUse.eventEntries : []);
			setAllEventKeys(dataToUse.eventKeys ? dataToUse.eventKeys : []);
			setAllEvents(dataToUse.items ? dataToUse.items : []);
			setTimeout(() => {
				onChangeDate(new Date(), dataToUse.items, dataToUse.eventEntries);
			}, 500);
		}
		if (isLoaded) {
		}
	}, [data]);

	const onChangeDate = (
		newDate,
		allEventsPara = null,
		allEventEntriesPara = null
	) => {
		const newDateTemp = moment(newDate).format('DD-MM-YYYY');
		setSelectedDate(newDateTemp);
		setDateEvent(
			newDateTemp,
			allEventsPara ? allEventsPara : allEvents,
			allEventEntriesPara ? allEventEntriesPara : allEventEntries
		);
	};

	const setDateEvent = (
		selectedDatePara,
		allEventsUsed,
		allEventEntriesUsed
	) => {
		let event = getEventByDateKey(
			selectedDatePara,
			allEventsUsed,
			allEventEntriesUsed
		);
		setSelectedEvent(event);
	};

	const onAddEvent = async (id) => {
		let eventToFind = allEvents.find((item) => {
			return item._id === id;
		});
		if (eventToFind) {
			// setAreEventsChanged(true);
			let result = await onSaveEvents(selectedDate, eventToFind._id, false);
			if (result) {
				if (result.success) {
					setSelectedEvent(eventToFind);
					setAllEventKeys(result.eventKeys);
					setAllEventEntries(result.eventEntries);
					modelState.openModal('Success', 'Event added. ', 'success');
				} else {
					modelState.openModal('Error', result.message, 'error');
				}
			}
		}
	};

	const onRemoveEvent = async (id) => {
		let result = await onSaveEvents(selectedDate, id, true);
		if (result && result.success) {
			setSelectedEvent({});
			setAllEventKeys(result.eventKeys);
			setAllEventEntries(result.eventEntries);
			modelState.openModal('Success', 'Event removed. ', 'success');
		} else if (result) {
			modelState.openModal('Error', result.message, 'error');
		}
	};

	return (
		<PageContainer header={'Events'}>
			<HelpCard
				seconds={48}
				title={'How to add events'}
				texts={[
					"Create an event (This event is not visible anywhere after it's created). ",
					'Select a date and press add event on one of the event cards. ',
					'One event can be added to many dates, so if there is always discount on a certain day, it can be added to all days easily.',
				]}
				link={'https://youtu.be/cfRyAyMtyPk'}
			/>

			<MainContainer>
				<PanelContainer isLoaded={isLoaded} header={'Selected events'}>
					<div
						style={{
							width: '100%',
							margin: 'auto',
							display: 'flex',
							justifyContent: 'center',
							marginBottom: 15,
						}}
					>
						<CalendarMain
							setSelectedDate={setSelectedDate}
							selectedDate={selectedDate}
							onCalendarPress={onChangeDate}
							data={allEventKeys}
						/>
					</div>

					<SubHeader
						text={
							'Event for the following day: ' +
							selectedDate +
							` (${moment(selectedDate, 'DD-MM-YYYY').format('LL')})`
						}
					/>
					{selectedEvent ? (
						<EventList
							events={[selectedEvent]}
							noEventsText={'No event has been added for this day. '}
							onClick={() => {}}
							onRemove={onRemoveEvent}
							showRemove={true}
							onRemovePermanently={(id) => {}}
						/>
					) : (
						<NoEvents noEventsText={'No event has been added for this day. '} />
					)}
				</PanelContainer>
			</MainContainer>
			{allEvents && allEvents.length > 0 ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ExpandLess style={{ fontSize: 48, color: '#111' }} />
				</div>
			) : null}

			<MainContainer>
				<PanelContainer isLoaded={isLoaded} header={'Your event backlog'}>
					<ConfirmHook
						isOpen={onDelete}
						onClose={() => {
							setOnDelete(false);
						}}
						onPress={async () => {
							let { id, name } = eventToDelete;
							console.log('id name: ', id, name);
							if (id && name) {
								let result = await deleteEvent({
									id,
									modal: modelState,
									csrfToken,
								});
								setOnDelete(false);
								//@ts-ignore
								let data = result.data;
								if (data && data.success) {
									window.location.reload();
								}
							}
						}}
						text={
							'Are you sure you want to permentantly delete this event called ' +
							eventToDelete.name +
							'? (It will also be deleted everywhere it is used) '
						}
						title={'Warning'}
					/>
					<EventList
						onRemove={() => {}}
						showRemove={false}
						events={allEvents}
						noEventsText={
							'No events have been created. First you create event, then you can add it to the calendar many times. '
						}
						onClick={(id) => {
							onAddEvent(id);
						}}
						onRemovePermanently={(id, name) => {
							setEventToDelete({
								id: id,
								name: name,
							});

							setOnDelete(true);
						}}
					/>
					<Grid item style={{ width: '100%' }}>
						<ButtonGroup>
							<MainButton
								color={'primary'}
								text={'Create event'}
								onClick={() => {
									history(route + '/0');
								}}
							/>
						</ButtonGroup>
					</Grid>
				</PanelContainer>
			</MainContainer>
			<AddEventFromFB user={user} isLoaded={isLoaded} />
		</PageContainer>
	);
};

export default Events;

// const onEventClick = (id) => {
// 	let eventToFind = allEvents.find((e) => e._id === id);
// 	if (eventToFind) {
// 		let doesEventBelongInGroup = selectedEvents.find((e) => e._id === id);
// 		if (!doesEventBelongInGroup) {
// 			setSelectedEvents([...selectedEvents, eventToFind]);
// 			setAreEventsChanged(true);
// 		} else {
// 			window.alert('You have already added this event at this date. ');
// 		}
// 	} else {
// 		window.alert('Could not find event. ');
// 	}
// };

// const onSaveEvents = () => {
// 	setAreEventsChanged(false);
// };
