import { Grid } from '@mui/material';
import LoginScreenWrapper from './LoginScreenWrapper';
import React from 'react';
import AuthButton from './AuthButton';
import './style.scss';
import { FixedLogoutButton } from './FixedLogoutButton';
import LoginSelectField from './LoginSelectField';
import { UnderInputLink } from './UnderInputLink';
import {
	useGetPlaceNamesMutation,
	useLoginToPlaceMutation,
} from '../../store/api/api';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { Header, LowerHeader, UnderInputText } from './LoginStyledComponents';
import ButtonContainer from './ButtonContainer';

const SelectPlace = ({ modalState, showBackButton }: any) => {
	// const [code, setCode] = React.useState('');
	// const showPassword = useAppSelector(showPasswordSelector);
	// const emailCode = useAppSelector(emailCodeSelector);
	// const [confirmEmail] = useConfirmEmailMutation();
	// const dispatch = useAppDispatch();
	const [loginToPlace] = useLoginToPlaceMutation();
	const [getPlaceNames, result] = useGetPlaceNamesMutation();
	const [selectedPlace, setSelectedPlace] = React.useState('');
	const history = useHistoryNav();

	let places =
		result && result?.data?.items && result?.data?.items.length > 0
			? result?.data?.items
			: [];

	// console.log('RESULT: ', result);

	React.useEffect(() => {
		getPlaceNamesFun(false);
		window.scrollTo({ top: 0 });
	}, []);

	const getPlaceNamesFun = async (shouldGet) => {
		await getPlaceNames(shouldGet);
	};

	return (
		<LoginScreenWrapper>
			<FixedLogoutButton />

			<Header style={{ marginTop: 40 }}>Select place</Header>
			<LowerHeader>Select the place you want to log into</LowerHeader>

			<LoginSelectField
				options={places}
				selectedValue={selectedPlace}
				onChange={setSelectedPlace}
			/>

			<UnderInputLink
				onClick={async () => {
					getPlaceNamesFun(true);
				}}
				text={'Refresh list'}
			/>

			<ButtonContainer>
				{places && places.length > 0 ? (
					<AuthButton
						disabled={!selectedPlace}
						onClick={async () => {
							let loginToPlaceObj = await loginToPlace({
								placeId: selectedPlace,
								modal: modalState,
							});

							//@ts-ignore
							let pushUrl = loginToPlaceObj?.data?.pushUrl;

							//@ts-ignore
							if (loginToPlaceObj?.data?.success) {
								//@ts-ignore
								let newUrl = window.location.origin + '/' + pushUrl;
								//@ts-ignore
								window.location.href = newUrl;
							}
						}}
						text={'Continue'}
					/>
				) : (
					<p style={{ textAlign: 'center' }}>
						You do not have access to any places. You need an invite link to
						manage a place.
					</p>
				)}
				{showBackButton ? (
					<AuthButton
						onClick={() => {
							history(-1);
						}}
						text={'Back'}
					/>
				) : null}
			</ButtonContainer>
		</LoginScreenWrapper>
	);
};

export default SelectPlace;
