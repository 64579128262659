import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
	media: {
		height: 140,
	},
});

const MenuCard = ({ icon, color, name, description, route, subscription }) => {
	const classes = useStyles();

	return (
		<Card>
			<Link to={route} style={{ color: '#111', textDecoration: 'none' }}>
				<CardActionArea>
					<div
						style={{
							width: '100%',
							height: 150,
							margin: 'auto',
							backgroundColor: color,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: '#111',
						}}
					>
						{icon(true)}
					</div>
					<CardContent style={{ minHeight: 120 }}>
						<Typography gutterBottom variant="h5" component="h2">
							{name}
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p">
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button size="small" color="primary">
						View
					</Button>
				</CardActions>
			</Link>
		</Card>
	);
};

export default MenuCard;
