import { styled } from '@mui/material';

export const Header = styled('h1')`
	color: #ffffff;
	font-weight: 600;
	font-size: 50px;

	${(props: any) => props.theme.breakpoints.down('sm')} {
		font-size: 30px !important;
	}
`;

export const LowerHeader = styled('p')`
	color: #ffffff;
	font-weight: 500;
	font-size: 20px;

	${(props: any) => props.theme.breakpoints.down('sm')} {
		font-size: 17px !important;
	}
`;

export const UnderInputText = styled('span')`
	display: flex;
	margin: auto;
	margin-top: -22px;
	color: white;
	width: 100%;
	float: left;
	justify-content: flex-start;
	cursor: pointer;

	${(props: any) => props.theme.breakpoints.down('sm')} {
		font-size: 15px !important;
	}
`;

export const BackText = styled('a')`
	${(props: any) => props.theme.breakpoints.down('sm')} {
		font-size: 15px !important;
	}
`;
