import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import { useParams } from 'react-router-dom';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
// import './styles/receipt.css';
import moment from 'moment';
import Header from '../../components/texts/Header';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import LocalOffer from '@mui/icons-material/LocalOffer';

import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import CropFreeIcon from '@mui/icons-material/CropFree';

import Survey from '../Survey/Survey';
import { showFeedback } from '../../business/receiptHelper';
import { getCurrencyTypeByCode } from '../../business/currencyHelper';
import SmallTitleText from './Misc/SmallTitleText';

import { goBack } from '../../business/routingHelper';
import PageContainer from '../../components/containers/PageContainer';
import { useGetUserReceiptCompanyMutation } from '../../store/api/api';
import EntryInfo from './Misc/EntryInfo';
import { Grid } from '@mui/material';

function Receipt({ user }) {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const [accessLog, setAccessLog] = React.useState({});
	const [isLoaded, setIsLoaded] = React.useState(false);
	const params = useParams<any>();
	const history = useHistoryNav();
	const [getUserReceiptCompany] = useGetUserReceiptCompanyMutation();

	React.useEffect(() => {
		getAccessLogEffect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getAccessLogEffect() {
		if (!params.id) return;
		const response = await getUserReceiptCompany({ id: params.id }).unwrap();
		console.log("response: ", 	response)
		console.log('responseafter: ', response);
		if (response && response.success && response.item) {
			setAccessLog(response.item);
		} else {
			goBack(history);
		}
		setIsLoaded(true);
	}

	// const getPlaceLogo = (log) => {
	// 	if (log && log['place']) {
	// 		let logPlace = log['place'];
	// 		if (logPlace && logPlace['name']) {
	// 			let image = logPlace['image'] ? logPlace['image'] : null;
	// 			return (
	// 				<div style={{ position: 'absolute', right: '25px', top: '25px' }}>
	// 					{image ? (
	// 						<img
	// 							style={{ width: 80, height: 80, marginTop: 10 }}
	// 							alt="place logo"
	// 							src={image}
	// 						/>
	// 					) : (
	// 						<p>{logPlace['name']}</p>
	// 					)}
	// 				</div>
	// 			);
	// 		}
	// 	}
	// 	return <p></p>;
	// };
	

	let mainIcon = {
		fontSize: 40,
		margin: 4,
		color: 'white',
	};

	return(
		<PageContainer header={'Receipt'}>
			<MainContainer direction="row" history={history}>
				<PanelContainer isLoaded={isLoaded} header={'Receipt'}>
				</PanelContainer>
			</MainContainer>
		</PageContainer>

	)

	console.log('access log: ', accessLog);

	return (
		<PageContainer header={'Access log'}>
			<MainContainer direction="row" history={history}>
				<PanelContainer isLoaded={isLoaded} header={'Access log'}>
					{accessLog['isTest'] ? (
						<div
							style={{
								top: 0,
								left: 0,
								width: '100%',
								backgroundColor: 'red',
								color: 'white',
								position: 'absolute',
								textAlign: 'center',
							}}
						>
							Tap set to testing. Nothing was charged.
						</div>
					) : null}

					<SmallTitleText accessLog={accessLog} />

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							// maxWidth: 700,
							marginTop: 20,
						}}
					>
						{/* {getPlaceLogo(accessLog)} */}

						{accessLog ? (
							<EntryInfo
								label={'Scan'}
								log={accessLog}
								icon={(color) => (
									<CropFreeIcon style={{ ...mainIcon, color }} />
								)}
								text={'Tap number ' + accessLog['tapNumber']}
								smallerText={
									accessLog['drinkName']
										? accessLog['drinkName'] + ' on tap.'
										: 'Drink at tap.'
								}
								badgeText={'Scan confirmed'}
								date={accessLog['localTimestamp']}
								isValid={
									accessLog['tapNumber'] &&
									accessLog['drinkName'] &&
									accessLog['localTimestamp']
								}
								fallbackMessage={'Scan information not available. '}
							/>
						) : (
							<span></span>
						)}

						{accessLog &&
						accessLog['price330Ml'] &&
						accessLog['dataReceivedTimestamp'] ? (
							<EntryInfo
								label={'Price of drink'}
								log={accessLog}
								icon={(color) => <LocalOffer style={{ ...mainIcon, color }} />}
								text={
									accessLog['price330Ml'] +
									getCurrencyTypeByCode(accessLog['currency']) +
									' per 330 ml (~11.16oz)'
								}
								smallerText={''}
								badgeText={'Price confirmed'}
								date={accessLog['dataReceivedTimestamp']}
								isValid={
									accessLog['price330Ml'] && accessLog['dataReceivedTimestamp']
								}
								fallbackMessage={'Drink price '}
							/>
						) : (
							<span></span>
						)}

						{accessLog['dataReceivedTimestamp'] ? (
							<EntryInfo
								label={'Ml poured'}
								log={accessLog}
								icon={(color) => (
									<LocalDrinkIcon style={{ ...mainIcon, color }} />
								)}
								text={
									accessLog['mlPoured'] > -1
										? accessLog['mlPoured'] + ' ml'
										: 'Unknown ml'
								}
								smallerText={
									accessLog['mlAllowed'] +
									' ml allowed. ' +
									accessLog['flowCircles'] +
									'/' +
									accessLog['flowCirclesAllowed']
								}
								badgeText={'Confirmed'}
								date={accessLog['dataReceivedTimestamp']}
								isValid={
									accessLog['mlPoured'] > -1 &&
									accessLog['dataReceivedTimestamp']
								}
								fallbackMessage={'Ml poured'}
							/>
						) : (
							<span></span>
						)}

						{accessLog['dataReceivedTimestamp'] ? (
							<EntryInfo
								label={'Price to pay'}
								log={accessLog}
								icon={(color) => (
									<LocalDrinkIcon style={{ ...mainIcon, color }} />
								)}
								text={
									accessLog['isFree']
										? 'Free'
										: accessLog['priceToPay'] > -1
										? accessLog['priceToPay'] + ' kr'
										: '0 kr'
								}
								smallerText={accessLog['payment']}
								badgeText={'Payment confirmed'}
								date={accessLog['dataReceivedTimestamp']}
								isValid={
									accessLog['mlPoured'] > -1 &&
									accessLog['dataReceivedTimestamp']
								}
								fallbackMessage={'Price to pay'}
							/>
						) : (
							<span></span>
						)}
					</div>

					{accessLog['flowHistory'] && user.systemRole === 'Admin' ? (
						<div>
							<div style={{ width: '100%', marginTop: 10 }}>
								<Header text={'Flow breakdown (Admin)'} />
							</div>
							{/* <CircleFlow flowHistory={accessLog['flowHistory']} /> */}
						</div>
					) : (
						<span></span>
					)}

					{user.systemRole === 'Admin' ? (
						<Grid
							item
							style={{
								width: '100%',
								marginTop: 10,
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Header text={'Further details'} />
							{accessLog
								? Object.keys(accessLog).map((key) => {
										return (
											<div>
												{key}: {accessLog[key]}
											</div>
										);
								  })
								: null}
						</Grid>
					) : null}

					<div
						style={{
							margin: 'auto',
							marginTop: 50,
							width: '100%',
							display: 'flex',
						}}
					>
						{accessLog['feedback'] && showFeedback(accessLog, user.role, '') ? (
							<div style={{ display: 'flex', margin: 'auto' }}>
								<Survey
									accessId={accessLog['_id']}
									priorFeedback={
										accessLog && accessLog['feedback'] ? (
											accessLog['feedback']
										) : (
											<span></span>
										)
									}
								/>
							</div>
						) : (
							<span></span>
						)}
					</div>
				</PanelContainer>
			</MainContainer>
		</PageContainer>
	);
}

export default Receipt;
