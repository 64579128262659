import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { profileQuery } from '@toriosoftware/torioshared/dist/profile';
import {
	manageEventQuery,
	getEvents,
} from '@toriosoftware/torioshared/dist/event';
import {
	GeneralRequestWithId,
	GeneralResponse,
	GeneralRequest,
} from '@toriosoftware/torioshared/dist/general';
import { GetPlaceUserResponse } from '@toriosoftware/torioshared/dist/placeUser';
import { TapQuery } from '@toriosoftware/torioshared/dist/taps/Tap';

const generalHeaders = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': process.env.REACT_APP_BACKEND_URL,
	'Access-Control-Allow-Credentials': true,
	// 'include'
	// "Access-Control-Allow-Origin": "*"
};

const axiosBaseQuery =
	(
		{ baseUrl }: { baseUrl: string } = { baseUrl: '' }
	): BaseQueryFn<
		{
			url: string;
			method: AxiosRequestConfig['method'];
			data?: AxiosRequestConfig['data'];
			modal?: {
				modal: any;
				onlyModalSuccess?: boolean;
				onlyModalError?: boolean;
				reloadSuccess?: boolean;
				goBackOnError?: boolean;
				navigation?: any;
			};
			params?: any;
			// onlyModalSuccess?: boolean,
			// modalOnError?: boolean,
		},
		any,
		any
	> =>
	async ({ url, method, data, params, modal }) => {
		try {
			let headers = Object.assign({}, generalHeaders);
			if (data && data.csrfToken && headers) {
				//@ts-ignore
				headers = { ...headers, 'csrf-token': data.csrfToken };
				delete data.csrfToken;
			}

			const result = await axios({
				url: baseUrl + url,
				method,
				data,
				params,
				withCredentials: true,
				headers: headers,
			}); //, withCredentials: true, headers: headers
			const resultData = result.data;

			if (
				!resultData.loggedIn &&
				!resultData.success &&
				resultData.routeToLogin
			) {
				window.location.href = window.location.origin + '/login';
			} else {
				if (modal && modal.modal) {
					if (modal.onlyModalSuccess && resultData.success === true) {
						modal.modal.openModal(
							resultData.success ? 'Success' : 'Error',
							resultData.message,
							resultData.success ? 'success' : 'error'
						);
					} else if (modal.onlyModalError && resultData.success === false) {
						modal.modal.openModal(
							resultData.success ? 'Success' : 'Error',
							resultData.message,
							resultData.success ? 'success' : 'error'
						);
						if (modal?.goBackOnError && modal?.navigation) {
							modal.navigation(-1);
						}
					} else if (!modal.onlyModalError && !modal.onlyModalSuccess) {
						modal.modal.openModal(
							resultData.success ? 'Success' : 'Error',
							resultData.message,
							resultData.success ? 'success' : 'error'
						);
					}
					if (modal.reloadSuccess && resultData.success) {
						window.location.reload();
					}
				}

				if (resultData) {
					if (resultData.reload) {
						window.location.reload();
					}
				}
			}

			if (result?.headers && result?.headers['x-csrf-token']) {
				let csrfResultData = {
					...resultData,
					csrfToken: result?.headers['x-csrf-token'],
				};
				return { data: csrfResultData };
			}

			return { data: resultData };
		} catch (axiosError) {
			let err = axiosError as AxiosError;
			return {
				error: { status: err.response?.status, data: err.response?.data },
			};
		}
	};

export const api = createApi({
	reducerPath: 'api',
	baseQuery: axiosBaseQuery({
		baseUrl: window.location.origin + '/api/', //process.env.REACT_APP_BACKEND_URL + '/'
	}),
	tagTypes: ['User', 'Taps'],
	endpoints: (builder) => ({
		// Get user
		getUser: builder.query<any, void>({
			query: () => ({ url: 'auth/getUserFromAuth', method: 'post' }),
			providesTags: ['User'],
		}),
		getUserForce: builder.mutation<any, void>({
			query: () => ({ url: 'auth/getUserFromAuth', method: 'post' }),
			invalidatesTags: ['User'],
		}),
		loginPhoneRequestCode: builder.mutation<
			any,
			{ modal: any; countryCode: string; phone: string }
		>({
			query: (data) => ({
				url: 'auth/loginPhoneRequestCode',
				method: 'post',
				data: data,
				modal: { modal: data.modal, reloadSuccess: true },
			}),
			invalidatesTags: ['User'],
		}),
		login: builder.mutation<any, any>({
			query: (data) => ({
				url: 'auth/login',
				method: 'post',
				data: data,
				modal: { modal: data.modal, reloadSuccess: true },
			}),
			invalidatesTags: ['User'],
		}),
		loginToDevice: builder.mutation<any, any>({
			query: ({ token, modal, deviceId }) => ({
				url: 'tapauth/allowLoginToDevice',
				method: 'post',
				data: { token, deviceId },
				modal: { modal: modal },
			}),
			invalidatesTags: ['User'],
		}),
		verifyJoinToken: builder.mutation<any, any>({
			query: ({ token, modal }) => ({
				url: 'auth/getJoinTokenDetails',
				method: 'post',
				data: { token },
				modal: { modal: modal, onlyModalError: true },
			}),
			invalidatesTags: ['User'],
		}),
		smsLogin: builder.mutation<any, any>({
			query: ({
				phone,
				countryPhoneCode,
				token,
				isRegister,
				modal,
				joinPlaceToken,
			}) => ({
				url: 'auth/login',
				method: 'post',
				data: {
					phone,
					token,
					isRegister: false,
					modal,
					joinPlaceToken,
					mode: process.env.DESKTOP_MODE,
				},
			}),
			invalidatesTags: ['User'],
		}),
		sendResetPasswordEmail: builder.mutation<any, any>({
			query: ({ email, modal }) => ({
				url: 'authmanage/sendResetPasswordEmail',
				method: 'post',
				data: { email },
				modal: { modal: modal },
			}),
			invalidatesTags: ['User'],
		}),
		resetPassword: builder.mutation<any, any>({
			query: ({ email, password, resetPasswordToken, modal }) => ({
				url: 'authmanage/confirmResetPassword',
				method: 'post',
				data: { email, password, resetPasswordToken },
				modal: { modal },
			}),
			invalidatesTags: ['User'],
		}),
		invalidate: builder.mutation<any, void>({
			query: () => ({ url: '../invalidate', method: 'post', data: {} }),
			invalidatesTags: ['User'],
		}),
		logout: builder.mutation<any, any>({
			query: ({ modal }) => ({
				url: 'logout',
				method: 'post',
				data: {},
				modal: { modal, reloadSuccess: true },
			}),
			invalidatesTags: ['User'],
		}),
		confirmEmail: builder.mutation<any, any>({
			query: ({ modal, code, token }) => ({
				url: 'auth/confirmEmailCode',
				method: 'post',
				data: { code, token },
				modal: { modal, reloadSuccess: true },
			}),
			invalidatesTags: ['User'],
		}),
		sendEmailAgain: builder.mutation<any, any>({
			query: ({ modal }) => ({
				url: 'auth/sendConfirmMessage',
				method: 'post',
				data: { typeToGet: 'EMAIL' },
				modal: { modal },
			}),
		}),
		// PHONE
		sendConfirmPhone: builder.mutation<any, any>({
			query: ({ modal, onlyModalError }) => ({
				url: 'auth/sendConfirmMessage',
				method: 'post',
				data: { typeToGet: 'PHONE' },
				modal: { modal, onlyModalError },
			}),
			invalidatesTags: ['User'],
		}),
		confirmPhone: builder.mutation<any, any>({
			query: ({ modal, token, code }) => ({
				url: 'auth/confirmPhoneCode',
				method: 'post',
				data: { typeToGet: 'PHONE', token, code },
				modal: { modal },
			}),
			invalidatesTags: ['User'],
		}),
		// PROFILE
		getProfile: builder.mutation<any, GeneralRequest>({
			query: ({ modal }) => ({
				url: 'users/getProfile/',
				method: 'post',
				data: {},
				modal: { modal, onlyModalError: true },
			}),
			invalidatesTags: ['User'],
		}),
		manageProfile: builder.mutation<any, profileQuery>({
			query: ({ modal, ...data }) => ({
				url: 'users/manageProfile/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
			invalidatesTags: ['User'],
		}),
		// EVENTS
		getEvents: builder.mutation<any, void>({
			query: () => ({
				url: 'events/getEvents/',
				method: 'get',
				params: { withImages: true, withKeys: true },
			}),
		}),
		getEvent: builder.mutation<any, GeneralRequestWithId>({
			query: ({ modal, id }) => ({
				url: 'events/getEvent/' + id,
				method: 'get',
				params: {},
				modal: { modal, onlyModalError: true },
			}),
		}),
		deleteEvent: builder.mutation<any, any>({
			query: ({ modal, id, csrfToken }) => ({
				url: 'events/deleteEvent/',
				method: 'post',
				data: { id, csrfToken },
				modal: { modal },
			}),
		}),
		manageEvent: builder.mutation<any, manageEventQuery>({
			query: ({ modal, id, ...data }) => ({
				url: 'events/manageEvent/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		// DETAILED PLACE
		saveDetailedPlaceInfo: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'detailedPlace/saveDetailedPlace/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		saveDetailedPlaceLinks: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'detailedPlace/saveLinks/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		saveDetailedPlaceLocation: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'detailedPlace/saveLocation/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		saveDetailedPlaceImages: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'detailedPlace/saveImages/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		//Place //! (uses users in place)
		getPlace: builder.mutation<any, GeneralRequestWithId>({
			query: ({ modal, id }) => ({
				url: 'places/getPlaceCompanyAdmin/',
				method: 'post',
				data: {},
				modal: { modal, onlyModalError: true },
			}),
		}),
		savePlace: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'places/managePlaceCompanyAdmin/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		getPaymentDetails: builder.mutation<any, any>({
			query: ({ modal }) => ({
				url: 'paymentDetails/paymentDetails',
				method: 'get',
				data: {},
				modal: { modal, onlyModalError: true },
			}),
		}),
		savePaymentDetails: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'paymentDetails/savePaymentDetails/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		// Place users
		getPlaceUser: builder.mutation<GetPlaceUserResponse, GeneralRequestWithId>({
			query: ({ modal, id }) => ({
				url: 'placeusers/getPlaceUser/' + id,
				method: 'get',
				data: {},
				modal: { modal, onlyModalError: true },
			}),
		}),
		savePlaceUser: builder.mutation<GeneralResponse, any>({
			query: ({ modal, id, ...data }) => ({
				url: 'placeusers/manageUserInPlace/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		removePlaceUser: builder.mutation<GeneralResponse, any>({
			query: ({ modal, id, csrfToken }) => ({
				url: 'placeusers/manageUserInPlace/' + id,
				method: 'post',
				data: { isRemove: true, csrfToken },
				modal: { modal },
			}),
		}),
		createCustomer: builder.mutation<any, any>({
			query: ({ modal, name }) => ({
				url: 'payments/createCustomer/',
				method: 'post',
				data: { name },
				modal: { modal },
			}),
		}),
		// Place for place users
		loginToPlace: builder.mutation<any, any>({
			query: ({ modal, placeId }) => ({
				url: 'placeusers/changeplaceSession',
				method: 'post',
				data: { placeId },
				modal: { modal, reloadSuccess: true },
			}),
			invalidatesTags: ['User'],
		}),
		// joinPlace: builder.mutation<any, any>({
		//   query: ({ modal, token }) => ({ url: 'placeusers/joinplace', method: 'post', data: {  token } , modal: {modal, } }),
		//   invalidatesTags: ['User']
		// }),
		removeUserFromPlace: builder.mutation<any, any>({
			query: ({ modal, email, placeId, token }) => ({
				url: 'placeusers/removeFromPlaceWithToken',
				method: 'post',
				data: { email, placeId, token },
				modal: { modal },
			}),
			invalidatesTags: ['User'],
		}),
		getPlaceNames: builder.mutation<
			{
				items: any;
				success: boolean;
			},
			any
		>({
			query: (getPlaceUsers) => ({
				url: 'placeusers/getUserPlaceNames',
				method: 'post',
				data: { getPlaceUsers: getPlaceUsers },
			}),
			// invalidatesTags: ['User']
		}),
		getJoinDetails: builder.mutation<any, any>({
			query: ({ token, modal }) => ({
				url: 'places/getInviteLinkData/' + token,
				method: 'post',
				modal: { modal, onlyModalError: true },
			}),
			invalidatesTags: ['User'],
		}),
		// getInvites: builder.mutation<any, void>({
		//   query: () => ({ url: 'placeusers/getInvites', method: 'post'}),
		// }),
		// TAPS

		getTapIds: builder.query<any, any>({
			query: ({ modal, test }) => ({
				url: 'taps/getTapIds/',
				method: 'get',
				modal: { modal, onlyModalError: true },
			}),
			providesTags: ['Taps'],
		}),
		getTap: builder.mutation<any, any>({
			query: ({ id, modal }) => ({
				url: 'taps/getTap/' + id,
				method: 'get',
				modal: { modal, onlyModalError: true },
			}),
		}),
		manageTap: builder.mutation<any, any>({
			query: (data) => ({
				url: 'taps/manageTaps/' + data.id,
				method: 'post',
				data: { ...data },
				modal: { modal: data.modal },
			}),
			invalidatesTags: ['Taps'],
		}),
		getAccessLog: builder.mutation<any, any>({
			query: ({ id, modal }) => ({
				url: 'logs/getAccessLogs/' + id,
				method: 'post',
				data: { id },
				modal: { modal, onlyModalError: true },
			}),
		}),
		getDrink: builder.mutation<any, any>({
			query: ({ id, modal }) => ({
				url: 'drinks/getDrink/' + id,
				method: 'post',
				data: { id },
				modal: { modal, onlyModalError: true },
			}),
		}),
		getKeg: builder.mutation<any, any>({
			query: ({ id, modal }) => ({
				url: 'kegs/getKegs/' + id,
				method: 'post',
				data: { id },
				// modal: { modal, onlyModalError: true },
			}),
		}),
		manageKeg: builder.mutation<any, TapQuery>({
			query: (data) => ({
				url: 'kegs/manageKeg/' + data.id,
				method: 'post',
				data: { ...data },
				modal: { modal: data.modal },
			}),
		}),
		getKegChange: builder.mutation<any, any>({
			query: ({ id, modal, kegId }) => ({
				url: 'kegs/getKegChanges/' + id,
				method: 'post',
				data: { id, kegId },
				// modal: { modal, onlyModalError: true },
			}),
		}),
		manageKegChange: builder.mutation<any, TapQuery>({
			query: (data) => ({
				url: 'kegs/manageKegChanges/' + data.id,
				method: 'post',
				data: { ...data },
				modal: { modal: data.modal },
			}),
		}),
		getKegByDrink: builder.mutation<any, any>({
			query: ({ id, modal }) => ({
				url: 'kegs/getKegByDrink/' + id,
				method: 'post',
				data: { id },
				// modal: { modal, onlyModalError: false },
			}),
		}),
		manageDrink: builder.mutation<any, any>({
			query: (data) => ({
				url: 'drinks/manageDrink/' + data.id,
				method: 'post',
				data: { ...data },
				modal: { modal: data.modal },
			}),
		}),
		getCanCreate: builder.mutation<any, { type: string }>({
			query: ({ type }) => ({
				url: 'auth/canCreate',
				method: 'get',
				data: { type },
			}),
		}),
		// FLOWS
		flowmeters: builder.mutation<any, void>({
			query: () => ({ url: 'flowmeters/flows', method: 'get' }),
		}),
		flowmeter: builder.mutation<any, { id; modal }>({
			query: ({ id, modal }) => ({
				url: 'flowmeters/flow/' + id,
				method: 'get',
				modal: { modal, onlyModalError: true },
			}),
		}),
		manageFlowmeter: builder.mutation<any, any>({
			query: ({ modal, id, ...data }) => ({
				url: 'flowmeters/manageFlow/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		manageUserByStaff: builder.mutation<
			any,
			{ phone; countryCode; amount; modal; hours; minutes; removePermission }
		>({
			query: ({
				removePermission,
				phone,
				countryCode,
				amount,
				modal,
				hours,
				minutes,
			}) => ({
				url: 'payments/manageUserByStaff',
				method: 'post',
				data: { phone, countryCode, amount, minutes, removePermission },
				modal: { modal, onlyModalError: true },
			}),
		}),
		confirmNumber: builder.mutation<any, { phone; countryCode; modal }>({
			query: ({ phone, countryCode, modal }) => ({
				url: 'auth/sendMessageToPhone',
				method: 'post',
				data: { phone, countryCode },
				modal: { modal, onlyModalError: true },
			}),
		}),
		flow: builder.mutation<any, { id; modal }>({
			query: ({ id, modal }) => ({
				url: 'flows/flow/' + id,
				method: 'get',
				modal: { modal, onlyModalError: true },
			}),
		}),
		manageFlow: builder.mutation<any, any>({
			query: ({ modal, id, ...data }) => ({
				url: 'flows/manageFlow/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		coupon: builder.mutation<any, { id; modal }>({
			query: ({ id, modal }) => ({
				url: 'coupons/coupons/' + id,
				method: 'get',
				modal: { modal, onlyModalError: true },
			}),
		}),
		manageCoupon: builder.mutation<any, any>({
			query: ({ modal, id, ...data }) => ({
				url: 'coupons/manageCoupon/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		discountTime: builder.mutation<any, { id; modal }>({
			query: ({ id, modal }) => ({
				url: 'discounts/discounttimes/' + id,
				method: 'get',
				modal: { modal, onlyModalError: true },
			}),
		}),
		manageDiscountTime: builder.mutation<any, any>({
			query: ({ modal, id, ...data }) => ({
				url: 'discounts/manageDiscountTime/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		getDevice: builder.mutation<any, { id; modal }>({
			query: ({ id, modal }) => ({
				url: 'devices/devices/' + id,
				method: 'get',
				modal: { modal, onlyModalError: true },
			}),
		}),
		manageDevice: builder.mutation<any, any>({
			query: ({ modal, id, ...data }) => ({
				url: 'devices/manageDevice/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		manageCompanyDevice: builder.mutation<any, any>({
			query: ({ modal, id, ...data }) => ({
				url: 'devices/manageCompanyDevice/' + id,
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		confirmRegisterEmail: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'registerPlace/confirmEmail/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		confirmRegister: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'registerPlace/confirmRegister/',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		getGoogleFromPlace: builder.mutation<any, void>({
			query: () => ({
				url: 'registerPlace/getGoogleFromPlace/',
				method: 'post',
				data: {},
			}),
		}),
		onSendPush: builder.mutation<any, { title; message; code }>({
			query: ({ title, message, code }) => ({
				url: 'marketing/sendPushNotifications/',
				method: 'post',
				data: { title, message, secureCode: code },
			}),
		}),
		getRegisteredPlace: builder.mutation<any, { id }>({
			query: ({ id }) => ({
				url: 'registerPlace/registeredplaces/' + id,
				method: 'post',
				data: { id },
			}),
		}),
		createRegisteredPlace: builder.mutation<any, any>({
			query: ({ modal, ...data }) => ({
				url: 'registerPlace/confirmregisteredplace',
				method: 'post',
				data: { ...data },
				modal: { modal },
			}),
		}),
		getIsAddCreditAllowed: builder.mutation<any, any>({
			query: ({ modal }) => ({
				url: 'places/getIsAddCreditAllowed',
				method: 'post',
				data: {},
				modal: { modal, onlyModalError: true },
			}),
		}),
		getUserReceiptCompany: builder.mutation<any, any>({
			query: ({ modal, id }) => ({
				url: 'logs/getUserReceiptCompany/' + id,
				method: 'post',
				data: {},
				modal: { modal },
			}),
		}),
		sendLoginEmail: builder.mutation<any, any>({
			query: ({ email, modal }) => ({
				url: 'auth/sendLoginEmail', //+ '/' + code,
				method: 'post',
				data: { email },
				modal: { modal },
			}),
		}),
	}),
});

export const {
	useGetPaymentDetailsMutation,
	useSavePaymentDetailsMutation,
	useDiscountTimeMutation,
	useManageDiscountTimeMutation,
	useGetUserReceiptCompanyMutation,
	useCouponMutation,
	useManageCouponMutation,
	useGetIsAddCreditAllowedMutation,
	useConfirmNumberMutation,
	useCreateRegisteredPlaceMutation,
	useGetRegisteredPlaceMutation,
	useOnSendPushMutation,
	useGetGoogleFromPlaceMutation,
	useConfirmRegisterEmailMutation,
	useConfirmRegisterMutation,
	useGetDeviceMutation,
	useManageCompanyDeviceMutation,
	useManageDeviceMutation,
	useGetTapIdsQuery,
	useManageUserByStaffMutation,
	useManageFlowMutation,
	useFlowMutation,
	useFlowmetersMutation,
	useFlowmeterMutation,
	useManageFlowmeterMutation,
	useGetDrinkMutation,
	useGetAccessLogMutation,
	useGetCanCreateMutation,
	useSendResetPasswordEmailMutation,
	useLoginToDeviceMutation,
	useVerifyJoinTokenMutation,
	useLoginPhoneRequestCodeMutation,
	useDeleteEventMutation,
	useManageDrinkMutation,
	useGetTapMutation,
	useManageTapMutation,
	useSavePlaceMutation,
	useGetUserForceMutation,
	useInvalidateMutation,
	useCreateCustomerMutation,
	useRemovePlaceUserMutation,
	useGetEventsMutation,
	useSavePlaceUserMutation,
	useGetPlaceUserMutation,
	useSaveDetailedPlaceInfoMutation,
	useGetPlaceMutation,
	useSaveDetailedPlaceLinksMutation,
	useSaveDetailedPlaceLocationMutation,
	useSaveDetailedPlaceImagesMutation,
	useGetUserQuery,
	useGetEventMutation,
	useManageProfileMutation,
	useManageEventMutation,
	useConfirmPhoneMutation,
	useSendConfirmPhoneMutation,
	useGetProfileMutation,
	useLoginToPlaceMutation,
	useGetPlaceNamesMutation,
	useRemoveUserFromPlaceMutation,
	useSendEmailAgainMutation,
	useConfirmEmailMutation,
	useGetJoinDetailsMutation,
	useLoginMutation,
	useLogoutMutation,
	useResetPasswordMutation,
	useSendLoginEmailMutation,
	useGetKegMutation,
	useManageKegMutation,
	useGetKegByDrinkMutation,
	useManageKegChangeMutation,
	useGetKegChangeMutation,
} = api;
