import './style.scss';

export const UnderInputLink = ({ onClick, text }) => {
	return (
		<span
			onClick={onClick}
			style={{
				display: 'flex',
				marginTop: -32,
				color: 'white',
				width: 200,
				float: 'left',
				justifyContent: 'flex-start',
				cursor: 'pointer',
			}}
		>
			{text}
		</span>
	);
};
