import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';

import { useTheme, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';

const MainDropdownChip = ({
	label,
	id,
	items,
	labelId,
	options,
	value,
	selectedItems,
	onChange,
	optionsValueKey,
	optionsTextKey,
	isWithLink,
	link,
}) => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			formControl: {
				// marginTop: theme.spacing(1),
				// minWidth: 120,
				// maxWidth: 300,
				width: '100%',
			},
			chips: {
				display: 'flex',
				flexWrap: 'wrap',
			},
			chip: {
				margin: 2,
			},
			noLabel: {
				marginTop: theme.spacing(3),
			},
		})
	);

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	function getStyles(name: string, itemName: string[], theme: Theme) {
		return {
			fontWeight:
				itemName.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	}

	const classes = useStyles();
	const theme = useTheme();
	const [itemName, setItemName] = React.useState<string[]>([]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		onChange(event.target.value as string[]);
	};

	// const handleChangeMultiple = (
	// 	event: React.ChangeEvent<{ value: unknown }>
	// ) => {
	// 	const { options } = event.target as HTMLSelectElement;
	// 	const value: string[] = [];
	// 	for (let i = 0, l = options.length; i < l; i += 1) {
	// 		if (options[i].selected) {
	// 			value.push(options[i].value);
	// 		}
	// 	}
	// 	setPersonName(value);
	// };

	return (
		<Grid item xs={12} md={12}>
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel id="demo-mutiple-chip-label">{label}</InputLabel>
				<Select
					labelId={labelId}
					id={id}
					multiple
					style={{ width: '100%' }}
					value={selectedItems}
					//@ts-expect-error
					onChange={handleChange}
					input={<Input id="select-multiple-chip" />}
					renderValue={(selected) => (
						<div className={classes.chips}>
							{(selected as string[]).map((value) => (
								<Chip key={value} label={value} className={classes.chip} />
							))}
						</div>
					)}
					MenuProps={MenuProps}
				>
					{items.map((item) => (
						<MenuItem
							key={item}
							value={item}
							style={getStyles(item, itemName, theme)}
						>
							{item}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Grid>
	);
};

export default MainDropdownChip;
