import FileUploadMany from '../uploads/FileUploadMany';
import { Controller } from 'react-hook-form';

// const getNewImage = (imageToSave) => {
// 	let newImage = {
// 		image: '',
// 		type: '',
// 	};

// 	if (imageToSave.file) {
// 		newImage['image'] = imageToSave.file;
// 		newImage['type'] = imageToSave.type;
// 	} else {
// 		newImage.image = imageToSave.image;
// 		newImage.type = imageToSave.type;
// 	}
// 	return newImage;
// };

interface MainImageLinksControllerInterface {
	control: any;
	initialFiles: any;
	text: any;
	identifier: any;
	compression: any;
	maxSize: any;
	setValue: any;
	filesLimit: any;
}

const MainImageLinksController = ({
	control,
	initialFiles,
	text,
	identifier,
	compression,
	maxSize,
	setValue,
	filesLimit = 1,
}: MainImageLinksControllerInterface) => {
	return (
		<Controller
			control={control}
			name={identifier}
			defaultValue={null}
			render={({
				field: { onChange, onBlur, value, ref },
				fieldState: { isTouched, isDirty, error },
				formState: { errors },
			}) => {
				return (
					<FileUploadMany
						filesLimit={filesLimit}
						text={text}
						maxSize={maxSize}
						compression={compression}
						handleFiles={(newImages: any) => {
							if (newImages && newImages.length > 0) {
								if (filesLimit === 1) {
									let imageToSave = newImages[0];
									onChange(imageToSave);
									setValue(identifier, imageToSave, { shouldDirty: true });
								} else {
									let imagesArray = [];
									for (let i = 0; i < newImages.length; i++) {
										let imageToSave = newImages[i];
										imagesArray.push(imageToSave);
									}
									onChange(imagesArray);
									setValue(identifier, imagesArray, { shouldDirty: true });
								}
							} else {
								onChange(null);
							}
						}}
						initialFiles={
							initialFiles && filesLimit === 1 ? [initialFiles] : initialFiles
						} //event['image'] ? [event['image']] : []}
					/>
				);
			}}
		/>
	);
};

export default MainImageLinksController;

/**
  
 handleFiles={(newImages: any) => {
							console.log('newImages', newImages);
							if (newImages && newImages.length > 0) {
								if (filesLimit === 1) {
									let imageToSave = newImages[0];
									let newImage = getNewImage(imageToSave);
									if (newImage.image) {
										console.log('newImage', newImage);
										onChange(newImage);
										setValue(identifier, newImage, { shouldDirty: true });
									}
								} else {
									let imagesArray = [];
									for (let i = 0; i < newImages.length; i++) {
										let imageToSave = newImages[i];
										let newImage = getNewImage(imageToSave);
										console.log('newImage', newImage);
										if (newImage.image) {
											imagesArray.push(newImage);
										}
									}
									onChange(imagesArray);
									setValue(identifier, imagesArray, { shouldDirty: true });
								}
							} else {
								onChange(null);
							}
						}}
  
 */
