import React from 'react';
import { useGetGoogleFromPlaceMutation } from '../../store/api/api';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MainButton from '../../components/buttons/MainButton';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import Header from './../../components/texts/Header';
import { postOnFacebook } from './../../api/miscData';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const MenuList = ({ user }) => {
	const [getGoogleFromPlace] = useGetGoogleFromPlaceMutation();
	const [selectedPlace, setSelectedPlace] = React.useState(null);
	const [cards, setCards] = React.useState([]);
	const navigate = useHistoryNav();
	const [oneShown, setOneShown] = React.useState(false);

	React.useEffect(() => {
		setupCards();
	}, []);

	console.log("user: ", user.systemRole === "Admin");

	const actions = [
		{
			name: 'Manage Google',
			onClick: () => {
				navigate('/admin/google');
			},
			header: 'Link place to Google',
			description:
				'You can link this place to google to retrieve information for your convenience.',
			showCard: async () => {
				let result = await getGoogleFromPlace().unwrap();
				if (result && result.googlePlace) {
					return false;
				} else {
					return true;
				}
			},
		},
		{
			name: 'Post to Facebook',
			onClick: async () => {
				await postOnFacebook();
			},
			textButton: 'POST',
			header: 'Post place to Facebook',
			description:
				'It will be shown on the Barhopp Facebook page. ',
			showCard: async () => {
				return user.systemRole === "Admin";
			},
		},
	];

	const setupCards = async () => {
		for (let i = 0; i < actions.length; i++) {
			let shouldShow = await actions[i].showCard();
			if (shouldShow) {
				setCards([...cards, actions[i]]);
			}
		}
	};

	return (
		<Stack spacing={2} style={oneShown ? { marginBottom: 24 } : {}}>
			{oneShown ? <Header text={'Things to do'} /> : null}

			{cards.map((card, index) => {

				if(!oneShown) {
					setOneShown(true);
				}

				return (
					<Item
						style={{
							padding: 28,
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginRight: 23 }}>
								<HighlightOffIcon
									style={{
										fontSize: 40,
										color: '#FF0000',
									}}
								/>
							</div>
							<div style={{ textAlign: 'start' }}>
								<Typography variant={'h2'}>{card.header}</Typography>
								<Typography style={{ marginTop: 7 }}>
									{card.description}
								</Typography>
							</div>
						</div>
						<div>
							<MainButton
								onClick={() => {
									if (card.onClick) {
										card.onClick();
									}
								}}
								// disabled={false}
								text={card.textButton ? card.textButton : 'Manage'}
								color={'primary'}
							/>
						</div>
					</Item>
				);
			})}
		</Stack>
	);

	return <div>{selectedPlace && selectedPlace.name ? <div></div> : null}</div>;
};

export default MenuList;
