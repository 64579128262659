import clsx from 'clsx';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Grid, GridSize } from '@mui/material';
import { formControlStyles } from '../../styles/materialStyles';
import { isNumberWithLetters } from '../../business/validationHelper';

interface OptionsProps {
	min?: number;
	max?: number;
}

interface InputMainProps {
	label: string;
	identifier: string;
	value: string | number;
	onChange: Function;
	maxlength?: number;
	md?: GridSize;
	type?: string;
	endText?: string;
	helperText?: string;
	helperTextDescribeBy?: string;
	options?: OptionsProps;
	rows?: string;
	big?: boolean;
	disabled?: boolean;
	isPassword?: boolean;
	four?: boolean;
	placeholder?: string;
	error?: boolean;
	placeDecimals?: number;
	hideInput?: boolean;
}

const InputMain = ({
	label,
	identifier,
	value,
	onChange,
	maxlength,
	options,
	type,
	//helper text
	helperText,
	helperTextDescribeBy,
	//endtext
	endText,
	rows,
	big,
	md,
	disabled,
	isPassword,
	placeholder,
	error,
	four,
	hideInput,
	placeDecimals,
}: InputMainProps) => {
	const numberValidator = (value) => {
		let endsWithDot = value.endsWith('.');
		let valueNumber = parseInt(value);
		if (options) {
			const { min, max } = options;
			if (valueNumber < min) {
				valueNumber = min;
			} else if (valueNumber > max) {
				return false;
				// valueNumber = max;
			}
			if (!valueNumber) {
				valueNumber = 0;
			}
		}
		if (!valueNumber) {
			valueNumber = 0;
		}

		let valueString = valueNumber.toString();

		// if (endsWithDot) {
		// 	valueString = valueNumber + '.';
		// }

		return valueString;
	};

	function setValue(value) {
		if (type) {
			if (type === 'number') {
				value = numberValidator(value);
			} else if (type === 'price') {
				value = value.replace(/\,/, '.');
				value = parseFloat(value);
				if (placeDecimals) {
					value = value.toFixed(placeDecimals);
				}
			} else if (type === 'comma') {
				value = value.toString().replace(',', '.');
				const regex = /^\d+(\.\d{1,2})?$/;
				if (regex.test(value)) {
					value = value.toString().replace(/\./, ',');
					return value;
				} else {
					return '';
				}
			}
		}

		if (!value) {
			return '';
		} else {
			return value;
		}
	}

	function onChangeInput(e) {
		let newValue = setValue(e.target.value);
		if (newValue !== false) {
			onChange(identifier, newValue);
		}
	}
	// md={big ? 12 : four ? 4 : 6}
	return (
		<Grid
			style={hideInput ? { display: 'none' } : {}}
			item
			xs={12}
			spacing={3}
			md={md}
		>
			<FormControl
				fullWidth={true}
				// style={{ width: '100%' }}
				className={clsx(formControlStyles().root)}
			>
				<TextField
					// id="standard-adornment-weight"
					// maxLength={maxlength ? maxlength : null}
					value={value}
					style={{ backgroundColor: 'white', width: '100%' }}
					variant={'outlined'}
					label={label}
					placeholder={placeholder ? placeholder : ''}
					disabled={disabled ? true : false}
					type={isPassword ? 'password' : 'text'}
					onChange={onChangeInput}
					rows={rows ? rows : ''}
					error={error ? error : false}
					multiline={rows ? true : false}
					InputProps={
						endText
							? {
									endAdornment: (
										<InputAdornment position="start">{endText}</InputAdornment>
									),
							  }
							: null
					}
					// endAdornment={
					//     <InputAdornment position="end">
					//         {endText}
					//     </InputAdornment>
					// }
					aria-describedby={helperTextDescribeBy ? helperTextDescribeBy : ''}
					inputProps={{
						'aria-label': label,
						maxLength: maxlength,
					}}
				/>
				{helperText ? (
					<FormHelperText id={helperTextDescribeBy}>
						{helperText}
					</FormHelperText>
				) : null}
			</FormControl>
		</Grid>
	);
};

// {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
export default InputMain;
