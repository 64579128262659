import React, { useContext } from 'react';
import MainContainer from '../../components/containers/MainContainer';
import { Context } from '../../store/modalContext/store';
import MainSwitchController from '../../components/switch/MainSwitchController';
import PanelContainer from '../../components/containers/PanelContainer';
import MainInputController from '../../components/inputs/MainInputController';
import MainDropdownController from '../../components/dropdowns/MainDropdownController';
import ButtonGroup from '../../components/buttons/ButtonGroup';
import MainButton from '../../components/buttons/MainButton';
import { getDetails } from '../../api/placeInfoData';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import FileUploadMany from '../../components/uploads/FileUploadMany';
// import { barTypes } from '../../data/data';
import barTypes from '@toriosoftware/torioshared/dist/data/barTypes';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import BarAttributesController from './BarAttributesController';
import FormContainer from '../../components/containers/FormContainer';
import {
	detailsSchema,
	detailsInitalized,
	detailsTypes,
	locationSchema,
	locationTypes,
	locationInitialized,
	linksSchema,
	linksInitalized,
	linksTypes,
	imagesInitialized,
	imagesTypes,
	imagesSchema,
} from '@toriosoftware/torioshared/dist/detailedPlace';
import StringDropdown from '../../components/dropdowns/StringDropdown';
import SubHeader from '../../components/texts/SubHeader';
import PageContainer from '../../components/containers/PageContainer';
import {
	useSaveDetailedPlaceImagesMutation,
	useSaveDetailedPlaceInfoMutation,
	useSaveDetailedPlaceLinksMutation,
	useSaveDetailedPlaceLocationMutation,
} from '../../store/api/api';
import MainImageController from '../../components/images/MainImageController';
import MainImageLinksController from '../../components/images/MainImageLinksController';

import { APP_TYPES, IMAGE_TYPES } from '@toriosoftware/torioshared/dist/config';
import BadgeInputController from '../../components/inputs/BadgeInputController';

const DetailedPlace = ({ user }: any) => {
	//@ts-ignore
	const [state] = useContext(Context);
	const [allFeatures, setAllFeatures] = React.useState([]);
	// const [imagesToSave, setImages] = React.useState([]);
	// const [mainImageNumber, setMainImageNumber] = React.useState('1');
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [csrfTokenMain, setCSRFToken] = React.useState('');
	const history = useHistoryNav();

	const [saveDetailedPlaceInfo, { isLoading: isLoadingDetails }] =
		useSaveDetailedPlaceInfoMutation();
	const [saveDetailedPlaceLinks, { isLoading: isLoadingLinks }] =
		useSaveDetailedPlaceLinksMutation();
	const [saveDetailedPlaceLocation, { isLoading: isLoadingLocation }] =
		useSaveDetailedPlaceLocationMutation();
	const [saveDetailedPlaceImages, { isLoading: isLoadingImages }] =
		useSaveDetailedPlaceImagesMutation();

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
	} = useForm<detailsTypes>({
		defaultValues: detailsInitalized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(detailsSchema),
	});

	const {
		formState: { isDirty: isDirtyLinks },
		handleSubmit: handleSubmitLinks,
		control: controlLinks,
		reset: resetLinks,
	} = useForm<linksTypes>({
		defaultValues: linksInitalized,
		mode: 'onBlur',
		resolver: joiResolver(linksSchema),
	});

	const {
		formState: { isDirty: isDirtyLocation },
		handleSubmit: handleSubmitLocation,
		control: controlLocation,
		reset: resetLocation,
	} = useForm<locationTypes>({
		defaultValues: locationInitialized,
		mode: 'onBlur',
		resolver: joiResolver(locationSchema),
	});

	const {
		formState: { isDirty: isDirtyImages },
		handleSubmit: handleSubmitImages,
		control: controlImages,
		watch: watchImages,
		reset: resetImages,
		setValue: setValueImages,
	} = useForm<imagesTypes>({
		// defaultValues: imagesInitialized,
		mode: 'onBlur',
		// resolver: joiResolver(imagesSchema),
	});

	const isActive = watch('isActive'); // you can supply default value as second argument
	const watchIsFood = watch('isFoodService'); // you can supply default value as second argument
	const translation = watch('translation'); // you can supply default value as second argument

	const imagesToSave = watchImages('imageLinks');
	const mainImageLink = watchImages('mainImageLink');
	const verticalAlign = watchImages('verticalAlign');

	React.useEffect(() => {
		getDetailsEffect();
	}, []);

	const getDetailsEffect = async () => {
		let { details, allFeatures, links, location, images, csrfToken } =
			await getDetails(history, state);
		setAllFeatures(allFeatures);
		reset(details);
		resetLinks(links);
		resetLocation(location);
		resetImages(images);
		setIsLoaded(true);
		setCSRFToken(csrfToken);
	};

	return (
		<PageContainer header={'Place information'}>
			<FormContainer
				modal={state}
				handleSubmit={handleSubmit}
				isDirty={isDirty}
				onSubmitFunction={saveDetailedPlaceInfo}
				csrfToken={csrfTokenMain}
				isLoading={isLoadingDetails}
				linkAfterSuccess={''}
				isLoaded={isLoaded}
				title={'General information'}
				depth={0}
				history={history}
				hideRemove={true}
			>
				{!isActive || user.systemRole === 'Admin' ? (
					<MainSwitchController
						control={control}
						label={'Set Barhopp listing to active'}
						md={6}
						// value={info['isActive'] ? info['isActive'] : ''}
						identifier={'isActive'}
					/>
				) : null}

				<MainSwitchController
					control={control}
					label={'Place serves food'}
					md={6}
					identifier={'isFoodService'}
				/>

				<MainSwitchController
					control={control}
					label={'Hide time'}
					md={6}
					identifier={'hideTime'}
				/>

				<MainSwitchController
					control={control}
					label={'Hide rating'}
					md={6}
					identifier={'hideRating'}
				/>

				<MainSwitchController
					control={control}
					label={'No emails'}
					md={6}
					identifier={'noEmails'}
				/>

				{/* <MainSwitchController
					control={control}
					label={'Is dog friendly'}
					md={6}
					// value={info['isActive'] ? info['isActive'] : ''}
					identifier={'dogFriendly'}
				/> */}

				{/* <MainDropdownController
					label={'Translation'}
					helperText="Select the top most option at any point to edit both English and Icelandic version of texts"
					options={[
						{
							text: 'Fill out both English and Icelandic myself (Recommended)',
							value: 'NONE',
						},
						{
							text: 'Fill out only Icelandic (system will translate from Icelandic to English)',
							value: 'ISTOEN',
						},
						{
							text: 'Fill out only English (system will translate from English to Icelandic)',
							value: 'ENTOIS',
						},
					]}
					md={12}
					control={control}
					optionsValueKey={'value'}
					identifier={'translation'}
					optionsTextKey={'text'}
					id={'translation'}
				/> */}

				<MainInputController
					control={control}
					label={'Name'}
					type={'text'}
					identifier={'name'}
					md={12}
				/>

				{/* <MainInputController
					control={control}
					label={'Created'}
					type={'number'}
					identifier={'dateOpened'}
					md={6}
				/> */}

				<MainDropdownController
					label={'Place genre (that describes this place the most)'}
					options={barTypes}
					isString={true}
					control={control}
					optionsValueKey={'_id'}
					identifier={'genre'}
					optionsTextKey={'text'}
					id={'typeOfMainBar'}
					md={12}
					// isWithLink={true}
					// link={'/managediscounts'}
				/>

				{/* These features are only the english strings, when they are added to the backend, they are changed into a Feature type */}
				<BarAttributesController
					identifier={'features'}
					control={control}
					features={[]}
					// features={info['features'] ? info['features'] : []}
					allFeatures={allFeatures}
					isLoaded={isLoaded}
					// onChange={setFeatures}
				/>
				{/* 
				<BadgeInputController
					md={12}
					label={'Awards'}
					maxLength={50}
					identifier={'awards'}
					control={control}
				/> */}

				{translation !== 'ISTOEN' ? <SubHeader text={'English'} /> : null}

				<MainInputController
					control={control}
					label={'Why come here / what to try - short pitch (English)'}
					helperText={
						'Ideas: "Biggest place for X in Europe", "You must try our X", "We have over 100 different X", "Voted best X in Iceland"'
					}
					type={'text'}
					identifier={'oneLiner'}
					md={12}
				/>

				<MainInputController
					control={control}
					hideInput={translation === 'ISTOEN'}
					label={'Description (English) *'}
					type={'text'}
					identifier={'description'}
					rows={'3'}
					md={12}
				/>

				{watchIsFood ? (
					<MainInputController
						control={control}
						hideInput={translation === 'ISTOEN'}
						label={'Food description (English)'}
						type={'text'}
						identifier={'foodDescription'}
						md={12}
					/>
				) : null}

				{/* <MainInputController
					control={control}
					hideInput={translation === 'ISTOEN'}
					label={'Opening times (English)'}
					type={'text'}
					identifier={'openingTimes'}
					md={6}
				/> */}

				<MainInputController
					control={control}
					hideInput={translation === 'ISTOEN'}
					label={'Music genre (English)'}
					type={'text'}
					identifier={'musicgenre'}
					md={6}
				/>

				<MainInputController
					control={control}
					hideInput={translation === 'ISTOEN'}
					label={'Dress code (English)'}
					type={'text'}
					identifier={'dresscode'}
					md={6}
				/>

				{/* <MainInputController
					control={control}
					hideInput={translation === 'ISTOEN'}
					label={'Country (English)'}
					type={'text'}
					identifier={'country'}
					md={6}
				/> */}

				{translation !== 'ENTOIS' ? (
					<SubHeader text={'Icelandic (optional)'} />
				) : null}

				<MainInputController
					control={control}
					label={'Why come here / what to try - short pitch (Iceland)'}
					helperText={
						'Ideas: "Biggest place for X in Europe", "You must try our X", "We have over 100 different X", "Voted best X in Iceland"'
					}
					type={'text'}
					identifier={'oneLineris'}
					md={12}
				/>

				<MainInputController
					hideInput={translation === 'ENTOIS'}
					control={control}
					label={'Description (Icelandic)'}
					type={'text'}
					identifier={'descriptionis'}
					rows={'3'}
					md={12}
				/>

				{watchIsFood ? (
					<MainInputController
						control={control}
						hideInput={translation === 'ENTOIS'}
						label={'Food description (Icelandic)'}
						type={'text'}
						identifier={'foodDescriptionis'}
						md={12}
					/>
				) : null}

				{/* <MainInputController
					control={control}
					hideInput={translation === 'ENTOIS'}
					label={'Opening times (Icelandic)'}
					type={'text'}
					identifier={'openingTimesis'}
					md={6}
				/> */}

				<MainInputController
					control={control}
					label={'Music genre (Icelandic)'}
					hideInput={translation === 'ENTOIS'}
					type={'text'}
					identifier={'musicgenreis'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Dress code (Icelandic)'}
					hideInput={translation === 'ENTOIS'}
					type={'text'}
					identifier={'dresscodeis'}
					md={6}
				/>
				{/* <MainInputController
					control={control}
					label={'Country (Icelandic)'}
					hideInput={translation === 'ENTOIS'}
					type={'text'}
					identifier={'countryis'}
					md={6}
				/> */}
			</FormContainer>

			<FormContainer
				modal={state}
				isLoaded={isLoaded}
				role={user.role}
				handleSubmit={handleSubmitLinks}
				isLoading={isLoadingLinks}
				csrfToken={csrfTokenMain}
				isDirty={isDirtyLinks}
				onSubmitFunction={saveDetailedPlaceLinks}
				title={'Manage links'}
				depth={0}
				history={history}
				hideRemove={true}
				removeText={''}
			>
				<MainInputController
					control={controlLinks}
					label={'Facebook link'}
					type={'text'}
					identifier={'facebookLink'}
					md={6}
				/>

				<MainInputController
					control={controlLinks}
					label={'Instagram link'}
					type={'text'}
					identifier={'instagramLink'}
					md={6}
				/>

				<MainInputController
					control={controlLinks}
					label={'Website link'}
					type={'text'}
					identifier={'websiteLink'}
					md={6}
				/>

				<MainInputController
					control={controlLinks}
					label={'Drink menu link'}
					type={'text'}
					identifier={'drinkMenu'}
					md={6}
				/>

				{watchIsFood ? (
					<MainInputController
						control={controlLinks}
						label={'Food menu link'}
						type={'text'}
						identifier={'foodMenu'}
						md={6}
					/>
				) : null}
			</FormContainer>

			<FormContainer
				role={user.role}
				modal={state}
				handleSubmit={handleSubmitLocation}
				onSubmitFunction={saveDetailedPlaceLocation}
				isLoading={isLoadingLocation}
				isDirty={isDirtyLocation}
				linkAfterSuccess={''}
				isLoaded={isLoaded}
				title={'Manage location'}
				csrfToken={csrfTokenMain}
				depth={0}
				history={history}
				hideRemove={true}
			>
				<MainInputController
					control={controlLocation}
					label={'Address'}
					type={'text'}
					identifier={'address'}
					md={12}
				/>
				<MainInputController
					control={controlLocation}
					label={'Latitude'}
					type={'number'}
					// value={info['instagramLink'] ? info['instagramLink'] : ''}
					identifier={'lat'}
					md={6}
				/>
				<MainInputController
					control={controlLocation}
					label={'Longitude'}
					type={'number'}
					// value={info['websiteLink'] ? info['websiteLink'] : ''}
					identifier={'long'}
					md={6}
				/>
			</FormContainer>

			<FormContainer
				role={user.role}
				isLoading={isLoadingImages}
				modal={state}
				header={'Manage images'}
				handleSubmit={handleSubmitImages}
				showBack={false}
				csrfToken={csrfTokenMain}
				isDirty={isDirtyImages}
				onSubmitFunction={saveDetailedPlaceImages}
				isLoaded={isLoaded}
				history={history}
				hideRemove={true}
			>
				{/* <MainDropdownController
					label={
						'Profile picture photo number (left starts at 1 to 5 at the end)'
					}
					options={['1', '2', '3', '4', '5'].map((value, index) => {
						return { value: value, text: value };
					})}
					md={12}
					control={controlImages}
					optionsValueKey={'value'}
					identifier={'mainImageNumber'}
					optionsTextKey={'text'}
					id={'mainImageNumber'}
				/> */}
				<MainDropdownController
					label={'What part of the profile image should be shown preferably'}
					options={['Center', 'Bottom', 'Top'].map((value, index) => {
						return { value: value, text: value };
					})}
					md={12}
					control={controlImages}
					optionsValueKey={'value'}
					identifier={'verticalAlign'}
					optionsTextKey={'text'}
					id={'verticalAlign'}
				/>
				<MainImageLinksController
					control={controlImages}
					maxSize={1.2}
					text={'Profle photo (1 image)'}
					initialFiles={mainImageLink}
					identifier={'mainImageLink'}
					filesLimit={1}
					compression={1.2}
					// compression={IMAGE_TYPES['detailedPlace'].compression}
					setValue={setValueImages}
				/>
				<MainImageLinksController
					control={controlImages}
					maxSize={1.2}
					text={`Upload of your place (Up to ${IMAGE_TYPES['detailedPlace'].maxCount} images) `}
					initialFiles={imagesToSave}
					identifier={'imageLinks'}
					filesLimit={IMAGE_TYPES['detailedPlace'].maxCount}
					compression={1.2}
					// compression={IMAGE_TYPES['detailedPlace'].compression}
					setValue={setValueImages}
				/>
			</FormContainer>
		</PageContainer>
	);
};

export default DetailedPlace;
