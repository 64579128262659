import React from 'react';
import { Context } from '../../store/modalContext/store';

import MainInput from '../../components/inputs/MainInput';
import { onObjectChange, sendAPI } from '../../api/helper';
import MainButton from '../../components/buttons/MainButton';
import { check } from 'react-interaction';
import { getSurveyIcons } from '../../business/surveyHelper';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	smileContainer: {
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxWidth: 600,
		textAlign: 'center',
		margin: 'auto',
		marginBottom: 30,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
}));

const Survey = ({ accessId, priorFeedback }) => {
	//@ts-ignore
	const [state] = React.useContext(Context);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [isNew, setIsNew] = React.useState(true);
	const classes = useStyles();
	const [feedback, setFeedback] = React.useState({
		text: '',
		rating: null,
	});

	React.useEffect(() => {
		if (priorFeedback) {
			setIsNew(false);
			setFeedback(priorFeedback);
			console.log('prior feedback: ', priorFeedback);
		}
		setIsLoaded(true);
	}, [priorFeedback]);

	if (!isLoaded) return <div></div>;

	function getSmileyIcon({
		color,
		icon,
		selectedIcon,
		onIconClick,
		index,
		rating,
	}) {
		let ratingUsed = null;
		if (rating && selectedIcon) {
			ratingUsed = parseInt(rating);
			selectedIcon = parseInt(selectedIcon);
		}
		console.log('raing: ', selectedIcon, index);

		let containerStyle = {
			backgroundColor:
				ratingUsed === 5
					? '#b9efbe'
					: ratingUsed === 4
					? '#deffecde'
					: ratingUsed === 3
					? '#efefef'
					: ratingUsed === 2
					? '#fff1d4'
					: ratingUsed === 1
					? '#ffc9c9'
					: '',
			borderRadius: 100,
		};

		return (
			<div
				key={index}
				onClick={() => onIconClick(index)}
				style={selectedIcon === rating ? containerStyle : {}}
			>
				<div style={{ padding: 25 }}>{icon(60)}</div>
			</div>
		);
	}

	const onIconClick = (selectedIconIndex) => {
		if (isNew) {
			let newFeedback = Object.assign({}, feedback);
			newFeedback['rating'] = selectedIconIndex;
			setFeedback(newFeedback);
		}
	};

	const getRatingPlaceholder = (ratingMain) => {
		if (ratingMain === 1) {
			return 'If you are not happy then we are not happy. Please tell us what can be done better. ';
		} else if (ratingMain === 2) {
			return 'If there is room for imrpovement, then we would love to know. ';
		} else if (ratingMain === 3) {
			return 'Not sure what to think? Tell us about your concerns!';
		} else if (ratingMain === 4) {
			return 'Great, please tell us more.';
		} else if (ratingMain === 5) {
			return 'Great to hear, please tell us more!';
		}
	};

	const onValueChange = (key, value) => {
		onObjectChange(feedback, key, value, setFeedback.bind(this));
	};

	return (
		<div style={{ width: '100%' }}>
			{isNew ? (
				<p
					style={{
						fontWeight: 600,
						color: '#222',
						textAlign: 'center',
						fontSize: 18,
					}}
				>
					Was everything okay with your transaction?
				</p>
			) : (
				<p
					style={{
						fontWeight: 600,
						color: '#222',
						textAlign: 'center',
						fontSize: 18,
					}}
				>
					User feedback:
				</p>
			)}
			{isNew ? (
				<p
					style={{
						fontSize: 16,
						marginTop: -10,
						color: 'gray',
						textAlign: 'center',
					}}
				>
					We value your feedback
				</p>
			) : null}
			<div className={classes.smileContainer}>
				{getSurveyIcons.map((value, index) => {
					return getSmileyIcon({
						color: value.color,
						icon: value.icon,
						selectedIcon: feedback['rating'],
						onIconClick,
						index: 6 - (index + 1),
						rating: value.rating,
					});
				})}
			</div>
			<MainInput
				md={12}
				rows={'5'}
				label={''}
				disabled={!isNew}
				value={feedback['text']}
				identifier={'text'}
				onChange={onValueChange}
				placeholder={getRatingPlaceholder(feedback['rating'])}
			/>
			<div style={{ margin: 'auto', display: 'flex', width: '100%' }}>
				{isNew ? (
					<MainButton
						text={'Submit feedback'}
						onClick={() => {
							check('Do you want to submit this feedback?', {}).then(
								async (result) => {
									if (result) {
										await sendAPI(
											`logs/addFeedback`,
											{
												rating: feedback['rating'],
												text: feedback['text'],
												accessId: accessId,
											},
											state.openModal,
											() => {
												window.location.reload();
											}
										);
									}
								}
							);
						}}
						color={'primary'}
					/>
				) : null}
			</div>
		</div>
	);
};

export default Survey;
