import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import { Button, styled } from '@mui/material';
import { onLogout } from '../../api/helper';
import { useInvalidateMutation } from '../../store/api/api';
import { useDispatch } from 'react-redux';
import { resetLoginData } from '../../store/login/loginSlice';

export const FixedLogoutButton = () => {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const [invalidate] = useInvalidateMutation();
	const dispatch = useDispatch();

	const LogoutButton = styled(Button)`
		color: white;
		width: 160px;
		position: absolute;
		top: 9px;
		right: 9px;
		padding: 10px;
		${(props: any) => props.theme.breakpoints.down('sm')} {
			width: 80px;
			font-size: 13px;
			top: 4px;
			right: 4px;
			padding: 4px;
		}
	`;

	return (
		<LogoutButton
			onClick={async () => {
				let res = await onLogout(modalState.openModal, invalidate);
				dispatch(resetLoginData());

				setTimeout(() => {
					window.location.reload();
				}, 500);
			}}
			variant="contained"
			// size="medium"
			color={'error'}
		>
			Logout
		</LogoutButton>
	);
};
