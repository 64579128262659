const Reducer = (state, action) => {
	switch (action.type) {
		case 'OPEN_MODAL_REF':
			return {
				...state,
				openModal: action.payload,
			};
		case 'GET_USER':
			return {
				...state,
				user: action.payload,
			};
		// case 'ADD_POST':
		//     return {
		//         ...state,
		//         posts: state.posts.concat(action.payload)
		//     };
		default:
			return state;
	}
};

export default Reducer;
