import { APP_TYPES, AUTH_TYPES } from '@toriosoftware/torioshared/dist/config';
import { headerStyles } from '../styles/materialStyles';
import { Box, CssBaseline } from '@mui/material';
import SideBar from '../components/menu/SideBar';
import { Navigate, Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const AdminRouteLayout = (props: any) => {
	const classes = headerStyles();
	let params = useParams();

	if (
		props.user &&
		AUTH_TYPES.companyAuth.isFirstAuth(props.user) &&
		AUTH_TYPES.companyAuth.isAuthAllowed(props.user) &&
		AUTH_TYPES.companyAuth.isAuthConfirmed(props.user) &&
		AUTH_TYPES.companyAuth.isPlaceSelected(props.user)
	) {
		return (
			<Box className={classes.root}>
				<CssBaseline />
				<SideBar
					user={props.user}
					placeName={props.user?.placeName}
					role={props.user?.role}
					systemRole={props.user?.systemRole}
					subscription={props.user?.subscription}
				/>
				<div style={{ width: '100vw', marginTop: 110, margin: 'auto' }}>
					<Outlet />
				</div>
			</Box>
		);
	} else {
		if (params?.token) {
			return <Navigate to={'/login/' + params.token} />;
		} else {
			return <Navigate to="/login" />;
		}
	}
};

export default AdminRouteLayout;
