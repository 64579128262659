import { GridCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import SuccessIcon from '@mui/icons-material/Done';
import Warning from '@mui/icons-material/ErrorOutline';
import { getKegInformation, getCurrencyAmount } from './tableComponents';
import { getSurveyIcons, getBadge } from './surveyHelper';
import {
	getPlaceFormatter,
	getPercentageFormatter,
	getPriceFormatter,
	getDateFormatter,
	getMlFormatter,
	paymentFeesFormatter,
	getHasJoinedFormatter,
	getIfEmptyFormatter,
	getIsTestFormatter,
	getJSDateFormatter,
} from './tableFormatters';
import { getCurrencyTypeByCode } from './currencyHelper';
import moment from 'moment';
import { getDaysDiscountText, getDiscountTimes } from './discountHelper';

function capitalizeTxt(txt) {
	return txt.charAt(0).toUpperCase() + txt.slice(1);
}

const getPhoneToUse = (countryPhoneCode, phone) => {
	let phoneToUse =
		(countryPhoneCode ? countryPhoneCode : '') + (phone ? phone : '');
	return phoneToUse;
};

const getIdField = () => {
	return {
		field: '_id',
		width: '0px',
		headerName: 'ID',
		hide: true,
		disableClickEventBubbling: true,
	};
};

const getRegularField = (
	field,
	headerName,
	widthInput,
	formatter = null,
	fallbackText = ''
) => {
	const width =
		widthInput && widthInput > 20
			? { width: widthInput }
			: { flex: widthInput };
	return {
		field: field,
		headerName: headerName,
		...width,
		disableClickEventBubbling: true,
		valueFormatter: (params: GridValueFormatterParams) => {
			if (formatter) {
				return formatter(params.value, fallbackText);
			} else {
				return params.value;
			}
		},
		...formatter,
	};
};

const getSeeMoreField = (field, headerName, routeSeeMore = '') => {
	return {
		field: field,
		headerName: headerName,
		width: 120,
		disableClickEventBubbling: true,
		renderCell: (params: GridCellParams) => (
			// to={routeSeeMore}
			<span className="table__link">
				<ArrowForwardIcon style={{ marginTop: 10 }} />
			</span>
		),
	};
};

// {/* <Link to={routeSeeMore + '/' + params.row._id} className="table__link">
// 				<ArrowForwardIcon style={{ marginTop: 10 }} />
// 			</Link> */}

const getDeleteField = (onClick) => {
	return {
		field: 'delete',
		headerName: 'Remove from place',
		width: 150,
		disableClickEventBubbling: true,
		renderCell: (params: GridCellParams) => (
			<div
				style={{ cursor: 'pointer' }}
				onClick={(e) =>
					onClick(params.row.user._id, params.row.name, params.row.email)
				}
			>
				<DeleteIcon style={{ marginTop: 10 }} />
			</div>
		),
	};
};

export function getSessionColumns() {
	return [
		getRegularField('email', 'Email', 1),
		getRegularField('expires', 'Expires', 1),
		getRegularField('phoneConfirmed', 'Phone confirmed', 1),
		getRegularField('emailConfirmed', 'Email confirmed', 1),
		getRegularField('systemRole', 'System role', 1),
		getRegularField('subscription', 'Subscription', 1),
		getRegularField('loggedIn', 'Logged in', 1),
		getRegularField('places', 'Places', 1),
	];
}

export function getDiscountTimesColumns() {
	return [
		getIdField(),
		getRegularField('name', 'Name', 1),
		getRegularField('description', 'Description', 1),
		{
			field: 'monday',
			headerName: 'Days',
			width: 300,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				if (params.row) {
					return getDaysDiscountText(
						params.row.monday,
						params.row.tuesday,
						params.row.wednesday,
						params.row.thursday,
						params.row.friday,
						params.row.saturday,
						params.row.sunday
					);
				}
				return 'None. ';
			},
		},
		{
			field: 'times',
			headerName: 'Times',
			width: 300,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				return getDiscountTimes(params.row.times);
			},
		},
	];
}

export function getDiscountColumns(currency) {
	return [
		getIdField(),
		getRegularField('name', 'Name', 1),
		{
			field: 'useDiscountPrice',
			headerName: 'Discount',
			width: 100,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				if (params.row.useDiscountPrice === false) {
					return params.row.percentage ? params.row.percentage + '%' : '';
				} else if (params.row.useDiscountPrice === true) {
					return params.row.discountPrice
						? params.row.discountPrice + getCurrencyTypeByCode(currency)
						: '';
				}
				return '';
			},
		},
		getRegularField('from', 'From', 90),
		getRegularField('to', 'To', 90),
		{
			field: 'dates',
			headerName: 'Dates',
			width: 450,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				if (params.row.type === 'custom') {
					return params.row.dates.join(', ');
				} else {
					return Object.keys(params.row.days)
						.map((key) => {
							if (params.row.days[key]) {
								return capitalizeTxt(key);
							} else {
								return '';
							}
						})
						.filter((value) => {
							if (value) return true;
							else return false;
						})
						.join(', ');
				}
			},
		},
	];
}

export function getCurrencyChangesColumns() {
	return [
		getIdField(),
		getRegularField('timestamp', 'Date', 1, getDateFormatter),
		{
			field: 'currencyBefore',
			headerName: 'From currency',
			width: 300,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				// return '';
				return getCurrencyAmount(params.row, 'amountBefore', 'currencyBefore');
			},
		},
		{
			field: 'currencyAfter',
			headerName: 'To currency',
			width: 300,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				return getCurrencyAmount(params.row, 'amountAfter', 'currencyAfter');
			},
		},
		{
			field: 'amountUSDBefore',
			headerName: 'USD amount before',
			width: 300,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				return getCurrencyAmount(
					params.row,
					'amountUSDBefore',
					false,
					false,
					'USD'
				);
			},
		},
		{
			field: 'amountUSDAfter',
			headerName: 'USD amount before',
			width: 300,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				return getCurrencyAmount(
					params.row,
					'amountUSDAfter',
					false,
					false,
					'USD'
				);
			},
		},
		{
			field: 'feeUSD',
			headerName: 'Fee',
			width: 150,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				return getCurrencyAmount(params.row, 'feeUSD', false, false, 'USD');
			},
		},
		// getRegularField('currencyAfter', 'Currency after', 1),
		// getRegularField('currencyBefore', 'Currency before', 1),
	];
}
// getIdField(),

export function getKegColumns(currency, includeTapNumber) {
	let kegColumns = [
		{
			field: 'drink',
			flex: 1,
			headerName: 'Drink in keg',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				//@ts-ignore
				if (params?.value?.name) {
					//@ts-ignore
					return params?.value?.name;
				} else {
					return 'No drink';
				}
			},
		},
		{
			field: 'numberOfKegs',
			flex: 1,
			headerName: 'Number of kegs',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				console.log('KEGS NUMBER: ', params);
				//@ts-ignore
				if (params?.value?.name) {
					//@ts-ignore
					return params?.value?.name;
				} else {
					return 'NA';
				}
			},
		},
		getRegularField('price', 'Price', 1, getPriceFormatter, ''),
		getRegularField(
			'discountPrice',
			'Discount price',
			1,
			getPriceFormatter,
			''
		),
		getRegularField('location', 'Location of keg', 120),
	];
	return kegColumns;
}

export function getSaleColumns(currency, includeTapNumber) {
	let kegColumns = [
		{
			field: 'orderByName',
			flex: 1,
			headerName: 'Order name',
			disableClickEventBubbling: true,
		},
		{
			field: 'orderByEmail',
			flex: 1,
			headerName: 'Order name',
			disableClickEventBubbling: true,
		},
		getRegularField('date', 'Date', 1, getJSDateFormatter),
		{
			field: 'orders',
			width: 120,
			headerName: 'Amount of orders',
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				console.log(
					'SALE SALE: ',
					params.row.orders.length ? params.row.orders.length : 0
				);
				return params.row.orders.length ? params.row.orders.length : 0;
			},
		},
	];
	return kegColumns;
}

export function getKegChangeColumns(currency, includeTapNumber) {
	let kegColumns = [
		// getRegularField('numberOfKegs', 'NO', 120),
		{
			field: 'numberOfKegs',
			width: 110,
			headerName: 'Number of kegs',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				//@ts-ignore
				if (params?.value) {
					//@ts-ignore
					return params?.value + (params?.value ? ' kegs' : 'keg');
				} else {
					return 'No kegs';
				}
			},
		},
		{
			field: 'keg',
			flex: 1,
			headerName: 'Drink in keg',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				console.log('params: ', params.value.drink);
				//@ts-ignore
				if (params?.value?.drink?.name) {
					//@ts-ignore
					return params?.value?.drink?.name;
				} else {
					return 'No drink';
				}
			},
		},
		getRegularField('date', 'Date', 1, getJSDateFormatter),
		getRegularField(
			'placeName',
			'Place name',
			1,
			getPlaceFormatter,
			'Not specified'
		),
		{
			field: 'reason',
			width: 120,
			headerName: 'Reason',
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				let color = 'green';
				if (params?.value === 'REMOVE') {
					color = 'red';
				}
				//@ts-ignore
				if (params?.value) {
					//@ts-ignore
					return (
						<div
							style={{
								color: 'white',
								backgroundColor: color,
								padding: 8,
								fontWeight: 'bold',
								borderRadius: 30,
							}}
						>
							{params?.value}
						</div>
					);
				} else {
					return 'No reason';
				}
			},
		},
		getRegularField('totalPrice', 'Total price', 1, getPriceFormatter, ''),
		getRegularField('reasonFix', 'Other reason', 1),
	];
	return kegColumns;
}

// getRegularField('serial', 'Keg serial', 200),
// {
// 	field: 'ml',
// 	headerName: 'Keg',
// 	width: 350,
// 	disableClickEventBubbling: true,
// 	renderCell: (params: GridCellParams) => {
// 		return getKegInformation(params.row, params.row.drink, currency);
// 		//getMlRenderCell(params.row.ml, params.row.totalMl),
// 	},
// },
// if (includeTapNumber) {
// 	let fieldToAdd = {
// 		field: 'tapNumber',
// 		width: 70,
// 		headerName: 'Tap NO.',
// 		disableClickEventBubbling: true,
// 		valueFormatter: (params: GridValueFormatterParams) => {
// 			console.log('params: ', params);
// 			// TODO: gera
// 			// if (params?.row?.tap?.tapNumber) {
// 			// 	return params?.row?.tap?.tapNumber;
// 			// } else {
// 			// 	return '';
// 			// }
// 		},
// 	};
// 	kegColumns.splice(1, 0, fieldToAdd);
// }

export function getTablesColumns(seeMoreRoute) {
	return [
		getIdField(),
		getRegularField('tableNumber', 'Table number', 1),
		getRegularField('description', 'Description', 1),
	];
}

export function getPaymentHistoryColumns(currency) {
	return [
		getIdField(),
		getRegularField('amount', 'Amount', 1, getPriceFormatter, ''),
		{
			field: 'toPay',
			headerName: 'Is paid',
			// width: 200,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				if (params && params.row) {
					let row = params.row;
					let toPay = row.toPay;
					let captured = row.captured;
					if (toPay && captured) {
						return (
							<SuccessIcon style={{ width: 22, height: 22, color: 'green' }} />
						);
					} else
						return (
							<CloseIcon style={{ width: 22, height: 22, color: 'red' }} />
						);
				} else {
					return '';
				}
			},
		},
		getRegularField('status', 'Status', 1),
		getRegularField('paymentFees', 'Payment fees', 1, paymentFeesFormatter),
		getRegularField('referenceId', 'Reference ID', 1),
		getRegularField('nextAction', 'Next action', 1),
	];
}

export function getLogsColumns(systemRole) {
	return [
		getIdField(),
		getRegularField('drinkName', 'Drink name', 200),
		{
			field: 'localTimestamp',
			width: 200,
			headerName: 'Time',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				console.log('params V: ', params.value);
				if (params.value) {
					return moment.unix(params.value).format('DD.MM.YYYY HH:mm');
				} else {
					return '';
				}
				return params.value; //? 'Added by staff' : 'Is paid';
			},
		},
		{
			field: 'price330Ml',
			width: 180,
			headerName: 'Price / 330ml',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				return getPriceFormatter(params.value, 'ISK');
			},
		},
		{
			field: 'priceToPay',
			width: 180,
			headerName: 'Paid',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				return getPriceFormatter(params.value, 'ISK');
			},
		},
		{
			field: 'isFree',
			width: 180,
			headerName: 'Is free',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				return params.value ? 'Yes' : 'No';
			},
		},
		getRegularField('mlPoured', 'Ml poured', 1, getMlFormatter),

		// getRegularField('currency', 'Currency', 120),
	];
}

export function getCreditColumns() {
	return [
		getIdField(),
		// getRegularField('credit', 'Price', 200),
		{
			field: 'credit',
			width: 180,
			headerName: 'Credit added',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				return getPriceFormatter(params.value, 'ISK');
			},
		},
		{
			field: 'isAddedManually',
			width: 180,
			headerName: 'Paid',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				return params.value ? 'Added by staff' : 'Is paid';
			},
		},
		{
			field: 'localTimestamp',
			width: 300,
			headerName: 'Time',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				console.log('params V: ', params.value);
				if (params.value) {
					return moment.unix(params.value).format('DD.MM.YYYY HH:mm');
				} else {
					return '';
				}
				return params.value; //? 'Added by staff' : 'Is paid';
			},
		},

		// getRegularField('currency', 'Currency', 120),
	];
}

export function getUserColumns(seeMoreRoute) {
	return [
		getIdField(),
		getRegularField('email', 'Email', 1),
		getRegularField('name', 'Name', 1),
		getRegularField('role', 'Role', 1, getRoleName),
		getRegularField('place', 'Place', 1, getPlaceFormatter),
	];
}

const getRoleName = (role) => {
	return role;
};

export function getUserPlaceColumns() {
	return [
		getIdField(),
		{
			field: 'email',
			headerName: 'Email',
			flex: 1,
			disableClickEventBubbling: true,
		},
		{
			field: 'name',
			headerName: 'Name',
			width: 200,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				let name = params.row.name;
				if (!name) {
					name = '';
				}

				// const user = params.row.user;
				// let name = '';
				// if (user) {
				// 	let firstName = user.firstName ? user.firstName : '';
				// 	let lastName = user.lastName ? user.lastName : '';
				// 	name = firstName + ' ' + lastName;
				// 	if (firstName || lastName) {
				// 		name = params.row.name;
				// 		if (!name) {
				// 			name = '';
				// 		}
				// 	}
				// }
				return <span>{`${name ? name : ''}`}</span>;
			},
		},
		{
			field: 'role',
			headerName: 'Role',
			width: 200,
			disableClickEventBubbling: true,
		},
	];
}

export function getDrinkColumns(currency) {
	return [
		getIdField(),
		getRegularField('name', 'Name', 1),
		getRegularField('type', 'Type', 100),
		{
			field: 'price330Ml',
			width: 180,
			headerName: 'Price per 330 ml',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				return getPriceFormatter(params.value, currency);
			},
		},
		{
			field: 'alc',
			width: 70,
			headerName: '%',
			disableClickEventBubbling: true,
			valueFormatter: (params: GridValueFormatterParams) => {
				return getPercentageFormatter(params.value);
			},
		},
		getRegularField('country', 'Country', 100),
		// getRegularField('inKegs', 'In how many kegs', 100),
		getRegularField('place', 'Place', 100, getPlaceFormatter),
		// getSeeMoreField('none', 'See more'),
	];
}

export function getTapColumns() {
	return [
		getIdField(),
		getRegularField('tapNumber', 'No', 80),
		getRegularField('tapName', 'Tap name', 1),
		{
			field: 'drink',
			headerName: 'Drink',
			width: 400,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				const drink = params.row.drink;
				let name = '';
				if (!drink) {
					return <span>No drink</span>;
				} else {
					name = drink.name;
				}
				// const drink = keg.drink;
				return <span>{name}</span>;
				// return getKegInformation(keg, drink, placeCurrency);
			},
		},
	];
}

export function getRegisterPlacesColumns() {
	return [
		getIdField(),
		getRegularField('companyName', 'Company name', 120),
		getRegularField('email', 'Email', 1),
		getRegularField('loginEmail', 'Login email', 1),
		getRegularField('date', 'Date', 1),
		getRegularField('emailConfirmed', 'Confirmed email', 1),
		getRegularField('placeAdded', 'Place added', 1),
	];
}

export function getDeviceColumns() {
	return [
		getIdField(),
		// getRegularField('location', 'Location', 80),
		{
			field: 'place',
			headerName: 'Place',
			width: 400,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				const place = params.row.place;
				if (place && place.name) {
					return <span>{place.name}</span>;
				} else {
					return <span>No place</span>;
				}
			},
		},
		getRegularField('description', 'Description', 1),

		// getRegularField('tabletId', 'Tablet ID', 1),
		// {
		// 	field: 'company',
		// 	headerName: 'Company',
		// 	width: 300,
		// 	disableClickEventBubbling: true,
		// 	renderCell: (params: GridCellParams) => {
		// 		const company = params.row.company;
		// 		if (company && company.name) {
		// 			return <span>{company.name}</span>;
		// 		} else {
		// 			return <span>No company</span>;
		// 		}
		// 	},
		// },
	];
}

export function getGPIOColumns(seeMoreRoute) {
	return [
		getIdField(),
		getRegularField('flowMeterGPIO', 'Flow meter GPIO', 80),
		getRegularField('solenoidValveGPIO', 'Solenoid GPIO', 80),
		getRegularField('information', 'Information', 150),
		{
			field: 'name',
			headerName: 'Flow meter',
			width: 140,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				console.log('params.row: ', params.row);
				let name = params.row?.flowmeter?.name;
				if (!name) {
					name = '';
				}
				return <span>{`${name ? name : ''}`}</span>;
			},
		},
		getRegularField('URL', 'URL', 1),
	];
}

// percentage: number;
// amount: number;
// originalAmount: number;
// name: string;
// description: string;
// from: string;
// to: string;
// imageLink: any;
// isMain: boolean;

export function getCouponColumns() {
	return [
		getIdField(),
		{
			field: 'name',
			headerName: 'Name',
			width: 140,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				let name = params.row?.name;
				if (!name) {
					name = '';
				}
				return <span>{`${name ? name : ''}`}</span>;
			},
		},
		getRegularField('description', 'Description', 1),
	];
}

export function getFlowColumns() {
	return [
		getIdField(),
		{
			field: 'name',
			headerName: 'Name',
			width: 140,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				let name = params.row?.flowmeter?.name;
				if (!name) {
					name = '';
				}
				return <span>{`${name ? name : ''}`}</span>;
			},
		},
		getRegularField('flowCircles', 'Flow circles', 1),
		getRegularField('flowCirclesAllowed', 'Flow circles allowed', 1),
		getRegularField('mlPoured', 'Ml poured', 1, getMlFormatter),
		getRegularField('actualMlPoured', 'Actual ml poured', 1, getMlFormatter),
		getRegularField('price330Ml', 'Price per 330ml', 1, getPriceFormatter),
		getRegularField('priceToPay', 'Price to pay', 1, getPriceFormatter),
		getRegularField('localTimestamp', 'Time', 1),
		getRegularField('isTest', 'Is test', 1, getIsTestFormatter),
	];
}

export function getFlowmeterColumns() {
	return [
		getIdField(),
		getRegularField('name', 'Name', 1),
		getRegularField('description', 'Description', 1),
		getRegularField('mlPerCircle', 'Ml per circle', 1, getMlFormatter),
		getRegularField('mlPerCircleToUse', 'Circle to use', 1, getMlFormatter),
		getRegularField('spinsPerLiter', 'Spins per liter', 1, getPriceFormatter),
	];
}

export function getPlaceColumns(seeMoreRoute) {
	return [
		getIdField(),
		getRegularField('name', 'Name', 100),
		getRegularField('ssn', 'SSN', 100),
		getRegularField('subscription', 'Subscription', 100),
		getRegularField('totalPoured', 'Total poured', 150),
	];
}
