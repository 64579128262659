import Skeleton from 'react-loading-skeleton';
import { Box, Breadcrumbs, Container, Grid } from '@mui/material';
import BreadcrumbsMain from '../breadcrumbs/BreadcrumbsMain';
import Header from '../texts/Header';
import { ContainerPaddingDiv } from '../../styles/styledComponents';
import { styled } from '@mui/material/styles';
import { SIZES } from './../../config/config';

const MainContainer = styled(Container)`
	${(props: any) => props.theme.breakpoints.down('sm')} {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
`;

const PageContainer = (props) => {
	// console.log('PROPS: ', props);

	//noMargonTop == has side bar
	return (
		<MainContainer
			style={{ margin: 'auto', maxWidth: SIZES.CONTENT_WIDTH }}
			// sx={{
			// 	width: {
			// 		xs: '100vw',
			// 	},
			// }}
		>
			<ContainerPaddingDiv style={{ padding: 10 }}>
				<Header type={'title'} text={props.header ? props.header : 'Manage'} />
				<BreadcrumbsMain breadPlaceholder={props.breadPlaceholder} />
			</ContainerPaddingDiv>

			{props.children}
		</MainContainer>
	);
};

export default PageContainer;
