import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';

export default function VerticalLinearStepper({
	onChangeSaleStep,
	step,
	steps,
	orderEmailSent,
	onClickOrderEmail,
	invoiceDetailsText,
	confirmationMessageText,
}: any) {
	// const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = () => {
		onChangeSaleStep(step + 1);
		// setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		onChangeSaleStep(step - 1);
		// setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		onChangeSaleStep(0);
		// setActiveStep(0);
	};

	return (
		<Box
			sx={{
				maxWidth: '100%',
				marginTop: 7,
				display: 'block !important',
				// margin: 'auto',
				// display: 'flex',
				// justifyContent: 'center',
			}}
		>
			<Stepper
				sx={{
					display: 'block !important',
				}}
				activeStep={step}
				orientation="vertical"
			>
				{steps.map((stepTemp, index) => (
					<Step
						sx={{
							display: 'block !important',
						}}
						key={stepTemp.label}
					>
						<StepLabel
							optional={
								index === 2 ? (
									<Typography variant="caption">Last step</Typography>
								) : null
							}
						>
							{stepTemp.label}
						</StepLabel>

						<StepContent style={{ width: '100%' }}>
							<Typography style={{ width: '100%' }}>
								{stepTemp.description}
							</Typography>
							{step === 1 ? (
								<div>
									{confirmationMessageText ? (
										<p style={{ fontStyle: 'italic' }}>
											<b>Custom text sent with email:</b>{' '}
											{confirmationMessageText}
										</p>
									) : null}
									{invoiceDetailsText ? (
										<p style={{ fontStyle: 'italic' }}>
											<b>Invoice text sent with email:</b> {invoiceDetailsText}
										</p>
									) : null}
								</div>
							) : null}
							<Typography style={{ width: '100%' }}></Typography>
							<Alert
								style={{ width: '100%', marginTop: 6, marginBottom: 6 }}
								severity={stepTemp.emailDate ? 'success' : 'error'}
								action={
									<Button
										onClick={async () => {
											onClickOrderEmail(step);
										}}
										style={{ alignItems: 'center', display: 'flex' }}
										color="inherit"
										size="small"
									>
										{stepTemp.emailButtonText}
									</Button>
								}
							>
								<div>
									{stepTemp.emailDate
										? stepTemp.emailSuccessText
										: stepTemp.emailErrorText}
									{/* {item.orderEmailSent ? ' ' : ' not '}sent the
								confirmation email{item.orderEmailSent ? ' ' : ' yet '}to the
								customer ({item.orderByEmail}). */}
								</div>
							</Alert>
							<Box sx={{ mb: 2 }}>
								<div>
									{index === steps.length - 1 ? null : (
										<Button
											variant="contained"
											onClick={handleNext}
											sx={{ mt: 1, mr: 1 }}
										>
											{index === steps.length - 1 ? 'Finish' : 'Next step'}
										</Button>
									)}

									<Button
										disabled={index === 0}
										onClick={handleBack}
										sx={{ mt: 1, mr: 1 }}
									>
										Back
									</Button>
								</div>
							</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>
			{step === steps.length && (
				<Paper square elevation={0} sx={{ p: 3 }}>
					<Typography>All steps completed - you&apos;re finished</Typography>
					<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
						Reset
					</Button>
				</Paper>
			)}
		</Box>
	);
}
