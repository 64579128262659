import { Box, Grid, styled } from '@mui/material';
const MainGrid = styled(Grid)`
	${(props: any) => props.theme.breakpoints.down('sm')} {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
`;

const MainContainer = (props) => {
	return (
		<MainGrid direction={props.direction} container>
			{props.children}
		</MainGrid>
	);
};

export default MainContainer;
