"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const barTypes = [
    'Bar',
    'Book cafe',
    'Bar & Live music',
    'Brewery',
    'Cafe',
    'Cocktail bar',
    'Cinema',
    'Craft beer bar',
    'Comedy club',
    'Food place',
    'Gay bar',
    'Gambling bar',
    'Hiphop bar',
    'Hotel bar',
    'Irish bar',
    'Jazz bar',
    'Karaokie bar',
    'Live music venue',
    'Local bar',
    'Nightclub',
    'Pool table bar',
    'Ping pong bar',
    'Restaurant',
    'Robotic bar',
    'Rooftop bar',
    'Sky bar',
    'Student bar',
    'Rock bar',
    'Sportbar',
    'Video game bar',
    'Wine bar',
    'Whiskey bar',
];
exports.default = barTypes;
