import { getTapColumns, getUserPlaceColumns } from '../../business/tableHelper';
// import { getUserPlaceColumns } from './business/tableHelper';
import PageContainer from '../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
// import {
// 	ROUTE_NAMES,
// 	ROUTE_TYPES,
// } from '@toriosoftware/torioshared/dist/config';
import { getKegColumns } from '../../business/tableHelper';
import { removeAllEmptyKegs } from '../../api/alterData';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import KegPageContainer from './KegPageContainer';

interface PlaceUserProps {
	user: any;
	modalState: any;
}

const Kegs = ({ user, modalState }: PlaceUserProps) => {
	const history = useHistoryNav();

	return (
		<PageContainer header={'Kegs'}>
			<React.Fragment>
				<ManageItems
					header={'Kegs in use'}
					key={1}
					getData={{
						url: 'kegs/getKegs',
						body: {},
					}}
					redirectLink={ROUTE_TYPES[ROUTE_NAMES.keg].route}
					columns={getKegColumns(user.placeCurrency, true)}
					noItemsText={'No kegs found.'}
					manageComponent={() => <KegPageContainer />}
				/>
			</React.Fragment>
		</PageContainer>
	);
};

export default Kegs;

/*

<ManageItems
					header={'Kegs not in use'}
					key={920}
					getData={{
						url: 'kegs/getKegsNotEmpty',
						body: {},
					}}
					redirectLink={ROUTE_TYPES[ROUTE_NAMES.keg].route}
					columns={getKegColumns(user.placeCurrency, false)}
					noItemsText={'No kegs found.'}
					manageComponent={() => <Keg />}
				/>
				<ManageItems
					header={'Empty kegs not in use'}
					key={34}
					getData={{
						url: 'kegs/getKegsEmpty',
						body: {},
					}}
					redirectLink={ROUTE_TYPES[ROUTE_NAMES.keg].route}
					columns={getKegColumns(user.placeCurrency, false)}
					noItemsText={'No empty kegs found.'}
					customButton={{
						onClick: () => removeAllEmptyKegs(modalState.openModal),
						text: 'Delete all',
					}}
					manageComponent={() => <Keg />}
				/>

*/
