import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import MainContainer from './MainContainer';
import PanelContainer from './PanelContainer';
import SubHeader from '../texts/SubHeader';
import ButtonGroup from '../buttons/ButtonGroup';
import MainButton from '../buttons/MainButton';
import { onErrorMain, removeItem, submitFormMain } from '../../api/helper';
import { CircularProgress } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

const FormContainer = (
	// props,
	{
		onSubmitFunction,
		onRemoveFunction,
		modal,
		role,
		requiredRole,
		linkAfterSuccess,
		linkAfterDelete,
		refreshAfterSuccess,
		reset,
		md,
		customData = {},
		removeData = {},
		removeText = '',
		isDirty = true,
		csrfToken,
		title,
		disabled,
		isLoaded,
		isLoading,
		link,
		handleSubmit,
		goBackDepth,
		history,
		hideRemove,
		removeCustomText,
		showBack,
		children,
	}: any
) => {
	//@ts-ignore
	const [state] = useContext(Context);
	const confirm = useConfirm();

	const onSubmit = async (data) => {
		let { data: resultData } = await onSubmitFunction({
			...data,
			...customData,
			modal: modal,
			csrfToken: csrfToken,
		});

		//TODO: Navigate to the new drink page here..

		if (resultData && resultData.success) {
			if (linkAfterSuccess) {
				history('/admin/' + linkAfterSuccess);
			} else if (refreshAfterSuccess) {
				window.location.reload();
			} else if (resultData?.itemId) {
				history('/admin/' + link + '/' + resultData?.itemId);
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			}
		}
	};

	// await submitFormMain(link, data, state.openModal, null, false);
	const onError = (errors, e) => {
		let message = '';
		if (errors) {
			Object.keys(errors).forEach((errorKey) => {
				let error = errors[errorKey];
				if (error && error.message) {
					message += error.message + '. ';
				}
			});
		}
		onErrorMain(state.openModal, message);
	};

	if (requiredRole) {
		if (!role || role !== requiredRole) {
			return (
				<MainContainer>
					<PanelContainer header={title}>
						<SubHeader text={'You are not authorized to edit this.'} />
					</PanelContainer>
				</MainContainer>
			);
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit, onError)}>
			<MainContainer direction="row" history={history}>
				<PanelContainer md={md ? md : 12} isLoaded={isLoaded} header={title}>
					{children}
					<ButtonGroup>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							{isLoading ? (
								<CircularProgress
									style={{
										width: 21,
										height: 21,
										marginTop: 9,
										marginRight: 8,
										marginLeft: 4,
									}}
								/>
							) : (
								<MainButton
									// disabled={!isDirty}
									disabled={isLoading === true ? true : disabled ? true : false}
									isSubmit={true}
									onClick={() => {}}
									// disabled={false}
									text={'Save'}
									color={'primary'}
								/>
							)}
							{showBack ? (
								<MainButton
									onClick={() => {
										history(-1);
									}}
									text={'Back'}
									color={'default'}
								/>
							) : null}
						</div>
						{!hideRemove ? (
							<MainButton
								// color={'inherit'}
								buttonColor={'error'}
								onClick={async () => {
									confirm({
										title: 'Warning',
										description: removeText
											? removeText
											: 'Are you sure you want to remove this item?',
										confirmationText: 'Yes',
										cancellationText: 'No',
									})
										.then(async () => {
											let { data: resultData } = await onRemoveFunction({
												modal: modal,
												isRemove: true,
												id: customData.id,
												...removeData,
												csrfToken: csrfToken,
											});
											if (resultData && resultData.success && linkAfterDelete) {
												history('/admin/' + linkAfterDelete);
											}
										})
										.catch((err) => {
											console.log('err: ', err);
										});
								}}
								text={removeCustomText ? removeCustomText : 'Remove'}
							/>
						) : null}
					</ButtonGroup>
				</PanelContainer>
			</MainContainer>
		</form>
	);
};

export default FormContainer;
