import { sendAPI } from './helper';

export const onTranslateApi = async (jsonString, language) => {
	const result = await sendAPI('misc/translateJson/', { jsonString, language });
	return result;
};

export const onTranslateApiRelease = async (text) => {
	const result = await sendAPI('misc/translateRelease/', { text });
	return result;
};

export const postOnFacebook = async () => {
	const result = await sendAPI('misc/barhoppFacebook/', {});
	return result;
};

