import LineContainer from '../lines/LineContainer';

interface SubHeaderProps {
	text: string;
	hide?: boolean;
}

const SubHeader = ({ text, hide }: SubHeaderProps) => {
	if (hide) {
		return null;
	}
	return (
		<LineContainer>
			<p style={{ fontWeight: 600, fontSize: 18 }}>{text}</p>
		</LineContainer>
	);
};

export default SubHeader;
