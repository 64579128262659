import MainDropdownChip from '../../components/dropdowns/MainDropdownChip';
import { Controller } from 'react-hook-form';

const BarAttributesController = ({
	isLoaded,
	features,
	control,
	identifier,
	allFeatures,
}) => {
	// const onHandleChange = () => {};

	return (
		<Controller
			control={control}
			name={identifier}
			render={({
				field: { onChange, onBlur, value, ref },
				fieldState: { invalid, isTouched, isDirty, error },
				formState: { errors },
			}) => {
				return (
					<MainDropdownChip
						items={allFeatures}
						selectedItems={value}
						label={'Features'}
						value={''}
						options={allFeatures}
						optionsValueKey={'_id'}
						optionsTextKey={'name'}
						id={'feature'}
						labelId={'featurehere'}
						link={''}
						isWithLink={false}
						onChange={onChange}
					/>
				);
			}}
		/>
	);
};
export default BarAttributesController;
