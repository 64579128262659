import { sendAPI } from './helper';

export const getHappyHours = async () => {
	const result = await sendAPI('detailedPlace/getHappyHours/');
	return result;
	// if (result && result.happyHours) {
	// 	return result.happyHours;
	// } else {
	// 	return null;
	// }
};

export const saveHappyHour = async (data, openModal) => {
	const result = await sendAPI(
		'detailedPlace/saveHappyHour/',
		{ data },
		openModal
	);
	return result;
};
