import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';
// import { withRouter } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams, useLocation } from 'react-router-dom';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { ROUTE_TYPES } from '@toriosoftware/torioshared/dist/config';

const Breadcrumbs = (props) => {
	// const {
	// 	history,
	// 	location: { pathname },
	// } = props;

	const params = useParams();
	let history = useHistoryNav();
	let { pathname } = useLocation();
	let pathnames = [];
	let lastPathName = '';

	if (pathname) {
		if (pathname[0] === '/') {
			pathname = pathname.substring(1);
		}
		pathnames = pathname.split('/').filter((x) => x);
	}

	//FIND OUT IF 24 CHARACTERS ARE IN..

	let sharedStyles = {
		fontWeight: 500,
		textDecoration: 'none',
		fontSize: 18,
		cursor: 'pointer',
	};

	const getRouteName = (name, isLast, lastPathNamePara) => {
		// console.log(
		// 	'name, isLast, lastPathNamePara: ',
		// 	name,
		// 	isLast,
		// 	lastPathNamePara
		// );
		if (name) {
			name = name.toLowerCase();
			lastPathNamePara = lastPathNamePara
				? lastPathNamePara.toLowerCase()
				: lastPathNamePara;

			if (name.length > 23 || (name.length === 1 && lastPathNamePara)) {
				let lastPara = ROUTE_TYPES[lastPathNamePara];
				if (lastPara && lastPara.itemRoute) {
					return lastPara.itemRoute;
				}
				return 'Item';
			} else {
				if (name === 'admin') {
					return 'Home';
				} else {
					let newName = ROUTE_TYPES[name];
					if (newName) {
						return newName.name;
					}
				}
			}
		}
		return name;
	};

	return (
		<MUIBreadcrumbs
			separator={
				<NavigateNextIcon
					style={{ marginTop: 2, marginRight: 0, marginLeft: 0 }}
					fontSize="medium"
				/>
			}
			aria-label="breadcrumb"
			style={{ marginBottom: 20, marginTop: 3 }}
		>
			{pathnames.map((name, index) => {
				const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
				const isLast = index === pathnames.length - 1;
				let lastPathnameToUse = lastPathName;
				lastPathName = name;
				return isLast ? (
					<Typography style={{ fontWeight: 500, fontSize: 18 }} key={name}>
						{/* {props.breadPlaceholder
							? props.breadPlaceholder
							:  */}
						{/* } */}
						{getRouteName(name, isLast, lastPathnameToUse)}
					</Typography>
				) : (
					<Link
						style={{ textDecoration: 'none' }}
						key={name}
						onClick={() => history(routeTo)}
					>
						<Typography style={sharedStyles}>
							{getRouteName(name, false, '')}
						</Typography>
					</Link>
				);
			})}
		</MUIBreadcrumbs>
	);
};

export default Breadcrumbs;
