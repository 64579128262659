import moment from 'moment';
import { Grid } from '@mui/material';
import MainInput from './MainInput';

const Times = ({ onValueChange, item }) => {
	function errorTimeText(key) {
		if (item && item[key]) {
			if (!moment(item[key], 'HH:mm', true).isValid()) {
				return { isError: true, text: 'Date must be on hh:mm format.' };
			}
		}

		return {
			isError: false,
			text: '',
		};
	}

	return (
		<Grid
			style={{ padding: 15, paddingRight: 0, paddingBottom: 0 }}
			container
			spacing={2}
		>
			<MainInput
				error={errorTimeText('from').isError}
				helperText={errorTimeText('from').text}
				label={'From *'}
				md={6}
				value={item['from'] ? item['from'] : ''}
				identifier={'from'}
				onChange={(key, value) => {
					onValueChange(key, value);
				}}
			/>
			<MainInput
				error={errorTimeText('to').isError}
				helperText={errorTimeText('to').text}
				label={'To *'}
				md={6}
				value={item['to'] ? item['to'] : ''}
				identifier={'to'}
				onChange={onValueChange}
			/>
		</Grid>
	);
};

export default Times;
