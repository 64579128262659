import { Typography } from '@mui/material';

interface HeaderProps {
	text: string;
	type?: null | undefined | 'title';
	whiteText?: boolean;
}

const Header = ({ text, type, whiteText }: HeaderProps) => {
	let styleToAdd = {};
	if (whiteText) {
		styleToAdd = { color: 'white' };
	}

	if (!type) {
		return (
			<Typography
				variant={'h2'}
				style={{ marginBottom: 12, marginTop: 0, ...styleToAdd }}
			>
				{text}
			</Typography>
		);
	} else if (type === 'title') {
		return (
			<Typography
				variant={'h1'}
				style={{ marginBottom: 12, marginTop: 0, ...styleToAdd }}
			>
				{text}
			</Typography>
		);
	}
};

export default Header;
