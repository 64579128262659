import React, { useContext, useEffect, useRef } from 'react';

import { sendAPI } from './../../api/helper';
import { Context } from '../../store/modalContext/store';
import CircularProgress from '@mui/material/CircularProgress';


const AddCardSalt = () => {

    //@ts-ignore
	const [modalState] = useContext(Context);
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [paReq, setPaReq] = React.useState('');
    const formRef = useRef();
    const formRefTest = useRef();
    const buttonRef = useRef();



    const sendAddCard = async () => {
        const result = await sendAPI(
            'paymentSalt/verifyCard/',
            {},
            modalState.openModal,
            null,
            () => {
                // window.location.reload();
            }
        );

        if(result && result.redirectUrl && result.paReq) {
            setRedirectUrl(result.redirectUrl)
            setPaReq(result.paReq)
            setTimeout(() => {
                //@ts-ignore
                buttonRef.current.click();

                // handleButtonClick();
            }, 2000)
        }
        
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <h1 style={{ display: 'none', textAlign: 'center', marginTop: 30 }}>Add card</h1>
            {/* <button style={{display: 'none'}} onClick={() => {
                sendAddCard();
            }}>
                Add card press
            </button>

            <button style={{display: 'none'}} onClick={() => {
                capturePayment();
            }}>
                Send payment of 0
            </button> */}

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                <CircularProgress style={{color: '#111', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop: 90 }} />

            </div>


            <form id="formId" style={{display: 'none'}} acceptCharset="UTF-8" ref={formRef} action={redirectUrl ? redirectUrl : ''} method="post">
                {/* <input type="hidden" name="PaReq" id="PaReq" value={paReq ? paReq : ''} /> */}
                <input  name="creq" id="creq" value={''} />
                <input  name="PaReq" id="pareq" value={''} />
                <input  id="MD" name="MD" value="" />
                <input id="threeDSSessionData"  name="threeDSSessionData" value="" />                
                <button id="submitbutton" ref={buttonRef}>Submit</button>
            </form>
            
        </div>
    );

}   

export default AddCardSalt;

/* <form ref={formRefTest} action={'http://localhost:3000/invalidate'} method="post">
    <input type="hidden" name="creq" value={'test'} />
    <button ref={buttonRef} onClick={handleButtonClickTest}>Submit Test</button>
</form>
<button onClick={() => {
    //@ts-ignore
    console.log("buttonRef.current: ", buttonRef.current);
    //@ts-ignore
    buttonRef.current.click();

}}>OUTSIDE</button> */
// const capturePayment = async () => {
//     const result = await sendAPI(
//         'paymentSalt/capturePayment/',
//         {},
//         modalState.openModal,
//         null,
//         () => {
//             // window.location.reload();
//         }
//     );
// }