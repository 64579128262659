import { getUserPlaceColumns } from './../../business/tableHelper';
import PageContainer from './../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import PlaceUser from './PlaceUser';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';

interface PlaceUserProps {
	placeName: string;
	showPageContainer: boolean;
	redirectToItem?: string;
	user: any;
	header?: string;
}

const PlaceUsers = ({ showPageContainer, header, user }: PlaceUserProps) => {
	return (
		<React.Fragment>
			<ManageItems
				key={8}
				header={'Emails that can login to place'}
				getData={{
					url: 'placeusers/getPlaceUsers',
					body: {},
				}}
				buttonText={'Add user'}
				includeContainer={showPageContainer}
				redirectLink={ROUTE_TYPES[ROUTE_NAMES.placeuser].route}
				columns={getUserPlaceColumns()}
				noItemsText={'No users have been added.'}
			/>
		</React.Fragment>
	);
};

export default PlaceUsers;
