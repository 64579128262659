import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';

const drawerWidth = 240;

export const paperStyles = makeStyles((theme: Theme) => ({
	root: {
		// borderColor: 'blue',
		// borderRadius: 5,
		// marginTop: theme.spacing(3),
		// paddingTop: theme.spacing(1),
	},
}));

export const formControlStyles = makeStyles((theme: Theme) => ({
	root: {
		marginTop: theme.spacing(0),
	},
}));

export const buttonStyles = makeStyles((theme: Theme) => ({
	root: {
		// margin: theme.spacing(1),
		marginTop: theme.spacing(3),
		marginRight: theme.spacing(1),

		// minWidth: styleConstants.width,
	},
}));

export const dropdownStyles = makeStyles((theme: Theme) => ({
	formControl: {
		margin: theme.spacing(1),
		// minWidth: styleConstants.width,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

export const inputStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		margin: {
			// margin: theme.spacing(1),
		},
		withoutLabel: {
			// marginTop: theme.spacing(3),
		},
		textField: {
			// minWidth: styleConstants.width,
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			// padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
		},
		// customInputLabel: {
		//     '& legend': {
		//         visibility: 'visible',
		//     },
		// },
	})
);

export const cardStyles = makeStyles({
	root: {
		// maxWidth: 345,
	},
	avatar: {
		backgroundColor: 'red',
	},
});

export const dropzoneStyles = makeStyles({
	root: {
		// maxWidth: 345,
	},
});

export const headerStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			marginTop: 100,
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1,
			},
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			// ...theme.mixins.toolbar,
		},
		// content: {
		// 	flexGrow: 1,
		// 	padding: theme.spacing(3),
		// },
	})
);

// theme color:
// paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// },
