export const loginTexts = {
	buttonText: (forgotPasswordToken, showForgotPassword, showRegister) => {
		if (forgotPasswordToken) return 'Confirm new password';
		if (showForgotPassword) return 'Confirm';
		if (showRegister) return 'Create account';
		return 'Login';
	},
	underInputText: (forgotPasswordToken, showForgotPassword, showRegister) => {
		if (forgotPasswordToken) return '';
		if (showForgotPassword) return 'Back to login';
		if (showRegister) return '';
		return 'Forgot password';
	},
	headerText: (forgotPasswordToken, showForgotPassword, showRegister) => {
		if (forgotPasswordToken) return 'Add your new password';
		if (showForgotPassword) return 'Reset password';
		if (showRegister) return 'Hi and welcome!';
		return 'Hi, welcome back!';
	},
	lowerHeaderText: (forgotPasswordToken, showForgotPassword, showRegister) => {
		if (forgotPasswordToken) return 'You can change your password here below.';
		if (showForgotPassword)
			return 'Add your email to receive an email with details to reset your password.';
		if (showRegister)
			return 'Please select a strong password for your account. ';
		return "Get your login link to your email - If you've been added";
	},
};

export const isResetPasswordUrl = (urlQuery: any) => {
	if (
		urlQuery &&
		urlQuery['email'] &&
		urlQuery['resetpassword'] &&
		urlQuery['email'][0] &&
		urlQuery['resetpassword'][0]
	) {
		return true;
	} else {
		return false;
	}
};

export const getJoinPlaceToken = (url: string) => {
	if (url) {
		let splitUrl = url.split('/');
		if (splitUrl && splitUrl.length > 2) {
			let token = splitUrl[splitUrl.length - 1];
			let name = splitUrl[splitUrl.length - 2];
			if (name === 'joinPlace' && token && token.length > 5) {
				return token;
			}
		}
	}
	return '';
};
