import Chip from '@mui/material/Chip';
import { currenciesObject } from '../data/data';
import { getCurrencyTypeByCode } from './../business/currencyHelper';

export const getMlRenderCell = (ml, totalMl) => {
	let mlLeftPercentage = (ml / totalMl) * 100;
	let color = 'gray';
	if (mlLeftPercentage >= 50) {
		color = 'green';
	} else if (mlLeftPercentage > 20 && mlLeftPercentage < 50) {
		color = '#f3ba00';
	} else if (mlLeftPercentage <= 20) {
		color = 'red';
	}

	if (mlLeftPercentage > 100) {
		return <div></div>;
	}

	return (
		<div
			style={{
				width: '100%',
				height: 25,
				backgroundColor: 'gray',
				borderRadius: 10,
				position: 'relative',
				textAlign: 'center',
				overflow: 'hidden',
			}}
		>
			<div
				style={{
					width: `${mlLeftPercentage}%`,
					backgroundColor: color,
					height: 25,
					borderTopLeftRadius: 10,
					borderBottomLeftRadius: 10,
				}}
			></div>
			<div style={{ position: 'absolute', top: '2px', width: '100%' }}>
				<span
					style={{
						color: 'white',
						// position: 'absolute',
						fontSize: 12,
						textAlign: 'center',
						// left: `${mlLeftPercentage - 30}%`,
					}}
				>
					{ml + ' ml / ' + totalMl + ' ml'}
				</span>
			</div>
		</div>
	);
};

export const getKegInformation = (keg, drink, currency) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			}}
		>
			<div style={{ width: '150px' }}>
				{getMlRenderCell(keg.ml, keg.totalMl)}
			</div>
			{drink ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						paddingLeft: 5,
					}}
				>
					{drink.price ? (
						<Chip
							style={{
								backgroundColor: 'green',
								color: 'white',
								marginLeft: '8px',
								marginRight: '8px',
								height: 25,
								borderRadius: 10,
							}}
							label={drink.price + getCurrencyTypeByCode(currency)}
						/>
					) : null}
					<span style={{ marginTop: 4 }}>
						{drink.name}, {drink.alc}% {}
					</span>
				</div>
			) : null}
		</div>
	);
};

export const getCurrencyAmount = (
	item,
	keyAmount,
	keyCurrency,
	showCurrencyName = true,
	currencyGiven = ''
) => {
	if (item) {
		let amount = item[keyAmount];
		let currency = '';
		if (keyCurrency) {
			currency = item[keyCurrency];
		} else if (currencyGiven) {
			currency = currencyGiven;
		}

		if (currency) {
			let currencyInformation = currenciesObject[currency];
			console.log('curr: ', currency, currenciesObject);
			if (currencyInformation) {
				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<span>
							{amount}
							{}
							{currencyInformation.symbol_native} ({currency}
							{showCurrencyName ? ' - ' + currencyInformation.name : null})
						</span>
					</div>
				);
			}
		}
	}
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			}}
		>
			<span>Currency not found.</span>
		</div>
	);
};
