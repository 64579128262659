import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import './registerPlace.scss';
import toriologo from '../../assets/toriologo.png';
import TextField from '@mui/material/TextField';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { formControlStyles } from '../../styles/materialStyles';
import {
	useConfirmRegisterMutation,
	useConfirmRegisterEmailMutation,
} from '../../store/api/api';

const RegisterPlace = () => {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [emailSent, setEmailSent] = React.useState(false);
	const [companyEmail, setCompanyEmail] = React.useState('');
	const [placeName, setPlaceName] = React.useState('');
	const [name, setName] = React.useState('');

	const [googleEmail, setGoogleEmail] = React.useState('');
	const [loginEmail, setLoginEmail] = React.useState('');
	const [facebookEmail, setFacebookEmail] = React.useState('');
	const [subscription, setSubscription] = React.useState('Barhopp');
	const [confirmRegisterEmail] = useConfirmRegisterEmailMutation();
	const [confirmRegister] = useConfirmRegisterMutation();

	React.useEffect(() => {
		console.log('modalState: ', window.location.search);
		if (window.location.search) {
			if (
				window.location.search.includes('email') &&
				window.location.search.includes('code')
			) {
				let email = window.location.search.split('=')[1];
				if (email) {
					email = email.split('&')[0];
				}
				let code = window.location.search.split('=')[2];

				if (email && code) {
					handleRegisterUrl(email, code);
				}

				// modalState.openModal(
				// 	'Success',
				// 	'You will receive an email with the next steps. ',
				// 	'success'
				// );
				// setTimeout(() => {
				// 	window.location.href = 'https://torio.is';
				// }, 3000);
			}
		}
	}, []);

	const handleRegisterUrl = async (email, code) => {
		let confirmResult = await confirmRegister({
			email: email,
			code: code,
			modal: modalState,
		}).unwrap();
		if (confirmResult && confirmResult.success) {
			setLoading(true);
			modalState.openModal(
				'Success',
				'Email has been confirmed. We will let you know about the next steps. ',
				'success'
			);
			setTimeout(() => {
				setLoading(false);
				window.location.href = 'https://torio.is';
			}, 3000);
		}
	};

	let background =
		subscription === 'Barhopp'
			? 'linear-gradient(#113bd7, #ec4899)'
			: 'linear-gradient(#000000, #243b55)';

	const getSubscriptionText = () => {
		switch (subscription) {
			case 'Barhopp':
				return "Barhopp can help your place attract customers with events, happy hour deals and more. We will setup your place on Barhopp and you'll get access to a page where you can edit information if you wish. Access is free and can be revoked at anytime.";
			case 'Web / App creation':
				return 'Torio specializes in web services, app creation for IOS and Android and IoT solutions. Contact us for more details. ';
			case 'Torio':
				return 'Torio IoT department can help with various tasks relating to self service and more. Contact us for more details. ';
			default:
				return '';
		}
	};

	return (
		<div className="register-container" style={{ background: background }}>
			<div className="login-box">
				<img src={toriologo} alt="torio-logo" className="register-torio-logo" />
				<h2>Register for {subscription}</h2>
				<p style={{ marginTop: -9, marginBottom: 18 }}>
					{getSubscriptionText()}
				</p>
				<form>
					<p>Service</p>

					<FormControl
						fullWidth={true}
						className={formControlStyles().root}
						variant="outlined"
						// style={{ width: '90%' }}
						style={{
							display: 'flex',
							flexDirection: 'row',
							// width: '100%', marginBottom: 16,
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<InputLabel htmlFor={'type'}>Type</InputLabel>
						<Select
							// native
							// autoWidth
							id={'type'}
							onChange={(e) => {
								console.log('e: ', e);
								setSubscription(e.target.value);
							}}
							label={'Type'}
							value={subscription ? subscription : ''}
							style={{
								backgroundColor: 'white',
								width: '100%',
								marginBottom: 16,
							}}
						>
							<MenuItem value={'Barhopp'} aria-label="none">
								Barhopp
							</MenuItem>

							<MenuItem value={'Web / App creation'} aria-label="none">
								Web / App creation
							</MenuItem>

							<MenuItem value={'Torio'} aria-label="none">
								Torio
							</MenuItem>
						</Select>
					</FormControl>

					<p>Company</p>

					<TextField
						label={'Name of place/company'}
						value={placeName}
						placeholder="Place name"
						style={{ width: '100%', marginBottom: 16 }}
						onChange={(e) => {
							setPlaceName(e.target.value);
						}}
					/>

					<TextField
						label={'Company email'}
						value={companyEmail}
						placeholder="Must be @companyName"
						style={{ width: '100%', marginBottom: 16 }}
						onChange={(e) => {
							setCompanyEmail(e.target.value);
						}}
					/>

					<p>Login details</p>

					<TextField
						label={'Your name'}
						value={name}
						placeholder="Your name"
						style={{ width: '100%', marginBottom: 16 }}
						onChange={(e) => {
							setName(e.target.value);
						}}
					/>

					<TextField
						label={'Login email'}
						value={loginEmail}
						placeholder="Can be the same as company email"
						style={{ width: '100%', marginBottom: 16 }}
						onChange={(e) => {
							setLoginEmail(e.target.value);
						}}
					/>

					<p>Social media login (optional)</p>

					{subscription === 'Barhopp' ? (
						<div>
							<TextField
								label={'Facebook email (optional)'}
								value={facebookEmail}
								placeholder="Facebook email"
								style={{ width: '100%', marginBottom: 16 }}
								helperText="Allows for easier login and various imports from Facebook. "
								onChange={(e) => {
									setFacebookEmail(e.target.value);
								}}
							/>
						</div>
					) : null}

					<TextField
						label={'Google email (optional)'}
						value={googleEmail}
						helperText="If you want to use login with Google"
						placeholder="For easier login"
						style={{ width: '100%', marginBottom: 16 }}
						onChange={(e) => {
							setGoogleEmail(e.target.value);
						}}
					/>

					<div style={{ margin: 'auto' }}>
						{loading ? null : (
							<a
								href="#"
								onClick={async () => {
									setLoading(true);
									let res = await confirmRegisterEmail({
										email: companyEmail,
										placeName: placeName,
										facebookEmail: facebookEmail,
										subscription: subscription,
										modal: modalState,
									}).unwrap();
									if (res && res.success) {
										setEmailSent(true);
										// setTimeout(() => {
										// 	window.location.href = 'https://torio.is';
										// }, 3000);
									}
									setLoading(false);
								}}
							>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								{emailSent ? 'Resend confirmation' : 'Register'}
							</a>
						)}
					</div>

					<a
						href="https://torio.is"
						style={{ marginTop: 33, color: '#111', cursor: 'pointer' }}
					>
						Cancel
					</a>
				</form>
			</div>
		</div>
	);
};

export default RegisterPlace;
