import { getDeviceColumns, getTapColumns } from '../../business/tableHelper';
import PageContainer from '../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import { useHistoryNav } from '../../hooks/useHistoryNav';

interface PlaceUserProps {
	user: any;
}

const Devices = ({ user }: PlaceUserProps) => {
	return (
		<React.Fragment>
			<ManageItems
				// customButtons={[
				// 	{
				// 		text: 'View live tap monitor',
				// 		onClick: () => {
				// 			navigate('/admin/monitortaps');
				// 		},
				// 	},
				// ]}
				header={'Devices'}
				key={1922}
				getData={{
					url: 'devices/devicesall',
					// url: 'taps/taps', // ROUTE_TYPES[ROUTE_NAMES.taps].getRoute,
					body: {},
				}}
				redirectLink={ROUTE_TYPES[ROUTE_NAMES.device].route}
				columns={getDeviceColumns()}
				noItemsText={'No devices have been added.'}
				includeContainer={true}
				buttonText={'Create device'}
			/>
		</React.Fragment>
	);
};

export default Devices;
