import MainButton from '../buttons/MainButton';
import { useLocation } from 'react-router-dom';
import { useHistoryNav } from './../../hooks/useHistoryNav';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { routeToId, goBack } from './../../business/routingHelper';
import { eventPlaceholderImage } from '../../data/imageData';
import { Paper, Snackbar } from '@mui/material';
import React from 'react';

const EventItem = ({
	id,
	name,
	description,
	from,
	to,
	image,
	onClick,
	onRemovePermanently,
	onRemove,
	showRemove,
}) => {
	const history = useHistoryNav();
	const location = useLocation();
	let route = location.pathname;
	const [smallerDescription, setSmallerDescription] =
		React.useState<string>('');
	const [showBigDescription, setShowBigDescription] =
		React.useState<boolean>(false);

	React.useEffect(() => {
		if (description) {
			if (description.length > 100) {
				setSmallerDescription(description.substring(0, 100) + '...');
			} else {
				setSmallerDescription(description);
			}
		}
	}, [description]);

	return (
		<Paper
			style={{
				border: '1px solid #f1f1f1',
				paddingLeft: 0,
				paddingTop: 0,
				// maxWidth: 330,
				width: '44%',
				minWidth: 290,
				margin: 10,
				marginBottom: 17,
				// width: '10%',
				flexDirection: 'column',
				position: 'relative',
			}}
		>
			<div
				style={{
					backgroundPosition: 'center',
					backgroundImage: `url(${image ? image : eventPlaceholderImage})`,
					width: '100%',
					height: 100,
					overflow: 'hidden',
					backgroundSize: 'cover',
					backgroundRepeat: 'none',
				}}
			></div>

			{showRemove ? (
				<div
					style={{
						position: 'absolute',
						cursor: 'pointer',
						top: 10,
						right: 10,
						backgroundColor: '#f96880',
						width: 40,
						height: 40,
						borderRadius: 40,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CloseIcon
						onClick={() => {
							if (showRemove) {
								onRemove(id);
							}
							// showRemove ? onRemove() : null;
						}}
						style={{
							fontSize: 22,
							color: '#111',
						}}
					/>
				</div>
			) : null}
			<div
				style={{
					position: 'absolute',
					cursor: 'pointer',
					top: 10,
					right: showRemove ? 55 : 10,
					backgroundColor: '#ffe16f',
					width: 40,
					height: 40,
					borderRadius: 40,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<EditIcon
					onClick={() => {
						routeToId(history, route, id);
					}}
					style={{
						fontSize: 22,
						color: '#111',
					}}
				/>
			</div>

			<div style={{ padding: 20 }}>
				<div style={{ minHeight: 200 }}>
					<div
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<h3 style={{ display: 'inline-block', fontSize: 18, width: '60%' }}>
							{name}
						</h3>
						<div
							style={{
								display: 'inline-block',
								color: 'gray',
								width: '40%',
								textAlign: 'right',
							}}
						>
							{from} - {to}
						</div>
					</div>
					<p style={{ wordBreak: 'break-all' }}>
						{showBigDescription ? description : smallerDescription}
						{!showBigDescription && description.length > 100 ? (
							<b
								style={{ cursor: 'pointer' }}
								onClick={() => setShowBigDescription(true)}
							>
								{' '}
								See all
							</b>
						) : null}
					</p>
				</div>

				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{!showRemove ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'row',
								width: '100%',
							}}
						>
							<MainButton
								color={'primary'}
								text={'Add event'}
								onClick={() => {
									onClick(id);
								}}
							/>
							<MainButton
								text={'Delete'}
								buttonColor={'error'}
								style={{ marginRight: 0 }}
								removePadding={true}
								onClick={() => {
									onRemovePermanently(id, name);
								}}
							/>
						</div>
					) : null}
				</div>
			</div>
		</Paper>
	);
};

export default EventItem;
