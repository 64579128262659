import MainSwitchController from '../../components/switch/MainSwitchController';
import MainInputController from '../../components/inputs/MainInputController';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import FormContainer from '../../components/containers/FormContainer';
import {
	useGetProfileMutation,
	useManageProfileMutation,
} from '../../store/api/api';
import { Alert, Button, Grid, Typography } from '@mui/material';
import ConfirmPhoneNumber from './ConfirmPhoneNumber';
import useManageHook from '../../components/hooks/useManageHook';
import {
	userSchema,
	profileInitalized,
	profile,
} from '@toriosoftware/torioshared/dist/profile';

import PageContainer from '../../components/containers/PageContainer';
import MainContainer from '../../components/containers/MainContainer';
import SmallDisplayCard from '../../components/cards/SmallDisplayCard';
import { Link, useLocation } from 'react-router-dom';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import { ROUTE_NAMES } from '@toriosoftware/torioshared/dist/config';
import PanelContainer from '../../components/containers/PanelContainer';
import React from 'react';
import SubHeader from '../../components/texts/SubHeader';
import ButtonWithIcon from '../../components/buttons/ButtonWithIcon';
import FacebookIcon from '@mui/icons-material/Facebook';

const Profile = ({ user }) => {
	const [getProfile] = useGetProfileMutation();
	const [manageProfile] = useManageProfileMutation();

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		getFieldState,
		setFocus,
	} = useForm<profile>({
		defaultValues: profileInitalized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(userSchema),
	});

	let facebookEmail = watch('facebookEmail');

	const { modelState, isLoaded, history, isNew, id, data } = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.profile, user)?.data?.get(
				getProfile,
				reset
			),
		],
		user: user,
	});

	return (
		<PageContainer header={'Your profile'}>
			<MainContainer direction={'row'}>
				<SmallDisplayCard md={4} header={'Email'} text={user.email} />
				<SmallDisplayCard md={4} header={'Role'} text={user.role} />
				<SmallDisplayCard md={4} header={'Managing'} text={user.placeName} />
			</MainContainer>

			<FormContainer
				modal={modelState}
				handleSubmit={handleSubmit}
				isDirty={isDirty}
				onSubmitFunction={manageProfile}
				isLoaded={isLoaded}
				title={'Account'}
				depth={0}
				history={history}
				hideRemove={true}
			>
				{user.systemRole === 'Admin' ? (
					<div>
						<SubHeader text={'Simpler login with Torio'} />
						<MainInputController
							control={control}
							label={'Facebook email'}
							type={'text'}
							identifier={'facebookEmail'}
							md={12}
						/>

						<ButtonWithIcon
							icon={() => {
								return <FacebookIcon />;
							}}
							width={270}
							text={'Connect to Facebook'}
							onClick={() => {}}
							isActive={
								facebookEmail && facebookEmail.length > 3 ? true : false
							}
							color={'#4267B2'}
						/>
					</div>
				) : null}

				<SubHeader text={'Profile details'} />

				<MainInputController
					control={control}
					label={'First name'}
					type={'text'}
					identifier={'firstName'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Last name'}
					type={'text'}
					identifier={'lastName'}
					md={6}
				/>
			</FormContainer>

			{/* <MainContainer direction={'row'}>
				<PanelContainer md={12} header={'Place invites'}>
					<Grid
						sm={12}
						xs={12}
						style={{ width: '100%', paddingBottom: 15 }}
						item
					>
						{invites && invites.length > 0 ? (
							invites.map((invite, index) => {
								if (!invite?.place?.name || !invite.inviteLink) {
									return null;
								}
								console.log('invite.inviteLink:Þ ', invite.inviteLink);

								return (
									<div key={index}>
										<Grid
											style={{
												paddingTop: 34,
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
											}}
										>
											<Typography
												variant={'body1'}
												fontSize={20}
												fontWeight={400}
											>
												You have been invited to {invite?.place?.name}.
											</Typography>
											<a
												style={{
													color: '#111',
													textDecoration: 'none',
													fontWeight: 'bolder',
												}}
												target={'_blank'}
												rel="noreferrer"
												href={
													invite?.inviteLink?.includes('https://')
														? invite.inviteLink
														: 'https://' + invite.inviteLink
												}
											>
												View invite
											</a>
										</Grid>
										<span>{invite.role}</span>
									</div>
								);
							})
						) : (
							<Typography variant={'body1'} fontSize={20} fontWeight={400}>
								You have no pending invites.
							</Typography>
						)}
					</Grid>
				</PanelContainer>
			</MainContainer> */}
		</PageContainer>
	);
};

export default Profile;

// <MainSwitchController
// control={control}
// label={'Allow login with phone number'}
// md={12}
// identifier={'allowPhoneLogin'}
// />

// <MainInputController
// control={control}
// label={'Phone country code'}
// type={'text'}
// identifier={'countryPhoneCode'}
// md={4}
// />

// <MainInputController
// control={control}
// label={'Phone'}
// type={'text'}
// identifier={'phone'}
// md={8}
// />
// {!allowPhoneLogin ? (
// <Grid item md={12}>
// 	<Alert style={{ width: '100%' }} severity="info">
// 		You will not be able to login with your phone unless pressing
// 		'Allow login with phone number'
// 	</Alert>
// </Grid>
// ) : null}

// <ConfirmPhoneNumber
// allowPhoneLogin={allowPhoneLogin}
// countryPhoneCode={countryPhoneCode}
// phone={phone}
// phoneConfirmed={originalUser?.phoneConfirmed}
// getFieldState={getFieldState}
// setFocus={setFocus}
// />
