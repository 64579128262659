import { getCreditColumns, getLogsColumns } from '../../business/tableHelper';
// import { getUserPlaceColumns } from './business/tableHelper';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import moment from 'moment';

interface PlaceUserProps {
	user: any;
}

const CreditReceipts = ({ user }: PlaceUserProps) => {
	return (
		<React.Fragment>
			<ManageItems
				noDoubleClick={true}
				header={'Receipts'}
				hideNewButton={true}
				key={1927}
				getData={{
					url: 'logs/getUserReceiptsCompany',
					body: {},
				}}
				redirectLink={ROUTE_TYPES[ROUTE_NAMES.creditreceipt].route}
				columns={getCreditColumns()}
				noItemsText={'No receipts found.'}
				includeContainer={true}
			/>
		</React.Fragment>
	);
};

export default CreditReceipts;
