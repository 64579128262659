import { Typography } from '@mui/material';
import { LIST_TEXT_SIZE } from '../Constants';

const ListText = ({ text, textAlign }) => {
	return (
		<Typography
			style={{
				color: '#868686',
				fontWeight: 400,
				// fontSize: 24,
				// lineHeight: 1.2,
				textAlign: textAlign,
			}}
			sx={{
				fontSize: LIST_TEXT_SIZE, //{ md: 26, sm: 22, xs: 20 },
			}}
		>
			{text}
		</Typography>
	);
};

export default ListText;
