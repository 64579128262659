import { Navigate } from 'react-router-dom';
import { AUTH_TYPES } from '@toriosoftware/torioshared/dist/config';
import Login from '../screens/Login/Login';
import ConfirmEmailMain from '../screens/Login/ConfirmEmailMain';
import SelectPlace from '../screens/Login/SelectPlace';

const LoginRoutes = ({ user, modalState, withToken, withEmailToken }: any) => {
	// User is not logged in
	if (!user || !AUTH_TYPES.companyAuth.isFirstAuth(user)) {
		return <Login withToken={withToken} />;
		// User is not allowed to continue
	} else if (!AUTH_TYPES.companyAuth.isAuthAllowed(user)) {
		//TODOS: display not allowedisAuthAllowed
		return <Navigate to="/" />;
	}
	// else if (!AUTH_TYPES.companyAuth.isAuthConfirmed(user)) {
	// 	return (
	// 		<ConfirmEmailMain
	// 			modalState={modalState}
	// 			withEmailToken={withEmailToken}
	// 			email={user.email}
	// 		/>
	// 	);
	// }
	else if (!AUTH_TYPES.companyAuth.isPlaceSelected(user)) {
		return <SelectPlace modalState={modalState} />;
	} else {
		return <Navigate to="/admin" />;
	}
};

export default LoginRoutes;
