import { useState } from 'react';
import MainInput from '../../components/inputs/MainInput';
import MainDropdown from '../../components/dropdowns/MainDropdown';
import MainButton from '../../components/buttons/MainButton';
import MainContainer from '../../components/containers/MainContainer';
// import KegIcon from './KegIcon';
import PanelContainer from '../../components/containers/PanelContainer';
import ButtonGroup from '../../components/buttons/ButtonGroup';
import { IconButton, Modal } from '@mui/material';
import PageContainer from '../../components/containers/PageContainer';
import LineContainer from '../../components/lines/LineContainer';
import { kegsDropdownMaker } from '../../business/dropdownHelper';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import useItems from '../../hooks/useItems';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon

function KegChange({ user, isShow, setShow, onAdd }: any) {
	const initialItem = {
		kegId: '',
		drinkName: '',
		drinkPrice: 0,
		kegPrice: 0,
		numberOfKegs: 0,
		oldNumberOfKegs: 0,
	};

	const [item, setItem] = useState(initialItem);

	const [kegs, keg, kegsDropdown] = useItems({
		id: '',
		dropdownFunction: kegsDropdownMaker.bind(this),
		useDropdown: true,
		url: 'kegs/getKegs/',
	});

	const initializeModal = () => {
		setItem(initialItem);
	};

	return (
		<Modal
			open={isShow}
			onClose={() => {
				setShow(false);
			}}
			aria-labelledby="Add sale"
			aria-describedby="Change sale"
		>
			<form onClick={() => {}}>
				<PageContainer header={'Sale'}>
					<MainContainer>
						<PanelContainer isLoaded={true} header={'Items ordered'}>
							<IconButton
								aria-label="close"
								onClick={() => {
									// setOpen(false);
									initializeModal();
									setShow(false);
								}}
								style={{
									position: 'absolute',
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>

							<MainDropdown
								label={'Drink'}
								options={kegsDropdown}
								size={12}
								optionsValueKey={'_id'}
								optionsTextKey={'text'}
								value={item.kegId}
								onChange={(a, b) => {
									let selectedKeg = kegs.find((k) => k._id === a.target.value);
									console.log('sele: keg: ', selectedKeg);
									setItem({
										...item,
										kegId: a.target.value,
										drinkName: selectedKeg?.drink?.name,
										drinkPrice: selectedKeg?.price,
										kegPrice: selectedKeg?.price,
									});
								}}
								isWithLink={true}
								link={`/${ROUTE_TYPES[ROUTE_NAMES.kegs].route}/0`}
								id={'keg'}
							/>

							<MainInput
								label={'Number of kegs'}
								value={item.numberOfKegs}
								onChange={(i, value) => {
									setItem({
										...item,
										numberOfKegs: value,
										oldNumberOfKegs: value,
									});
								}}
								// helperText={'Lager, IPA, Soda, etc.'}
								type={'number'}
								identifier={'numberOfKegs'}
								md={12}
							/>
							{item.kegId ? (
								<LineContainer>
									<ButtonGroup>
										<MainButton
											// isSubmit={true}
											onClick={() => {
												onAdd(item);
												initializeModal();
												setShow(false);
											}}
											// disabled={false}
											text={'Save changes'}
											color={'primary'}
										/>
									</ButtonGroup>
								</LineContainer>
							) : null}
						</PanelContainer>
					</MainContainer>
				</PageContainer>
			</form>
		</Modal>
	);
}

export default KegChange;
