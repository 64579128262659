import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ContextStore from './store/modalContext/store';
// import { ThemeProvider } from '@mui/material';
import {
	ThemeProvider,
	Theme,
	StyledEngineProvider,
} from '@mui/material/styles';
import theme from '../src/Theme';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { ConfirmProvider } from 'material-ui-confirm';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

// const middleware = [thunk];

// const rootReducer = combineReducers({
// 	// ScanReducer,
// 	UserReducer,
// });

// const store = createStore(rootReducer, applyMiddleware(...middleware));

ReactDOM.render(
	<LocalizationProvider
		dateAdapter={AdapterMoment}
		// libInstance={moment}
		// utils={MomentUtils}
		// locale="is"
	>
		<Provider store={store}>
			<PersistGate
				persistor={persistor}
				loading={() => {
					return <div></div>;
				}}
			>
				{/* <BrowserRouter> */}
				{/* <LocalizationProvider dateAdapter={DateAdapter}> */}
				<ContextStore>
					{/* <React.StrictMode> */}
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<ConfirmProvider>
								<App />
								{/* </React.StrictMode> */}
							</ConfirmProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</ContextStore>
				{/* </LocalizationProvider> */}
				{/* </BrowserRouter> */}
			</PersistGate>
		</Provider>
	</LocalizationProvider>,
	document.getElementById('root')
);

reportWebVitals();
