import React, { useContext } from 'react';
import MainContainer from '../../components/containers/MainContainer';
import { Context } from '../../store/modalContext/store';
import PanelContainer from '../../components/containers/PanelContainer';
import ButtonGroup from '../../components/buttons/ButtonGroup';
import MainButton from '../../components/buttons/MainButton';
import HappyHourItem from './HappyHourItem';
import { saveHappyHour, getHappyHours } from '../../api/happyHourData';
import { getCurrencyAppend } from '../../business/currencyHelper';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { useGetUserQuery } from '../../store/api/api';
import Snackbar from '@mui/material/Snackbar';
import PageContainer from '../../components/containers/PageContainer';

let tempObject = {
	from: '',
	to: '',
	drink: '',
	wine: '',
	whiskey: '',
	shot: '',
	cocktail: '',
	champagne: '',
	happyText: '',
	isHappyHour: false,
	isHappyHourText: false,
};

// import MainDropdown from './MainDropdown';
// import SubHeader from './SubHeader';
// import { useDispatch } from 'react-redux';
// import MainRoutes from './MainRoutes';
// import MainInput from './MainInput';
// import {
// 	saveDetailedPlaceInfo,
// 	saveLinks,
// 	getDetailedPlace,
// } from './api/placeInfoData';
// import { goOutOfItem } from './business/routingHelper';

const HappyHour = ({ user }) => {
	//@ts-ignore
	const [state] = useContext(Context);
	// const { data: user } = useGetUserQuery();
	const history = useHistoryNav();
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [isSnackOpen, setIsSnackOpen] = React.useState(false);

	// let user = useAppSelector(getUserSelector);

	const [happyHour, setHappyHour] = React.useState({
		monday: {
			...tempObject,
		},
		tuesday: {
			...tempObject,
		},
		wednesday: {
			...tempObject,
		},
		thursday: {
			...tempObject,
		},
		friday: {
			...tempObject,
		},
		saturday: {
			...tempObject,
		},
		sunday: {
			...tempObject,
		},
	});

	React.useEffect(() => {
		getHappyHoursEffect();
	}, []);

	const getHappyHoursEffect = async () => {
		let result = await getHappyHours();

		if (result && result.success) {
			if (result.happyHours) {
				setHappyHour(result.happyHours);
			}
			setIsLoaded(true);
		} else {
			state.openModal(
				'Error',
				'You do not have access to this area. ',
				'error'
			);
			history('./');
		}
	};

	const onValueChange = (key, value, day) => {
		const newObject = Object.assign({}, happyHour);
		newObject[day][key] = value;
		setHappyHour(newObject);
	};

	const onCopyEntry = (targetFrom, targetTo) => {
		const newObject = Object.assign({}, happyHour);

		if (targetTo === 'all') {
			[
				'monday',
				'tuesday',
				'wednesday',
				'thursday',
				'friday',
				'saturday',
				'sunday',
			].forEach((dayTemp) => {
				let newDay = {
					...newObject[targetFrom],
				};
				newObject[dayTemp] = newDay;
			});
		} else {
			let newDay = {
				...newObject[targetFrom],
			};
			newObject[targetTo] = newDay;
		}

		setHappyHour(newObject);
		setIsSnackOpen(true);
	};

	const onSave = async () => {
		let result = await saveHappyHour(happyHour, state.openModal);
	};

	let currencyAppend = getCurrencyAppend(
		user.placeCurrency,
		user.currencyAppend
	);

	return (
		<PageContainer header={'Happy hour'}>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={isSnackOpen}
				autoHideDuration={3500}
				onClose={() => {
					setIsSnackOpen(false);
				}}
				message="Happy hour successfully copied. Changes have not been saved. "
				key={2}
			/>
			<MainContainer>
				<PanelContainer isLoaded={isLoaded} header={'Happy hour'}>
					<HappyHourItem
						currencyAppend={currencyAppend}
						item={happyHour.monday}
						onCopyEntry={onCopyEntry}
						onValueChange={onValueChange}
						dayText={'Monday'}
					/>
					<HappyHourItem
						currencyAppend={currencyAppend}
						onCopyEntry={onCopyEntry}
						item={happyHour.tuesday}
						onValueChange={onValueChange}
						dayText={'Tuesday'}
					/>
					<HappyHourItem
						currencyAppend={currencyAppend}
						item={happyHour.wednesday}
						onCopyEntry={onCopyEntry}
						onValueChange={onValueChange}
						dayText={'Wednesday'}
					/>
					<HappyHourItem
						currencyAppend={currencyAppend}
						item={happyHour.thursday}
						onCopyEntry={onCopyEntry}
						onValueChange={onValueChange}
						dayText={'Thursday'}
					/>
					<HappyHourItem
						currencyAppend={currencyAppend}
						item={happyHour.friday}
						onCopyEntry={onCopyEntry}
						onValueChange={onValueChange}
						dayText={'Friday'}
					/>
					<HappyHourItem
						currencyAppend={currencyAppend}
						item={happyHour.saturday}
						onCopyEntry={onCopyEntry}
						onValueChange={onValueChange}
						dayText={'Saturday'}
					/>
					<HappyHourItem
						currencyAppend={currencyAppend}
						item={happyHour.sunday}
						onCopyEntry={onCopyEntry}
						onValueChange={onValueChange}
						dayText={'Sunday'}
					/>
					<ButtonGroup>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<MainButton
								onClick={() => {
									onSave();
								}}
								disabled={false}
								text={'Save'}
								color={'primary'}
							/>
							<MainButton
								onClick={() => {
									history('/admin');
								}}
								text={'Back'}
								color={'default'}
							/>
						</div>
					</ButtonGroup>
				</PanelContainer>
			</MainContainer>
		</PageContainer>
	);
};

export default HappyHour;
