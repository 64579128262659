import React, { useContext, useEffect } from 'react';
import LineContainer from '../../components/lines/LineContainer';
import { Context } from '../../store/modalContext/store';
import MainButton from '../../components/buttons/MainButton';
import MainInput from '../../components/inputs/MainInput';
import AutoCompleteMain from '../../components/dropdowns/AutoCompleteMain';
// import { getPriceWithDot } from './business/calcHelper';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import SubHeader from '../../components/texts/SubHeader';
// import MainRoutes from './MainRoutes';
import AutoCompleteWrapper from '../../components/dropdowns/AutoCompleteWrapper';
import { Button, CircularProgress, Grid } from '@mui/material';
import { currencies, countries } from '../../data/data';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import {
	goToCheckout,
	onCurrencyChange,
	onCountryChange,
} from '../../store/actions/UserActions';
import { getCurrencyTypeByCode } from '../../business/currencyHelper';
import {
	useConfirmNumberMutation,
	useGetIsAddCreditAllowedMutation,
	useManageUserByStaffMutation,
} from '../../store/api/api';
import { SIZES } from '../../config/config';
import PageContainer from '../../components/containers/PageContainer';
import MainSwitchController from '../../components/switch/MainSwitchController';
import MainSwitch from '../../components/switch/MainSwitch';

const ManageCredit = ({ user }) => {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const dispatch = useDispatch();
	const [amount, setAmount] = React.useState('');
	const [isCreditAllowed, setisCreditAllowed] = React.useState(false);
	const [getIsAddCreditAllowed, { isLoading }] =
		useGetIsAddCreditAllowedMutation();
	const [confirmUserPhone, setConfirmUserPhone] = React.useState(false);
	const [isNumberConfirmed, setIsNumberConfirmed] = React.useState(false);
	const [isConfirmNumberVisible, setIsConfirmNumberVisible] =
		React.useState(true);
	const [codeMessage, setCodeMessage] = React.useState('');

	const [countryCode, setCountryCode] = React.useState('+354');
	const [phone, setPhone] = React.useState('');
	const [manageUserByStaff] = useManageUserByStaffMutation();
	const [confirmNumber] = useConfirmNumberMutation();
	const [minutes, setMinutes] = React.useState('');
	const [hours, setHours] = React.useState('');
	const [days, setDays] = React.useState('');
	const [removePermission, setRemovePermission] = React.useState(false);

	useEffect(() => {
		(async (modalState) => {
			let creditResult = await getIsAddCreditAllowed({
				modal: modalState,
			}).unwrap();
			if (creditResult) {
				console.log('creditResult: ', creditResult);
				setisCreditAllowed(creditResult.isAllowed);
			}
		})();
	}, [modalState]);

	const setTime = (identifier, time) => {
		if (identifier === 'hours') {
			let hoursTemp = 0;
			let daysTemp = 0;
			let minutesTemp = 0;
			if (time && time > 0) {
				hoursTemp = time;
			}
			minutesTemp = hoursTemp * 60;
			daysTemp = Math.floor(hoursTemp / 24);
			setMinutes(minutesTemp.toString());
			setHours(hoursTemp.toString());
			setDays(daysTemp.toString());
		} else if (identifier === 'days') {
			let hoursTemp = 0;
			let daysTemp = 0;
			let minutesTemp = 0;
			if (time && time > 0) {
				daysTemp = time;
			}
			hoursTemp = daysTemp * 24;
			minutesTemp = hoursTemp * 60;
			setMinutes(minutesTemp.toString());
			setHours(hoursTemp.toString());
			setDays(daysTemp.toString());
		} else if (identifier === 'minutes') {
			let hoursTemp = 0;
			let daysTemp = 0;
			let minutesTemp = 0;
			if (time && time > 0) {
				minutesTemp = time;
			} else {
				minutesTemp = 0;
			}
			hoursTemp = minutesTemp / 60;
			daysTemp = Math.floor(hoursTemp / 24);
			setMinutes(minutesTemp.toString());
			setHours(hoursTemp.toFixed(2));
			setDays(daysTemp.toFixed(2));
		}
	};

	let shouldHide = !phone || (phone && phone.length < 6);
	//phone, countryCode, amount,

	if (isLoading) {
		return null;
	}

	// let allowManualCredit = place.allowManualCredit;
	if (!isCreditAllowed) {
		return (
			<div>
				<p style={{ textAlign: 'center', marginTop: 58 }}>
					Adding manual credit is not allowed for this account. Please contact
					administrator to allow this.{' '}
				</p>
			</div>
		);
	}

	return (
		<PageContainer header={'User '}>
			<MainContainer isLoaded={true}>
				<PanelContainer
					lowerIcon={() => (
						<InfoIcon
							style={{
								color: 'gray',
								fontSize: 25,
								marginRight: 10,
							}}
						/>
					)}
					lowerText={
						'If you add credit to your account then you can pour as much as you want and pay later. '
					}
					header={'In-app credit'}
				>
					<SubHeader text={'User'} />

					<MainInput
						label={'Country code'}
						value={countryCode}
						identifier={'countryCode'}
						onChange={(identifier, value) => setCountryCode(value)}
						md={3}
					/>

					<MainInput
						label={'Phone number'}
						type={'number'}
						value={phone}
						identifier={'phone'}
						onChange={(identifier, value) => setPhone(value)}
						md={9}
					/>

					{isConfirmNumberVisible ? (
						<Button
							color={'primary'}
							variant="outlined"
							style={{ margin: 15 }}
							// variant={'outline'}
							onClick={async () => {
								if (
									!phone ||
									phone.length < 5 ||
									!countryCode ||
									countryCode.length < 2
								) {
									window.alert(
										'Please enter a valid phone number and country code.'
									);
								} else {
									let confirmNumberResponse = await confirmNumber({
										phone: phone,
										countryCode: countryCode,
										modal: modalState,
									}).unwrap();

									if (confirmNumberResponse.success) {
										setCodeMessage(confirmNumberResponse.codeMessage);
										setIsConfirmNumberVisible(false);
										setTimeout(() => {
											setIsConfirmNumberVisible(true);
										}, 5000);
									}

									// modalState.openModal(
									// 	credits.success ? 'Success' : 'Error',
									// 	credits.message,
									// 	credits.success ? 'success' : 'error'
									// );
								}
							}}
						>
							{'Confirm using SMS'}
						</Button>
					) : (
						<CircularProgress color={'primary'} />
					)}
					{codeMessage ? (
						<p style={{ padding: 15, fontWeight: 700 }}>{codeMessage}</p>
					) : null}

					<SubHeader hide={shouldHide} text={'Add credit'} />

					<MainInput
						label={'Amount to add to user'}
						value={amount}
						type={'number'}
						hideInput={shouldHide}
						options={{
							min: 0,
							max: 99999,
						}}
						identifier={'amountToAdd'}
						onChange={(identifier, value) => setAmount(value)}
						md={12}
					/>

					<SubHeader
						hide={shouldHide}
						text={'Give tap permission (if needed)'}
					/>

					<MainInput
						label={'Minutes'}
						hideInput={shouldHide}
						type={'number'}
						value={minutes}
						identifier={'minutes'}
						onChange={(identifier, value) => setTime(identifier, value)}
						md={4}
					/>

					<MainInput
						label={'Hours'}
						type={'number'}
						hideInput={shouldHide}
						value={hours}
						identifier={'hours'}
						onChange={(identifier, value) => {
							console.log('al: ', value);
							setTime(identifier, value);
						}}
						md={4}
					/>

					<MainInput
						label={'Days'}
						type={'number'}
						hideInput={shouldHide}
						value={days}
						identifier={'days'}
						onChange={(identifier, value) => setTime(identifier, value)}
						md={4}
					/>

					<MainSwitch
						label={'Remove user permission'}
						onChange={(key, value) => {
							setRemovePermission(value);
						}}
						hide={shouldHide}
						value={removePermission}
						identifier={'removePermission'}
					/>

					{!shouldHide ? (
						<LineContainer>
							<MainButton
								customColor={'green'}
								customTextColor={'white'}
								onClick={async () => {
									if (!amount || (amount && 20001 > parseInt(amount))) {
										if (
											window.confirm(
												'Do you want to add ' + (amount ? amount : 0)
											)
										) {
											let credits = await manageUserByStaff({
												amount: parseFloat(amount),
												phone: phone,
												countryCode: countryCode,
												hours: hours,
												minutes: minutes,
												modal: modalState,
												removePermission: removePermission,
											}).unwrap();
											modalState.openModal(
												credits.success ? 'Success' : 'Error',
												credits.message,
												credits.success ? 'success' : 'error'
											);
											if (credits.success) {
												setAmount('0');
												setPhone('');
											}
										}
									} else {
										window.alert(
											'You cannot add more than 20000 kr in credits. '
										);
									}
								}}
								text={'Submit'}
								color={'primary'}
							/>
						</LineContainer>
					) : null}
				</PanelContainer>
			</MainContainer>
		</PageContainer>
	);
};

export default ManageCredit;
