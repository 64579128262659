import { getKegChangeColumns } from '../../business/tableHelper';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import KegChange from './KegChange';

interface PlaceUserProps {
	user: any;
	kegId: any;
	customClickFunction?: any;
	drinkName?: string;
	keg: any;
	// modalState: any;
}

const KegChanges = ({
	user,
	kegId,
	customClickFunction,
	drinkName,
	keg,
}: PlaceUserProps) => {
	// const history = useHistoryNav();

	const [kegChangeId, setKegChange] = React.useState<any>(null);
	console.log('keg id in: ', kegId);

	return (
		// <PageContainer header={'Kegs'}>
		<div>
			<React.Fragment>
				<ManageItems
					header={'Inventory changes'}
					key={3232}
					getData={{
						url: 'kegs/getKegChanges',
						body: {
							kegId: kegId,
						},
					}}
					buttonText={'Sell / Change inventory'}
					noDoubleClick={true}
					customClickFunction={(data) => {
						setKegChange(data);
					}}
					redirectLink={ROUTE_TYPES[ROUTE_NAMES.kegchanges].route}
					columns={getKegChangeColumns(user.placeCurrency, true)}
					noItemsText={'No keg changes found.'}
					// manageComponent={() => <KegChangePageContainer />}
				/>
			</React.Fragment>
			{kegChangeId ? (
				<KegChange
					drinkName={drinkName}
					user={user}
					kegChangeId={kegChangeId}
					kegId={keg?._id}
				/>
			) : null}
		</div>
	);
};

export default KegChanges;
