import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import MainButton from '../buttons/MainButton';
import LineContainer from '../lines/LineContainer';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: 600,
	},
}));

const SingleAccordion = ({ header, text, onClick, buttonText, color }: any) => {
	const classes = useStyles();

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className={classes.heading}>{header}</Typography>
			</AccordionSummary>
			<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
				<LineContainer>
					<Typography>{text}</Typography>
				</LineContainer>
				<LineContainer>
					{buttonText ? (
						<MainButton
							onClick={onClick}
							text={buttonText}
							color={color ? color : 'primary'}
						/>
					) : null}
				</LineContainer>
			</AccordionDetails>
		</Accordion>
	);
};

export default SingleAccordion;
