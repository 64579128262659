// import { Player } from '@lottiefiles/react-lottie-player';
import { useState } from 'react';


const OrangeScan = () => {

    const bodyStyle = {
        margin: 0,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(135deg, orange, white)'
      };
    
      const containerStyle = {
        background: 'white',
        borderRadius: '5px',
        boxShadow: '0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)',
        padding: '50px',
        margin: '20px'
      };

      const radioButtonStyle = {
        display: 'none'
      };
    
      const labelStyle = {
        display: 'inline-block',
        padding: '10px',
        backgroundColor: '#FFA500',
        borderRadius: '50%',
        // position: 'relative',
        margin: '10px',
        cursor: 'pointer',
      };
    
      const labelSpanStyle = {
        // position: 'relative',
        zIndex: '2',
      };
      const [selectedSize, setSelectedSize] = useState('100ml');

    return (
        <div style={bodyStyle}>
            <div style={containerStyle}>
                <h1 style={{textAlign: 'center', fontSize: 60, marginTop: -22}}>Torio</h1>
                {/* <Player
                    src="https://assets8.lottiefiles.com/packages/lf20_rmpmxft3.json"
                    className="player"
                    loop
                    autoplay
                    style={{ width: '70%', maxWidth: 370, marginTop: -60 }}
                /> */}
                <h2 style={{textAlign: 'center', marginTop: -80}}>I'm Mr. Orange!</h2>
                <p style={{textAlign: 'center', marginTop: -5 }} className='text-center'>and welcome to the <b>Icelandic Innovation Week!</b></p>
                <p style={{textAlign: 'center', marginTop: -5, maxWidth: 370 }} className='text-center'>The Torio Tap equipment is optimized for beer, however, in certain situations, the machine can be great for other drinks as well!  </p>

                <div>
                    <input 
                    id="orange50" 
                    type="radio" 
                    name="fruit" 
                    style={radioButtonStyle} 
                    value="50ml" 
                    checked={selectedSize === '50ml'}
                    onChange={() => setSelectedSize('50ml')}
                    />
                    <label htmlFor="orange50" style={labelStyle}>
                        {
                            selectedSize === '50ml' ?
                            <span role="img" aria-label="orange" style={labelSpanStyle}>🍊</span>
                            :
                            <span style={{padding: 10}} />

                        }
                    </label>
                    <span>~50ml</span>
                </div>
                <div>
                    <input 
                    id="orange100" 
                    type="radio" 
                    name="fruit" 
                    style={radioButtonStyle} 
                    value="100ml" 
                    checked={selectedSize === '100ml'}
                    onChange={() => setSelectedSize('100ml')}
                    />
                    <label htmlFor="orange100" style={labelStyle}>
                        {
                            selectedSize === '100ml' ?
                            <span role="img" aria-label="orange" style={labelSpanStyle}>🍊</span>
                            :
                            <span style={{padding: 10}} />
                        }
                    </label>
                    <span>~100ml</span>
                </div>

            </div>
        </div>
    );

}

export default OrangeScan;