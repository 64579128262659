import { Typography } from '@mui/material';
import { LIST_HEADER_SIZE } from '../Constants';

const FrontPageText = ({ type }) => {
	if (type === 'header') {
		return (
			<Typography
				style={{ fontWeight: 600, marginBottom: 0, paddingBottom: 30 }}
				variant={'h1'}
				sx={{
					fontSize: {
						md: '119px !important',
						sm: '101px !important',
						xs: '101px !important',
					},
				}}
			>
				Torio
			</Typography>
		);
	} else if (type === 'subheader') {
		return (
			<Typography
				// variant={'body1'}
				style={{ paddingTop: 0, marginBottom: 0, marginTop: 30 }}
				sx={{
					fontSize: {
						md: '39px !important',
						sm: '35px !important',
						xs: '35px !important',
					},
				}}
			>
				Admin Web
			</Typography>
		);
	} else if (type === 'text') {
		return (
			<Typography
				// variant={'body1'}
				// style={{ lineHeight: 1.5 }}
				style={{ marginBottom: 0, paddingBottom: 0 }}
				sx={{
					fontSize: {
						md: '28px !important',
						sm: '24px !important',
						xs: '24px !important',
					},
				}}
			>
				Login to manage your Torio devices.
			</Typography>
		);
	}

	return null;
};

export default FrontPageText;
