import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import { useParams } from 'react-router-dom';

import {
	discountRepeatMaker,
	discountCustomMaker,
	discountTimesMaker,
} from '../../business/dropdownHelper';
import SubHeader from '../../components/texts/SubHeader';
import CloseIcon from '@mui/icons-material/Close';
import { check } from 'react-interaction';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { goBack } from '../../business/routingHelper';
import PageContainer from '../../components/containers/PageContainer';
import {
	useGetDrinkMutation,
	useManageDrinkMutation,
} from '../../store/api/api';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	DrinkInterface,
	DrinkInitialized,
	DrinkSchema,
} from '@toriosoftware/torioshared/dist/drinks';

import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import FormContainer from '../../components/containers/FormContainer';
import MainInputController from '../../components/inputs/MainInputController';
import MainDropdownController from '../../components/dropdowns/MainDropdownController';
import DiscountDropdown from '../../components/dropdowns/APIDropdown';
// import DiscountItems from '../Discounts/DiscountItems';
import MainImageController from '../../components/images/MainImageController';
import MainColorInputController from '../../components/pickers/MainColorInputController';
import MainSwitchController from '../../components/switch/MainSwitchController';
import { IMAGE_TYPES } from '@toriosoftware/torioshared/dist/config';
import MainImageLinksController from '../../components/images/MainImageLinksController';
import DiscountItems from '../Discounts/DiscountItems';
import LineContainer from '../../components/lines/LineContainer';
import Keg from '../Kegs/Keg';
import {
	beerCategories,
	drinkType,
} from '@toriosoftware/torioshared/dist/drinks';

function Drink({ user }: any) {
	//@ts-ignore
	const [getDrink] = useGetDrinkMutation();
	const [manageDrink, { isLoading }] = useManageDrinkMutation();

	// const [selectedCustomDiscount, setSelectedCustomDiscount] =
	// 	React.useState('');

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		getFieldState,
		setFocus,
	} = useForm<DrinkInterface>({
		defaultValues: DrinkInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(DrinkSchema),
	});

	const { modelState, isLoaded, history, isNew, id, data } = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(
				ROUTE_NAMES?.drink ? ROUTE_NAMES?.drink : 'drinks/:id',
				user
			)?.data?.get(getDrink, reset),
		],
		user: user,
	});

	console.log('DAA: ', data);

	const discountTimes = watch('discountTimes');
	const imageLink = watch('imageLink');
	const imageClearLink = watch('imageClearLink');
	const drinkTypeVariable = watch('drinkType');

	React.useEffect(() => {
		setValue('type', '');
		if (drinkTypeVariable !== 'Beer') {
			setValue('type', '');
		}
	}, [drinkTypeVariable]);

	React.useEffect(() => {
		if (isNew) {
			setValue('drinkType', 'Beer');
		}
	}, [isNew]);

	// console.log('user.subscription: ', user.subscription);

	return (
		<PageContainer header={'Drink'}>
			{user.subscription.includes('Kegs') ? (
				<Keg
					user={user}
					//@ts-ignore
					kegId={data?.drink?.drink?.keg}
					// drink={isNew ? null : data}
				/>
			) : null}

			<FormContainer
				modal={modelState}
				handleSubmit={handleSubmit}
				isDirty={isDirty}
				onSubmitFunction={manageDrink}
				onRemoveFunction={manageDrink}
				isLoaded={isLoaded}
				customData={{ id }}
				showBack={true}
				isLoading={isLoading}
				link={'drinks'}
				linkAfterDelete={'drinks'}
				title={'Drink'}
				// linkAfterSuccess={
				// 	isNew ? '/admin/' + ROUTE_TYPES[ROUTE_NAMES.drinks].route : ''
				// }
				depth={0}
				history={history}
				hideRemove={isNew}
			>
				<SubHeader text={'General information'} />

				<MainInputController
					control={control}
					label={'Drink name'}
					type={'text'}
					identifier={'name'}
					md={12}
				/>

				{/* 
				<MainInputController
					control={control}
					hideInput={!user.subscription.includes('TorioTap')}
					label={'Minimum ml to select'}
					type={'text'}
					identifier={'minimumSelectMl'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Maximum ml to select'}
					hideInput={!user.subscription.includes('TorioTap')}
					type={'text'}
					identifier={'maximumSelectMl'}
					md={6}
				/> */}

				<MainInputController
					control={control}
					label={'Alcohol percentage'}
					// hideInput={!user.subscription.includes('TorioTap')}
					type={'text'}
					endText={'%'}
					md={drinkTypeVariable === 'Beer' ? 4 : 6}
					identifier={'alc'}
				/>

				<MainDropdownController
					label={'Drink type'}
					helperText="Select drink type"
					options={drinkType.map((drinkType) => {
						return { text: drinkType, value: drinkType };
					})}
					md={drinkTypeVariable === 'Beer' ? 4 : 6}
					control={control}
					optionsValueKey={'value'}
					optionsTextKey={'text'}
					identifier={'drinkType'}
					id={'drinkType'}
				/>

				<MainDropdownController
					label={'Drink category'}
					helperText="Select drink type"
					options={beerCategories.map((cat) => {
						return { text: cat, value: cat };
					})}
					md={drinkTypeVariable === 'Beer' ? 4 : 0}
					control={control}
					hideInput={drinkTypeVariable !== 'Beer'}
					optionsValueKey={'value'}
					optionsTextKey={'text'}
					identifier={'type'}
					id={'type'}
				/>

				{/* <MainInputController
					control={control}
					label={'Category'}
					helperText={'Lager, IPA, etc.'}
					type={'number'}
					identifier={'type'}
					md={drinkTypeVariable === 'Beer' ? 4 : 6}
				/> */}

				<SubHeader text={'Optional information'} />

				<MainInputController
					control={control}
					label={'Good food pairing'}
					maxlength={20}
					type={'text'}
					identifier={'foodPair'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Taste'}
					maxlength={50}
					type={'text'}
					identifier={'taste'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Drink creator / brewery'}
					maxlength={40}
					type={'text'}
					identifier={'brewery'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Drink country'}
					maxlength={25}
					type={'text'}
					identifier={'country'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Description'}
					maxlength={200}
					rows={'4'}
					type={'text'}
					identifier={'description'}
					md={12}
				/>

				<MainSwitchController
					control={control}
					label={'Is drink alcoholic'}
					hideInput={!user.subscription.includes('TorioTap')}
					md={12}
					identifier={'isAlcoholic'}
				/>

				<MainInputController
					control={control}
					hideInput={
						!user.subscription.includes('TorioTap') &&
						user.systemRole === 'Admin'
					}
					label={'Price for 330 ml (not used)'}
					type={'text'}
					identifier={'price330Ml'}
					endText={
						user.currencyAppend +
						' ' +
						(user.placeCurrency ? `(${user.placeCurrency})` : '')
					}
					md={12}
				/>

				<SubHeader
					hide={!user.subscription.includes('TorioTap')}
					text={'Price'}
				/>

				<MainInputController
					control={control}
					hideInput={!user.subscription.includes('TorioTap')}
					label={'Ml content for price'}
					type={'text'}
					identifier={'mlPriceContent'}
					endText={'ml'}
					md={6}
				/>

				<MainInputController
					control={control}
					// hideInput={!user.subscription.includes('Kegs')}
					label={'Price'}
					type={'text'}
					identifier={'price'}
					endText={
						user.currencyAppend +
						' ' +
						(user.placeCurrency ? `(${user.placeCurrency})` : '')
					}
					md={6}
				/>

				<SubHeader
					hide={!user.subscription.includes('TorioTap')}
					text={'Happy hour / Discount'}
				/>

				<MainInputController
					control={control}
					hideInput={!user.subscription.includes('TorioTap')}
					label={'Discount Price'}
					type={'text'}
					identifier={'discountPrice'}
					endText={
						user.currencyAppend +
						' ' +
						(user.placeCurrency ? `(${user.placeCurrency})` : '')
					}
					md={12}
				/>

				<LineContainer>
					{user.subscription.includes('TorioTap') ? (
						<DiscountItems
							setValue={setValue}
							discounts={discountTimes}
							label={'Discount times'}
							typePara={'items'}
							getItemsUrl={'discounts/discounttimes/'}
							createDropdownFunction={discountTimesMaker}
							control={control}
							drink={{}}
							customButtonText={'Add discount'}
							id={'discountTimes'}
							noDiscountText={
								'No discount times / happy hour times have been selected for this drink. '
							}
						/>
					) : null}
				</LineContainer>

				<SubHeader
					hide={!user.subscription.includes('TorioTap')}
					text={'Theme colors'}
				/>

				<MainColorInputController
					hide={!user.subscription.includes('TorioTap')}
					md={6}
					label={'Color one, HEX'}
					identifier={'colorOne'}
					control={control}
				/>

				{/* <MainColorInputController
					label={'Color two, HEX (optional)'}
					md={6}
					identifier={'colorTwo'}
					control={control}
				/> */}

				<SubHeader text={'Drink image'} />

				<MainImageLinksController
					control={control}
					text={
						'Upload up to ' +
						IMAGE_TYPES['drink'].maxCount +
						' images of this drink'
					}
					initialFiles={imageLink} //? [imageLink] : []}
					identifier={'imageLink'}
					maxSize={IMAGE_TYPES['drink'].maxSize}
					filesLimit={IMAGE_TYPES['drink'].maxCount}
					compression={IMAGE_TYPES['drink'].compression}
					setValue={setValue}
				/>

				{/* <SubHeader text={'Drink image with clear background'} />

				<MainImageLinksController
					control={control}
					text={
						'Upload up to ' +
						IMAGE_TYPES['clearDrink'].maxCount +
						' images of this place'
					}
					initialFiles={imageClearLink} //? [imageClearLink] : []}
					identifier={'imageClearLink'}
					maxSize={IMAGE_TYPES['clearDrink'].maxSize}
					filesLimit={IMAGE_TYPES['clearDrink'].maxCount}
					compression={IMAGE_TYPES['clearDrink'].compression}
					setValue={setValue}
				/> */}
			</FormContainer>
		</PageContainer>
	);
}

export default Drink;

// const getDiscounts = (discounts, typePara) => {
// 	console.log('DISCOUNTS SUPPLIED: ', discounts);
// 	return (
// 		<div>
// 			{discounts && discounts.length > 0 ? (
// 				<Grid
// 					spacing={2}
// 					md={12}
// 					container
// 					style={{
// 						display: 'flex',
// 						flexDirection: 'row',
// 						marginTop: 20,
// 						marginBottom: 20,
// 					}}
// 				>
// 					{discounts && discounts.length > 0 ? (
// 						discounts.map((discountId, index) => {
// 							let discountedItem = null;

// 							if (typePara === 'customDiscounts') {
// 								discountedItem = discountCustomItems.find(
// 									(i) => discountId === i._id
// 								);
// 							} else if (typePara === 'repeatedDiscounts') {
// 								discountedItem = discountRepeatItems.find(
// 									(i) => discountId === i._id
// 								);
// 							}

// 							if (discountedItem) {
// 								return (
// 									<Grid style={{ width: '100%' }} item md={6} key={index}>
// 										<div
// 											style={{
// 												display: 'flex',
// 												border: '1px solid lightgray',
// 												padding: 25,
// 												borderRadius: 5,
// 												paddingTop: 34,
// 												position: 'relative',
// 												minHeight: 270,
// 												flexDirection: 'column',
// 												width: '100%',
// 												justifyContent: 'space-between',
// 												backgroundColor: '#f8f8f8',
// 											}}
// 										>
// 											<div
// 												style={{
// 													display: 'flex',
// 													justifyContent: 'space-between',
// 												}}
// 											>
// 												<div
// 													style={{
// 														position: 'absolute',
// 														top: 0,
// 														left: 0,
// 														backgroundColor: 'gray',
// 														color: 'white',
// 														fontSize: 12,
// 														borderBottomRightRadius: 3,
// 														padding: 4,
// 													}}
// 												>
// 													Discount
// 												</div>
// 												<div
// 													style={{
// 														position: 'absolute',
// 														top: 5,
// 														right: 5,
// 														color: 'gray',
// 														fontSize: 12,
// 														borderBottomRightRadius: 3,
// 														padding: 4,
// 														cursor: 'pointer',
// 													}}
// 													onClick={() => {
// 														console.log('sfdsf');
// 														onRemoveDiscount(discountId, typePara);
// 													}}
// 												>
// 													<CloseIcon
// 														style={{ color: 'gray', fontSize: 16 }}
// 													/>
// 												</div>
// 												<div>
// 													<h4
// 														style={{
// 															fontWeight: 700,
// 															fontSize: 20,
// 															marginBottom: 0,
// 															marginTop: 0,
// 														}}
// 													>
// 														{discountedItem.name}
// 													</h4>
// 													<p
// 														style={{
// 															marginBottom: 0,
// 															marginTop: 0,
// 															fontSize: 19,
// 														}}
// 													>
// 														At {' ' + discountedItem.from} -{' '}
// 														{discountedItem.to}
// 													</p>
// 													<div
// 														style={{
// 															display: 'flex',
// 															flexDirection: 'row',
// 															flexWrap: 'wrap',
// 														}}
// 													>
// 														{discountedItem.type === 'custom'
// 															? discountedItem.dates &&
// 															  discountedItem.dates.length > 0
// 																? discountedItem.dates.map((value, index) => {
// 																		return (
// 																			<div
// 																				key={index}
// 																				style={{
// 																					color: 'white',
// 																					backgroundColor: '#222',
// 																					borderRadius: 15,
// 																					padding: 10,
// 																					marginRight: 10,
// 																					marginTop: 10,
// 																				}}
// 																			>
// 																				{value}
// 																			</div>
// 																		);
// 																  })
// 																: null
// 															: discountedItem.type === 'repeated'
// 															? Object.keys(discountedItem.days).map(
// 																	(key, index) => {
// 																		let isSelected = discountedItem.days[key];
// 																		return (
// 																			<div
// 																				key={index}
// 																				style={{
// 																					backgroundColor: isSelected
// 																						? 'black'
// 																						: 'white',
// 																					color: isSelected
// 																						? 'white'
// 																						: 'black',
// 																					border: '1px solid black',
// 																					borderRadius: 15,
// 																					padding: 10,
// 																					marginRight: 10,
// 																					marginTop: 10,
// 																				}}
// 																			>
// 																				{capitalizeTxt(key)}
// 																			</div>
// 																		);
// 																	}
// 															  )
// 															: null}
// 													</div>
// 												</div>
// 												<div>
// 													<p
// 														style={{
// 															fontWeight: 600,
// 															fontSize: 18,
// 															color: 'green',
// 															marginBottom: 0,
// 															marginTop: 0,
// 														}}
// 													>
// 														{discountedItem.discountPrice
// 															? discountedItem.discountPrice +
// 															  getCurrencyType(
// 																	drink && drink['place']
// 																		? drink['place']
// 																		: ''
// 															  )
// 															: discountedItem.percentage
// 															? discountedItem.percentage + '% off'
// 															: null}
// 													</p>
// 												</div>
// 											</div>
// 											<MainButton
// 												text={'View'}
// 												onClick={() => {
// 													const win = window.open(
// 														'/managediscounts/' + discountId,
// 														'_blank'
// 													);
// 													win.focus();
// 												}}
// 											/>
// 										</div>
// 									</Grid>
// 								);
// 							} else {
// 								return <p>Discounts not found. </p>;
// 							}
// 						})
// 					) : (
// 						<p style={{ marginTop: 20, textAlign: 'center' }}>
// 							No discounts found.{' '}
// 						</p>
// 					)}
// 				</Grid>
// 			) : (
// 				<Grid md={12} container style={{ marginLeft: 10 }}>
// 					<p style={{ color: 'gray' }}>
// 						No discounts have been selected for this drink
// 					</p>
// 				</Grid>
// 			)}
// 		</div>
// 	);
// };

// {/* <MainDropdownController
// 	label={'Repeated discounts'}
// 	// value={selectedRepeatDiscount}
// 	options={discountRepeatDropdown}
// 	optionsValueKey={'_id'}
// 	optionsTextKey={'text'}
// 	id={'repdiscounts'}
// 	isWithLink={true}
// 	link={'/managediscounts'}
// 	md={12}
// /> */}
// {/* <MainButton
// 	text={'Add repeated discount to drink'}
// 	removePadding={true}
// 	onClick={() =>
// 		onAddDiscount(selectedRepeatDiscount, 'repeatedDiscounts')
// 	}
// /> */}

// {/* Show selected discounts here */}

// {/* {getDiscounts(
// 	drink ? drink['repeatedDiscounts'] : [],
// 	'repeatedDiscounts'
// )} */}
// {/* <MainDropdownController
// 	label={'Custom discounts'}
// 	// value={selectedCustomDiscount}
// 	options={discountCustomDropdown}
// 	optionsValueKey={'_id'}
// 	optionsTextKey={'text'}
// 	id={'customdiscounts'}
// 	isWithLink={true}
// 	link={'/managediscounts'}
// 	md={12}
// /> */}
// {/* <MainButton
// 	removePadding={true}
// 	text={'Add custom discount'}
// 	onClick={() =>
// 		onAddDiscount(selectedCustomDiscount, 'customDiscounts')
// 	}
// /> */}

// {/* Show selected discounts here */}

// {/* {getDiscounts(drink ? drink['customDiscounts'] : [], 'customDiscounts')} */}
// let useKegs = watch('useKegs');

// const [discountRepeatItems, discountRepeatItem, discountRepeatDropdown] =
// 	useItems({
// 		id: '',
// 		dropdownFunction: discountRepeatMaker.bind(this),
// 		useDropdown: true,
// 		url: 'discounts/getRepeatedDiscounts/',
// 	});

// const [discountCustomItems, discountCustomItem, discountCustomDropdown] =
// 	useItems({
// 		id: '',
// 		dropdownFunction: discountCustomMaker.bind(this),
// 		useDropdown: true,
// 		url: 'discounts/getCustomDiscounts/',
// 	});

// async function onSend(isNewPara, isRemove = false) {
// 	let result = await sendAPI(
// 		'drinks/manageDrink/' + params.id,
// 		{ drink: drink, isNew: isNewPara, isRemove: isRemove },
// 		state.openModal
// 	);
// 	if (result.success) {
// 		goBack(history);
// 	}
// }

/* <DiscountItems
					discounts={[]}
					label={'Repeated discounts'}
					typePara={'repeatedDiscounts'}
					getItemsUrl={'discounts/getRepeatedDiscounts/'}
					createDropdownFunction={discountRepeatMaker}
					control={control}
					drink={{}}
					customButtonText={'Add repeated discount'}
					id={'repdiscounts'}
					noDiscountText={
						'No repeated discounts have been selected for this drink. '
					}
				/>

				<DiscountItems
					discounts={[]}
					label={'Custom discounts'}
					typePara={'customDiscounts'}
					getItemsUrl={'discounts/getCustomDiscounts/'}
					createDropdownFunction={discountCustomMaker}
					control={control}
					drink={{}}
					customButtonText={'Add custom discount'}
					id={'repdiscounts'}
					noDiscountText={
						'No custom discounts have been selected for this drink. '
					}
				/> 
				*/
