import { Button } from '@mui/material';
import emailFB from './../../assets/emailFB.png';
import badthumb from './../../assets/badthumb.png';
import useEffect, { useState } from 'react';
import React from 'react';

const FBError = () => {
	const [isGoogle, setIsGoogle] = useState(false);

	React.useEffect(() => {
		if (window.location.href.includes('google')) {
			setIsGoogle(true);
		}
	}, []);

	return (
		<div>
			{/* <div
				style={{
					backgroundColor: '#ff6d6d',
					maxWidth: 400,
					lineHeight: 1.5,
					padding: 30,
					borderRadius: 15,
					display: 'flex',
					justifyContent: 'center',
					margin: 'auto',
					marginTop: 80,
				}}
			>
				<p
					style={{
						textAlign: 'center',
						color: 'white',
						fontSize: 18,
						fontWeight: 'bold',
					}}
				>
					You could not sign in with Facebook. A company has to add your
					Facebook email address to their Torio user list for you to be able to
					sign into the Torio admin system using Facebook. Please contact us at
					torio@torio.is if you have any questions.
				</p>
			</div> */}
			<h1 style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
				Error
			</h1>
			<div style={{ flexDirection: 'column', display: 'flex' }}>
				{!isGoogle ? (
					<img
						src={badthumb}
						alt="Email Facebook add"
						style={{
							maxWidth: 200,
							width: '100%',
							borderRadius: 40,
							margin: 'auto',
						}}
					/>
				) : null}
				<p
					style={{
						textAlign: 'center',
						fontSize: 21,
						maxWidth: 400,
						padding: 20,
						margin: 'auto',
						marginTop: 20,
						marginBottom: 20,
					}}
				>
					You could not sign in with {isGoogle ? 'Google' : 'Facebook'}. Make
					sure your company has added your{' '}
					<b>{isGoogle ? 'Google email' : 'Facebook email'}</b> address to their
					allowed login emails, like below.
				</p>

				<img
					src={emailFB}
					alt="Email Facebook add"
					style={{
						margin: 'auto',
						borderRadius: 15,
						maxWidth: 600,
						width: '100%',
					}}
				/>
			</div>
			<div
				style={{
					margin: 'auto',
					marginTop: 17,
					display: 'flex',
					justifyContent: 'center',
					marginBottom: 130,
				}}
			>
				<Button
					href={'/login'}
					variant="contained"
					size="large"
					style={{
						color: 'white',
						width: 270,
						padding: 10,
						margin: 'auto',
						marginTop: 40,
					}}
				>
					Back to login
				</Button>
			</div>
		</div>
	);
};

export default FBError;
