import React from 'react';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import KegChanges from './KegChanges';
import PageContainer from '../../components/containers/PageContainer';
import Sales from '../Sales/Sales';

interface PlaceUserProps {
	user: any;
	modalState: any;
}

const KegOverview = ({ user, modalState }: PlaceUserProps) => {
	const history = useHistoryNav();

	const [kegChangeId, setKegChange] = React.useState<any>(null);

	return (
		<PageContainer header={'Sales and changes'}>
			<Sales user={user} modalState={modalState} />
			{/* <KegChanges
				keg={null}
				customClickFunction={() => {}}
				drinkName=""
				kegId={null}
				user={user}
			/> */}
		</PageContainer>
	);
};

export default KegOverview;
