import React, { useContext } from 'react';
import LoadingScreen from '../../components/loading/LoadingScreen';

const GetBarhopp = ({ appToGet }: any) => {
	React.useEffect(() => {
		//@ts-ignore
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/android/i.test(userAgent)) {
			if (appToGet === 'barhopp') {
				window.location.replace(
					'https://play.google.com/store/apps/details?id=com.icedsolutions.barhopp'
				);
			} else if (appToGet === 'torio') {
				window.location.replace(
					'https://play.google.com/store/apps/details?id=com.torio.torio'
				);
			}
		} else {
			if (appToGet === 'barhopp') {
				window.location.replace(
					'https://apps.apple.com/is/app/barhopp-happy-hour-events/id1617443083'
				);
			} else if (appToGet === 'torio') {
				window.location.replace(
					'https://apps.apple.com/us/app/torio/id1645441137'
				);
			}
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		// if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {

		// } e

		// return 'unknown';
	}, []);

	return (
		<div style={{ margin: 'auto', marginTop: 200 }}>
			<LoadingScreen />
		</div>
	);
};

export default GetBarhopp;
