import Grid from '@mui/material/Grid';
import Header from '../texts/Header';
import clsx from 'clsx';
import {
	ContainerPaddingDiv,
	GENERAL_PADDING_AROUND_CARD_DIV,
	Panel,
} from '../../styles/styledComponents';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import './style.scss';
import { styled } from '@mui/material';
const MainGrid = styled(Grid)`
	${(props: any) => props.theme.breakpoints.down('sm')} {
		margin-top: 15px !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
`;

const variants = {
	visible: { opacity: 1, y: 0 },
	hidden: { opacity: 0, y: -10 },
};

// Spacing
// https://stackoverflow.com/questions/61797942/reactjs-using-material-ui-grid-spacing

const PanelContainer = (props) => {
	return (
		<MainGrid
			style={{ margin: 'auto' }}
			xs={12}
			sm={12}
			md={props.md ? props.md : 12}
			item
		>
			<motion.div
				animate={'visible'}
				variants={variants}
				initial={props && props.noAnimation === true ? 'visible' : 'hidden'}
				transition={{ duration: 0.4 }}
				// style={{ padding: GENERAL_PADDING_AROUND_CARD_DIV }}
				className={'motiondiv'}
			>
				<Panel style={props.style ? props.style : {}}>
					{props.isLoaded === false ? (
						<Skeleton height={100} count={5} />
					) : (
						<MainGrid
							sm={12}
							flexDirection={'row'}
							container
							columnSpacing={2}
							rowSpacing={2}
						>
							{props.noHeader ? null : (
								<Grid width={'100%'} item>
									<Header
										whiteText={props.whiteText}
										text={props.header ? props.header : 'Manage'}
									/>
								</Grid>
							)}
							{props.children}
						</MainGrid>
					)}
				</Panel>
			</motion.div>
		</MainGrid>
	);
};

export default PanelContainer;
