import React from 'react';
import { getCurrencyAppend } from '../../business/currencyHelper';
import { getNewLanguage } from '../../api/eventsData';
import {
	useGetEventMutation,
	useManageEventMutation,
} from '../../store/api/api';
import { useForm } from 'react-hook-form';
import { Button, Snackbar } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import useManageHook from '../../components/hooks/useManageHook';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	event,
	eventSchema,
	eventInitalized,
} from '@toriosoftware/torioshared/dist/event';
import MainInputController from '../../components/inputs/MainInputController';
import FormContainer from '../../components/containers/FormContainer';
import SubHeader from '../../components/texts/SubHeader';
import TextDialog from '../../components/dialogs/TextDialog';
import MainImageController from '../../components/images/MainImageController';
import PageContainer from '../../components/containers/PageContainer';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	IMAGE_TYPES,
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import MainImageLinksController from '../../components/images/MainImageLinksController';
import MainSwitchController from '../../components/switch/MainSwitchController';

const Event = ({ user }) => {
	// State
	const [languageModalOpen, setLanguageModalOpen] = React.useState(false);
	const [getEvent] = useGetEventMutation();
	const [manageEvent] = useManageEventMutation();
	const [openSnack, setOpenSnack] = React.useState<boolean>(false);

	// POST DATA
	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
	} = useForm<event>({
		defaultValues: eventInitalized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(eventSchema),
	});

	// GET DATA
	const {
		modelState,
		setIsLoaded,
		isLoaded,
		history,
		isNew,
		id,
		data,
		csrfToken,
	} = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.event, user)?.data?.get(getEvent, reset),
		],
		user: user,
	});

	React.useEffect(() => {
		// Get cookie

		if (!isNew) {
			setOpenSnack(true);
		}
	}, [isNew]);

	const name = watch('name');
	const nameis = watch('nameis');
	const description = watch('description');
	const descriptionis = watch('descriptionis');
	const imageLink = watch('imageLink');
	const fee = watch('fee');

	console.log('imageLink: ', imageLink);

	const copyLanguageOver = async (langKey: string) => {
		let newData = null;
		if (langKey === 'ISTOEN') {
			newData = await getNewLanguage(nameis, descriptionis, langKey);
		} else if (langKey === 'ENTOIS') {
			newData = await getNewLanguage(name, description, langKey);
		}
		if (newData && newData.name && newData.description) {
			if (langKey === 'ISTOEN') {
				setValue('name', newData.name, { shouldValidate: true });
				setValue('description', newData.description, { shouldValidate: true });
			} else if ('ENTOIS') {
				setValue('nameis', newData.name, { shouldValidate: true });
				setValue('descriptionis', newData.description, {
					shouldValidate: true,
				});
			}
		}
	};

	let currencyAppend = getCurrencyAppend(
		user.placeCurrency,
		user.currencyAppend
	);

	return (
		<PageContainer breadPlaceholder={'Event'} header={'Event'}>
			<FormContainer
				csrfToken={csrfToken}
				isDirty={isDirty}
				modal={modelState}
				handleSubmit={handleSubmit}
				showBack={true}
				onSubmitFunction={manageEvent}
				linkAfterSuccess={isNew ? ROUTE_TYPES[ROUTE_NAMES.events].route : ''}
				customData={{ id }}
				isLoaded={isLoaded}
				goBackDepth={0}
				history={history}
				hideRemove={true}
			>
				<TextDialog
					isOpen={languageModalOpen}
					setIsOpen={setLanguageModalOpen}
					title={'Warning'}
					text={'There cannot be text inside the target text boxes. '}
				/>
				{/* <Snackbar
					open={openSnack}
					autoHideDuration={3000}
					message="This event will be changed for the dates it appears on. "
					// onClose={setOpenSnack(false)}
				/> */}
				<SubHeader text={'English'} />

				<MainInputController
					control={control}
					label={'Name (English)'}
					type={'text'}
					identifier={'name'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Description (English)'}
					type={'text'}
					rows={'3'}
					identifier={'description'}
					md={12}
				/>

				<div
					style={{
						flexDirection: 'column',
						justifyContent: 'center',
						margin: 'auto',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<Button
						style={{ marginTop: 15, marginRight: 2 }}
						startIcon={<ArrowUpwardIcon />}
						variant={!description && descriptionis ? 'contained' : 'outlined'}
						onClick={() => {
							if (!description && descriptionis) {
								copyLanguageOver('ISTOEN');
							} else {
								if (description || descriptionis) {
									setLanguageModalOpen(true);
								}
							}
						}}
					>
						Translate Icelandic to English{' '}
					</Button>
					<Button
						style={{ marginTop: 15, marginRight: 2 }}
						startIcon={<ArrowDownwardIcon />}
						variant={description && !descriptionis ? 'contained' : 'outlined'}
						onClick={() => {
							if (description && !descriptionis) {
								copyLanguageOver('ENTOIS');
							} else {
								if (description || descriptionis) {
									setLanguageModalOpen(true);
								}
							}
						}}
					>
						Translate English to Icelandic
					</Button>
				</div>

				<SubHeader text={'Icelandic'} />

				<MainInputController
					control={control}
					label={'Name (Icelandic)'}
					type={'text'}
					identifier={'nameis'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Description (Icelandic) - Optional'}
					type={'text'}
					rows={'3'}
					identifier={'descriptionis'}
					md={12}
				/>

				<SubHeader text={'General'} />

				<MainInputController
					control={control}
					label={'From'}
					type={'text'}
					identifier={'from'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'To'}
					type={'text'}
					identifier={'to'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Fee'}
					type={'number'}
					identifier={'fee'}
					md={12}
					options={{
						max: 30000,
						min: 0,
					}}
					endText={currencyAppend}
				/>

				{fee ? (
					<MainInputController
						control={control}
						label={'Purchase link (Buy tickets)'}
						type={'text'}
						identifier={'payLink'}
						md={12}
					/>
				) : null}

				<SubHeader text={'Is repeating on'} />
				
				{
					['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
						return(
							<MainSwitchController
								key={index}
								control={control}
								label={day}
								md={3}
								identifier={day.toLowerCase() + 'Repeat' }
							/>
						)
					})
				}


				<MainImageLinksController
					control={control}
					maxSize={IMAGE_TYPES['event'].maxSize}
					text={'Upload an image of this event'}
					initialFiles={imageLink}
					identifier={'imageLink'}
					setValue={setValue}
					compression={IMAGE_TYPES['event'].compression}
					filesLimit={1}
				/>
			</FormContainer>
		</PageContainer>
	);
};

export default Event;
