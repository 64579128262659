import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import PageContainer from '../../components/containers/PageContainer';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import { useConfirm } from 'material-ui-confirm';
import { onObjectChange, sendAPI } from '../../api/helper';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import LineContainer from '../../components/lines/LineContainer';
import MainInput from '../../components/inputs/MainInput';
import MainButton from '../../components/buttons/MainButton';
import moment from 'moment';
import { Alert, Button, ButtonGroup } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { goBack, goOutOfItem } from '../../business/routingHelper';
import SaleItem from './SaleItem';
import SaleItemModal from './SaleItemModal';
import { current } from '@reduxjs/toolkit';
import SaleStepper from './SaleStepper';

function Sale({ user }: any) {
	//@ts-ignore
	const [state] = useContext(Context);
	const confirm = useConfirm();
	const [showAddItem, setShowAddItem] = React.useState(false);
	const [isNew, setIsNew] = React.useState(true);
	const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
	const history = useHistoryNav();
	const params = useParams<any>();
	const location = useLocation();

	const initialItem = {
		orderByName: '',
		orderByEmail: '',
		date: moment().format('DD-MM-YYYY HH:mm'),
		orders: [],
		orderEmailSent: '',
		confirmEmailSent: '',
		saleStep: 0,
		invoiceDetails: '',
		confirmationMessage: '',
	};

	const [item, setItem] = React.useState({ ...initialItem });

	console.log('item: ', item);

	const steps = [
		{
			label: 'Sale created',
			description: `Order is created and user is notified by email that their order was sent in.`,
			emailErrorText: `Customer has not received an order sent email. `,
			emailSuccessText: 'Customer has received an order sent email. ',
			emailDate: item.orderEmailSent,
			emailButtonText: 'SEND CREATE ORDER EMAIL',
		},
		{
			label: 'Sale confirmed',
			description:
				'Establishment confirms what is available in stock and a confirmation email is sent to the user where they get invoice details. ',
			emailErrorText: `Customer has not received an order confirmation email yet. `,
			emailSuccessText:
				'Customer has received an order confirmation email yet. ',
			emailButtonText: 'SEND CONFIRM ORDER EMAIL',
			emailDate: item.confirmEmailSent,
		},
		// {
		// 	label: 'Item being shipped / delivered',
		// 	description: `Here the establishment can confirm that the item is being shipped or delivered to the user. The user is notified by email that the item is on its way.`,
		// 	emailText: '...',
		// 	emailDate: '...',
		// },
	];

	async function getItem() {
		if (params && params.id && params.id !== '0') {
			const result = await sendAPI('kegs/getSale/', {
				id: params.id,
			});

			if (result && result.item) {
				console.log('item: ', result.item);
				const item = Object.assign({}, result.item);
				setIsNew(false);
				setItem(item);
			} else {
				// goOutOfItem(history);
			}
		} else if (params.id === '0') {
			setItem(initialItem);
			setIsLoaded(true);
		} else {
			//something is wrong
			// goOutOfItem(history);
		}
	}

	React.useEffect(() => {
		getItem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function onSend(
		isNewPara,
		isRemove = false,
		sendEmail = false,
		sendEmailToSelf = false
	) {
		let result = await sendAPI(
			'kegs/manageSale/' + params.id,
			{
				sendEmail: sendEmail,
				sendEmailToSelf: sendEmailToSelf,
				item: item,
				isNew: isNewPara,
				isRemove: isRemove,
			},
			state.openModal,
			null,
			() => {
				// history('/sales');
			}
		);
		if (isNewPara && result.success) {
			// change the url to from /0 to the new id
			// goBack(history);
			if (result.id) {
				let currentPath = location.pathname;
				currentPath = currentPath.replace('/0', '/' + result.id);
				console.log('curr: ', currentPath);
				history(currentPath, { replace: true });
			}
			window.location.reload();
		} else if (result.success && isRemove) {
			goBack(history);
		} else if (result.success) {
			window.location.reload();
		}
		return result;
	}

	async function onSendEmail() {
		let result = await sendAPI(
			'kegs/emailSale/' + params.id,
			{
				item: item,
			},
			state.openModal,
			null,
			() => {
				// history('/sales');
			}
		);
	}

	const onValueChange = (key, value) => {
		onObjectChange(item, key, value, setItem.bind(this));
	};

	const onAddItem = (itemPara) => {
		const preorders = item?.orders ? item.orders : [];
		setItem({
			...item,
			orders: [...preorders, itemPara],
		});
	};

	return (
		<form onClick={() => {}}>
			<SaleItemModal
				user={user}
				isShow={showAddItem}
				setShow={setShowAddItem}
				onAdd={onAddItem}
			/>

			<PageContainer header={'Sale'}>
				<MainContainer>
					<PanelContainer isLoaded={true} header={'Items ordered'}>
						<LineContainer>
							<p style={{ color: 'gray', marginTop: -20 }}>
								Ordered at{' '}
								{item.date
									? moment(item.date).format('MMMM D, YYYY HH:mm')
									: ''}
							</p>
						</LineContainer>

						{item?.orders?.length > 0 ? (
							item?.orders?.map((sale, index) => {
								return (
									<SaleItem
										key={index}
										sale={sale}
										item={item}
										index={index}
										setItem={setItem}
									/>
								);
							})
						) : (
							<p style={{ color: 'gray', textAlign: 'center', margin: 'auto' }}>
								No drinks added.{' '}
							</p>
						)}

						<LineContainer>
							<ButtonGroup>
								<MainButton
									variant="outlined"
									style={{ color: '#111' }}
									onClick={() => {
										setShowAddItem(true);
									}}
									text={'Add drink'}
									color={'primary'}
								/>
							</ButtonGroup>
						</LineContainer>

						<div
							style={{
								height: '0.5px',
								backgroundColor: 'lightgray',
								width: '100%',
								marginTop: 15,
							}}
						/>

						<MainInput
							label={'Customer name'}
							type={'text'}
							value={item.orderByName ? item.orderByName : ''}
							identifier={'orderByName'}
							onChange={onValueChange}
							md={6}
						/>

						<MainInput
							label={'Customer email'}
							type={'text'}
							//@ts-ignore
							value={item.orderByEmail ? item.orderByEmail : ''}
							identifier={'orderByEmail'}
							onChange={onValueChange}
							md={6}
						/>

						<MainInput
							rows={'4'}
							label={'Confirmation message sent with email (optional)'}
							type={'text'}
							helperText="You can specify when the kegs will be delivered and other details if you wish. "
							//@ts-ignore
							value={item.confirmationMessage ? item.confirmationMessage : ''}
							identifier={'confirmationMessage'}
							onChange={onValueChange}
							md={12}
						/>

						<MainInput
							label={'Invoice details (optional)'}
							type={'text'}
							//@ts-ignore
							value={item.invoiceDetails ? item.invoiceDetails : ''}
							identifier={'invoiceDetails'}
							onChange={onValueChange}
							md={12}
						/>

						{/* <LineContainer>
							<Alert
								style={{ width: '100%' }}
								severity={item.orderEmailSent ? 'success' : 'error'}
								action={
									<Button
										onClick={async () => {
											await onSend(isNew, false, true, false);
										}}
										style={{ alignItems: 'center', display: 'flex' }}
										color="inherit"
										size="small"
									>
										SEND EMAIL
									</Button>
								}
							>
								<div>
									You have{item.orderEmailSent ? ' ' : ' not '}sent the
									confirmation email{item.orderEmailSent ? ' ' : ' yet '}to the
									customer ({item.orderByEmail}).
								</div>
							</Alert>
						</LineContainer>

						<LineContainer>
							<Alert
								style={{ width: '100%' }}
								severity="info"
								action={
									<Button
										onClick={async () => {
											await onSend(isNew, false, true, true);
										}}
										style={{ alignItems: 'center', display: 'flex' }}
										color="inherit"
										size="small"
									>
										SEND EMAIL
									</Button>
								}
							>
								<div>
									Send a preview email to your own email address ({user.email}).
								</div>
							</Alert>
						</LineContainer> */}

						<LineContainer isBlock={true}>
							<SaleStepper
								confirmationMessageText={
									item.confirmationMessage ? item.confirmationMessage : ''
								}
								invoiceDetailsText={
									item.invoiceDetails ? item.invoiceDetails : ''
								}
								steps={steps}
								step={item.saleStep ? item.saleStep : 0}
								orderEmailSent={item.orderEmailSent}
								onChangeSaleStep={(stepTemp) => {
									onValueChange('saleStep', stepTemp);
									// saleStep
								}}
								onClickOrderEmail={async (currentOrder) => {
									confirm({
										description: 'Do you want to send this email?',
									})
										.then(async () => {
											// if (currentOrder === 0) {
											// 	onObjectChange(item, 'saleStep', 1, setItem.bind(this));
											// }
											await onSend(isNew, false, true, false);
										})
										.catch(() => {});
								}}
							/>
						</LineContainer>
						<LineContainer>
							<ButtonGroup
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									marginTop: 80,
								}}
							>
								<MainButton
									onClick={() => {
										onSend(isNew);
									}}
									// disabled={false}
									text={'Save changes'}
									color={'primary'}
								/>
								{isNew ? null : (
									<MainButton
										onClick={() => {
											confirm({
												description: 'Do you want to remove this sale?',
											})
												.then(() => {
													onSend(false, true);
												})
												.catch(() => {});
										}}
										// disabled={false}
										text={'Remove sale'}
										color={'danger'}
										buttonColor={'error'}
									/>
								)}
							</ButtonGroup>
						</LineContainer>
					</PanelContainer>
				</MainContainer>
			</PageContainer>
		</form>
	);
}

export default Sale;

// const onSubmit = async (data) => {
// 	let result = await manageKeg({
// 		...data,
// 		modal: state.modal,
// 	}).unwrap();
// 	console.log('res data: ', result);
// };

// const onError = (errors, e) => {
// 	let message = '';
// 	if (errors) {
// 		Object.keys(errors).forEach((errorKey) => {
// 			let error = errors[errorKey];
// 			if (error && error.message) {
// 				message += error.message + '. ';
// 			}
// 		});
// 	}
// };

// onClickOrderEmail={async () => {
// 	confirm({
// 		description: 'Do you want to send this email?',
// 	})
// 		.then(async () => {
// 			onObjectChange(item, 'saleStep', 1, setItem.bind(this));
// 			await onSend(isNew, false, true, false);
// 		})
// 		.catch(() => {});
// }}
// onClickConfirmEmail={async () => {
// 	confirm({
// 		description: 'Do you want to send this email?',
// 	})
// 		.then(async () => {
// 			await onSend(isNew, false, true, false);
// 		})
// 		.catch(() => {});
// }}
