import { Grid } from '@mui/material';

const LineContainer = (props) => {
	return (
		<Grid
			item
			style={{
				display: props.isBlock ? 'block' : 'flex',
				width: '100%',
				lineBreak: 'strict',
			}}
		>
			{props.children}
		</Grid>
	);
};

export default LineContainer;
