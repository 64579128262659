import {
	useFlowmeterMutation,
	useManageFlowmeterMutation,
} from '../../store/api/api';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	flowmeterInterface,
	flowmeterSchema,
	flowmeterInitialized,
} from '@toriosoftware/torioshared/dist/flowmeter/Flowmeter';
import MainInputController from '../../components/inputs/MainInputController';
import FormContainer from '../../components/containers/FormContainer';
import PageContainer from '../../components/containers/PageContainer';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';

const Flowmeter = ({ user }) => {
	// State
	const [getFlowmeter] = useFlowmeterMutation();
	const [manageFlowmeter] = useManageFlowmeterMutation();

	// POST DATA
	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
	} = useForm<flowmeterInterface>({
		defaultValues: flowmeterInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(flowmeterSchema),
	});

	// GET DATA
	const {
		modelState,
		setIsLoaded,
		isLoaded,
		history,
		isNew,
		id,
		data,
		csrfToken,
	} = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.flowmeter, user)?.data?.get(
				getFlowmeter,
				reset
			),
		],
		user: user,
	});

	return (
		<PageContainer breadPlaceholder={'Flow'} header={'Flow'}>
			<FormContainer
				csrfToken={csrfToken}
				isDirty={isDirty}
				modal={modelState}
				handleSubmit={handleSubmit}
				showBack={true}
				onSubmitFunction={manageFlowmeter}
				linkAfterSuccess={
					isNew ? ROUTE_TYPES[ROUTE_NAMES.flowmeters].route : ''
				}
				customData={{ id }}
				isLoaded={isLoaded}
				goBackDepth={0}
				history={history}
				hideRemove={isNew ? true : false}
			>
				<MainInputController
					control={control}
					label={'Name'}
					type={'text'}
					identifier={'name'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Description'}
					type={'text'}
					rows={'3'}
					identifier={'description'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Ml per circle to use (allow foam disposal)'}
					type={'number'}
					identifier={'mlPerCircleToUse'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Ml per circle'}
					type={'number'}
					identifier={'mlPerCircle'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Ml per circle normally (without foam disposal)'}
					type={'number'}
					identifier={'mlPerCircleNormal'}
					md={12}
				/>


				<MainInputController
					control={control}
					label={'Spins per liter'}
					type={'number'}
					identifier={'spinsPerLiter'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Flow category'}
					identifier={'flowCategory'}
					md={12}
				/>
			</FormContainer>
		</PageContainer>
	);
};

export default Flowmeter;
