import { useFlowMutation, useManageFlowMutation } from '../../store/api/api';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	flowInterface,
	flowSchema,
	flowInitialized,
} from '@toriosoftware/torioshared/dist/flow/Flow';
import MainInputController from '../../components/inputs/MainInputController';
import FormContainer from '../../components/containers/FormContainer';
import PageContainer from '../../components/containers/PageContainer';
import moment from 'moment';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';

const Flow = ({ user }) => {
	// State
	const [flow] = useFlowMutation();
	const [manageFlow] = useManageFlowMutation();

	// POST DATA
	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
	} = useForm<flowInterface>({
		defaultValues: flowInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(flowSchema),
	});



	// GET DATA
	const {
		modelState,
		setIsLoaded,
		isLoaded,
		history,
		isNew,
		id,
		data,
		csrfToken,
	} = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.flow, user)?.data?.get(flow, reset),
		],
		user: user,
	});

	return (
		<PageContainer breadPlaceholder={'Flow'} header={'Flow'}>
			<FormContainer
				csrfToken={csrfToken}
				isDirty={isDirty}
				modal={modelState}
				handleSubmit={handleSubmit}
				showBack={true}
				onSubmitFunction={manageFlow}
				linkAfterSuccess={isNew ? ROUTE_TYPES[ROUTE_NAMES.flows].route : ''}
				customData={{ id }}
				isLoaded={isLoaded}
				goBackDepth={0}
				history={history}
				hideRemove={isNew ? true : false}
			>

				<MainInputController
					control={control}
					label={'Flow circles'}
					type={'number'}
					identifier={'flowCircles'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Constant used'}
					type={'number'}
					disabled={true}
					identifier={'constantUsed'}
					md={12}
				/>
				<MainInputController
					control={control}
					disabled={true}
					label={'Flow circles allowed'}
					type={'number'}
					identifier={'flowCirclesAllowed'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Ml poured'}
					type={'number'}
					identifier={'mlPoured'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Actual ml poured'}
					type={'number'}
					identifier={'actualMlPoured'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Price per 330 ml'}
					type={'number'}
					identifier={'price330Ml'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Price to pay'}
					type={'number'}
					identifier={'priceToPay'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Is test'}
					type={'string'}
					identifier={'isTest'}
					md={12}
				/>
			</FormContainer>
		</PageContainer>
	);
};

export default Flow;
