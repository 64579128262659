import React, { useContext, useEffect } from 'react';
import { Context } from '../../store/modalContext/store';
import { sendAPI, onObjectChangeReturn } from '../../api/helper';
import { useParams } from 'react-router-dom';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import InputMain from '../../components/inputs/MainInput';
import MainButton from '../../components/buttons/MainButton';
import { Grid } from '@mui/material';
import FileUpload from '../../components/uploads/FileUpload';
import MainDropdown from '../../components/dropdowns/MainDropdown';
import TimezoneSelect from '../../components/dropdowns/TimezoneSelect';
import PanelContainer from '../../components/containers/PanelContainer';
import MainContainer from '../../components/containers/MainContainer';
import { goOutOfItem } from '../../business/routingHelper';
import Header from '../../components/texts/Header';
import { Panel } from '../../styles/styledComponents';
import { check } from 'react-interaction';
import { currencies } from '../../data/data';
import MainSwitch from '../../components/switch/MainSwitch';
import CountrySelect from '../../components/dropdowns/CountrySelect';
import PageContainer from '../../components/containers/PageContainer';

function CreatePlace({ user }: any) {
	//@ts-ignore
	const [state] = useContext(Context);
	const [place, setPlace] = React.useState({});
	const params = useParams<any>();
	const history = useHistoryNav();

	useEffect(() => {
		if (place && !place['timezone']) {
			setPlace({ ...place, timezone: 'Atlantic/Reykjavik' });
		}
	}, []);

	function setSelectedCountryManage(value) {
		let newPlace = Object.assign({}, place);
		newPlace['country'] = value;
		setPlace(newPlace);
	}

	async function onSend() {
		let result = sendAPI(
			'places/createPlace/',
			{ place },
			state.openModal,
			() => {}
		);
		// history('/');
	}

	const onValueChange = (key, value) => {
		let obj = onObjectChangeReturn(place, key, value);
		setPlace(obj);
	};

	const onCurrencyChange = (e) => {
		const newCurrency = e.target.value;
		let newPlace = Object.assign({}, place);
		newPlace['currency'] = newCurrency;
		setPlace(newPlace);
	};

	return (
		<PageContainer breadPlaceholder={'Create place'} header={'Create place'}>
			{user.systemRole === 'Admin' ? (
				<MainContainer isLoaded={true} direction="row" history={history}>
					<PanelContainer header={'Create place'}>
						<Grid spacing={2} container>
							<MainSwitch
								label={'Is Barhopp active'}
								value={place['isActive'] ? place['isActive'] : ''}
								identifier={'isActive'}
								onChange={(key, value) => {
									if (!place['isActive']) {
										onValueChange(key, value);
									} else {
										onValueChange(key, value);
									}
								}}
							/>
							<InputMain
								md={6}
								label={'Place name'}
								value={place['name'] ? place['name'] : ''}
								identifier={'name'}
								onChange={onValueChange}
							/>
							<InputMain
								md={6}
								label={'Timezone'}
								value={place['timezone'] ? place['timezone'] : ''}
								identifier={'timezone'}
								onChange={onValueChange}
							/>
							<MainDropdown
								id={'currency-select'}
								value={place['currency']}
								options={currencies}
								onChange={onCurrencyChange}
								label={'Currency'}
								size={6}
								optionsValueKey={'code'}
								optionsTextKey={'code'}
							/>
							<InputMain
								md={12}
								label={'Subscription'}
								value={place['subscription'] ? place['subscription'] : ''}
								identifier={'subscription'}
								onChange={onValueChange}
							/>
							{/* <InputMain
								md={6}
								label={'Detailed place ID (if it exists)'}
								value={place['detailedPlaceId'] ? place['detailedPlaceId'] : ''}
								identifier={'detailedPlaceId'}
								onChange={onValueChange}
							/> */}
							<InputMain
								md={6}
								label={'Identifier'}
								value={place['identifier'] ? place['identifier'] : ''}
								identifier={'identifier'}
								onChange={onValueChange}
							/>

							<InputMain
								md={6}
								label={'Lat'}
								value={place['lat'] ? place['lat'] : ''}
								identifier={'lat'}
								onChange={onValueChange}
							/>
							<InputMain
								md={6}
								label={'Long'}
								value={place['long'] ? place['long'] : ''}
								identifier={'long'}
								onChange={onValueChange}
							/>

							<InputMain
								md={6}
								label={'SSN'}
								maxlength={100}
								value={place['ssn'] ? place['ssn'] : ''}
								identifier={'ssn'}
								onChange={onValueChange}
							/>
							<InputMain
								md={6}
								label={'Email'}
								maxlength={300}
								value={place['email'] ? place['email'] : ''}
								identifier={'email'}
								onChange={onValueChange}
							/>

							{/* Atlantic/Reykjavik */}
							{/* <TimezoneSelect
									setTimezone={setTimezone}
									timezone={place['timezone']}
								/> */}

							{/* <CountrySelect
								md={6}
								selectedCountry={place['country']}
								onCountryChange={setSelectedCountryManage}
							/> */}

							<InputMain
								md={6}
								label={'Username'}
								value={place['username'] ? place['username'] : ''}
								identifier={'username'}
								onChange={onValueChange}
							/>
						</Grid>

						<Grid style={{ width: '100%', marginTop: 20 }}>
							<FileUpload
								text={'Place logo'}
								handleFile={(image) => {
									place['image'] = image;
								}}
								initialFile={place['image'] ? place['image'] : ''}
							/>
						</Grid>
						<Grid style={{ display: 'flex' }}>
							<MainButton
								color={'primary'}
								onClick={() => onSend()}
								text={'Create'}
							/>
							<MainButton
								color={'default'}
								onClick={() => {
									goOutOfItem(history);
								}}
								text={'Go back'}
							/>
						</Grid>
					</PanelContainer>
				</MainContainer>
			) : null}
		</PageContainer>
	);
}

export default CreatePlace;
