import { Typography } from '@mui/material';

const NoEvents = ({ noEventsText }) => {
	return (
		<Typography
			textAlign={'center'}
			margin={'auto'}
			variant="body1"
			marginTop={3}
			marginBottom={3}
		>
			{noEventsText}
		</Typography>
	);
};

export default NoEvents;
