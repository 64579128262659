import { useGetPlaceMutation, useSavePlaceMutation } from '../../store/api/api';
import {
	placeInitalized,
	PlaceProps,
} from '@toriosoftware/torioshared/dist/place';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import FormContainer from '../../components/containers/FormContainer';
import PageContainer from '../../components/containers/PageContainer';
import MainInputController from '../../components/inputs/MainInputController';
import MainSwitchController from '../../components/switch/MainSwitchController';

import SmallDisplayCard from '../../components/cards/SmallDisplayCard';
import { Grid } from '@mui/material';
import { Alert } from '@mui/material';
import { placeSchema } from '@toriosoftware/torioshared/dist/place';
import MainContainer from '../../components/containers/MainContainer';
import { joiResolver } from '@hookform/resolvers/joi';
import PlaceUsers from '../PlaceUsers/PlaceUsers';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import { ROUTE_NAMES, APP_TYPES } from '@toriosoftware/torioshared/dist/config';
import MainDropdownController from '../../components/dropdowns/MainDropdownController';
import { timezones } from '../../data/data';
import PanelContainer from '../../components/containers/PanelContainer';
import { Typography } from '@mui/material';
// import { Player } from '@lottiefiles/react-lottie-player';
import HelpCard from '../../components/cards/HelpCard';

function Place({ user }: any) {
	const [getPlace] = useGetPlaceMutation();
	const [savePlace] = useSavePlaceMutation();

	// useForm
	const { formState, handleSubmit, control, reset, watch, setValue } =
		useForm<PlaceProps>({
			// useForm<Place>({
			defaultValues: placeInitalized,
			shouldFocusError: true,
			mode: 'onChange',
			resolver: joiResolver(placeSchema),
		});

	let subscription = watch('subscription');
	let email = watch('email');
	// let image = watch('image');

	const { modelState, setIsLoaded, isLoaded, history, isNew, id, data } =
		useManageHook({
			isLoadedInitial: false,
			retrieveData: [
				GET_ROUTE_COMPONENT(ROUTE_NAMES.place, user)?.data?.get(
					getPlace,
					reset
				),
			],
			user: user,
		});

	// Find subscription here..
	return (
		<PageContainer header={'Manage place subscription'}>
			{/* {
				<div>
					how to use this page
					<img src={AddUserGif} alt={'User'} />
				</div>
			} */}
			<HelpCard
				seconds={29}
				title={'How to add users'}
				texts={[
					'Add an user by an email and select a role. ',
					'User can only create an account using the email and link you send him (or email to him). ',
					'If user already has an account, then he can simply login and access your place after you have added him. ',
				]}
				link={'https://youtu.be/IIkrXou6a-k'}
			/>

			{subscription === 'Barhopp' ? (
				<MainContainer direction={'row'}>
					<PanelContainer
						whiteText={true}
						style={{
							background:
								'linear-gradient(90deg, rgba(113,9,121,1) 0%, rgba(0,13,201,1) 100%)',
						}}
						md={12}
						header={'Barhopp status'}
					>
						<Grid
							spacing={2}
							style={{}}
							sx={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
								flexDirection: { xs: 'column', md: 'row' },
							}}
							md={3}
							sm={12}
						>
							{/* <Player
								src="https://assets3.lottiefiles.com/packages/lf20_4tg3fb79.json"
								className="player"
								loop
								autoplay
								style={{ width: 120, height: 120, marginTop: 10 }}
							/> */}
						</Grid>
						<Grid
							md={9}
							sm={12}
							style={{
								width: '100%',
								paddingBottom: 15,
							}}
							item
						>
							<Typography
								textAlign={'center'}
								variant={'body1'}
								fontSize={20}
								fontWeight={400}
								// margin={'auto'}
								style={{
									display: 'flex',
									// justifyContent: 'center',
									color: 'white',
								}}
							>
								Barhopp will help tourists, visitors and locals find various
								places in Iceland. You can add your events, happy hour offers
								and details. The app will be advertised in various locations
								around Iceland. The Barhopp subscription is completely free of
								charge.
							</Typography>

							<Typography
								textAlign={'center'}
								variant={'body1'}
								fontSize={20}
								fontWeight={400}
								margin={'auto'}
								style={{
									display: 'flex',
									justifyContent: 'center',
									color: 'white',
									marginTop: 20,
								}}
							>
								Please contact us at torio@torio.is if you have any questions or
								if anything does not work as expected.
							</Typography>
						</Grid>
					</PanelContainer>
				</MainContainer>
			) : null}

			<MainContainer isLoaded={isLoaded}>
				<SmallDisplayCard md={6} header={'Subscription'} text={subscription} />
				<SmallDisplayCard
					md={6}
					header={'Contact email'}
					text={email ? email : 'Not defined'}
				/>
			</MainContainer>

			<PlaceUsers
				redirectToItem={'placeusers'}
				placeName={user.placeName}
				showPageContainer={false}
				user={user}
			/>

			{user.systemRole === 'Admin' ? (
				<FormContainer
					modal={modelState}
					handleSubmit={handleSubmit}
					onSubmitFunction={savePlace}
					showBack={true}
					linkAfterSuccess={''}
					hideBack={true}
					customData={{ id }}
					isLoaded={isLoaded}
					title={user.placeName + ' admin settings'}
					goBackDepth={0}
					history={history}
					hideRemove={true}
				>
					{user.systemRole === 'Admin' ? (
						<MainInputController
							control={control}
							label={'Contact email'}
							type={'text'}
							identifier={'email'}
							md={6}
						/>
					) : null}

					{user.systemRole === 'Admin' ? (
						<MainInputController
							control={control}
							label={'Contact phone'}
							type={'text'}
							identifier={'phone'}
							md={6}
						/>
					) : null}

					{user.systemRole === 'Admin' ? (
						<MainDropdownController
							label={'Payment provider'}
							helperText="Select drink type"
							options={[
								APP_TYPES.paymentProviders.Rapyd,
								APP_TYPES.paymentProviders.Valitor,
								APP_TYPES.paymentProviders.Teya,
							].map((item) => {
								return { text: item, value: item };
							})}
							md={6}
							control={control}
							optionsValueKey={'value'}
							optionsTextKey={'text'}
							identifier={'paymentProvider'}
							id={'paymentProvider'}
						/>
					) : null}

					{user.systemRole === 'Admin' ? (
						<MainDropdownController
							label={'Refund process'}
							helperText="Select refund process"
							options={[
								"Credit",
								"Payment"
							].map((item) => {
								return { text: item, value: item };
							})}
							md={6}
							control={control}
							optionsValueKey={'value'}
							optionsTextKey={'text'}
							identifier={'refundProcess'}
							id={'refundProcess'}
						/>
					) : null}

					{user.systemRole === 'Admin' ? (
						<MainDropdownController
							label={'POS provider'}
							helperText="Select drink type"
							options={[
								'Verifone',
							].map((item) => {
								return { text: item, value: item };
							})}
							md={6}
							control={control}
							optionsValueKey={'value'}
							optionsTextKey={'text'}
							identifier={'posProvider'}
							id={'posProvider'}
						/>
					) : null}

					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'Identifier'}
						type={'text'}
						identifier={'identifier'}
						md={6}
					/>

					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'Timezone'}
						type={'text'}
						identifier={'timezone'}
						md={6}
					/>

					<MainSwitchController
						control={control}
						label={'Allow manual credit'}
						identifier={'allowManualCredit'}
						md={6}
					/>

					<MainSwitchController
						control={control}
						label={'Use test payment provider'}
						identifier={'testPayment'}
						md={6}
					/>

					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'Currency'}
						type={'text'}
						identifier={'currency'}
						md={6}
					/>

					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'FireID'}
						type={'text'}
						identifier={'fireID'}
						md={6}
					/>

					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'Country'}
						type={'text'}
						identifier={'country'}
						md={6}
					/>

					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'Username'}
						type={'text'}
						identifier={'username'}
						md={6}
					/>

					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'Password'}
						type={'text'}
						identifier={'password'}
						md={6}
					/>
					<MainInputController
						control={control}
						hideInput={user.systemRole !== 'Admin'}
						label={'Subscription'}
						type={'text'}
						identifier={'subscription'}
						md={6}
					/>

					{/* <MainImageController
					setValue={setValue}
					control={control}
					text={'Company / Place logo'}
					initialFiles={image && image.image ? [image] : []}
					identifier={'image'}
				/> */}
					<Grid item>
						<Alert style={{ width: '100%' }} severity="info">
							If you cannot find what you want to change you can contact
							administrator at torio@torio.is
						</Alert>
					</Grid>
				</FormContainer>
			) : null}
		</PageContainer>
	);
}

export default Place;
