import axios from 'axios';
import { check } from 'react-interaction';

const headers = {
	'Content-Type': 'application/json',
	// 'Access-Control-Allow-Origin': process.env.REACT_APP_BACKEND_URL,
	// "Access-Control-Allow-Origin": "*"
};


export async function sendAPI(
	url: string,
	body?: any,
	openModal?: any,
	callback?: Function,
	callbackSuccess?: Function,
	callbackError?: Function,
	defaultMessage = '',
	isGet = false	
) {
	callback = callback ? callback : () => {};
	callbackSuccess = callbackSuccess ? callbackSuccess : () => {};
	callbackError = callbackError ? callbackError : () => {};
	body = body ? body : {};

	// let urlHere = window.location.origin + '/api/' + url;
	// console.log("URL HERE: ", urlHere, body);
	let urlToPost = '/api/' + url;
	try {
		let mainResult = null;

		if(isGet){
			mainResult = await axios
				.create({
					baseURL: '' //window.location.origin, //'api/' + process.env.REACT_APP_BACKEND_URL,
				})
				.get(urlToPost, {
					// withCredentials: true,
					headers: headers,
				});
		} else {
			mainResult = await axios
			.create({
				baseURL: '' //window.location.origin, //'api/' + process.env.REACT_APP_BACKEND_URL,
			})
			.post(urlToPost, JSON.stringify(body), {
				// withCredentials: true,
				headers: headers,
			});
		}


		if (mainResult && mainResult.data && mainResult.data.success) {
			if (mainResult.data.message) { 
				if (openModal) {
					openModal(
						'Success',
						mainResult.data.message,
						'success',
						callback,
						callbackSuccess,
						callbackError
					);
				} else {
					callback();
				}
			}
			let data = {...mainResult.data};
			if(mainResult?.headers['x-csrf-token']){ 
				let xToken = mainResult.headers['x-csrf-token'];
				data['csrfToken'] = xToken;
			}

			return data;
		} else {
			if (mainResult.data.message) {
				if (openModal) {
					if (mainResult.data.redirectFrontpage) {
						callback = redirectToFrontpage.bind(this);
					}

					openModal('Warning', mainResult.data.message, 'error', callback);
				} else {
					callback();
				}
			}
			return mainResult.data;
		}
	} catch (ex) {
		if (openModal) {
			openModal(
				'Warning',
				'Error came up while processing your request. Please contact administrator if this persists. '+ ex.response.data,
				'error'
			);
		}
		return {};
	}
}

export const submitFormMain = async (url: string, body: any, openModal: any, history: any, goBackAfterCreate: boolean) => {

	// find out if create.. 

	let result = await sendAPI(url, body, openModal, null, () => { 
		if(goBackAfterCreate && history) { 
		} else {
		}
		// window.location.reload();
	});
}

export const onErrorMain = (openModal, message = "") => {
	openModal(
		'Error',
		message ? message : 'There are errors in the form you must fix before submitting. ',
		'error'
	);
}


export const onObjectChange = (object, key, value, updateFunction) => {
	const newObject = Object.assign({}, object);
	newObject[key] = value;
	updateFunction(newObject);
};

export const onObjectChangeReturn = (object, key, value) => {
	const newObject = Object.assign({}, object);
	newObject[key] = value;
	return newObject;
	// updateFunction(newObject);
};

export async function onLogout(openModal, invalidate) {
	let result = await sendAPI('/auth/logout', {}, openModal, () => {
		setTimeout(() => {
			window.location.reload()
		}, 1500);
	});
	await invalidate();

}

export function redirectToItemNewTab(route, id) {
	return () => {
		if (id) {
			const win = window.open(route + id, '_blank');
			win.focus();
		}
	};
}

export function redirectToFrontpage() {
	window.history.pushState('', '', '/');
}

export function removeItem(text, callback) {
	if(window.confirm(text)) {
		callback();
	}
}
