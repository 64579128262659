import { Component } from 'react';
// import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneArea } from 'react-mui-dropzone';
import ImageCompression from 'browser-image-compression';
import './App.css';
import { Grid } from '@mui/material';
import { check } from 'react-interaction';

interface FileUploadProps {
	handleFiles: Function;
	initialFiles: any;
	text: string;
	filesLimit?: number;
	compression?: number;
	maxSize?: number;
	currentFiles?: Array<File>;
}
// (JSX attribute) onChange?: (files: File[

async function getFile(file) {
	return new Promise(function (res, rej) {
		try {
			fetch(file)
				.then((res) => res.blob())
				.then((blob) => {
					if (blob) {
						const file = new File([blob], 'File', {
							type: blob.type,
						});
						res(file);
					}
					rej(null);
				});
		} catch (err) {
			rej(err);
		}
	});
}

class FileUpload extends Component<
	FileUploadProps,
	{
		isLoaded: boolean;
		initialFiles: any; //Array<File>;
		initialFilesFromServer: any; //Array<File>;
		filesLimit?: number;
		minImageSize?: number;
		currentFiles?: Array<File>;
		compression?: number;
	}
> {
	constructor(props) {
		super(props);
		this.state = {
			initialFiles: [],
			isLoaded: false,
			initialFilesFromServer: [],
		};
	}

	showUploadManyError = (error) => {
		window.alert(error);
		// check(error, {});
	};

	componentDidMount = async () => {
		this.initialize(this.props.initialFiles);
	};

	isFileTooBig = (sizeByte) => {
		let size = this.props.maxSize ? this.props.maxSize : 0.38;
		size += 0.03;
		if (sizeByte) {
			let mb = sizeByte / 1024 / 1024;
			if (mb <= size) {
				//0.38) {
				return false;
			} else {
				return true;
			}
		}
		return true;
	};

	handleFileRead = (imageToUse, rawImage) => {
		return new Promise((res) => {
			try {
				const reader = new FileReader();
				reader.readAsDataURL(imageToUse);
				reader.onload = (e) => {
					if (e.target.result) {
						res({
							base64Image: e.target.result,
							originalSize: rawImage.size,
							lastModified: rawImage.lastModified,
							type: rawImage.type,
							link: rawImage.link,
							assetId: rawImage.assetId,
							public_id: rawImage.public_id,
						});
					} else {
						res(null);
					}
				};
			} catch (err) {
				res(null);
			}
		});
	};

	handleFileDrop = async (files) => {
		if (files && files.length > 0) {
			let allFiles = [];
			for (let i = 0; i < files.length; i++) {
				let image = files[i];
				let imageToUse = null;
				let isTooBig = this.isFileTooBig(image.size);
				if (isTooBig) {
					const options = {
						maxSizeMB: this.props.compression ? this.props.compression : 0.21, // (default: Number.POSITIVE_INFINITY)
						maxWidthOrHeight: 700, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
					};
					let compressedImage = await ImageCompression(image, options);
					if (compressedImage) {
						imageToUse = compressedImage;
					} else {
						this.showUploadManyError('Could not compress image. ');
					}
				} else {
					imageToUse = image;
				}

				if (imageToUse) {
					let file = await this.handleFileRead(imageToUse, image);
					if (file) {
						allFiles.push(file);
					}
				}
			}
			this.props.handleFiles(allFiles);
		} else {
			this.props.handleFiles([]);
		}
	};

	initialize = async (files) => {
		let preparedFiles = [];
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				let file = files[i];

				if (file && file.link) {
					let updatedFile = await getFile(file.link);
					updatedFile['link'] = file.link;
					updatedFile['assetId'] = file.assetId;
					updatedFile['quality'] = file.quality;
					updatedFile['public_id'] = file.public_id;
					if (updatedFile) {
						preparedFiles.push(updatedFile);
					}
				}
			}
		}
		this.setState({
			initialFiles: preparedFiles,
			isLoaded: true,
		});
	};

	render() {
		// if (!this.state.isLoaded) return <div></div>;
		if (!this.state.isLoaded) return <div></div>;
		return (
			<Grid style={{ width: '100%', marginTop: 20 }} item md={12} >
				{/* https://yuvaleros.github.io/material-ui-dropzone/ */}
				<DropzoneArea
					onChange={(files) => {
						this.handleFileDrop(files);
					}}
					onDrop={(files) => {}}
					onDelete={(file) => {}}
					acceptedFiles={['image/jpeg', 'image/png']}
					filesLimit={this.props.filesLimit ? this.props.filesLimit : 1}
					dropzoneText={this.props.text}
					// multiple={false}
					initialFiles={this.state.initialFiles}
					maxFileSize={9000000}
					dropzoneClass="dropzone"
					dropzoneParagraphClass="dropzone-text"
					classes={{}}
				/>
			</Grid>
		);
	}
}

export default FileUpload;
