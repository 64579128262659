import PageContainer from '../../components/containers/PageContainer';
import GoogleConnect from './GoogleConnect';

const GoogleConnectPage = ({ user }: any) => {
	return (
		<PageContainer header={'Google connect'}>
			<GoogleConnect user={user} />
		</PageContainer>
	);
};

export default GoogleConnectPage;
