const LoginScreenWrapper = ({ children }) => {
	return (
		<div className="login-main-wrapper">
			<div className="login-main-wrapper__left-content"></div>
			<div className="login-main-wrapper__login-form">
				<div className="login-main-wrapper__login-content">{children}</div>
			</div>
		</div>
	);
};

export default LoginScreenWrapper;
