import {createSlice, createSelector} from '@reduxjs/toolkit';
import { LoginStore } from './loginTypes';
import type { RootState } from '../store';
import { parseUrlQuery } from '../../business/routingHelper';
import { isResetPasswordUrl, getJoinPlaceToken } from '../../business/loginHelper';
import { api } from '../api/api';

// ** Initial state **
const initialState: LoginStore = {
  email: '',
  forgotPasswordToken: '',
  showForgotPassword: false,
  error: '',
  showPassword: false,
  allowRegister: false,
  showRegister: false,
  token: '',
  placeName: '',
  image: '',
  isTokenValid: false,
  emailCode: '',
  emailToken: '',
  phoneToken: '',
};

// ** Slice **
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateEmail(state, action) {
      state.email = action.payload;
    },
    updateEmailToken(state, action) {
      state.emailToken = action.payload;
    },
    updatePhoneToken(state, action) {
      state.phoneToken = action.payload;
    },
    setShowForgotPassword(state, action) {
      state.showForgotPassword = action.payload
      state.error = '';
    },
    setShowPassword(state) {
      state.showPassword = !state.showPassword //action.payload
      state.error = '';
    },
    setEmailCode(state, action) {
      state.emailCode = action.payload
      state.error = '';
    },
    setShowLogin(state) {
      state.showRegister = false;
      state.error = '';
    },  
    setShowRegister(state) {
      state.showRegister = true;
      state.error = '';
      // if(state.allowRegister) {
      // } else {
      //   state.showRegister = false;
      // }
    },
    setForgotPasswordToken(state, action) {
      state.forgotPasswordToken = action.payload;
    },
    resetSomeLoginData(state) {
      state.showForgotPassword = false;
      state.showRegister = false;
      state.email = '';
      state.forgotPasswordToken = '';
      state.error = '';
    },
    resetLoginData(state) {
      state.email = '';
      state.forgotPasswordToken = '';
      state.showForgotPassword = false;
      state.error = '';
      state.showPassword = false;
      state.allowRegister = false;
      state.showRegister = false;
      state.token = '';
      state.placeName = '';
      state.image = '';
      state.isTokenValid = false;
      state.emailCode = '';
    },
    removeChangePasswordDetails(state) {
      state.forgotPasswordToken = '';
      state.showForgotPassword = false;
      state.error = '';
    },
    // setJoinPlaceToken(state, action) {
    //   // if(action.payload) {
    //   //   state.allowRegister = true;
    //   //   state.joinPlaceToken = action.payload;
    //   // } else {
    //   //   state.allowRegister = false;
    //   //   state.joinPlaceToken = '';
    //   // }
    // },
    handleUrl(state) {
      let url = window.location.href;
      if (url) {
        let query = parseUrlQuery(url);
        // Reset password
        if (isResetPasswordUrl(query)) {
          state.email = query['email'][0];
          state.forgotPasswordToken = query['resetpassword'][0];
          state.error = '';
        } else {
          // console.log("hanlde url error");
          // state.email = '';
          // state.forgotPasswordToken = '';
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getJoinDetails.matchFulfilled,
      (state, { payload }) => {
        if(payload && payload.invite && payload.success && payload.invite.isTokenValid && payload.invite.token && payload.invite.invitedEmail) {
          const invite = payload.invite;
          state.placeName = invite.placeName;
          state.image = invite.image;
          state.isTokenValid = invite.isTokenValid;
          state.token = invite.token;
          state.email = invite.invitedEmail;
          state.allowRegister = true;
          state.showRegister = true;
          state.error = '';
        } else {
          state.error = payload.message;
        }
      }
    ).addMatcher(
      api.endpoints.confirmEmail.matchFulfilled,
      (state, {payload}) => {
        state.emailCode = '';
      }
    ).addMatcher(
      api.endpoints.logout.matchFulfilled,
      (state, {payload}) => {
        state.email = '';
        state.forgotPasswordToken = '';
        state.showForgotPassword = false;
        state.error = '';
        state.showPassword = false;
        state.allowRegister = false;
        state.showRegister = false;
        state.token = '';
        state.placeName = '';
        state.image = '';
        state.isTokenValid = false;
        state.emailCode = '';
      }
    ).addMatcher(
      api.endpoints.resetPassword.matchFulfilled,
      (state, {payload}) => {
        if(payload.success) {
          state.forgotPasswordToken = '';
          state.showForgotPassword = false;
          state.error = '';
          window.location.href = '/login';
        }
      }
    )
  },
});

// ** Actions **
export const { updateEmail, handleUrl, resetLoginData, setForgotPasswordToken, updatePhoneToken, updateEmailToken, setShowForgotPassword, resetSomeLoginData, setShowPassword, setEmailCode, setShowRegister, setShowLogin, removeChangePasswordDetails } = loginSlice.actions;

// ** Selectors **
export const self = (state: RootState) => state.login;
export const loginDataSelector = createSelector(self, (state) => state);
export const selectedEmailSelector = createSelector(self, (state) => state.email);
export const forgotPasswordSelector = createSelector(self, (state) => state.forgotPasswordToken);
export const showForgotPasswordSelector = createSelector(self, (state) => state.showForgotPassword);
export const showPasswordSelector = createSelector(self, (state) => state.showPassword);
export const allowRegisterSelector = createSelector(self, (state) => state.allowRegister);
export const showRegisterSelector = createSelector(self, (state) => state.showRegister);
export const isTokenValidSelector = createSelector(self, (state) => state.isTokenValid);
export const emailCodeSelector = createSelector(self, (state) => state.emailCode);
export const emailTokenSelector = createSelector(self, (state) => state.emailToken);
export const phoneTokenSelector = createSelector(self, (state) => state.phoneToken);

export const errorSelector = createSelector(self, (state) => state.error);
export const getTokenDetailsSelector = createSelector(self, (state) => {
  const data = {
    placeName: state.placeName,
    image: state.image,
    isTokenValid: state.isTokenValid,
    showRegister: state.showRegister,
    allowRegister: state.allowRegister,
  }
  return data;
});

// export const isUserLoggedIn = createSelector(
//   user,
//   (state: UserStore) => state.loggedIn
// );

