import lock from './../../assets/lock.png';
import './style.scss';

// interface LoginSelectFieldOptionProps {
// 	_id: string;
// 	name: string;
// 	ssn?: string;
// }

interface LoginSelectFieldProps {
	options: Array<any>;
	onChange: Function;
	selectedValue: string;
}

// { value, setValue, label }
const LoginSelectField = ({
	options,
	onChange,
	selectedValue,
}: LoginSelectFieldProps) => {
	return (
		<div
			className="login-main-wrapper_input-wrapper"
			style={{
				position: 'relative',
				marginTop: 36,
			}}
		>
			<img src={lock} alt="message-icon" className="img-fluid img-fluid-icon" />
			<select
				onChange={(e) => onChange(e.target.value)}
				className="login-select"
			>
				<option value="" className="login-option">
					Select a place to log into
				</option>
				{options && options.length
					? options.map((option, index) => {
							return (
								<option
									selected={selectedValue === option._id}
									key={index}
									className="login-option"
									value={option._id}
								>
									{option.name}{' '}
									{option?.detailedPlace?.isActive ? '(On Barhopp)' : ''}
								</option>
							);
					  })
					: null}
			</select>
		</div>
	);
};

export default LoginSelectField;
