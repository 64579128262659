import { Grid } from '@mui/material';

const ButtonGroup = ({ children }) => {
	return (
		<Grid
			md={12}
			item
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			{children}
		</Grid>
	);
};

export default ButtonGroup;
