import { getCurrencyTypeByCode } from './currencyHelper';
import { getDaysDiscountText, getDiscountTimes } from './discountHelper';

export const tapsDropdownMaker = (taps) => {
	return taps.map((tap) => {
		let drink = null;
		if (tap && tap.useKeg && tap.keg && tap.keg.drink) {
			drink = tap?.keg?.drink;
		} else {
			drink = tap.drink;
		}

		if (drink) {
			return {
				_id: tap._id,
				text: `Tap number ${tap.tapNumber} - ${drink.name}`,
			};
		} else {
			return {};
		}
	});
};

export const flowDropdownMaker = (flows) => {
	if (flows) {
		let drinksDropdown = flows.map((value) => {
			let dropdown: { _id: string; text: string } = {
				_id: '',
				text: '',
			};

			dropdown._id = value._id;
			dropdown.text =
				getItem(value.name) +
				getItem(value.mlPerCircleToUse) +
				' Spins per liter: ' +
				getItem(value.spinsPerLiter);
			return dropdown;
		});
		return drinksDropdown;
	}
	return [];
};

export const drinksDropdownMaker = (drinks) => {
	if (drinks) {
		let drinksDropdown = drinks.map((value) => {
			let dropdown: { _id: string; text: string } = {
				_id: '',
				text: '',
			};
			dropdown._id = value._id;
			dropdown.text =
				getItem(value.name) +
				getItem(value.type) +
				getItem(value.price330Ml + 'kr per 330ml');
			return dropdown;
		});
		return drinksDropdown;
	}
	return [];
};

export const kegsDropdownMaker = (kegs) => {
	if (kegs) {
		let kegsDropdown = kegs.map((value) => {
			let dropdown: { _id: string; text: string } = {
				_id: '',
				text: '',
			};
			dropdown._id = value._id;
			let price = '';
			if (value.discountPrice) {
				price = value?.discountPrice
					? value?.discountPrice + ' kr (discount). '
					: '';
			} else if (value.price) {
				price = value?.price ? value?.price + ' kr. ' : '';
			} else {
				price = 'No price. ';
			}

			let drinkName = value?.drink?.name ? value.drink.name : 'No drink.';

			dropdown.text = drinkName + ' - ' + price;

			return dropdown;
		});
		return kegsDropdown;
	}
	return [];
};

export const googleItemsDropdownMaker = (items) => {
	if (items) {
		let itemsDropdown = items.map((value) => {
			let dropdown: { place_id: string; text: string } = {
				place_id: '',
				text: '',
			};
			dropdown.place_id = value.place_id;
			let name = value.name;
			let address = value.formatted_address;
			let status = value.business_status;
			dropdown.text = name + ' - ' + address + ' - ' + status;
			return dropdown;
		});
		return itemsDropdown;
	}
	return [];
};

export const placeDropdownMaker = (places) => {
	if (places) {
		let placesDropdown = places.map((value) => {
			let dropdown: { _id: string; text: string } = {
				_id: value._id,
				text: value.name,
			};
			return dropdown;
		});

		return placesDropdown;
	}
	return [];
};

export const devicesDropdownMaker = (places) => {
	if (places) {
		let placesDropdown = places.map((value) => {
			let dropdown: { _id: string; text: string } = {
				_id: value._id,
				text: value.name,
			};
			return dropdown;
		});

		return placesDropdown;
	}
	return [];
};

export const gpiosDropdownMaker = (gpios) => {
	if (gpios) {
		return gpios.map((item) => {
			return {
				_id: item._id,
				text:
					'Using GPIO ' +
					item.flowMeterGPIO +
					'. Solenoid valve GPIO ' +
					item.solenoidValveGPIO +
					(+' ' + item?.flowmeter?.name
						? '. Flow used is: ' + item?.flowmeter?.name
						: '.') +
					(item?.information ? '. Information: ' + item?.information : ''),
			};
		});
	}
	return [];
};

function getItem(value) {
	if (value) {
		return value + '. ';
	} else {
		return '';
	}
}

function capitalizeTxt(txt) {
	return txt.charAt(0).toUpperCase() + txt.slice(1);
}

function getDiscountDates(dates) {
	return dates
		.map((value, index) => {
			return value;
		})
		.join(', ');
}

function getDaysAvailable(days) {
	if (days) {
		let finalDays = '';
		let isFirstDay = true;
		Object.keys(days).forEach((key) => {
			if (key && days[key]) {
				if (!isFirstDay) {
					finalDays += ', ';
				} else {
					isFirstDay = false;
				}
				finalDays += capitalizeTxt(key);
			}
		});
		if (finalDays) {
			finalDays += '. ';
		}
		return finalDays;
	} else {
	}
}

function getDiscount(percentage, fixed, useDiscountPrice, currency) {
	if (percentage && !useDiscountPrice) {
		return percentage + '% discount. ';
	} else if (fixed && useDiscountPrice) {
		return percentage + getCurrencyTypeByCode(currency) + ' discounted price. ';
	} else {
		return '';
	}
}

export const discountTimesMaker = (discounts) => {
	if (discounts && discounts.length > 0) {
		return discounts.map((item) => {
			let daysText = getDaysDiscountText(
				item.monday,
				item.tuesday,
				item.wednesday,
				item.thursday,
				item.friday,
				item.saturday,
				item.sunday
			);
			let datesText = getDiscountTimes(item.times);
			let text = daysText + datesText;
			return { _id: item._id, text: text };
		});
	}
};

export const discountRepeatMaker = (discounts) => {
	// if (discounts && discounts.length > 0) {
	// 	return discounts.map((item) => {
	// 		let daysText = getDaysDiscountText(item.monday, item.tuesday, item.wednesday, item.thursday, item.friday, item.saturday, item.sunday);
	// 		let datesText = getDiscountTimes(item.times);
	// 		let text = daysText + datesText;
	// 		return { _id: item._id, text: text };
	// 	});
	// }
};

export const discountCustomMaker = (discounts, currency) => {
	if (discounts && discounts.length > 0) {
		return discounts.map((item) => {
			let text =
				getDiscount(
					item.percentage,
					item.fixed,
					item.useDiscountPrice,
					currency
				) +
				getItem(item.name) +
				getItem(item.from + ' - ' + item.to) +
				getDiscountDates(item.dates);
			return { _id: item._id, text: text };
		});
	}
};
