import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import MainInput from '../../components/inputs/MainInput';
import MainButton from '../../components/buttons/MainButton';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import { sendAPI } from '../../api/helper';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { goBack } from '../../business/routingHelper';
import urlID from '../../assets/URL_id.png';
import { Link } from 'react-router-dom';

const AddEventFromFB = ({ isLoaded, user }) => {
	const [eventID, setEventID] = React.useState<string>('');
	//@ts-ignore
	const [state] = useContext(Context);
	const [inEnglish, setInEnglish] = React.useState<boolean>(true);
	const [showInstructions, setShowInstructions] =
		React.useState<boolean>(false);

	const history = useHistoryNav();

	async function onSend() {
		console.log('sending data: ');
		if (!eventID) return;
		let result = await sendAPI(
			'events/addEventFB/' + eventID,
			{},
			state.openModal,
			null,
			() => {}
		);
		if (result.success) {
			window.location.reload();
		}
	}

	return (
		<MainContainer>
			<PanelContainer isLoaded={isLoaded} header={'Add Facebook event'}>
				<span
					style={{
						color: 'red',
						fontSize: 18,
						width: '80%',
						// fontWeight: 600,
						textAlign: 'center',
						margin: 'auto',
						marginTop: 20,
						marginBottom: 20,
					}}
				>
					{user.provider === 'Facebook'
						? ''
						: "You need to be logged in with Facebook and have access to your place's Facebook page to add events from Facebook."}
				</span>
				{user.provider !== 'Facebook' ? (
					<Link
						style={{
							margin: 'auto',
							justifyContent: 'center',
							marginBottom: 15,
							marginTop: -10,
						}}
						to={'/admin/place/placeusers'}
					>
						Click here to add your Facebook email address to allowed email
						addresses
					</Link>
				) : null}

				<MainInput
					label={'Facebook event URL (or ID)'}
					value={eventID}
					md={12}
					identifier={'eventID'}
					onChange={(a, b) => {
						console.log('b: ', b);
						if (b && b.includes('facebook.com')) {
							let id = b.split('events/')[1].split('/')[0];
							setEventID(id);
						} else {
							setEventID(b);
						}
					}}
				/>
				<span
					style={{
						color: 'gray',
						marginTop: 5,
						marginLeft: 15,
						cursor: 'pointer',
					}}
					onClick={() => {
						setShowInstructions(!showInstructions);
					}}
				>
					Where is the event ID?
				</span>
				{showInstructions ? (
					<div style={{ width: '100%', textAlign: 'center' }}>
						<span style={{ marginTop: 15 }}>
							Event IDs are in the Facebook event URL
						</span>
						<img
							src={urlID}
							style={{
								width: '90%',
								margin: 'auto',
								marginTop: 18,
								borderRadius: 15,
							}}
						/>
					</div>
				) : null}
				{/* <MainSwitch
					label={'Text is English'}
					value={inEnglish}
					isSmall={true}
					identifier={'inEnglish'}
					onChange={(key, value) => {
						setInEnglish(true);
					}}
				/>
				<MainSwitch
					label={'Text is Icelandic'}
					value={!inEnglish}
					isSmall={true}
					identifier={'inIcelandic'}
					onChange={(key, value) => {
						setInEnglish(false);
					}}
				/> */}
				<div style={{ width: '100%' }}>
					<MainButton
						style={{ marginLeft: 15, marginTop: 30 }}
						color={'default'}
						disabled={!eventID || user.provider !== 'Facebook'}
						onClick={() => {
							onSend();
						}}
						text={'Add event'}
					/>
				</div>
			</PanelContainer>
		</MainContainer>
	);
};

export default AddEventFromFB;
