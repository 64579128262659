import {createSlice, createSelector, isAnyOf} from '@reduxjs/toolkit';
import { UserStore } from './userTypes';
import type { RootState } from '../store';
import { api } from '../api/api';
// import { api } from '../../store/api/api';

// ** Initial state **
const initialState: UserStore = {
  token: '',
  email: '',
	firstName: '',
	lastName: '',
  isLoaded : false,
  loggedIn: false,
  jwtExpired: false,
  customerId: '',

  _id:  '',
  userId:  '',
  role:  '',
  placeCurrency: '',
  placeDecimals: 0,
  currencyAppend: 0,

  subscription:  '',
  country:  '',
  placeKey:  '',
  timezone:  '',
  accountType:  '',

  place: '',
  placeName: '',
  systemRole: '',
  status: '',
  creditCardAdded:false,
  places: [],
  emailConfirmed: false,
  getReceipts: false,
  authType:  '',
  isEmailConfirmed: false,
  userPlacesSession:  '',
};

// ** Slice **
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateToken(state, action) {
      state.token = action.payload.token;
    },
    setIsLoaded(state,action) {
      state.isLoaded = action.payload;
    },
    setCustomerId(state,action) {
      console.log("SETTING CUSTOMER ID: ", action.payload);
      state.customerId = action.payload;
    }
  }
});

// ** Actions **
export const { updateToken, setCustomerId } = userSlice.actions;

// ** Selectors **
export const self = (state: RootState) => state.user;
export const getUserSelector = createSelector(self, (state) => state);
export const isLoadedSelector = createSelector(self, (state) => state.isLoaded);
export const placeCurrencySelector = createSelector(self, (state) => state.placeCurrency);
export const customerIdSelector = createSelector(self, (state) => state.customerId);


// export const isUserLoggedIn = createSelector(
//   user,
//   (state: UserStore) => state.loggedIn
// );

