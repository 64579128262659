import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { formControlStyles } from '../../styles/materialStyles';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

interface MainDropdownProps {
	label: string;
	id: string;
	value: any;
	onChange: any;
	optionsValueKey: string;
	optionsTextKey: string;
	options: any;
	isWithLink?: boolean;
	link?: string;
	size?: any;
	isString?: boolean;
	style?: any;
}

const MainDropdown = ({
	label,
	id,
	value,
	onChange,
	optionsValueKey,
	optionsTextKey,
	options,
	isWithLink,
	link,
	size,
	isString,
	style = {},
}: MainDropdownProps) => {
	const mdSize: any = size ? size : 6;
	return (
		<Grid style={{ display: 'grid' }} item xs={12} md={mdSize}>
			<FormControl
				// fullWidth={fullWidth ? fullWidth : false}
				className={formControlStyles().root}
				variant="outlined"
				// style={{ width: '90%' }}
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					alignItems: 'center',
					justifyContent: 'space-between',
					borderRadius: 5,
					borderColor: 'blue',
				}}
			>
				<InputLabel htmlFor={id}>{label}</InputLabel>
				<Select
					// native
					onChange={onChange}
					label={label}
					// sx={{
					// 	borderRadius: 2,
					// 	borderColor: 'blue',
					// 	borderWidth: 2,
					// 	fontWeight: 'bold',
					// }}
					value={value ? value : ''}
					style={{
						width: '100%',
						borderColor: 'blue',
						backgroundColor: 'white',
						...style,
					}}
					inputProps={{
						name: id,
						id: id,
					}}
				>
					<MenuItem aria-label="none">None</MenuItem>
					{options && options.length > 0
						? options.map((value, index) => {
								return (
									<MenuItem
										style={{ borderColor: 'blue' }}
										key={index}
										value={isString ? value : value[optionsValueKey]}
									>
										{isString ? value : value[optionsTextKey]}
									</MenuItem>
								);
						  })
						: null}
				</Select>

				{isWithLink ? (
					<Link
						style={{
							textDecoration: 'none',
							color: 'black',
							padding: 5,
						}}
						target="_blank"
						rel="noreferrer"
						to={link}
					>
						<span
							style={{
								textAlign: 'center',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							Create new
						</span>
					</Link>
				) : null}
			</FormControl>
		</Grid>
	);
};

export default MainDropdown;
