import React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import { formControlStyles } from '../../styles/materialStyles';
import { Grid, GridSize } from '@mui/material';
import { Controller } from 'react-hook-form';

const MainSwitchController = ({
	identifier,
	// value,
	label,
	isSmall,
	control,
	about,
	md,
	hideInput,
}: {
	identifier: string;
	// value: any;
	label: string;
	control: any;
	isSmall?: boolean;
	hideInput?: boolean;
	about?: string;
	md?: GridSize;
}) => {
	// const [checked, setChecked] = React.useState(false);

	// const toggleChecked = () => {
	// 	setChecked((prev) => !prev);
	// };

	// const getValue = () => {
	// 	if (value) {
	// 		return true;
	// 	}
	// 	return false;
	// };

	if (hideInput) {
		return null;
	}

	return (
		<Controller
			control={control}
			name={identifier}
			render={({
				field: { onChange, onBlur, value, ref },
				fieldState: { invalid, isTouched, isDirty, error },
				formState: { errors },
			}) => {
				return (
					<Grid item xs={12} md={md ? md : 12}>
						<FormControl
							// fullWidth={true}
							className={clsx(formControlStyles().root)}
							// style={{ width: '98%' }}
						>
							<FormControlLabel
								control={
									<Switch
										ref={ref}
										color={'primary'}
										checked={value}
										onBlur={onBlur}
										onChange={onChange}
										// toggleChecked={toggleChecked()}
									/>
								}
								label={label}
							/>
						</FormControl>
					</Grid>
				);
			}}
		/>
	);
};

export default MainSwitchController;

// {/* <p
// 		style={{
// 			color: 'gray',
// 			fontWeight: 600,
// 			cursor: 'pointer',
// 			marginBottom: 0,
// 		}}
// 	>
// 		What is this?
// 	</p> */}
// 				{/* <p style={{ color: 'gray' }}>{about}</p> */}
