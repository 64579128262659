import {
	InsertEmoticon,
	SentimentSatisfied,
	SentimentDissatisfied,
	SentimentVeryDissatisfied,
	Face,
} from '@mui/icons-material';

// const commonIconStyles = { fontSize: 60 };

export const getSurveyIcons = [
	{
		icon: (fontSize) => (
			<InsertEmoticon style={{ color: 'green', fontSize: fontSize }} />
		),
		color: '#edf7ed',
		rating: 5,
	},
	{
		icon: (fontSize) => (
			<SentimentSatisfied style={{ color: '#62d159', fontSize: fontSize }} />
		),
		color: '#221133',
		rating: 4,
	},
	{
		icon: (fontSize) => (
			<Face style={{ color: '#8e8e8e', fontSize: fontSize }} />
		),
		color: '#8e8e8e',
		rating: 3,
	},
	{
		icon: (fontSize) => (
			<SentimentDissatisfied style={{ color: '#f2b534', fontSize: fontSize }} />
		),
		color: '#221133',
		rating: 2,
	},
	{
		icon: (fontSize) => (
			<SentimentVeryDissatisfied style={{ color: 'red', fontSize: fontSize }} />
		),
		color: '#221133',
		rating: 1,
	},
];

export const getBadge = (firstColor, secondColor, text) => {
	return (
		<div
			style={{
				backgroundColor: firstColor,
				borderRadius: 50,
				padding: 13,
				fontWeight: 600,
				height: 20,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				color: secondColor,
			}}
		>
			{text}
		</div>
	);
};
