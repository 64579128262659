import React, { useContext } from 'react';
import { Context } from './store/modalContext/store';
import './App.css';
import Modal from './components/modals/Modal';
import LoadingScreen from './components/loading/LoadingScreen';
import AppRoutes from './routing/AppRoutes';
import { useGetUserQuery, useGetUserForceMutation } from './store/api/api';
import 'moment/locale/is';
// import { MessengerChat } from 'react-messenger-chat-plugin';
// import ReactGA from 'react-ga4';

export default function App() {
	// const { data: user, error, isLoading } = useGetUserQuery();
	//@ts-ignore
	const [modalState, modalDispatch] = useContext(Context);
	const modalRef = React.useRef();

	// ReactGA.initialize('G-1T1Q0GM8V2');

	const [getUserForce, { data: user, isLoading, error }] =
		useGetUserForceMutation();

	React.useEffect(() => {
		getUserForce();
		//@ts-ignore
		modalDispatch({
			type: 'OPEN_MODAL_REF',
			payload: (header, text, type, callback, successCallback, errorCallback) =>
				//@ts-ignore
				modalRef?.current?.open(
					header,
					text,
					type,
					callback,
					successCallback,
					errorCallback
				),
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!user || (user && !user.isLoaded) || isLoading) {
		return <LoadingScreen />;
	}

	return (
		<div>
			<Modal ref={modalRef} />
			<AppRoutes user={user} modalState={modalState} />
			{/* <MessengerChat
				pageId="toriosoftware"
				language="en"
				loggedOutGreeting={'Have a great day. '}
				themeColor={'#222'}
				bottomSpacing={300}
				loggedInGreeting="Hello, do you need any help? :)"
				greetingDialogDisplay={'show'}
			/> */}
		</div>
	);
}
