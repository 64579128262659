import { buttonStyles } from '../../styles/materialStyles';
import { Button, Grid } from '@mui/material';

interface MainButtonProps {
	text: string;
	onClick: Function;
	color?: any;
	customColor?: string;
	style?: any;
	buttonColor?:
		| 'primary'
		| 'error'
		| 'inherit'
		| 'secondary'
		| 'success'
		| 'info'
		| 'warning';
	customTextColor?: string;
	disabled?: boolean;
	removePadding?: boolean;
	isSubmit?: boolean;
	variant?: undefined | 'contained' | 'outlined',
}

const MainButton = ({
	text,
	onClick,
	color,
	buttonColor,
	customColor,
	variant,
	customTextColor,
	disabled,
	isSubmit,
	removePadding,
	style = {},
}: MainButtonProps) => {
	let backgroundColorStyle =
		customColor && customTextColor
			? { backgroundColor: customColor, color: customTextColor }
			: {};

	if (color === 'dark') {
		color = 'primary';
	}

	if (color === 'danger') {
		color = 'error';
	}

	return (
		<Grid item>
			<Button
				type={isSubmit ? 'submit' : 'button'}
				style={{
					maxWidth: 300,
					marginRight: 10,
					color: 'white',
					marginTop: 10,
					textDecoration: 'none',
					...style,
					// ...backgroundColorStyle,
				}}
				className={!removePadding ? buttonStyles().root : null}
				onClick={() => onClick()}
				color={buttonColor ? buttonColor : 'primary'}
				disabled={disabled}
				variant={ variant ? variant : "contained"}
			>
				{text}
			</Button>
		</Grid>
	);
};

export default MainButton;
