import { useGetPaymentDetailsMutation, useSavePaymentDetailsMutation } from '../../store/api/api';
import {
	paymentDetailsInitalized,
	PaymentDetailsProps,
	paymentDetailsSchema
} from '@toriosoftware/torioshared/dist/paymentDetails';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import FormContainer from '../../components/containers/FormContainer';
import PageContainer from '../../components/containers/PageContainer';
import MainInputController from '../../components/inputs/MainInputController';
import MainSwitchController from '../../components/switch/MainSwitchController';

import { joiResolver } from '@hookform/resolvers/joi';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import { ROUTE_NAMES, APP_TYPES } from '@toriosoftware/torioshared/dist/config';
import SubHeader from '../../components/texts/SubHeader';
// import { Player } from '@lottiefiles/react-lottie-player';

function PaymentDetails({ user }: any) {
	const [getPaymentDetails] = useGetPaymentDetailsMutation();
	const [savePaymentDetails] = useSavePaymentDetailsMutation();

	// useForm
	const { formState, handleSubmit, control, reset, watch, setValue } =
		useForm<PaymentDetailsProps>({
			defaultValues: paymentDetailsInitalized,
			shouldFocusError: true,
			mode: 'onChange',
			resolver: joiResolver(paymentDetailsSchema),
		});

	// let subscription = watch('subscription');
	// let email = watch('email');
	// let image = watch('image');

	console.log("ROUTE_NAMES.paymentdetails: ", ROUTE_NAMES.paymentdetails);

	const { modelState, setIsLoaded, isLoaded, history, isNew, id, data } =
		useManageHook({
			isLoadedInitial: false,
			retrieveData: [
				GET_ROUTE_COMPONENT(ROUTE_NAMES.paymentdetails, user)?.data?.get(
					getPaymentDetails,
					reset
				),
			],
			user: user,
		});

	// Find subscription here..
	return (
		<PageContainer header={'Manage payment details'}>

			<FormContainer
				modal={modelState}
				handleSubmit={handleSubmit}
				onSubmitFunction={savePaymentDetails}
				showBack={true}
				linkAfterSuccess={''}
				hideBack={true}
				customData={{ id }}
				isLoaded={isLoaded}
				title={user.placeName + ' admin settings'}
				goBackDepth={0}
				history={history}
				hideRemove={true}
			>

				{
					[
						{"id": "", "label": "", "subheader": "Teya Test"},
						{"id": "teyaPublicKeyTest", "label": "Teya Public Key Test"},
						{"id": "teyaPrivateKeyTest", "label": "Teya Private Key Test"},
						{"id": "vendorIdTest", "label": "Vendor Id Test"},
						{"id": "borgunMerchantIdTest", "label": "Borgun Merchant Id Test"},
						{"id": "borgunSecretKeyTest", "label": "Borgun Secret Key Test"},
						{"id": "", "label": "", "subheader": "Teya Production"},
						{"id": "teyaPublicKey", "label": "Teya Public Key"},
						{"id": "teyaPrivateKey", "label": "Teya Private Key"},
						{"id": "vendorId", "label": "Vendor Id"},
						{"id": "borgunMerchantId", "label": "Borgun Merchant Id"},
						{"id": "borgunSecretKey", "label": "Borgun Secret Key"},
						{"id": "", "label": "", "subheader": "POS details"},
						{"id": "authorizationKey", "label": "Authorization key"},
						{"id": "siteEntityId", "label": "Site entity id"},
						{"id": "serialNumber", "label": "Serial number"},
						{"id": "POIID", "label": "POIID"},
					].map((item, index) => {
						if(item.subheader){
							return (
								<SubHeader text={item.subheader} />
							)
						}
						return (
							<MainInputController
								control={control}
								label={item.label}
								type={'text'}
								identifier={item.id}
								md={6}
							/>
						)
					})
				}
				
			</FormContainer>
		</PageContainer>
	);
}

export default PaymentDetails;
