import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { formControlStyles } from '../../styles/materialStyles';
import { Link } from 'react-router-dom';
import { FormHelperText, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { GridSize } from '@mui/material';

interface MainDropdownProps {
	label: string;
	id: string;
	identifier: string;
	// value: any;
	optionsValueKey: string;
	optionsTextKey: string;
	options: any;
	isWithLink?: boolean;
	link?: string;
	md?: GridSize;
	disabled?: boolean;
	hideInput?: boolean;
	isString?: boolean;
	control: any;
	helperText?: string;
}

const MainDropdownController = ({
	label,
	id,
	// value,
	optionsValueKey,
	optionsTextKey,
	options,
	disabled,
	hideInput,
	control,
	isWithLink,
	helperText,
	link,
	md,
	identifier,
	isString,
}: MainDropdownProps) => {
	if (hideInput) return <></>;

	return (
		<Controller
			control={control}
			name={identifier}
			render={({
				field: { onChange, onBlur, value, ref },
				fieldState: { invalid, isTouched, isDirty, error },
				formState: { errors },
			}) => {
				return (
					<Grid item xs={12} md={md ? md : 12}>
						<FormControl
							fullWidth={true}
							className={formControlStyles().root}
							variant="outlined"
							// style={{ width: '90%' }}
							style={{
								display: 'flex',
								flexDirection: 'row',
								// width: '100%',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<InputLabel htmlFor={id}>{label}</InputLabel>
							<Select
								// native
								// autoWidth
								onChange={onChange}
								onBlur={onBlur}
								ref={ref}
								label={label}
								disabled={disabled}
								value={value ? value : ''}
								style={{ backgroundColor: 'white', width: '100%' }}
								inputProps={{
									name: id,
									id: id,
								}}
							>
								<MenuItem value={''} aria-label="none">
									None
								</MenuItem>
								{options && options.length > 0
									? options.map((value, index) => {
											return (
												<MenuItem
													dense={true}
													style={{ overflow: 'hidden' }}
													key={index}
													value={isString ? value : value[optionsValueKey]}
												>
													<div
														style={{
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															whiteSpace: 'normal',
														}}
													>
														{isString ? value : value[optionsTextKey]}
													</div>
												</MenuItem>
											);
									  })
									: null}
							</Select>

							{isWithLink ? (
								<Link
									style={{
										textDecoration: 'none',
										color: 'black',
										padding: 5,
									}}
									rel="noreferrer"
									target="_blank"
									to={link}
								>
									<span
										style={{
											textAlign: 'center',
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										Create new
									</span>
								</Link>
							) : null}
						</FormControl>
						<FormHelperText>{helperText}</FormHelperText>
					</Grid>
				);
			}}
		/>
	);
};

export default MainDropdownController;
