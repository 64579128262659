import React, { useContext } from 'react';
import MainContainer from '../../components/containers/MainContainer';
import { Context } from '../../store/modalContext/store';
import PanelContainer from '../../components/containers/PanelContainer';
import MainInput from '../../components/inputs/MainInput';
import ButtonGroup from '../../components/buttons/ButtonGroup';
import MainButton from '../../components/buttons/MainButton';
import { onTranslateApi, onTranslateApiRelease } from '../../api/miscData';
import { useOnSendPushMutation } from '../../store/api/api';

const Misc = ({ role }: any) => {
	//@ts-ignore
	const [state] = useContext(Context);
	const [title, setTitle] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [code, setCode] = React.useState('');
	const [jsonString, setJsonString] = React.useState('');
	const [releaseTextEn, setReleaseTextEn] = React.useState('');
	const [language, setLanguage] = React.useState('');
	const [translatedJsonString, setTranslatedJsonString] = React.useState('');
	const [translatedReleaseString, setTranslatedReleaseString] =
		React.useState('');
	const [onSendPush] = useOnSendPushMutation();

	// const [table, setTable] = React.useState({});
	// const [isLoaded, setIsLoaded] = React.useState(false);
	// const history = useHistoryNav();
	// const params = useParams<any>();

	async function onTranslate() {
		let translationData = await onTranslateApi(
			JSON.stringify(jsonString),
			language
		);
		if (translationData && translationData.translation) {
			setTranslatedJsonString(JSON.stringify(translationData.translation));
		}
	}

	async function onTranslateRelease() {
		let translationData = await onTranslateApiRelease(releaseTextEn);
		if (translationData && translationData.translation) {
			setTranslatedReleaseString(translationData.translation);
		}
	}

	return (
		<MainContainer isLoaded={true}>
			<PanelContainer header={'Helpers'}>
				<MainInput
					label={
						'JSON string to translate (THIS DOES NOT WORK - put manually in backend)'
					}
					value={jsonString}
					identifier={'jsonString'}
					onChange={(key, value) => {
						setJsonString(value);
					}}
					md={12}
					// rows={'10'}
				/>
				<p>Kína er zh-CN</p>
				<MainInput
					label={'Target language'}
					value={language}
					identifier={'language'}
					onChange={(key, value) => {
						setLanguage(value);
					}}
					md={12}
				/>

				<p>{translatedJsonString}</p>

				<ButtonGroup>
					<div style={{ display: 'flex' }}>
						<MainButton
							color={'primary'}
							onClick={() => {
								onTranslate();
							}}
							text={'Save'}
						/>
					</div>
				</ButtonGroup>
			</PanelContainer>
			<PanelContainer header={'Translate release'}>
				<MainInput
					label={'Release text'}
					value={releaseTextEn}
					identifier={'text'}
					onChange={(key, value) => {
						setReleaseTextEn(value);
					}}
					md={12}
					// rows={'10'}
				/>

				<p>{translatedReleaseString}</p>

				<ButtonGroup>
					<div style={{ display: 'flex' }}>
						<MainButton
							color={'primary'}
							onClick={() => {
								onTranslateRelease();
							}}
							text={'Save'}
						/>
					</div>
				</ButtonGroup>
			</PanelContainer>
			<PanelContainer header={'Push on'}>
				<MainInput
					label={'Title'}
					value={title}
					identifier={'text'}
					onChange={(key, value) => {
						setTitle(value);
					}}
					md={12}
				/>
				<MainInput
					label={'Message'}
					value={message}
					identifier={'message'}
					onChange={(key, value) => {
						setMessage(value);
					}}
					md={12}
				/>
				<MainInput
					label={'Code'}
					value={code}
					identifier={'text'}
					onChange={(key, value) => {
						setCode(value);
					}}
					md={12}
				/>

				<ButtonGroup>
					<div style={{ display: 'flex' }}>
						<MainButton
							color={'primary'}
							onClick={() => {
								const res = onSendPush({ title, message, code }).unwrap();
							}}
							text={'Save'}
						/>
					</div>
				</ButtonGroup>
			</PanelContainer>
		</MainContainer>
	);
};

export default Misc;
