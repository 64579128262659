export const routeToId = (history, url, id) => {
	history(url + '/' + id);
};

export const goBack = (history) => {
	history(-1);
};

export const goOutOfItem = (history) => {
	const path = history.location.pathname;
	const pathArray = path?.split('/');
	if (pathArray && pathArray.length > 2) {
		const newUrl = '/' + pathArray[1];
		window.location.href = newUrl;
	}
};

export const parseUrlQuery = (value) => {
	var urlParams = new URL(value).searchParams;
	return Array.from(urlParams.keys()).reduce((acc, key) => {
		acc[key] = urlParams.getAll(key);
		return acc;
	}, {});
};
