export const getDaysDiscountText = (mon, tue, wed, thu, fri, sat, sun) => {
    let days = [];

    if (mon) {
        days.push('Mon');
    }
    if (tue) {
        days.push('Tue');
    }
    if (wed) {
        days.push('Wed');
    }
    if (thu) {
        days.push('Thu');
    }
    if (fri) {
        days.push('Fri');
    }
    if (sat) {
        days.push('Sat');
    }
    if (sun) {
        days.push('Sun');
    }

    if(days.length > 0) {
        let newDays = (days.join(', ') )+ '. ';
        return newDays;
    } else {
        return 'No days. ';
    }
}

export const getDiscountTimes = (times) => {
    let timesText = [];
    if(times.length === 0) {
        return 'No times. ';
    }
    times.forEach((time) => {
        timesText.push(time.from + ' - ' + time.to);
    });
    let timesTextNew = timesText.join(', ');
    timesTextNew = timesTextNew + '. ';
    return timesTextNew;
}
