import React, { useEffect } from 'react';
import { Alert, Button, Grid, Snackbar } from '@mui/material';
import FormContainer from '../../components/containers/FormContainer';
import PageContainer from '../../components/containers/PageContainer';
import useManageHook from '../../components/hooks/useManageHook';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	PlaceUserForm,
	placeUserFormInitialized,
	placeUserSchema,
} from '@toriosoftware/torioshared/dist/placeUser';
import {
	useGetPlaceUserMutation,
	useRemovePlaceUserMutation,
	useSavePlaceUserMutation,
} from '../../store/api/api';
import MainInputController from '../../components/inputs/MainInputController';
import MainDropdownController from '../../components/dropdowns/MainDropdownController';
import { useParams } from 'react-router-dom';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import MainButton from '../../components/buttons/MainButton';
import MainSwitchController from '../../components/switch/MainSwitchController';

interface AddUserProps {
	user: any;
}

const PlaceUser = ({ user }: AddUserProps) => {
	const [getPlaceUser] = useGetPlaceUserMutation();
	const [savePlaceUser] = useSavePlaceUserMutation();
	const [removePlaceUser] = useRemovePlaceUserMutation();
	const [textCopied, setTextCopied] = React.useState(false);

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
	} = useForm<PlaceUserForm>({
		defaultValues: placeUserFormInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(placeUserSchema),
	});

	// const params = useParams<any>();
	// console.log('params 44: ', params);

	const {
		modelState,
		setIsLoaded,
		isLoaded,
		history,
		isNew,
		id,
		data,
		csrfToken,
	} = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.placeuser, user)?.data?.get(
				getPlaceUser,
				reset
			),
		],
		user: user,
	});

	useEffect(() => {
		if (isNew === true) {
			setValue('emailInvitation', true);
		}
	}, [isNew]);

	let roleSelected = watch('role');
	let emailSelected = watch('email');
	//@ts-ignore
	let placeUser = data?.user?.user;

	return (
		<PageContainer
			breadPlaceholder={roleSelected ? roleSelected : 'Place user'}
			header={'User in ' + user.placeName}
		>
			<FormContainer
				csrfToken={csrfToken}
				modal={modelState}
				handleSubmit={handleSubmit}
				onSubmitFunction={savePlaceUser}
				onRemoveFunction={removePlaceUser}
				isDirty={isDirty}
				showBack={true}
				linkAfterSuccess={
					isNew ? ROUTE_TYPES[ROUTE_NAMES.placeusers].route : ''
				}
				linkAfterDelete={ROUTE_TYPES[ROUTE_NAMES.placeusers].route}
				removeText={
					'Are you sure you want to remove this user from this place?'
				}
				customData={{ id }}
				removeData={{ id }}
				isLoaded={isLoaded}
				goBackDepth={1}
				removeCustomText={
					user.email === emailSelected ? 'Remove yourself from place' : 'Remove'
				}
				history={history}
				hideRemove={isNew}
			>
				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={textCopied}
					autoHideDuration={3500}
					onClose={() => {
						setTextCopied(false);
					}}
					message="Invite link has been copied to clipboard."
					key={2}
				/>

				<MainInputController
					control={control}
					label={'User email *'}
					type={'text'}
					disabled={!isNew}
					identifier={'email'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Name'}
					type={'text'}
					identifier={'name'}
					md={12}
				/>

				{/* <MainInputController
					hideInput={user.systemRole !== 'Admin'}
					control={control}
					label={'Text type'}
					type={'text'}
					identifier={'textType'}
					md={12}
				/>

				{user.systemRole === 'Admin' ? (
					<p>event restaurant cocktail wine admin cafe faraway</p>
				) : null} */}
				<MainDropdownController
					label={'User role *'}
					helperText={
						roleSelected === 'Place user'
							? 'Place user can change general information about the place such as description, upload photos and create events. He/she cannot add users to the company or change company settings.'
							: roleSelected === 'Place admin'
							? 'Place admin can do everything a place user can do and also add users to the company, manage them and change sensitive details. '
							: ''
					}
					options={[
						{
							text: 'Place user',
							value: 'Place user',
						},
						{
							text: 'Place admin',
							value: 'Place admin',
						},
					]}
					md={12}
					control={control}
					optionsValueKey={'value'}
					identifier={'role'}
					optionsTextKey={'text'}
					id={'role'}
				/>

				<MainInputController
					control={control}
					hideInput={user.systemRole !== 'Admin'}
					label={'Sub role'}
					type={'text'}
					identifier={'subRole'}
					md={12}
				/>


				{isNew ? (
					<MainSwitchController
						control={control}
						label={'Let user know by email'}
						md={6}
						identifier={'emailInvitation'}
					/>
				) : null}

				{/* 
				<Grid>
					<p>Optional</p>
				</Grid> */}

				{/* <MainInputController
					control={control}
					label={'Phone country code (optional)'}
					type={'text'}
					identifier={'countryPhoneCode'}
					md={4}
				/>

				<MainInputController
					control={control}
					label={'Phone (optional)'}
					type={'text'}
					identifier={'phone'}
					md={8}
				/> */}

				<Grid md={12} item spacing={2}>
					{placeUser?.inviteLink ? (
						!isNew ? (
							<div>
								<Alert style={{ wordBreak: 'break-all' }} severity={'warning'}>
									User's invite link: {placeUser?.inviteLink}
								</Alert>
								<MainButton
									onClick={() => {
										// history(-1);
										navigator.clipboard.writeText(placeUser.inviteLink);
										setTextCopied(true);
									}}
									text={'Copy invitation link to clipboard'}
									color={'default'}
								/>
							</div>
						) : null
					) : null}
				</Grid>

				{isNew ? (
					<Grid md={12} item spacing={2}>
						<Alert severity={'info'}>
							The email you add here will be able to login to this place /
							company.
						</Alert>
					</Grid>
				) : null}
			</FormContainer>
		</PageContainer>
	);
};

export default PlaceUser;
