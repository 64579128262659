import { getSaleColumns } from '../../business/tableHelper';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
// import PanelContainer from '../../../components/containers/PanelContainer';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import KegPageContainer from '../Kegs/KegPageContainer';
import Sale from './Sale';

interface PlaceUserProps {
	user: any;
	modalState: any;
}

const Sales = ({ user, modalState }: PlaceUserProps) => {
	const history = useHistoryNav();

	return (
		<React.Fragment>
			<ManageItems
				header={'Sales'}
				key={195}
				customClickFunction={null}
				getData={{
					url: 'kegs/getSales',
					body: {},
				}}
				noItemsText={'No sales found.'}
				columns={getSaleColumns(user.placeCurrency, true)}
				redirectLink={'sales/:id'}
				manageComponent={() => <Sale />}
			/>
		</React.Fragment>
	);
};

export default Sales;
