import Calendar from 'react-calendar';
import moment from 'moment';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import TextField from '@mui/material/TextField';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import MomentUtils from '@date-io/moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface CalendarMainProps {
	onCalendarPress: any;
	data: any;
	selectedDate: any;
	setSelectedDate?: any;
}

const CalendarMain = ({
	onCalendarPress,
	data,
	selectedDate,
}: CalendarMainProps) => {
	let today = moment().format('DD-MM-YYYY');

	const renderCalendarDay = (day) => {
		let europeDay = moment(day).format('DD-MM-YYYY');
		let color = '#fff';
		if (selectedDate === europeDay) {
			color = 'rgba(0, 0, 255, 0.6)';
		} else if (data.includes(europeDay)) {
			color = 'rgba(0, 0, 255, 0.3)';
		}
		let border = today === europeDay ? '3px solid #111' : 'none';
		return { color, border };
	};
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<StaticDatePicker<Date>
				// orientation="landscape"
				openTo="day"
				displayStaticWrapperAs="desktop"
				renderDay={(day, selectedDays, misc) => {
					let { color, border } = renderCalendarDay(day);
					return (
						<div role="row" className="css-mvmu1r">
							<button
								onClick={() => {
									onCalendarPress(day);
								}}
								style={{
									display: 'inline-flex',
									WebkitAlignItems: 'center',
									WebkitBoxAlign: 'center',
									alignItems: 'center',
									WebkitBoxPack: 'center',
									WebkitJustifyContent: 'center',
									justifyContent: 'center',
									position: 'relative',
									boxSizing: 'border-box',
									WebkitTapHighlightColor: 'transparent',
									backgroundColor: color,
									outline: '0',
									margin: '0 2px',
									borderRadius: '50%',
									border: border,
									padding: '0',
									cursor: 'pointer',
									verticalAlign: 'middle',
									textDecoration: 'none',
									color: 'rgba(0, 0, 0, 0.87)',
									fontFamily: 'DM Sans,sans-serif',
									fontWeight: '400',
									fontSize: '0.75rem',
									lineHeight: '1.33',
									width: '36px',
									height: '36px',
								}}
								className="MuiButtonBase-root MuiPickersDay-root MuiPickersDay-dayWithMargin css-2mg32r-MuiButtonBase-root-MuiPickersDay-root"
								type="button"
								aria-label="month date"
							>
								{day.getDate()}
								<span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
							</button>
						</div>
					);
				}}
				inputFormat={'DD-MM-YYYY'}
				value={moment(selectedDate, 'DD-MM-YYYY').toDate()}
				onChange={(newValue) => {
					// setSelectedDate(moment(newValue).format('DD-MM-YYYY'));
				}}
				renderInput={(params) => <TextField {...params} />}
			/>
		</LocalizationProvider>
	);
};

export default CalendarMain;
