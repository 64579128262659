import React, { forwardRef, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import './modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// interface OpenProps {
//     header: string,
//     text: string,
//     type: string,
// }

//@ts-ignore
const Modal = forwardRef((props, ref) => {
	const [header, setHeader] = React.useState('');
	const [text, setText] = React.useState('');
	const [type, setType] = React.useState('');
	let callbackRef = React.useRef(() => {});
	let errorCallbackRef = React.useRef(() => {});
	let successCallbackRef = React.useRef(() => {});

	const [display, setDisplay] = React.useState(false);

	useImperativeHandle(ref, () => {
		return {
			open: (header, text, type, callback, successCallback, errorCallback) => {
				open(header, text, type);
				if (callback) callbackRef.current = callback;
				if (successCallback) successCallbackRef.current = successCallback;
				if (errorCallback) errorCallbackRef.current = errorCallback;
			},
		};
	});

	const open = (header, text, type) => {
		setHeader(header);
		setText(text);
		setType(type);
		setDisplay(true);
	};

	const close = () => {
		setHeader('');
		setText('');
		setType('');
		callbackRef.current();
		if (type === 'error' || type === 'warning') {
			errorCallbackRef.current();
		} else if (type === 'success') {
			console.log('success callback here ', successCallbackRef);
			successCallbackRef.current();
		}
		callbackRef.current = () => {};
		errorCallbackRef.current = () => {};
		successCallbackRef.current = () => {};
		setDisplay(false);
	};

	if (display) {
		return ReactDOM.createPortal(
			<div>
				<div
					onClick={() => close()}
					style={{
						position: 'absolute',
						left: 0,
						top: 0,
						width: '100vw',
						height: `calc(100vh + 3000px)`, //document.body.scrollHeight + 800,
						overflow: 'hidden',
						opacity: 0.5,
						backgroundColor: 'black',
					}}
				></div>
				<div role="alert">
					<div className="alert-main">
						<h3 className="alert-header">{header}</h3>
						<FontAwesomeIcon
							icon={type === 'success' ? 'check-circle' : 'times-circle'}
							style={{
								fontSize: 60,
								color: type === 'success' ? 'green' : 'red',
							}}
						/>
						<p className="alert-body">{text}</p>
						<button
							onClick={() => close()}
							className="alert-button"
							style={{
								backgroundColor: type === 'success' ? 'green' : 'red',
							}}
						>
							Close
						</button>
					</div>
				</div>
			</div>,
			document.getElementById('modal-root')
		);
	} else {
		return null;
	}
});

export default Modal;
