/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import {
	sendAPI,
	onObjectChange,
	removeItem,
	redirectToItemNewTab,
} from '../../api/helper';
import { useParams, useLocation } from 'react-router-dom';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import MainInput from '../../components/inputs/MainInput';
import MainDropdown from '../../components/dropdowns/MainDropdown';
import {
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
} from '@mui/material';
import TapStatus from './TapStatus';
import MainButton from '../../components/buttons/MainButton';
import MainSwitch from '../../components/switch/MainSwitch';
import MainContainer from '../../components/containers/MainContainer';
import LineContainer from '../../components/lines/LineContainer';
import { goOutOfItem } from '../../business/routingHelper';
import MainCard from '../../components/cards/MainCard';
import KegIcon from '../Kegs/KegIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { check } from 'react-interaction';
import Alert from '@mui/material/Alert';
import useItems from '../../hooks/useItems';
import {
	kegsDropdownMaker,
	drinksDropdownMaker,
} from '../../business/dropdownHelper';
import PanelContainer from '../../components/containers/PanelContainer';
import InnerDiv from '../../components/containers/InnerDiv';
import BoxContainer from '../../components/containers/BoxContainer';
import ButtonGroup from '../../components/buttons/ButtonGroup';
import { getCurrencyTypeByCode } from '../../business/currencyHelper';
import { placeCurrencySelector } from '../../store/user/userSlice';
import { useAppSelector } from '../../store/store';
import PageContainer from '../../components/containers/PageContainer';
import FormContainer from '../../components/containers/FormContainer';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	TapInterface,
	TapInitialized,
	TapSchema,
} from '@toriosoftware/torioshared/dist/taps';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import {
	useGetTapMutation,
	useManageProfileMutation,
	useManageTapMutation,
} from '../../store/api/api';
import MainSwitchController from '../../components/switch/MainSwitchController';
import MainInputController from '../../components/inputs/MainInputController';
import MainDropdownController from '../../components/dropdowns/MainDropdownController';
import RadioController from '../../components/radio/RadioController';
import { textAlign } from '@mui/system';
import MonitorTap from '../MonitorTaps/MonitorTap';
import SubHeader from '../../components/texts/SubHeader';

function Tap({ user }) {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const [getTap] = useGetTapMutation();
	const [manageTap] = useManageTapMutation();

	const [showTapDeviceDropdown, setShowTapDeviceDropdown] =
		React.useState(false);

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		getFieldState,
		setValue,
		setFocus,
	} = useForm<TapInterface>({
		defaultValues: TapInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(TapSchema),
	});

	const { modelState, isLoaded, history, isNew, id, data } = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(
				ROUTE_NAMES?.tap ? ROUTE_NAMES?.tap : 'taps/:id',
				user
			)?.data?.get(getTap, reset),
		],
		user: user,
	});

	//@ts-ignore
	let accessLog = data?.tap?.accessLog;
	//@ts-ignore
	let theTap = data?.tap?.tap;
	//@ts-ignore
	let selectedDrink = theTap?.drink?._id;

	let useKegs = watch('useKegs');
	let payOrder = watch('payOrder');
	let paymentType = watch('paymentType');
	let allowTabletQR = watch('allowTabletQR');
	let allowPaperQR = watch('allowPaperQR');
	let flowType = watch('flowType');
	let isActive = watch('isActive');
	let useAgeLimit = watch('useAgeLimit');
	let device = watch('device');

	const [drinks, drink, drinksDropdown] = useItems({
		id: '',
		dropdownFunction: drinksDropdownMaker.bind(this),
		useDropdown: true,
		url: 'drinks/getDrinks/',
	});

	const [kegs, keg, kegsDropdown] = useItems({
		id: '',
		dropdownFunction: kegsDropdownMaker.bind(this),
		useDropdown: true,
		url: 'kegs/getKegs/',
	});

	React.useEffect(() => {
		setValue('drink', selectedDrink);
	}, [selectedDrink]);

	const closeTap = async () => {
		let manageTapResult = await sendAPI(
			'taps/closeTap/' + id,
			{},
			modalState.openModal,
			null,
			() => {
				window.location.reload();
			}
		);
	};

	return (
		<PageContainer header={'Tap'}>
			{id && id !== '0' ? (
				<MonitorTap
					onClick={() => {
						window.location.reload();
					}}
					user={user}
					tapId={id}
					md={12}
				/>
			) : null}

			{/* {theTap && selectedDrink && !isNew ? (
				<TapStatus
					closeTap={closeTap}
					tap={theTap}
					accessLog={accessLog ? accessLog : {}}
				/>
			) : null} */}

			<FormContainer
				modal={modelState}
				handleSubmit={handleSubmit}
				isDirty={isDirty}
				onSubmitFunction={manageTap}
				onRemoveFunction={manageTap}
				linkAfterSuccess={isNew ? ROUTE_TYPES[ROUTE_NAMES.taps].route : ''}
				showBack={true}
				refreshAfterSuccess={true}
				linkAfterDelete={'taps'}
				customData={{ id }}
				reset={reset}
				isLoaded={isLoaded}
				title={'Tap'}
				depth={0}
				history={history}
				hideRemove={isNew}
			>
				<MainInputController
					control={control}
					label={'Tap number'}
					hideInput={user.role !== 'Place admin'}
					type={'number'}
					identifier={'tapNumber'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'Tap name'}
					hideInput={user.role !== 'Place admin'}
					type={'text'}
					identifier={'tapName'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'Tap description'}
					hideInput={user.role !== 'Place admin'}
					type={'text'}
					identifier={'description'}
					md={6}
				/>
				<MainInputController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Tap location details'}
					type={'text'}
					identifier={'location'}
					md={6}
				/>
				<MainInputController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Tap wait time (in seconds)'}
					type={'number'}
					identifier={'waitTime'}
					md={6}
				/>
				<MainInputController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Ml of glass (if used)'}
					type={'number'}
					identifier={'glassMl'}
					md={6}
				/>
				<MainInputController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Ml of glass allowed (if used)'}
					type={'number'}
					identifier={'allowGlassMl'}
					md={6}
				/>
				<MainInputController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Maximum number of glasses'}
					type={'number'}
					identifier={'maxGlassesToAllow'}
					md={6}
				/>
				<MainSwitchController
					control={control}
					hideInput={false}
					label={'Show terms text'}
					md={6}
					identifier={'showTermsText'}
				/>
				<MainSwitchController
					control={control}
					hideInput={true}
					label={'Use kegs'}
					md={12}
					identifier={'useKegs'}
				/>
				<MainInputController
					control={control}
					label={'Tablet code'}
					type={'number'}
					identifier={'tabletCode'}
					md={6}
				/>
				{/* {useKegs && false ? (
					<MainDropdownController
						label={'Keg on tap'}
						isWithLink={true}
						helperText={
							'You can use kegs to have an idea of how much is left in the keg.'
						}
						options={kegsDropdown}
						md={12}
						control={control}
						optionsValueKey={'_id'}
						optionsTextKey={'text'}
						identifier={'keg'}
						link={`/${ROUTE_TYPES[ROUTE_NAMES.kegs].route}/0`}
						id={'keg'}
					/>
				) : null} */}
				<MainDropdownController
					isWithLink={true}
					label={'Drink on tap'}
					hideInput={user.role !== 'Place admin'}
					helperText={'Specify what drink is on this tap.'}
					options={drinksDropdown}
					md={12}
					control={control}
					optionsValueKey={'_id'}
					optionsTextKey={'text'}
					identifier={'drink'}
					id={'drink'}
					link={`/admin/${ROUTE_TYPES[ROUTE_NAMES.drinks].route}/0`}
				/>
				<LineContainer>
					<p style={{ fontWeight: 600, fontSize: 18, marginBottom: 0 }}>
						Device connected to tap
					</p>
				</LineContainer>
				<LineContainer>
					{device && device.name ? (
						<p style={{ marginTop: -3 }}>
							Connected to the device {device?.name}.
						</p>
					) : (
						<p style={{ marginTop: -3 }}>No device</p>
					)}
				</LineContainer>
				<SubHeader text={'Screen looks'} />
				<MainInputController
					control={control}
					label={'Tap top margin'}
					type={'text'}
					identifier={'fixedMarginTop'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'Tap bottom margin'}
					type={'text'}
					identifier={'fixedMarginBottom'}
					md={6}
				/>
				<SubHeader
					hide={user.role !== 'Place admin'}
					text={'Tap restrictions'}
				/>
				<MainInputController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Callback select URL'}
					md={6}
					identifier={'callbackSelectURL'}
				/>
				<MainInputController
					hideInput={user.role !== 'Place admin'}
					control={control}
					label={'Callback finish URL'}
					md={6}
					identifier={'callbackFinishURL'}
				/>
				<MainSwitchController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Use age limit'}
					md={6}
					identifier={'useAgeLimit'}
				/>
				<MainSwitchController
					control={control}
					label={'Require Icelandic digital certificates'}
					hideInput={useAgeLimit && user.role === 'Place admin' ? false : true}
					md={6}
					identifier={'iceCertRequired'}
				/>
				<MainInputController
					control={control}
					label={'Minutes until age check'}
					md={6}
					hideInput={useAgeLimit && user.role === 'Place admin' ? false : true}
					helperText={'0 = user can use the tap while his session is active.'}
					type={'number'}
					identifier={'minutesUntilAgeCheck'}
				/>
				<MainInputController
					control={control}
					label={'Age limit (the lowest allowed age)'}
					hideInput={useAgeLimit && user.role === 'Place admin' ? false : true}
					type={'number'}
					identifier={'ageLimit'}
					md={6}
				/>
				<MainSwitchController
					control={control}
					label={'Require staff to allow access'}
					md={6}
					identifier={'requireStaff'}
				/>
				<LineContainer>
					<p style={{ fontWeight: 600, fontSize: 18, marginBottom: 0 }}>
						Tap flow type
					</p>
				</LineContainer>
				<RadioController
					md={12}
					restrictions={{
						logic: () => {
							return isActive === false || !isActive;
						},
						failMessage: 'Tap must be closed to change the flow type',
					}}
					label={'Select how the tap is accessed'}
					radios={[
						{
							value: 'ContinuousFlow',
							label: 'Regular',
						},
						{
							value: 'Manual',
							label: 'Manual',
						},
					]}
					selected={flowType}
					identifier={'flowType'}
					helperText={
						flowType === 'Manual'
							? 'Manual flow allows you to manually set whether the tap is open or closed. '
							: 'Regular flow allows user to scan a QR code to access the tap. '
					}
					control={control}
				/>
				{user.systemRole === 'Admin' ? (
					<LineContainer>
						<p style={{ fontWeight: 600, fontSize: 18, marginBottom: 0 }}>
							Tap listing
						</p>
					</LineContainer>
				) : null}
				{/* <RadioController
					hide={user.systemRole !== 'Admin'}
					md={6}
					label={'Payment order'}
					radios={[
						{
							value: 'PayBefore',
							label: 'Pay before',
						},
						{
							value: 'PayAfter',
							label: 'Pay after',
						},
					]}
					selected={payOrder}
					identifier={'payOrder'}
					control={control}
				/>

				<RadioController
					hide={user.systemRole !== 'Admin'}
					md={6}
					label={'Payment type'}
					radios={[
						{
							value: 'Credit',
							label: 'Credit',
						},
						{
							value: 'Payment',
							label: 'Payment',
						},
					]}
					selected={paymentType}
					identifier={'paymentType'}
					control={control}
				/> */}
				<LineContainer>
					<p style={{ fontWeight: 600, fontSize: 18, marginBottom: 0 }}>
						Tap status
					</p>
				</LineContainer>
				<MainSwitchController
					control={control}
					label={'Ignore empty keg'}
					md={6}
					identifier={'ignoreEmptyKeg'}
				/>
				{/* <MainSwitchController
					control={control}
					label={'Tap is open'}
					md={6}
					identifier={'isActive'}
				/> */}
				<MainSwitchController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={"Don't charge for tap (make tap free)"}
					md={6}
					identifier={'isFree'}
				/>
				<MainSwitchController
					control={control}
					label={'Testing mode'}
					md={6}
					identifier={'isTest'}
				/>
				<MainSwitchController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Only allow credit'}
					md={6}
					identifier={'onlyAllowCredit'}
				/>
				<MainSwitchController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'Purchase mode (pay through the app)'}
					md={6}
					identifier={'isPurchaseMode'}
				/>
				<MainSwitchController
					control={control}
					hideInput={user.role !== 'Place admin'}
					label={'POS mode (pay through pos)'}
					md={6}
					identifier={'isPOSMode'}
				/>
				<MainSwitchController
					control={control}
					label={'Use Torio Web url'}
					md={6}
					identifier={'useTorioWebUrl'}
				/>

				{/* <LineContainer>
					<p style={{ fontWeight: 600, fontSize: 18, marginBottom: 0 }}>
						Tap type
					</p>
				</LineContainer> */}
				{/* <MainSwitchController
					control={control}
					label={'Use paper QR codes'}
					md={6}
					identifier={'allowPaperQR'}
				/> */}
				{/* <MainSwitchController
					control={control}
					label={'Use tablet QR codes'}
					md={6}
					identifier={'allowTabletQR'}
				/> */}
				<Grid width={'100%'} container p={2}>
					<LineContainer>
						<p style={{ fontWeight: 600, fontSize: 18 }}>Tap screen UI</p>
					</LineContainer>

					<MainSwitchController
						control={control}
						label={'Show logout button on tap screen'}
						md={6}
						identifier={'showLogout'}
					/>
				</Grid>
				{theTap?.isActive ? (
					<Alert
						severity="error"
						style={{
							width: '100%',
							marginLeft: 15,
							marginTop: 10,
							marginBottom: 10,
						}}
					>
						You cannot change tap while it's open.{' '}
						<span
							style={{ fontWeight: 700, cursor: 'pointer' }}
							onClick={async () => {
								await sendAPI(
									'taps/changeTap/' + id,
									{ action: 'CLOSE' },
									modalState.openModal,
									null
								);
							}}
						>
							Click here to close it
						</span>
					</Alert>
				) : null}
			</FormContainer>
		</PageContainer>
	);
}

export default Tap;

// {/* {theTap && !theTap['closePending'] ? (
// 	<Grid style={{ width: '100%', marginTop: 15 }} spacing={2} item>
// 		<Alert severity={'warning'}>
// 			The tap is currently being used by someone. You cannot change
// 			the tap while its being used. You can close the tap, change it
// 			and then open it again.
// 		</Alert>
// 		<MainButton
// 			customColor={'red'}
// 			customTextColor={'white'}
// 			onClick={() => {
// 				check(
// 					'Do you want to close this tap as soon as the user is finished using it?',
// 					{}
// 				).then(async (res) => {
// 					if (res) {
// 						await closeTap();
// 					}
// 				});
// 			}}
// 			text={'Close tap when its finished'}
// 		/>
// 	</Grid>
// ) : (
// 	<div style={{ marginTop: 15 }}>
// 		<Alert severity={'success'}>
// 			The tap will close for maintenance (for Switching kegs for
// 			instance) when it's finished being used.
// 		</Alert>
// 	</div>
// )} */}

/**
 * 
 <MainContainer isLoaded={isLoaded}>
				<BoxContainer>
					{keg ? (
						<MainCard
							header={'Keg details'}
							iconComponent={() => (
								<KegIcon totalMl={keg['totalMl']} ml={keg['ml']} />
							)}
							lines={[
								{ label: 'Keg name', text: keg['name'] },
								{ label: 'Keg serial', text: keg['serial'] },
								{ label: 'Keg location', text: keg['location'] },
								{
									label: 'Content',
									text: keg['ml']
										? keg['ml'] + ' ml '
										: 'Not known' + ' ml / ' + keg['totalMl']
										? keg['totalMl'] + ' ml'
										: 'Not known' + ' ml',
								},
							]}
							buttons={[
								{
									text: 'View',
									onClick: redirectToItemNewTab('/kegs/', keg?._id),
								},
							]}
						/>
					) : null}
					{keg && keg?.drink ? (
						<MainCard
							header={'Drink on tap'}
							buttons={[
								{
									text: 'View',
									onClick: redirectToItemNewTab('/drinks/', keg?.drink?._id),
								},
							]}
							iconComponent={
								keg?.drink && keg?.drink['image']
									? () => (
											<img
												style={{
													width: 100,
													height: 100,
													display: 'block',
												}}
												src={keg?.drink['image']}
												alt="drink"
											/>
									  )
									: () => (
											<div>
												<FontAwesomeIcon
													style={{ fontSize: 70, color: 'lightgray' }}
													icon={'beer'}
												/>
											</div>
									  )
							}
							lines={[
								{ label: 'Name', text: keg?.drink['name'] },
								{ label: 'Description', text: keg?.drink['description'] },
								{ label: 'Drink type', text: keg?.drink['type'] },
								{ label: 'Alcohol vol', text: keg?.drink['alc'] + '%' },
								{
									label: 'Price',
									text: keg?.drink['price']
										? keg?.drink['price'] + getCurrencyTypeByCode(placeCurrency)
										: 'No price',
								},
								{ label: 'Taste', text: keg?.drink['taste'] },
							]}
						/>
					) : null}
				</BoxContainer>
			</MainContainer>
 * 
 */
