import { Grid, IconButton } from '@mui/material';
import MainInput from '../../components/inputs/MainInput';
import CloseIcon from '@mui/icons-material/Close';
import { addThousandSeparator } from '../../business/currencyHelper';

const SaleItem = ({ sale, item, index, setItem }) => {
	const handleInputChange = (indetifier, value) => {
		// Create a new array of sale items
		const updateOrders = item?.orders.map((saleItem, idx) => {
			if (idx === index) {
				// Update the item at the current index
				return { ...saleItem, numberOfKegs: Number(value) };
			}
			return saleItem;
		});

		// Update the item state in the parent component
		setItem({ ...item, orders: updateOrders });
	};

	const removeSaleItem = (index) => {
		// Filter out the item to be removed

		const updatedOrders = item?.orders?.map((order, idx) => {
			return idx === index ? { ...order, isRemove: true } : order;
		});

		setItem({
			...item,
			orders: updatedOrders,
			// isRemove: true,
		});

		// const updateOrders = item?.orders?.filter((_, idx) => idx !== index);
		// Update the item state
		// setItem({ ...item, orders: updateOrders });
	};

	const getPrice = () => {
		let kegPrice = sale.kegPrice ? addThousandSeparator(sale.kegPrice) : 0;
		return `Each: ${kegPrice ? kegPrice : 0} kr`;
	};

	const getTotalFor = () => {
		let kegPrice =
			sale.kegPrice && sale.numberOfKegs
				? addThousandSeparator(sale.kegPrice * sale.numberOfKegs)
				: 0;
		return `Total for ${
			sale.numberOfKegs ? sale.numberOfKegs : 0
		}x:${' '}${kegPrice} kr`;
	};

	// addThousandSeparator

	if (sale.isRemove) {
		return null;
	}

	return (
		<Grid
			item
			md={6}
			// style={{
			// 	borderWidth: 1,
			// 	borderRadius: 15,
			// 	borderStyle: 'solid',
			// 	borderColor: 'gray',
			// }}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '20px',
					border: '1px solid gray',
					borderRadius: '15px',
					margin: '5px',
					backgroundColor: '#f9f9f9',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<h4 style={{ marginBottom: 4 }}>{sale.drinkName}</h4>
					<IconButton
						aria-label="close"
						onClick={() => {
							removeSaleItem(index);
							// Add remove
						}}
						style={{
							// position: 'absolute',
							position: 'relative',
						}}
					>
						<CloseIcon />
					</IconButton>
				</div>
				<span style={{ color: 'gray' }}>{getPrice()}</span>
				<span style={{ color: 'gray' }}>{getTotalFor()}</span>
				<p style={{ display: 'flex' }}>
					Original kegs asked for:{' '}
					<span style={{ fontWeight: 'bold' }}>
						{' ' + sale.oldNumberOfKegs}
					</span>
				</p>

				<div>
					<MainInput
						label={'Kegs to supply '}
						type={'number'}
						value={sale.numberOfKegs ? sale.numberOfKegs : 0}
						identifier={'sale' + index}
						onChange={handleInputChange}
						md={12}
					/>
				</div>
			</div>
		</Grid>
	);
};

export default SaleItem;
