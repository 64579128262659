import styled, { keyframes } from 'styled-components';
export const GENERAL_PADDING_AROUND_CARD_DIV = 15;
export const INSIDE_CARD_PADDING = 30;

export const Panel = styled.div`
	background: #fff;
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #e2e2e2;
	border: none;
	color: #5b5b5b;
	color: #393939;
	width: 100%;
	min-height: 30px;
	position: relative;
	-webkit-box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
	box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	padding-bottom: 20px;
	padding: ${INSIDE_CARD_PADDING}px;
`;

export const ContainerPaddingDiv = styled.div`
	padding: ${GENERAL_PADDING_AROUND_CARD_DIV}px;
`;

// width: 100%;
// margin-right: 10px;
// margin-top: 10px;

export const Gradient = keyframes`
0% {background-position: top;}
100% {background-position: bottom;}
`;

export const LinearGradientLine = (colorOne, colorTwo) => {
	return styled.div`
		width: 100%;
		height: 5px;
		background: linear-gradient(${colorOne} 0%, ${colorTwo} 100%) !important;
		background: -webkit-linear-gradient(${colorOne}, ${colorTwo}) !important;
		background: -moz-linear-gradient(${colorOne}, ${colorTwo}) !important;
		zindex: 1000;
		position: absolute;
		top: 0;
		left: 0;
	`;
};

export const LinearGradientCircle = (colorOne, colorTwo) => {
	return styled.div`
		width: 80px;
		height: 80px;
		background: linear-gradient(${colorOne} 0%, ${colorTwo} 100%) !important;
		background: -webkit-linear-gradient(${colorOne}, ${colorTwo}) !important;
		background: -moz-linear-gradient(${colorOne}, ${colorTwo}) !important;
		border-radius: 100px;
	`;
};

// background size was 1000
export const ColoredContainer = (colorOne, colorTwo, grad, minusHeight) => {
	return styled.div`
		width: 100%;
		height: calc(100vh - ${minusHeight});
		background: linear-gradient(${colorOne} 0%, ${colorTwo} 100%) !important;
		background: -webkit-linear-gradient(${colorOne}, ${colorTwo}) !important;
		background: -moz-linear-gradient(${colorOne}, ${colorTwo}) !important;
		background-size: 400% !important;
		-moz-background-size: 400%;
		-webkit-background-size: 400%;

		/* W3C */
		animation-name: ${Gradient};
		animation-duration: 5s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		animation-play-state: running;
	`;
};

export const GradientOld = keyframes`
	0% {
		background-position: 0% 50%
	}
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
`;

export const ColoredContainerOld = (colorOne, colorTwo, grad) => {
	return styled.div`
		width: 100%;
		height: 100vh;
		background: linear-gradient(180deg, ${colorOne}, ${colorTwo});
		background-size: 400% 400%;
		animation: ${grad} 15s ease infinite;
	`;
};
