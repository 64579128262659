import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../store/modalContext/store';
import {
	sendAPI,
	onObjectChange,
	removeItem,
	redirectToItemNewTab,
} from '../../api/helper';
import { useParams } from 'react-router-dom';
import MainInput from '../../components/inputs/MainInput';
import MainDropdown from '../../components/dropdowns/MainDropdown';
import MainButton from '../../components/buttons/MainButton';
import MainCard from '../../components/cards/MainCard';
import MainContainer from '../../components/containers/MainContainer';
import { goOutOfItem } from '../../business/routingHelper';
// import KegIcon from './KegIcon';
import PanelContainer from '../../components/containers/PanelContainer';
import BoxContainer from '../../components/containers/BoxContainer';
import ButtonGroup from '../../components/buttons/ButtonGroup';
import { Grid, IconButton, Modal } from '@mui/material';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { check } from 'react-interaction';
import { routeToId, goBack } from '../../business/routingHelper';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import {
	KegChangeInterface,
	KegChangeInitialized,
	KegChangeSchema,
} from '@toriosoftware/torioshared/dist/kegchanges';
import useManageHook from '../../components/hooks/useManageHook';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import { ROUTE_NAMES } from '@toriosoftware/torioshared/dist/config';
import {
	useGetKegChangeMutation,
	useManageKegChangeMutation,
} from '../../store/api/api';
import PageContainer from '../../components/containers/PageContainer';
import FormContainer from '../../components/containers/FormContainer';
import SubHeader from '../../components/texts/SubHeader';
import MainInputController from '../../components/inputs/MainInputController';
import MainSwitchController from '../../components/switch/MainSwitchController';
import MainDropdownController from '../../components/dropdowns/MainDropdownController';

function KegChange({ user, kegChangeId, drinkName, kegId }: any) {
	const [getKegChange] = useGetKegChangeMutation();
	const [manageKegChange, { isLoading }] = useManageKegChangeMutation();
	const [open, setOpen] = useState(true);

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		getFieldState,
		setFocus,
	} = useForm<KegChangeInterface>({
		defaultValues: KegChangeInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(KegChangeSchema),
	});

	let reason = watch('reason');
	let numberOfKegs = watch('numberOfKegs');
	numberOfKegs = numberOfKegs ? numberOfKegs : 0;

	const { modelState, isLoaded, history, isNew, id, data, handleData } =
		useManageHook({
			dontInitialize: true,
			isLoadedInitial: false,
			retrieveData: [
				GET_ROUTE_COMPONENT(ROUTE_NAMES.kegchange, user)?.data?.get(
					getKegChange,
					reset,
					//@ts-ignore
					kegChangeId ? kegChangeId : '0'
				),
			],
			user: user,
		});

	useEffect(() => {
		if ((kegChangeId && kegChangeId.length > 5) || kegChangeId === '0') {
			handleData([
				GET_ROUTE_COMPONENT(ROUTE_NAMES.kegchange, user)?.data?.get(
					getKegChange,
					reset,
					//@ts-ignore
					kegChangeId
				),
			]);
		}
		if (kegChangeId == '0') {
			console.log('resetting');
			setValue('numberOfKegs', 0);
			setValue('reason', 'ADD');
			setValue('reasonFlex', '');
			// setLoading(false);
		}
		setOpen(true);
	}, [kegChangeId]);

	return (
		<Modal
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			aria-labelledby="Change inventory"
			aria-describedby="Inventory change modal"
		>
			<FormContainer
				md={6}
				modal={modelState}
				handleSubmit={handleSubmit}
				isDirty={isDirty}
				refreshAfterSuccess={true}
				onSubmitFunction={manageKegChange}
				onRemoveFunction={manageKegChange}
				isLoaded={isLoaded}
				customData={{ id, kegId }}
				showBack={false}
				isLoading={isLoading}
				linkAfterDelete={'kegs'}
				title={'Keg change'}
				depth={0}
				history={history}
				hideRemove={isNew}
			>
				<IconButton
					aria-label="close"
					onClick={() => {
						setOpen(false);
					}}
					style={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}
				>
					<CloseIcon />
				</IconButton>
				{/* <SubHeader text={'General information'} /> */}
				<MainInputController
					control={control}
					label={'Number of kegs'}
					// helperText={'Lager, IPA, Soda, etc.'}
					type={'number'}
					identifier={'numberOfKegs'}
					md={6}
				/>
				<MainDropdownController
					disabled={kegChangeId !== '0'}
					label={'Reason'}
					options={[
						{ _id: 'SALE', text: 'Sale' },
						{ _id: 'REMOVE_SALE', text: 'Remove sale' },
						{ _id: 'ADD', text: 'Add' },
						{ _id: 'REMOVE', text: 'Remove / Destroy' },
					]}
					md={6}
					control={control}
					optionsValueKey={'_id'}
					optionsTextKey={'text'}
					helperText={
						reason === 'SALE'
							? numberOfKegs + ' kegs sold and removed from stock.'
							: reason === 'ADD'
							? numberOfKegs + ' kegs added to stock.'
							: numberOfKegs + ' kegs removed.'
					}
					identifier={'reason'}
					id={'reason'}
				/>
				{reason === 'SALE' && (
					<MainInputController
						control={control}
						label={'Place name'}
						helperText={'Name of the buyer / place.'}
						type={'text'}
						identifier={'placeName'}
						md={6}
					/>
				)}
				{/* {reason === 'SALE' && (
					<MainInputController
						control={control}
						label={'Customer email'}
						helperText={
							'If you want the user to receive an email regarding kegs being sent to him/her.'
						}
						type={'text'}
						identifier={'customerEmail'}
						md={6}
					/>
				)} */}
				{/* {reason === 'SALE' && (
					<MainInputController
						control={control}
						label={'Customer notes'}
						helperText={
							'Text that will be added to the email such as "delivery expected at 12:00 on 12.12.2026"'
						}
						type={'text'}
						identifier={'customerNotes'}
						md={6}
					/>
				)} */}

				<MainInputController
					control={control}
					label={'Other reasons'}
					type={'text'}
					identifier={'reasonFlex'}
					md={12}
				/>
			</FormContainer>
		</Modal>
	);
}

export default KegChange;
