import React, { Fragment, useEffect } from 'react';
// import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import { Grid } from '@mui/material';
// import LoginScreen from '../../screens/Login/LoginScreen';
// import SelectPlace from '../../screens/Login/SelectPlace';
import styles from './frontpageStyle.module.scss';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

// import styles
import './style.scss';
import { Helmet } from 'react-helmet';

// import components
import Header from './header';
import Footer from './Footer';
import BottomTabs from '../../components/tabs/BottomTabs';

import mab from './images/banner/mab-2-img-00.png';
import mabtwo from './images/banner/mab-2-img-00.png';
import AOS from 'aos';
import shapeFive from './images/banner/shape-5.png';
import takni from './images/logo/takni.jpg';
import appleButton from './images/buttons/downloadApple.png';
import googleButton from './images/buttons/downloadAndroid.png';
import quest from './images/phone/quest.png';
import imageleft from './images/mob.png';
import anm from './images/anm.gif';

import phone from './images/phone/phone.png';
import point from './images/icons/point.png';

import FeatureList from './FeatureList';
import FrontPageNumberText from './FrontPageNumberText';
import ListText from './texts/ListText';
import Point from './Point';
import FrontPageText from './texts/FrontPageText';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { api } from '../../store/api/api';
import MainButton from '../../components/buttons/MainButton';
import { useHistoryNav } from '../../hooks/useHistoryNav';

// import OwlList from './OwlList';

// src = '/assets/images/logo/logo.png';

// home-page-2

const Home = ({ user, loggedIn }) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		AOS.init();
	}, []);

	const history = useHistoryNav();

	React.useEffect(() => {
		if ((loggedIn === false || loggedIn === true) && !loggedIn) {
			// dispatch(api.util.resetApiState());
		}
	}, [loggedIn]);

	React.useEffect(() => {
		let url = window.location.host;
		console.log('URL: ', url.includes('localhost'));
		if (
			url.includes('admin.') &&
			!url.includes('/login') &&
			!url.includes('localhost')
		) {
			// window.location.href = 'https://admin.torio.is/login';
		}
	}, []);

	return (
		<div>
			<div className={styles.bootstrap}>
				<div id="home" className="front-page-body">
					<Header loggedIn={loggedIn} />

					<section
						// style={{ marginTop: -92 }}
						id="banner-section"
						className="banner-section clearfix"
						style={{ top: -113, position: 'relative' }}
					>
						<div
							className="mobile-app-banner-2 front-page-background"

							// style={{ backgroundImage: 'url("./images/banner/bg-img.png")' }}
						>
							<span
								className="shape-image-1"
								data-aos="fade-right"
								data-aos-duration="1000"
								data-delay="100"
							>
								<img src={shapeFive} alt="background" />
							</span>

							<div className="container">
								<div className="row justify-content-lg-start justify-content-md-center">
									<div className="col-lg-6 col-md-8 col-sm-12">
										<div className="mobile-banner-image d-none">
											<img src={mab} alt="App view" />
										</div>
										<div style={{ top: -165 }} className="banner-content">
											<FrontPageText type="header" />
											<FrontPageText type="subheader" />
											<FrontPageText type="text" />

											<button
												onClick={() => {
													history('/login');
												}}
												style={{
													width: '320px',
													height: '90px', // You can adjust the height as needed
													backgroundColor: '#32a94f',
													color: 'white',
													border: 'none',
													borderRadius: '15px',
													fontSize: '25px',
													fontWeight: 'bold',
													marginTop: '20px',
													cursor: 'pointer',
													transition: 'background-color 0.3s',
												}}
											>
												{loggedIn ? 'Company admin page' : 'Company login'}
											</button>
										</div>
									</div>

									<div className="col-lg-6 col-md-8 col-sm-12">
										<div className="banner-image scene">
											<span
												className="phone-image"
												data-aos="zoom-out"
												data-aos-duration="1000"
												data-aos-delay="200"
											>
												{/* <Box
													sx={{
														fontSize: { md: 370, sm: 260, xs: 260 },
													}}
												>
												</Box> */}
												<img src={mabtwo} alt="Scan app" />
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section
						id="business"
						className="app-section sec-ptb-30 clearfix mb-200"
						data-aos="fade-up"
						data-aos-duration="400"
						data-aos-delay="100"
					>
						<div className="container">
							<div
								className="row justify-content-lg-start justify-content-md-center mt-40"
								style={{ alignItems: 'center' }}
							>
								<div
									className="col-lg-6 col-md-8 col-sm-12"
									style={{ marginBottom: 300 }}
								>
									<div
										className="app-content"
										style={{ paddingTop: '0px important' }}
									>
										<div className="section-title mb-70">
											<h2 className="title-text mb-30">
												Welcome to the Torio admin web
											</h2>
											<ListText
												text={`If you have received your credentials for the Torio admin web, you can log in here or contact Torio to ask to join.`}
												textAlign={'left'}
											/>
										</div>

										<div
											className="btns-group ul-li mt-65 clearfix"
											style={{ marginTop: 40 }}
										>
											<ul className="clearfix">
												<li>
													<a
														href="#!"
														onClick={() => {
															window.open(
																'mailto:torio@torio.is?subject=Regarding Torio'
															);
														}}
														className="store-btn bg-default-blue"
													>
														Join / Contact Torio
													</a>
												</li>
												<li>
													<a
														href="https://admin.torio.is/login"
														className="store-btn bg-default-black"
													>
														{loggedIn ? 'Company admin page' : 'Company login'}
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-lg-6 col-md-5 col-sm-12">
									<div
										className="app-image float-right"
										style={{ margin: 'auto', paddingTop: '0px !important' }}
									>
										<span className="">
											<img
												style={{ marginTop: '-173px' }}
												src={anm}
												alt="Locate on map"
											/>
										</span>
										<span
											className="phone-image float-right"
											data-aos="zoom-out"
											data-aos-delay="400"
										></span>
									</div>
								</div>
							</div>
						</div>
					</section>

					{/* <Footer loggedIn={loggedIn} /> */}

					<BottomTabs activeTab={0} />
					<Helmet>
						<script src="./js/jquery-3.3.1.min.js"></script>
						<script src="./js/popper.min.js"></script>
						<script src="./js/aos.js"></script>
						<script src="./js/custom.js"></script>
						{/* <script src="./js/jquery.countdown.js"></script>
						<script src="./js/jquery.counterup.min.js"></script>
						<script src="./js/jquery.magnific-popup.min.js"></script> */}
						<script src="./js/waypoints.min.js"></script>
						<script src="./js/parallax.min.js"></script>
						<script src="./js/jquery.mCustomScrollbar.js"></script>
						<script src="./js/jquery.mCustomScrollbar.js"></script>
						<script src="./js/bootstrap.min.js"></script>
					</Helmet>
				</div>
			</div>
		</div>
	);
};

export default Home;
