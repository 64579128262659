"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drinkTypes = void 0;
exports.drinkTypes = [
    'Beer',
    'Cocktail',
    'Coffee',
    'Tea',
    'Kombucha',
    'Whisky',
    'Wine',
    'Other',
    'Soda',
    'Water',
    'Energy drink',
];
