import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import MainContainer from '../../components/containers/MainContainer';
import PageContainer from '../../components/containers/PageContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import MainDropdown from '../../components/dropdowns/MainDropdown';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import MainInput from '../../components/inputs/MainInput';
import { Alert, Button } from '@mui/material';
import MainButton from '../../components/buttons/MainButton';
import { sendAPI } from '../../api/helper';
import { googleItemsDropdownMaker } from '../../business/dropdownHelper';
import { useGetGoogleFromPlaceMutation } from '../../store/api/api';

const GoogleConnect = ({ user, setSelectedId, isMinified }: any) => {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const navigation = useHistoryNav();
	const [name, setName] = React.useState('');
	const [getGoogleFromPlace] = useGetGoogleFromPlaceMutation();
	const [placeId, setPlaceId] = React.useState('');
	const [googlePlaces, setGooglePlaces] = React.useState([]);
	const [selectedPlace, setSelectedPlace] = React.useState('');

	const getGooglePlaces = async () => {
		let result = await sendAPI(
			'registerPlace/getPlacesByName/',
			{ name: name },
			modalState.openModal,
			null
		);

		if (result && result.items && result.items.length > 0) {
			setGooglePlaces(googleItemsDropdownMaker(result.items));
			if (result.items.length === 1) {
				setPlaceId(result.items[0].place_id);
				setSelectedId(result.items[0].place_id);
			}
		}
	};

	const setGooglePlaceID = async () => {
		if (placeId) {
			let placeToSave = googlePlaces.find((p: any) => p.place_id === placeId);
			if (placeToSave) {
				let placeString = placeToSave.text;
				if (placeString) {
					let result = await sendAPI(
						'registerPlace/setGoogleID',
						{
							googleId: placeId,
							googleName: placeString,
						},
						modalState.openModal,
						null
					);
					if (result.success) {
						window.location.reload();
					}
				} else {
					modalState.openModal('Error', 'Could not find detail in place. ');
				}
			} else {
				modalState.openModal('Error', 'Cannot find place to save. ');
			}
		} else {
			modalState.openModal('Error', 'Please select a place. ');
		}
	};

	const getPlaceID = async () => {
		let result = await getGoogleFromPlace().unwrap();
		if (result && result.googlePlace) {
			setSelectedPlace(result.googlePlace);
			setPlaceId(result.googleId);
		}
	};

	React.useEffect(() => {
		if (!isMinified) {
			if (user.placeName) {
				setName(user.placeName);
			}
			getPlaceID();
		}
	}, []);

	return (
		<MainContainer direction="row" history={navigation}>
			<PanelContainer isLoaded={true} header={'Connect to Google'}>
				<MainInput
					label={'Place name on Google'}
					value={name}
					identifier={'name'}
					onChange={(identifier, value) => setName(value)}
					md={12}
				/>
				<div style={{ padding: 15 }}>
					<Button
						onClick={() => {
							getGooglePlaces();
						}}
						variant={'outlined'}
					>
						Search place on Google
					</Button>
				</div>
				{googlePlaces.length > 0 ? (
					<div style={{ padding: 15, marginTop: 16, width: '100%' }}>
						<MainDropdown
							label={'Places on Google'}
							options={googlePlaces}
							onChange={(e) => {
								let value = e.target.value;
								if (setSelectedId) {
									setSelectedId(value);
								}
								if (value) {
									setPlaceId(value);
								} else {
									setPlaceId('');
								}
							}}
							value={placeId}
							size={12}
							style={{ width: '100%' }}
							optionsValueKey={'place_id'}
							optionsTextKey={'text'}
							id={'googlePlaces'}
						/>
						{!isMinified ? (
							<MainButton
								onClick={() => {
									setGooglePlaceID();
								}}
								text={'Connect place to Google'}
								color={'default'}
							/>
						) : null}
					</div>
				) : null}

				{selectedPlace && !isMinified ? (
					<Alert
						style={{
							width: '100%',
							padding: 15,
							marginLeft: 15,
							marginTop: 10,
						}}
						severity={'success'}
					>
						<span>This place is linked to the Google place @ </span>{' '}
						{selectedPlace}
					</Alert>
				) : null}
				{selectedPlace && !isMinified ? (
					<MainButton
						onClick={() => {
							navigation('/admin');
						}}
						text={'Go to menu'}
						color={'default'}
					/>
				) : null}
			</PanelContainer>
		</MainContainer>
	);
};

export default GoogleConnect;
