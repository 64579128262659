import React from 'react';
import MainContainer from '../containers/MainContainer';
import { Grid, linkClasses } from '@mui/material';
import MenuCard from '../cards/MenuCard';
import PageContainer from '../containers/PageContainer';

import { ContainerPaddingDiv } from '../../styles/styledComponents';
import { getLinks } from '../../config/configHelperFunctions';
import { useGetGoogleFromPlaceMutation } from '../../store/api/api';
import MenuList from '../../screens/MenuList/MenuList';
import Header from '../texts/Header';

const MenuSelect = ({ user }) => {
	const iconStyle = { fontSize: 80 };

	let subscription = user.subscription;
	if (!subscription) return <div></div>;

	// let role = user.role;
	// let systemRole = user.systemRole;


	let links = getLinks(user);
	return (
		<PageContainer>
			<MainContainer isLoaded={true}>
				<ContainerPaddingDiv>
					<MenuList user={user} />

					<Header text={'Menu'} />
					<Grid spacing={2} container>
						{links.map((link, index) => {
							if (link) {
								if (link.sideBarObject.route) {
									return (
										<Grid key={index} item xs={12} sm={6} md={4}>
											<MenuCard
												icon={link.theme.icon ? link.theme.icon : null}
												color={link.theme.color}
												name={link.name}
												description={link.theme.description}
												subscription={link.sideBarObject.subscription}
												route={link.sideBarObject.route}
											/>
										</Grid>
									);
								}
							}
						})}
					</Grid>
				</ContainerPaddingDiv>
			</MainContainer>
		</PageContainer>
	);
};

export default MenuSelect;
