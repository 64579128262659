import { getFlowColumns } from '../../business/tableHelper';
import PageContainer from '../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';

const Flows = ({ user }: any) => {
	return (
		<PageContainer header={'Flows'}>
			<React.Fragment>
				<ManageItems
					header={'Flows'}
					key={112}
					getData={{
						url: 'flows/flows',
						body: {},
					}}
					buttonText={'Add flow'}
					// includeContainer={showPageContainer}
					redirectLink={ROUTE_TYPES[ROUTE_NAMES.flow].route}
					columns={getFlowColumns()}
					noItemsText={'No flows have been added.'}
				/>
			</React.Fragment>
		</PageContainer>
	);
};

export default Flows;
