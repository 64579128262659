import {
	getRegisterPlacesColumns,
	getTapColumns,
} from './../../business/tableHelper';
import PageContainer from './../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import { useHistoryNav } from '../../hooks/useHistoryNav';

interface PlaceUserProps {
	user: any;
}

const RegisteredPlaces = ({ user }: PlaceUserProps) => {
	const navigate = useHistoryNav();

	return (
		<React.Fragment>
			<ManageItems
				header={'Taps'}
				key={1920}
				getData={{
					url: 'registerplace/registeredplaces',
					// url: 'taps/taps', // ROUTE_TYPES[ROUTE_NAMES.taps].getRoute,
					body: {},
				}}
				redirectLink={'registeredplaces/:id'}
				columns={getRegisterPlacesColumns()}
				noItemsText={'No registrations have been added.'}
				includeContainer={true}
			/>
		</React.Fragment>
	);
};

export default RegisteredPlaces;
