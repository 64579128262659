import moment from 'moment';
import { getCurrencyTypeByCode } from './currencyHelper';

export const getDrinkValueFormatter = (params) => {
	if (params.value) {
		//@ts-ignore
		if (params.value.name) {
			//@ts-ignore
			return params.value.name;
		}
	}
	return 'No drink selected';
};

export const getPriceFormatter = (value, currency) => {
	if (value) {
		if (!currency) currency = 'ISK';
		return value + getCurrencyTypeByCode(currency);
	} else {
		return 'Not specified';
		// return '0 kr (ISK)';
	}
};

export const getMlFormatter = (value) => {
	if (value) {
		return value + ' ml';
	} else {
		return '0 ml';
	}
};

export const getPlaceFormatter = (place) => {
	if (place && place.name) {
		return place.name;
	}
};

export const getHasJoinedFormatter = (hasJoined) => {
	if (hasJoined) {
		return 'Has joined';
	} else {
		return 'Has not joined';
	}
};

export const getPercentageFormatter = (value) => {
	if (value) {
		return value + '%';
	}
};

export const getDateFormatter = (value) => {
	if (value) {
		console.log('vaa: ', value);
		return moment.unix(value).format('DD/MM/YYYY HH:mm:ss');
	}
};

export const getJSDateFormatter = (value) => {
	return moment(value).format('DD-MM-YYYY HH:mm');
};

export const getIsTestFormatter = (value) => {
	if (value && value === true) {
		return 'Yes';
	} else {
		return 'No';
	}
};

export const getIfEmptyFormatter = (value, fallbackText) => {
	if (value) {
		return value;
	} else {
		return fallbackText;
	}
};

export const paymentFeesFormatter = (value) => {
	if (value) {
		return value;
	} else {
		return 'No fees';
	}
};
