import { Typography } from '@mui/material';

const MonitorSection = ({
	image,
	header,
	list,
	buttons,
	iconComponent,
}: any) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginTop: 28,
			}}
		>
			<div style={{ marginRight: 19, width: 76 }}>
				{image ? (
					<img
						src={image}
						alt={'Drink'}
						style={{ width: 72, height: 72, borderRadius: 15 }}
					/>
				) : iconComponent ? (
					iconComponent()
				) : (
					<div></div>
				)}
			</div>
			<div>
				{header ? (
					<Typography style={{ fontWeight: 'bold' }}>{header}</Typography>
				) : null}
				{list.map((item: any) => {
					if (item.text) {
						let text = `${item.label}: ${item.text}`;
						return <Typography>{text}</Typography>;
					} else {
						return null;
					}
				})}
			</div>
		</div>
	);
};

export default MonitorSection;
