import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, IconButton, Typography } from '@mui/material';
import { sendAPI } from '../../api/helper';

const HandleManual = ({ tap, modalState }) => {
	const handleOpenAndClose = async (shouldOpen: boolean) => {
		await sendAPI(
			'gpios/handleTapAccessManually/' + tap?._id,
			{
				shouldOpen,
			},
			modalState.openModal,
			null
		);
	};

	return (
		<div style={{ marginTop: 38 }}>
			{tap.solenoidOn ? (
				<Button
					variant={'outlined'}
					onClick={async () => {
						handleOpenAndClose(false);
					}}
				>
					Set tap to closed for everyone
				</Button>
			) : (
				<Button
					variant={'outlined'}
					onClick={async () => {
						handleOpenAndClose(true);
					}}
				>
					Set tap to open for everyone
				</Button>
			)}
		</div>
	);
};

export default HandleManual;
