import {
	AUTH_TYPES,
	SIDEBAR_TYPES,
	ROUTE_NAMES,
} from '@toriosoftware/torioshared/dist/config';
import { GET_ROUTE_COMPONENT } from './config';

export const getLinks = (user) => {
	// console.log('ROUTE_NAMES: ', ROUTE_NAMES);
	let links = [];
	// console.log('SIDEBAR_TYPES: ', SIDEBAR_TYPES);
	Object.keys(SIDEBAR_TYPES).map((key) => {
		let sidebarTypes = SIDEBAR_TYPES[key];
		for (let i = 0; i < sidebarTypes.length; i++) {
			let sideBarObject = sidebarTypes[i];
			if (sideBarObject) {
				let theme = GET_ROUTE_COMPONENT(sideBarObject.id, user)?.theme;
				if (theme) {
					//@ts-ignore
					if (AUTH_TYPES.subscriptions(user, sideBarObject.subscriptions)) {
						if (AUTH_TYPES.role(user, sideBarObject.role)) {
							links.push({ sideBarObject, theme });
						}
					}
				}
			}
		}
	});
	return links;
};
