import React from 'react';
import { getSessionColumns } from '../../business/tableHelper';
// import { getUserPlaceColumns } from './business/tableHelper';
import PageContainer from '../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import moment from 'moment';

const Sessions = ({ user }) => {
	return (
		<React.Fragment>
			<ManageItems
				header={'Sessions'}
				hideNewButton={true}
				key={1920}
				// showFilter={true}
				getData={{
					url: 'sessions/sessions',
					body: {},
				}}
				redirectLink={'/admin/sessions'}
				columns={getSessionColumns()}
				noItemsText={'No sessions found.'}
				includeContainer={true}
			/>
		</React.Fragment>
	);
};

export default Sessions;

//                     return <PageContainer header={'Tap'}>
// </PageContainer>;
