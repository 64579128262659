/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import PageContainer from '../../components/containers/PageContainer';
import FormContainer from '../../components/containers/FormContainer';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import {
	useGetDeviceMutation,
	useManageCompanyDeviceMutation,
	useManageDeviceMutation,
} from '../../store/api/api';
import MainInputController from '../../components/inputs/MainInputController';
import {
	deviceInitialized,
	deviceInterface,
	deviceSchema,
} from '@toriosoftware/torioshared/dist/devices/Device';
import SubHeader from '../../components/texts/SubHeader';
import useItems from '../../hooks/useItems';
import {
	gpiosDropdownMaker,
	tapsDropdownMaker,
	placeDropdownMaker,
} from '../../business/dropdownHelper';
import MainDropdownController from '../../components/dropdowns/MainDropdownController';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';

function Device({ user }) {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const [getDevice] = useGetDeviceMutation();
	const [manageDevice] = useManageDeviceMutation();
	const [manageCompanyDevice] = useManageCompanyDeviceMutation();

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		getFieldState,
		setValue,
		setFocus,
	} = useForm<deviceInterface>({
		defaultValues: deviceInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(deviceSchema),
	});

	const { modelState, isLoaded, history, isNew, id, data } = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.device, user)?.data?.get(
				getDevice,
				reset
			),
		],
		user: user,
	});

	const [places, place, placesDropdown] = useItems({
		id: '',
		dropdownFunction: placeDropdownMaker.bind(this),
		useDropdown: true,
		url: 'places/getPlaceNames/',
	});

	const [GPIOS, GPIO, gpiosDropdown] = useItems({
		id: '',
		dropdownFunction: gpiosDropdownMaker.bind(this),
		useDropdown: true,
		url: 'gpios/getGpios/',
	});

	const [taps, tap, tapsDropdown] = useItems({
		id: '',
		dropdownFunction: tapsDropdownMaker.bind(this),
		useDropdown: true,
		url: 'taps/taps',
	});

	return (
		<PageContainer header={'Device'}>
			<FormContainer
				modal={modelState}
				handleSubmit={handleSubmit}
				isDirty={isDirty}
				onSubmitFunction={
					user && user.systemRole === 'Admin'
						? manageDevice
						: manageCompanyDevice
				}
				onRemoveFunction={manageDevice}
				linkAfterSuccess={isNew ? ROUTE_TYPES[ROUTE_NAMES.devices].route : ''}
				showBack={true}
				// refreshAfterSuccess={true}
				customData={{ id }}
				reset={reset}
				isLoaded={isLoaded}
				title={'Device'}
				depth={0}
				history={history}
				hideRemove={isNew}
			>
				<SubHeader text={'About'} />
				<MainInputController
					control={control}
					label={'Name'}
					disabled={user.systemRole !== 'Admin'}
					identifier={'name'}
					md={12}
				/>
				<MainInputController
					rows={'2'}
					control={control}
					disabled={user.systemRole !== 'Admin'}
					label={'Description'}
					identifier={'description'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Location'}
					identifier={'location'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Device location'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'deviceLocation'}
					md={12}
				/>
				<SubHeader hide={user.systemRole !== 'Admin'} text={'Certificate'} />
				<MainInputController
					control={control}
					hideInput={user.systemRole !== 'Admin'}
					label={'Cert name'}
					identifier={'certName'}
					md={6}
				/>
				<MainInputController
					control={control}
					hideInput={user.systemRole !== 'Admin'}
					label={'Cert location'}
					identifier={'certLocation'}
					md={6}
				/>
				<SubHeader hide={user.systemRole !== 'Admin'} text={'Tablet'} />
				<MainInputController
					control={control}
					hideInput={user.systemRole !== 'Admin'}
					label={'Tablet ID'}
					identifier={'tabletId'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'Tablet name'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'tabletName'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'Tablet link'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'tabletLink'}
					md={6}
				/>

				<SubHeader hide={user.systemRole !== 'Admin'} text={'Credentials'} />

				<MainInputController
					control={control}
					label={'Username'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'username'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Password'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'password'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'Sensor key'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'sensorKey'}
					md={6}
				/>

				<SubHeader hide={user.systemRole !== 'Admin'} text={'Connection'} />

				<MainInputController
					control={control}
					hideInput={user.systemRole !== 'Admin'}
					label={'Connect ID'}
					identifier={'connectID'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'Connect name'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'connectName'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'Connect tags'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'connectTags'}
					md={6}
				/>

				<SubHeader hide={user.systemRole !== 'Admin'} text={'POS Device'} />

				<MainInputController
					control={control}
					label={'POS serial number'}
					type={'string'}
					identifier={'deviceSerialNumber'}
					md={6}
				/>
				<MainInputController
					control={control}
					label={'POS sale ID'}
					type={'string'}
					identifier={'deviceSaleID'}
					md={6}
				/>

				<SubHeader hide={user.systemRole !== 'Admin'} text={'Access'} />
				<MainInputController
					control={control}
					helperText={'Remember to add device to the URL (and no / at the end)'}
					label={'Device URL'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'deviceURL'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Edge'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'edge'}
					md={12}
				/>
				<MainInputController
					control={control}
					label={'Device auth ID'}
					hideInput={user.systemRole !== 'Admin'}
					identifier={'deviceAuthID'}
					md={12}
				/>
				<MainDropdownController
					hideInput={user.systemRole !== 'Admin'}
					label={'First to the left'}
					isWithLink={true}
					options={gpiosDropdown}
					md={12}
					control={control}
					optionsValueKey={'_id'}
					optionsTextKey={'text'}
					identifier={'gpioOne'}
					link={`/admin/${ROUTE_TYPES[ROUTE_NAMES.devices].route}/0`}
					id={'gpioOne'}
				/>
				<MainDropdownController
					label={'Second to the left'}
					hideInput={user.systemRole !== 'Admin'}
					isWithLink={true}
					options={gpiosDropdown}
					md={12}
					control={control}
					optionsValueKey={'_id'}
					optionsTextKey={'text'}
					identifier={'gpioTwo'}
					link={`/admin/${ROUTE_TYPES[ROUTE_NAMES.devices].route}/0`}
					id={'gpioTwo'}
				/>
				<SubHeader text={'Taps'} />
				<MainDropdownController
					label={'First to the left'}
					isWithLink={true}
					options={tapsDropdown}
					md={12}
					control={control}
					optionsValueKey={'_id'}
					optionsTextKey={'text'}
					identifier={'tapOne'}
					link={`/admin/${ROUTE_TYPES[ROUTE_NAMES.taps].route}/0`}
					id={'tapOne'}
				/>
				<MainDropdownController
					label={'Second to the left'}
					isWithLink={true}
					options={tapsDropdown}
					md={12}
					control={control}
					optionsValueKey={'_id'}
					optionsTextKey={'text'}
					identifier={'tapTwo'}
					link={`/admin/${ROUTE_TYPES[ROUTE_NAMES.taps].route}/0`}
					id={'tapTwo'}
				/>
				<SubHeader text={'Place'} hide={user.systemRole !== 'Admin'} />
				<MainDropdownController
					label={'Place'}
					hideInput={user.systemRole !== 'Admin'}
					isWithLink={false}
					options={placesDropdown}
					md={12}
					control={control}
					optionsValueKey={'_id'}
					optionsTextKey={'text'}
					identifier={'place'}
					id={'place'}
				/>
			</FormContainer>
			{/* <MainContainer isLoaded={true}>
				<PanelContainer
					header={'Login to device'}
					// style={{ marginTop: -13, marginBottom: 30, width: '100%' }}
				></PanelContainer>
			</MainContainer> */}
		</PageContainer>
	);
}

export default Device;
