import { useState, useEffect } from 'react';
import { sendAPI } from '../api/helper';

interface useItemsProps {
	id?: string | undefined | null;
	dropdownFunction?: Function;
	useDropdown?: boolean;
	url: string;
}

function useItems({ id, dropdownFunction, useDropdown, url }: useItemsProps) {
	const [items, setItems] = useState(null);
	const [item, setItem] = useState(null);
	const [dropdown, setDropdown] = useState(null);

	useEffect(() => {
		if (!items) {
			getItems();
		}

		getItem(id, items);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	async function getItems() {
		const itemsResult = await sendAPI(url);

		let items = [];
		if (itemsResult) {
			items = itemsResult.items;
		}

		setItems(items);
		getItem(id, items);
		if (useDropdown) {
			const dropdownTemp = dropdownFunction(items);
			setDropdown(dropdownTemp);
		}
	}

	function getItem(id, items) {
		if (id && items && items.length > 0) {
			const selectedItem = items.find((el) => {
				return el._id === id;
			});

			if (selectedItem) {
				setItem(selectedItem);
			}
		}
	}

	return [items, item, dropdown];
}

export default useItems;
