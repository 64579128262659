export const LIST_HEADER_SIZE = { md: 26, sm: 22, xs: 22 };
export const LIST_TEXT_SIZE = { md: 24, sm: 18, xs: 18 };

const TAP_COLORS = {
	SUCCESS: '#dcefdc',
	ERROR: '#f8d7da',
	DEFAULT: '#ebebeb',
};

export const TAP_STATES = {
	CLOSED: {
		color: TAP_COLORS.DEFAULT,
	},
	Unknown: {
		color: TAP_COLORS.DEFAULT,
	},
	AVAILABLE: {
		color: TAP_COLORS.SUCCESS,
	},
	CHOOSE: {
		color: TAP_COLORS.SUCCESS,
	},
	BUSY: {
		color: TAP_COLORS.SUCCESS,
	},
	EMPTY: {
		color: TAP_COLORS.ERROR,
	},
	ERROR: {
		color: TAP_COLORS.ERROR,
	},
	'NO CONNECTION': {
		color: TAP_COLORS.ERROR,
	},
};
