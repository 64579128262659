import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	Outlet,
	useParams,
} from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import AdminRouteLayout from './AdminRouteLayout';

// import MainRoutes from './MainRoutes';
import Home from '../screens/Home/Home';
import HomeMain from '../screens/HomeMain/HomeMain';
import ReactGA from 'react-ga4';

import {
	AUTH_TYPES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import { GET_ROUTE_COMPONENT } from '../config/config';

import Kegs from '../screens/Kegs/Kegs';
import Drink from '../screens/Drinks/Drink';
import KegPageContainer from '../screens/Kegs/KegPageContainer';

import ManageUser from '../store/user/ManageUser';
// import ManageDrink from '../screens/Drinks/Drink';
// import JoinPlace from '../screens/Login/JoinPlace';
import MenuSelect from '../components/menu/MenuSelect';
import PlaceUser from '../screens/PlaceUsers/PlaceUser';
import Event from '../screens/Events/Event';
import ManageTable from '../components/tables/ManageTable';
import Misc from '../screens/Misc/Misc';
import HappyHour from '../screens/HappyHour/HappyHour';
import Flowmeter from '../screens/Flowmeters/Flowmeter';
import CreditReceipt from '../screens/CreditReceipts/CreditReceipt';
import CreditReceipts from '../screens/CreditReceipts/CreditReceipts';
import Flowmeters from '../screens/Flowmeters/Flowmeters';
import KegOverview from '../screens/KegChanges/KegOverview';
import Flows from '../screens/Flows/Flows';

import Coupons from '../screens/Coupons/Coupons';
import Coupon from '../screens/Coupons/Coupon';

import Sessions from '../screens/Sessions/Sessions';
import Events from '../screens/Events/Events';
import ManagePlace from '../screens/Place/Place';
import CreatePlace from '../screens/Place/CreatePlace';
import PrintTapQR from '../screens/Taps/PrintTapQR';
import Devices from '../screens/Devices/Devices';
import DevicesAll from '../screens/Devices/DevicesAll';

import Device from '../screens/Devices/Device';
import RegisterPlace from '../screens/RegisterPlaces/RegisterPlace';
import RegisteredPlaces from '../screens/RegisterPlaces/RegisteredPlaces';
import RegisteredPlace from '../screens/RegisterPlaces/RegisteredPlace';

import GPIO from '../screens/GPIOs/GPIO';
import ManageCredit from '../screens/Credit/ManageCredit';

import DetailedPlace from '../screens/DetailedPlace/DetailedPlace';
import Profile from '../screens/Profile/Profile';
import ManageDiscount from '../screens/Discounts/DiscountTime';
import { removeAllEmptyKegs } from '../api/alterData';
import SelectPlace from '../screens/Login/SelectPlace';
import PlaceUsers from '../screens/PlaceUsers/PlaceUsers';
import GetApp from '../screens/Misc/GetApp';

import Place from '../screens/Place/Place';

import Tap from '../screens/Taps/Tap';
import Taps from '../screens/Taps/Taps';
import MonitorTaps from '../screens/Taps/MonitorTaps';

import { ROUTE_NAMES } from '@toriosoftware/torioshared/dist/config';
import {
	getDiscountColumns,
	getUserColumns,
	getDrinkColumns,
	getTablesColumns,
	getTapColumns,
	getGPIOColumns,
} from '../business/tableHelper';
import ManageItems from '../components/tables/ManageItems';
import React from 'react';
import PageContainer from '../components/containers/PageContainer';
import Scan from '../screens/Scan/Scan';
import ScanAdmin from '../screens/Scan/ScanAdmin';
import Settings from '../screens/Settings/Settings';
import Receipts from '../screens/Receipts/Receipts';
import Receipt from '../screens/Receipts/Receipt';
import Flow from '../screens/Flows/Flow';
import Wallet from '../screens/Wallet/Wallet';

import { Button } from '@mui/material';
import FBError from '../screens/Errors/FBError';
import GoogleConnect from '../screens/GoogleConnect/GoogleConnect';
import GoogleConnectPage from '../screens/GoogleConnect/GoogleConnectPage';
import OrangeScan from '../screens/OrangeScan/OrangeScan';
import AddCardSalt from '../screens/AddCard/AddCardSalt';
import ManageDiscounts from '../screens/Discounts/ManageDiscounts';
import PaymentDetails from '../screens/PaymentDetails/PaymentDetails';
import KegChanges from '../screens/KegChanges/KegChanges';
import KegChange from '../screens/KegChanges/KegChange';
import Sale from '../screens/Sales/Sale';
// import ConfirmEmail from '../screens/Login/ConfirmEmail';

const AppRoutes = ({ user, modalState }) => {
	React.useEffect(() => {
		ReactGA.send('pageview'); //window.location.pathname + window.location.search);
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				{['/', '/home/:id', '/authed'].map((path) => (
					<Route
						key={path}
						path={path}
						element={<HomeMain user={user} loggedIn={user?.loggedIn} />}
					/>
				))}

				{/* <Route
					path="/confirmEmailLogin/:token/:code"
					element={<ConfirmEmail />}
				/> */}

				<Route path="/registerplace" element={<RegisterPlace />} />

				<Route path="/wallettest" element={<Wallet />} />

				{/* {['/oldHome'].map((path) => (
					<Route
						key={path}
						path={path}
						element={<Home user={user} loggedIn={user?.loggedIn} />}
					/>
				))} */}

				{/* <Route
					path="/"
					element={<Home user={user} loggedIn={user?.loggedIn} />}
				/> */}

				<Route
					path="/.well-known/apple-developer-merchantid-domain-association.txt"
					element={
						<object
							data={
								window.location.origin +
								'/.well-known/apple-developer-merchantid-domain-association.txt'
							}
							type="text/html"
							width="100%"
							height="100%"
						></object>
					}
				/>

				<Route
					path="/login/:token"
					element={
						<LoginRoutes user={user} modalState={modalState} withToken={true} />
						// user?.loggedIn && user?.isEmailConfirmed && user?.place ? (
						// 	<RedirectJoin user={user} />
						// ) : (
						// )
					}
				/>

				<Route
					path="/login"
					element={<LoginRoutes user={user} modalState={modalState} />}
				/>

				<Route
					path="/login"
					element={<LoginRoutes user={user} modalState={modalState} />}
				/>

				<Route path="/addcard" element={<AddCardSalt />} />
				<Route path="/processpayment" element={<AddCardSalt />} />

				<Route path="/getbarhopp" element={<GetApp appToGet={'barhopp'} />} />
				<Route path="/gettorio" element={<GetApp appToGet={'torio'} />} />

				<Route path="/orangescan" element={<OrangeScan />} />

				<Route
					path="/api/taps/onScan/:id/:id2"
					element={<GetApp appToGet={'torio'} />}
				/>

				{/* 
				<Route path="/scan" element={<Scan user={user} />} />

				<Route path="/profile" element={<Settings />} /> */}

				<Route
					path="/admin"
					element={<AdminRouteLayout user={user} modalState={modalState} />}
					// element={}
				>
					<Route index={true} element={<MenuSelect user={user} />} />
					<Route path="place" element={<Place user={user} />} />
					{/* <Route path="joinplace/:token" element={<JoinPlace />} /> */}
					<Route
						path="place/placeusers"
						element={
							<PlaceUsers
								redirectToItem={''}
								placeName={user?.placeName}
								user={user}
								showPageContainer={true}
								header={'Place users'}
							/>
						}
					/>

					<Route
						path="registeredplaces"
						element={<RegisteredPlaces user={user} />}
					/>

					<Route
						path="registeredplaces/:id"
						element={<RegisteredPlace user={user} />}
					/>

					<Route
						path="sales"
						element={<KegOverview user={user} modalState={modalState} />}
					/>

					<Route
						path="sales/:id"
						element={<Sale user={user} modalState={modalState} />}
					/>

					{/* 

					<Route path="kegchanges/:id" element={<KegChange user={user} />} /> */}

					<Route
						path="place/placeusers/:id"
						element={<PlaceUser user={user} />}
					/>
					<Route path="profile" element={<Profile user={user} />} />

					<Route path="useraccess" element={<ManageCredit user={user} />} />

					<Route path="taps/login/:id" element={<ScanAdmin user={user} />} />

					<Route
						path={'creditreceipts'}
						element={<CreditReceipts user={user} />}
					/>
					<Route
						path={'creditreceipts/:id'}
						element={<CreditReceipt user={user} />}
					/>

					<Route path="accesslogs" element={<Receipts user={user} />} />
					<Route path="accesslogs/:id" element={<Receipt user={user} />} />
					{/* <Route
						path="managediscounts"
						element={<ManageDiscounts user={user} />}
					/> */}
					<Route
						path="discounttimes"
						element={<ManageDiscounts user={user} />}
					/>
					<Route
						path="discounttimes/:id"
						element={<ManageDiscount user={user} />}
					/>
					{/* 
					<Route path="managedrinks" element={<ManageDrinks user={user} />} />
				<Route path="manageGpios" element={<ManageGpios user={user} />} /> */}
					{/* <Route path="scan" element={<Scan user={user} />} /> */}
					<Route path="detailedPlace" element={<DetailedPlace user={user} />} />
					<Route path="google" element={<GoogleConnectPage user={user} />} />
					<Route path="events" element={<Events user={user} />} />
					<Route path="events/:id" element={<Event user={user} />} />
					<Route path="happyhour" element={<HappyHour user={user} />} />
					{/* <Route path="joinplace" element={<JoinPlace />} /> */}
					<Route
						path="changeplace"
						element={<SelectPlace modalState={modalState} user={user} />}
					/>
					{/* <Route path={ROUTE_TYPES['createqr']?.route}>
						<PrintTapQR />
					</Route> */}

					<Route
						path={
							ROUTE_TYPES['taps']?.route ? ROUTE_TYPES['taps']?.route : 'taps'
						}
						element={<Taps user={user} />}
					/>

					<Route path={'monitortaps'} element={<MonitorTaps user={user} />} />

					<Route
						path={
							ROUTE_TYPES['tap']?.route ? ROUTE_TYPES['tap']?.route : 'taps/:id'
						}
						element={<Tap user={user} />}
					/>

					{/* <Kegs user={user} /> */}

					<Route
						path={
							ROUTE_TYPES['kegs']?.route ? ROUTE_TYPES['kegs']?.route : 'kegs'
						}
						element={<Kegs modalState={modalState} user={user} />}
					/>
					<Route
						path={
							ROUTE_TYPES['keg']?.route ? ROUTE_TYPES['keg']?.route : 'kegs/:id'
						}
						element={<KegPageContainer user={user} usePathId={true} />}
					/>

					{/* Flows, many and one */}
					<Route
						path={
							ROUTE_TYPES['flowmeters']?.route
								? ROUTE_TYPES['flowmeters']?.route
								: 'flowmeters'
						}
						element={<Flowmeters user={user} />}
					/>
					<Route
						path={
							ROUTE_TYPES['flowmeter']?.route
								? ROUTE_TYPES['flowmeter']?.route
								: 'flowmeters/:id'
						}
						element={<Flowmeter user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['flows']?.route
								? ROUTE_TYPES['flows']?.route
								: 'flows'
						}
						element={<Flows user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['flow']?.route
								? ROUTE_TYPES['flow']?.route
								: 'flows/:id'
						}
						element={<Flow user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['coupons']?.route
								? ROUTE_TYPES['coupons']?.route
								: 'coupons'
						}
						element={<Coupons user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['coupon']?.route
								? ROUTE_TYPES['coupon']?.route
								: 'coupons/:id'
						}
						element={<Coupon user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['paymentdetails']?.route
								? ROUTE_TYPES['paymentdetails']?.route
								: 'paymentdetails'
						}
						element={<PaymentDetails user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['drinks']?.route
								? ROUTE_TYPES['drinks']?.route
								: 'drinks'
								? ROUTE_TYPES['drinks']?.route
									? ROUTE_TYPES['drinks']?.route
									: 'drinks'
								: 'drinks'
						}
						element={
							<PageContainer header={'Drinks'}>
								<React.Fragment>
									<ManageItems
										header={'Drinks'}
										buttonText={'Add drink'}
										key={3}
										getData={{
											url: 'drinks/getDrinks',
											body: {},
										}}
										columns={getDrinkColumns(user?.placeCurrency)}
										redirectLink={
											ROUTE_TYPES['drink']?.route
												? ROUTE_TYPES['drink']?.route
												: 'drinks/:id'
										}
										noItemsText={'No drinks have been added.'}
										// manageComponent={() => <Drink user={user} />}
									/>
								</React.Fragment>
							</PageContainer>
						}
					/>

					<Route
						path={
							ROUTE_TYPES['drink']?.route
								? ROUTE_TYPES['drink']?.route
								: 'drinks/:id'
						}
						element={<Drink user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['gpios']?.route
								? ROUTE_TYPES['gpios']?.route
								: 'gpios'
						}
						element={
							<PageContainer header={'GPIOs'}>
								<React.Fragment>
									<ManageItems
										header={'GPIOs'}
										key={3111}
										getData={{
											url: 'gpios/getGpios',
											body: {},
										}}
										buttonText={'Add GPIO'}
										redirectLink={
											ROUTE_TYPES['gpio']?.route
												? ROUTE_TYPES['gpio']?.route
												: 'gpios/:id'
										}
										columns={getGPIOColumns('')}
										noItemsText={'No gpios combinations have been added.'}
										manageComponent={() => <GPIO user={user} />}
									/>
								</React.Fragment>
							</PageContainer>
						}
					/>

					<Route
						path={
							ROUTE_TYPES['gpio']?.route
								? ROUTE_TYPES['gpio']?.route
								: 'gpio/:id'
						}
						element={<GPIO user={user} />}
					/>

					<Route path={'devicesall'} element={<DevicesAll user={user} />} />

					<Route path={'sessions'} element={<Sessions user={user} />} />

					<Route
						path={
							ROUTE_TYPES['devices']?.route
								? ROUTE_TYPES['devices']?.route
								: 'devices/:id'
						}
						element={<Devices user={user} />}
					/>

					<Route
						path={
							ROUTE_TYPES['device']?.route
								? ROUTE_TYPES['device']?.route
								: 'devices/:id'
						}
						element={<Device user={user} />}
					/>

					{/* <Route

						path={ROUTE_TYPES['tap']?.route}
						element={<Tap user={user} />}
					/> */}

					<Route path="misc" element={<Misc user={user} />} />
					<Route path="createplace" element={<CreatePlace user={user} />} />
				</Route>
				{/* {Object.keys(ROUTE_TYPES).map((key) => {
						const routeObject = ROUTE_TYPES[key];
						let routeItemTest = GET_ROUTE_COMPONENT(key, user);
						console.log(routeItemTest);
						let stringThing = `<Route path="${routeObject.route}" element={<${routeObject.id} />} /> `;
						routeObjectsStr += stringThing;

						if (routeObject) {
							let routeItem = GET_ROUTE_COMPONENT(key, user);
							if (
								routeItem &&
								routeItem.component &&
								AUTH_TYPES.role(user, routeObject.role)
							) {
								return (
									<Route
										path={routeObject.route}
										element={routeItem?.component()}
									/>
								);
							}
						}
						console.log('routeObjects: ', routeObjectsStr);
						return null;
					})} */}

				<Route
					path="/removedata"
					element={
						<div>
							<h2 style={{ textAlign: 'center', marginTop: 160 }}>
								You can send an email to torio@torio.is to have your data
								removed. Please visit our privacy policy to learn more.
							</h2>
						</div>
					}
				/>

				<Route path="/error" element={<FBError />} />

				<Route
					path="*"
					element={
						<div>
							<h2 style={{ textAlign: 'center', marginTop: 160 }}>Not found</h2>
						</div>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;
