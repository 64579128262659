import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import PersonAdd from '@mui/icons-material/PersonAdd';

// import {
// 	linksAdmin,
// 	linksBarhopp,
// 	links,
// 	linksSettings,
// } from '../../config/config';
import { onLogout } from '../../api/helper';
import { useInvalidateMutation, useLogoutMutation } from '../../store/api/api';
import { getLinks } from '../../config/configHelperFunctions';
import { useDispatch } from 'react-redux';
import { resetLoginData } from '../../store/login/loginSlice';

const drawerWidth = 240;

const ChangeLink = styled(Link)(({ theme }) => ({
	marginTop: 4,
	top: 4,
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));

const BarHeader = styled(Typography)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		fontSize: 18,
		marginLeft: -10,
	},
}));

// theme.palette.primary.contrastText,

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.down('sm')]: {
		// width: `calc(${theme.spacing(8)} + 1px)`,
		display: 'none',
		width: 0,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default function SideBar({
	role,
	subscription,
	placeName,
	systemRole,
	user,
}) {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	//@ts-ignore
	const [modal] = useContext(Context);
	const [logout] = useLogoutMutation();
	const [invalidate] = useInvalidateMutation();
	let links = getLinks(user);
	const dispatch = useDispatch();

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	function getHeaderText() {
		if (placeName) {
			return '' + placeName;
		} else {
			return '';
		}
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar color={'primary'} position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<div
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							display: 'flex',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<Link
								to="/admin"
								style={{ color: 'white', textDecoration: 'none' }}
							>
								<BarHeader variant="h6" noWrap>
									{getHeaderText()}{' '}
									{/* <span
										style={{
											fontSize: 12,
											fontStyle: 'italic',
											paddingLeft: 7,
										}}
									>
										@ Torio Admin Beta
									</span> */}
								</BarHeader>
							</Link>
							{(user && user.systemRole === 'Admin') ||
							user.role === 'Place admin' ? (
								<Link
									to="/admin/place/placeusers"
									style={{
										color: 'white',
										textDecoration: 'none',
										marginLeft: 8,
										marginTop: 7,
									}}
								>
									<PersonAdd />
								</Link>
							) : null}

							{/* <ChangeLink
								style={{
									marginLeft: 15,
									// fontSize: 17,
									color: 'white',
									textDecoration: 'none',
									fontSize: '0.875rem',
									// fontFamily: 'Roboto',
									fontWeight: 500,
									lineHeight: 1.75,
									borderRadius: 4,
									marginTop: 5.5,
									letterSpacing: '0.02857em',
									textTransform: 'uppercase',
								}}
								to={'/admin/changeplace'}
								onClick={() => {
									setTimeout(() => {
										window.location.reload();
									}, 500);
								}}
							>
								Change
							</ChangeLink> */}
						</div>
						<Button
							onClick={async () => {
								// let inData = await invalidate();
								let res = await onLogout(modal.openModal, invalidate);
								dispatch(resetLoginData());

								setTimeout(() => {
									window.location.reload();
								}, 500);
								// let logoutResult = await logout({ modal: modal.openModal });
							}}
							variant="text"
							style={{
								cursor: 'pointer',
								color: 'white',
								fontWeight: 'bold',
								marginTop: 4,
							}}
						>
							Logout
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>

				{links.map((link, index) => {
					return (
						<div key={index}>
							<Link
								onClick={() => {
									// if (reload) {
									// 	setTimeout(() => {
									// 		window.location.reload();
									// 	}, 700);
									// }
								}}
								to={link.sideBarObject.route}
								style={{ color: 'black', textDecoration: 'none' }}
							>
								<ListItem button>
									<ListItemIcon>
										{link.theme.icon ? link.theme.icon(false) : null}
									</ListItemIcon>
									<ListItemText primary={link.sideBarObject.name} />
								</ListItem>
							</Link>
						</div>
					);
				})}
			</Drawer>
		</Box>
	);
}
