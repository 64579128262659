export const countries = [
	{ code: 'AD', label: 'Andorra', phone: '+376' },
	{ code: 'AE', label: 'United Arab Emirates', phone: '+971' },
	{ code: 'AF', label: 'Afghanistan', phone: '+93' },
	{ code: 'AG', label: 'Antigua and Barbuda', phone: '+1-268' },
	{ code: 'AI', label: 'Anguilla', phone: '+1-264' },
	{ code: 'AL', label: 'Albania', phone: '+355' },
	{ code: 'AM', label: 'Armenia', phone: '+374' },
	{ code: 'AO', label: 'Angola', phone: '+244' },
	{ code: 'AQ', label: 'Antarctica', phone: '+672' },
	{ code: 'AR', label: 'Argentina', phone: '+54' },
	{ code: 'AS', label: 'American Samoa', phone: '+1-684' },
	{ code: 'AT', label: 'Austria', phone: '+43' },
	{ code: 'AU', label: 'Australia', phone: '+61' },
	{ code: 'AW', label: 'Aruba', phone: '+297' },
	{ code: 'AX', label: 'Alland Islands', phone: '+358' },
	{ code: 'AZ', label: 'Azerbaijan', phone: '+994' },
	{ code: 'BA', label: 'Bosnia and Herzegovina', phone: '+387' },
	{ code: 'BB', label: 'Barbados', phone: '+1-246' },
	{ code: 'BD', label: 'Bangladesh', phone: '+880' },
	{ code: 'BE', label: 'Belgium', phone: '+32' },
	{ code: 'BF', label: 'Burkina Faso', phone: '+226' },
	{ code: 'BG', label: 'Bulgaria', phone: '+359' },
	{ code: 'BH', label: 'Bahrain', phone: '+973' },
	{ code: 'BI', label: 'Burundi', phone: '+257' },
	{ code: 'BJ', label: 'Benin', phone: '+229' },
	{ code: 'BL', label: 'Saint Barthelemy', phone: '+590' },
	{ code: 'BM', label: 'Bermuda', phone: '+1-441' },
	{ code: 'BN', label: 'Brunei Darussalam', phone: '+673' },
	{ code: 'BO', label: 'Bolivia', phone: '+591' },
	{ code: 'BR', label: 'Brazil', phone: '+55' },
	{ code: 'BS', label: 'Bahamas', phone: '+1-242' },
	{ code: 'BT', label: 'Bhutan', phone: '+975' },
	{ code: 'BV', label: 'Bouvet Island', phone: '+47' },
	{ code: 'BW', label: 'Botswana', phone: '+267' },
	{ code: 'BY', label: 'Belarus', phone: '+375' },
	{ code: 'BZ', label: 'Belize', phone: '+501' },
	{ code: 'CA', label: 'Canada', phone: '+1', suggested: true },
	{ code: 'CC', label: 'Cocos (Keeling) Islands', phone: '+61' },
	{ code: 'CD', label: 'Congo, Democratic Republic of the', phone: '+243' },
	{ code: 'CF', label: 'Central African Republic', phone: '+236' },
	{ code: 'CG', label: 'Congo, Republic of the', phone: '+242' },
	{ code: 'CH', label: 'Switzerland', phone: '+41' },
	{ code: 'CI', label: "Cote d'Ivoire", phone: '+225' },
	{ code: 'CK', label: 'Cook Islands', phone: '+682' },
	{ code: 'CL', label: 'Chile', phone: '+56' },
	{ code: 'CM', label: 'Cameroon', phone: '+237' },
	{ code: 'CN', label: 'China', phone: '+86' },
	{ code: 'CO', label: 'Colombia', phone: '+57' },
	{ code: 'CR', label: 'Costa Rica', phone: '+506' },
	{ code: 'CU', label: 'Cuba', phone: '+53' },
	{ code: 'CV', label: 'Cape Verde', phone: '+238' },
	{ code: 'CW', label: 'Curacao', phone: '+599' },
	{ code: 'CX', label: 'Christmas Island', phone: '+61' },
	{ code: 'CY', label: 'Cyprus', phone: '+357' },
	{ code: 'CZ', label: 'Czech Republic', phone: '+420' },
	{ code: 'DE', label: 'Germany', phone: '+49', suggested: true },
	{ code: 'DJ', label: 'Djibouti', phone: '+253' },
	{ code: 'DK', label: 'Denmark', phone: '+45' },
	{ code: 'DM', label: 'Dominica', phone: '+1-767' },
	{ code: 'DO', label: 'Dominican Republic', phone: '+1-809' },
	{ code: 'DZ', label: 'Algeria', phone: '+213' },
	{ code: 'EC', label: 'Ecuador', phone: '+593' },
	{ code: 'EE', label: 'Estonia', phone: '+372' },
	{ code: 'EG', label: 'Egypt', phone: '+20' },
	{ code: 'EH', label: 'Western Sahara', phone: '+212' },
	{ code: 'ER', label: 'Eritrea', phone: '+291' },
	{ code: 'ES', label: 'Spain', phone: '+34' },
	{ code: 'ET', label: 'Ethiopia', phone: '+251' },
	{ code: 'FI', label: 'Finland', phone: '+358' },
	{ code: 'FJ', label: 'Fiji', phone: '+679' },
	{ code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '+500' },
	{ code: 'FM', label: 'Micronesia, Federated States of', phone: '+691' },
	{ code: 'FO', label: 'Faroe Islands', phone: '+298' },
	{ code: 'FR', label: 'France', phone: '+33', suggested: true },
	{ code: 'GA', label: 'Gabon', phone: '+241' },
	{ code: 'GB', label: 'United Kingdom', phone: '+44' },
	{ code: 'GD', label: 'Grenada', phone: '+1-473' },
	{ code: 'GE', label: 'Georgia', phone: '+995' },
	{ code: 'GF', label: 'French Guiana', phone: '+594' },
	{ code: 'GG', label: 'Guernsey', phone: '+44' },
	{ code: 'GH', label: 'Ghana', phone: '+233' },
	{ code: 'GI', label: 'Gibraltar', phone: '+350' },
	{ code: 'GL', label: 'Greenland', phone: '+299' },
	{ code: 'GM', label: 'Gambia', phone: '+220' },
	{ code: 'GN', label: 'Guinea', phone: '+224' },
	{ code: 'GP', label: 'Guadeloupe', phone: '+590' },
	{ code: 'GQ', label: 'Equatorial Guinea', phone: '+240' },
	{ code: 'GR', label: 'Greece', phone: '+30' },
	{
		code: 'GS',
		label: 'South Georgia and the South Sandwich Islands',
		phone: '+500',
	},
	{ code: 'GT', label: 'Guatemala', phone: '+502' },
	{ code: 'GU', label: 'Guam', phone: '+1-671' },
	{ code: 'GW', label: 'Guinea-Bissau', phone: '+245' },
	{ code: 'GY', label: 'Guyana', phone: '+592' },
	{ code: 'HK', label: 'Hong Kong', phone: '+852' },
	{ code: 'HM', label: 'Heard Island and McDonald Islands', phone: '+672' },
	{ code: 'HN', label: 'Honduras', phone: '+504' },
	{ code: 'HR', label: 'Croatia', phone: '+385' },
	{ code: 'HT', label: 'Haiti', phone: '+509' },
	{ code: 'HU', label: 'Hungary', phone: '+36' },
	{ code: 'ID', label: 'Indonesia', phone: '+62' },
	{ code: 'IE', label: 'Ireland', phone: '+353' },
	{ code: 'IL', label: 'Israel', phone: '+972' },
	{ code: 'IM', label: 'Isle of Man', phone: '+44' },
	{ code: 'IN', label: 'India', phone: '+91' },
	{ code: 'IO', label: 'British Indian Ocean Territory', phone: '+246' },
	{ code: 'IQ', label: 'Iraq', phone: '+964' },
	{ code: 'IR', label: 'Iran, Islamic Republic of', phone: '+98' },
	{ code: 'IS', label: 'Iceland', phone: '+354', suggested: true },
	{ code: 'IT', label: 'Italy', phone: '+39' },
	{ code: 'JE', label: 'Jersey', phone: '+44' },
	{ code: 'JM', label: 'Jamaica', phone: '+1-876' },
	{ code: 'JO', label: 'Jordan', phone: '+962' },
	{ code: 'JP', label: 'Japan', phone: '+81', suggested: true },
	{ code: 'KE', label: 'Kenya', phone: '+254' },
	{ code: 'KG', label: 'Kyrgyzstan', phone: '+996' },
	{ code: 'KH', label: 'Cambodia', phone: '+855' },
	{ code: 'KI', label: 'Kiribati', phone: '+686' },
	{ code: 'KM', label: 'Comoros', phone: '+269' },
	{ code: 'KN', label: 'Saint Kitts and Nevis', phone: '+1-869' },
	{
		code: 'KP',
		label: "Korea, Democratic People's Republic of",
		phone: '+850',
	},
	{ code: 'KR', label: 'Korea, Republic of', phone: '+82' },
	{ code: 'KW', label: 'Kuwait', phone: '+965' },
	{ code: 'KY', label: 'Cayman Islands', phone: '+1-345' },
	{ code: 'KZ', label: 'Kazakhstan', phone: '+7' },
	{ code: 'LA', label: "Lao People's Democratic Republic", phone: '+856' },
	{ code: 'LB', label: 'Lebanon', phone: '+961' },
	{ code: 'LC', label: 'Saint Lucia', phone: '+1-758' },
	{ code: 'LI', label: 'Liechtenstein', phone: '+423' },
	{ code: 'LK', label: 'Sri Lanka', phone: '+94' },
	{ code: 'LR', label: 'Liberia', phone: '+231' },
	{ code: 'LS', label: 'Lesotho', phone: '+266' },
	{ code: 'LT', label: 'Lithuania', phone: '+370' },
	{ code: 'LU', label: 'Luxembourg', phone: '+352' },
	{ code: 'LV', label: 'Latvia', phone: '+371' },
	{ code: 'LY', label: 'Libya', phone: '+218' },
	{ code: 'MA', label: 'Morocco', phone: '+212' },
	{ code: 'MC', label: 'Monaco', phone: '+377' },
	{ code: 'MD', label: 'Moldova, Republic of', phone: '+373' },
	{ code: 'ME', label: 'Montenegro', phone: '+382' },
	{ code: 'MF', label: 'Saint Martin (French part)', phone: '+590' },
	{ code: 'MG', label: 'Madagascar', phone: '+261' },
	{ code: 'MH', label: 'Marshall Islands', phone: '+692' },
	{
		code: 'MK',
		label: 'Macedonia, the Former Yugoslav Republic of',
		phone: '+389',
	},
	{ code: 'ML', label: 'Mali', phone: '+223' },
	{ code: 'MM', label: 'Myanmar', phone: '+95' },
	{ code: 'MN', label: 'Mongolia', phone: '+976' },
	{ code: 'MO', label: 'Macao', phone: '+853' },
	{ code: 'MP', label: 'Northern Mariana Islands', phone: '+1-670' },
	{ code: 'MQ', label: 'Martinique', phone: '+596' },
	{ code: 'MR', label: 'Mauritania', phone: '+222' },
	{ code: 'MS', label: 'Montserrat', phone: '+1-664' },
	{ code: 'MT', label: 'Malta', phone: '+356' },
	{ code: 'MU', label: 'Mauritius', phone: '+230' },
	{ code: 'MV', label: 'Maldives', phone: '+960' },
	{ code: 'MW', label: 'Malawi', phone: '+265' },
	{ code: 'MX', label: 'Mexico', phone: '+52' },
	{ code: 'MY', label: 'Malaysia', phone: '+60' },
	{ code: 'MZ', label: 'Mozambique', phone: '+258' },
	{ code: 'NA', label: 'Namibia', phone: '+264' },
	{ code: 'NC', label: 'New Caledonia', phone: '+687' },
	{ code: 'NE', label: 'Niger', phone: '+227' },
	{ code: 'NF', label: 'Norfolk Island', phone: '+672' },
	{ code: 'NG', label: 'Nigeria', phone: '+234' },
	{ code: 'NI', label: 'Nicaragua', phone: '+505' },
	{ code: 'NL', label: 'Netherlands', phone: '+31' },
	{ code: 'NO', label: 'Norway', phone: '+47' },
	{ code: 'NP', label: 'Nepal', phone: '+977' },
	{ code: 'NR', label: 'Nauru', phone: '+674' },
	{ code: 'NU', label: 'Niue', phone: '+683' },
	{ code: 'NZ', label: 'New Zealand', phone: '+64' },
	{ code: 'OM', label: 'Oman', phone: '+968' },
	{ code: 'PA', label: 'Panama', phone: '+507' },
	{ code: 'PE', label: 'Peru', phone: '+51' },
	{ code: 'PF', label: 'French Polynesia', phone: '+689' },
	{ code: 'PG', label: 'Papua New Guinea', phone: '+675' },
	{ code: 'PH', label: 'Philippines', phone: '+63' },
	{ code: 'PK', label: 'Pakistan', phone: '+92' },
	{ code: 'PL', label: 'Poland', phone: '+48' },
	{ code: 'PM', label: 'Saint Pierre and Miquelon', phone: '+508' },
	{ code: 'PN', label: 'Pitcairn', phone: '+870' },
	{ code: 'PR', label: 'Puerto Rico', phone: '+1' },
	{ code: 'PS', label: 'Palestine, State of', phone: '+970' },
	{ code: 'PT', label: 'Portugal', phone: '+351' },
	{ code: 'PW', label: 'Palau', phone: '+680' },
	{ code: 'PY', label: 'Paraguay', phone: '+595' },
	{ code: 'QA', label: 'Qatar', phone: '+974' },
	{ code: 'RE', label: 'Reunion', phone: '+262' },
	{ code: 'RO', label: 'Romania', phone: '+40' },
	{ code: 'RS', label: 'Serbia', phone: '+381' },
	{ code: 'RU', label: 'Russian Federation', phone: '+7' },
	{ code: 'RW', label: 'Rwanda', phone: '+250' },
	{ code: 'SA', label: 'Saudi Arabia', phone: '+966' },
	{ code: 'SB', label: 'Solomon Islands', phone: '+677' },
	{ code: 'SC', label: 'Seychelles', phone: '+248' },
	{ code: 'SD', label: 'Sudan', phone: '+249' },
	{ code: 'SE', label: 'Sweden', phone: '+46' },
	{ code: 'SG', label: 'Singapore', phone: '+65' },
	{ code: 'SH', label: 'Saint Helena', phone: '+290' },
	{ code: 'SI', label: 'Slovenia', phone: '+386' },
	{ code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '+47' },
	{ code: 'SK', label: 'Slovakia', phone: '+421' },
	{ code: 'SL', label: 'Sierra Leone', phone: '+232' },
	{ code: 'SM', label: 'San Marino', phone: '+378' },
	{ code: 'SN', label: 'Senegal', phone: '+221' },
	{ code: 'SO', label: 'Somalia', phone: '+252' },
	{ code: 'SR', label: 'Suriname', phone: '+597' },
	{ code: 'SS', label: 'South Sudan', phone: '+211' },
	{ code: 'ST', label: 'Sao Tome and Principe', phone: '+239' },
	{ code: 'SV', label: 'El Salvador', phone: '+503' },
	{ code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '+1-721' },
	{ code: 'SY', label: 'Syrian Arab Republic', phone: '+963' },
	{ code: 'SZ', label: 'Swaziland', phone: '+268' },
	{ code: 'TC', label: 'Turks and Caicos Islands', phone: '+1-649' },
	{ code: 'TD', label: 'Chad', phone: '+235' },
	{ code: 'TF', label: 'French Southern Territories', phone: '+262' },
	{ code: 'TG', label: 'Togo', phone: '+228' },
	{ code: 'TH', label: 'Thailand', phone: '+66' },
	{ code: 'TJ', label: 'Tajikistan', phone: '+992' },
	{ code: 'TK', label: 'Tokelau', phone: '+690' },
	{ code: 'TL', label: 'Timor-Leste', phone: '+670' },
	{ code: 'TM', label: 'Turkmenistan', phone: '+993' },
	{ code: 'TN', label: 'Tunisia', phone: '+216' },
	{ code: 'TO', label: 'Tonga', phone: '+676' },
	{ code: 'TR', label: 'Turkey', phone: '+90' },
	{ code: 'TT', label: 'Trinidad and Tobago', phone: '+1-868' },
	{ code: 'TV', label: 'Tuvalu', phone: '+688' },
	{ code: 'TW', label: 'Taiwan, Province of China', phone: '+886' },
	{ code: 'TZ', label: 'United Republic of Tanzania', phone: '+255' },
	{ code: 'UA', label: 'Ukraine', phone: '+380' },
	{ code: 'UG', label: 'Uganda', phone: '+256' },
	{ code: 'US', label: 'United States', phone: '+1', suggested: true },
	{ code: 'UY', label: 'Uruguay', phone: '+598' },
	{ code: 'UZ', label: 'Uzbekistan', phone: '+998' },
	{ code: 'VA', label: 'Holy See (Vatican City State)', phone: '+379' },
	{ code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '+1-784' },
	{ code: 'VE', label: 'Venezuela', phone: '+58' },
	{ code: 'VG', label: 'British Virgin Islands', phone: '+1-284' },
	{ code: 'VI', label: 'US Virgin Islands', phone: '+1-340' },
	{ code: 'VN', label: 'Vietnam', phone: '+84' },
	{ code: 'VU', label: 'Vanuatu', phone: '+678' },
	{ code: 'WF', label: 'Wallis and Futuna', phone: '+681' },
	{ code: 'WS', label: 'Samoa', phone: '+685' },
	{ code: 'XK', label: 'Kosovo', phone: '+383' },
	{ code: 'YE', label: 'Yemen', phone: '+967' },
	{ code: 'YT', label: 'Mayotte', phone: '+262' },
	{ code: 'ZA', label: 'South Africa', phone: '+27' },
	{ code: 'ZM', label: 'Zambia', phone: '+260' },
	{ code: 'ZW', label: 'Zimbabwe', phone: '+263' },
];

export const currencies = [
	{
		symbol: '$',
		name: 'US Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'USD',
		name_plural: 'US dollars',
	},
	{
		symbol: 'CA$',
		name: 'Canadian Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'CAD',
		name_plural: 'Canadian dollars',
	},
	{
		symbol: '€',
		name: 'Euro',
		symbol_native: '€',
		decimal_digits: 2,
		rounding: 0,
		code: 'EUR',
		name_plural: 'euros',
	},
	{
		symbol: 'AED',
		name: 'United Arab Emirates Dirham',
		symbol_native: 'د.إ.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'AED',
		name_plural: 'UAE dirhams',
	},
	{
		symbol: 'Af',
		name: 'Afghan Afghani',
		symbol_native: '؋',
		decimal_digits: 0,
		rounding: 0,
		code: 'AFN',
		name_plural: 'Afghan Afghanis',
	},
	{
		symbol: 'ALL',
		name: 'Albanian Lek',
		symbol_native: 'Lek',
		decimal_digits: 0,
		rounding: 0,
		code: 'ALL',
		name_plural: 'Albanian lekë',
	},
	{
		symbol: 'AMD',
		name: 'Armenian Dram',
		symbol_native: 'դր.',
		decimal_digits: 0,
		rounding: 0,
		code: 'AMD',
		name_plural: 'Armenian drams',
	},
	{
		symbol: 'AR$',
		name: 'Argentine Peso',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'ARS',
		name_plural: 'Argentine pesos',
	},
	{
		symbol: 'AU$',
		name: 'Australian Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'AUD',
		name_plural: 'Australian dollars',
	},
	{
		symbol: 'man.',
		name: 'Azerbaijani Manat',
		symbol_native: 'ман.',
		decimal_digits: 2,
		rounding: 0,
		code: 'AZN',
		name_plural: 'Azerbaijani manats',
	},
	{
		symbol: 'KM',
		name: 'Bosnia-Herzegovina Convertible Mark',
		symbol_native: 'KM',
		decimal_digits: 2,
		rounding: 0,
		code: 'BAM',
		name_plural: 'Bosnia-Herzegovina convertible marks',
	},
	{
		symbol: 'Tk',
		name: 'Bangladeshi Taka',
		symbol_native: '৳',
		decimal_digits: 2,
		rounding: 0,
		code: 'BDT',
		name_plural: 'Bangladeshi takas',
	},
	{
		symbol: 'BGN',
		name: 'Bulgarian Lev',
		symbol_native: 'лв.',
		decimal_digits: 2,
		rounding: 0,
		code: 'BGN',
		name_plural: 'Bulgarian leva',
	},
	{
		symbol: 'BD',
		name: 'Bahraini Dinar',
		symbol_native: 'د.ب.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'BHD',
		name_plural: 'Bahraini dinars',
	},
	{
		symbol: 'FBu',
		name: 'Burundian Franc',
		symbol_native: 'FBu',
		decimal_digits: 0,
		rounding: 0,
		code: 'BIF',
		name_plural: 'Burundian francs',
	},
	{
		symbol: 'BN$',
		name: 'Brunei Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'BND',
		name_plural: 'Brunei dollars',
	},
	{
		symbol: 'Bs',
		name: 'Bolivian Boliviano',
		symbol_native: 'Bs',
		decimal_digits: 2,
		rounding: 0,
		code: 'BOB',
		name_plural: 'Bolivian bolivianos',
	},
	{
		symbol: 'R$',
		name: 'Brazilian Real',
		symbol_native: 'R$',
		decimal_digits: 2,
		rounding: 0,
		code: 'BRL',
		name_plural: 'Brazilian reals',
	},
	{
		symbol: 'BWP',
		name: 'Botswanan Pula',
		symbol_native: 'P',
		decimal_digits: 2,
		rounding: 0,
		code: 'BWP',
		name_plural: 'Botswanan pulas',
	},
	{
		symbol: 'Br',
		name: 'Belarusian Ruble',
		symbol_native: 'руб.',
		decimal_digits: 2,
		rounding: 0,
		code: 'BYN',
		name_plural: 'Belarusian rubles',
	},
	{
		symbol: 'BZ$',
		name: 'Belize Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'BZD',
		name_plural: 'Belize dollars',
	},
	{
		symbol: 'CDF',
		name: 'Congolese Franc',
		symbol_native: 'FrCD',
		decimal_digits: 2,
		rounding: 0,
		code: 'CDF',
		name_plural: 'Congolese francs',
	},
	{
		symbol: 'CHF',
		name: 'Swiss Franc',
		symbol_native: 'CHF',
		decimal_digits: 2,
		rounding: 0.05,
		code: 'CHF',
		name_plural: 'Swiss francs',
	},
	{
		symbol: 'CL$',
		name: 'Chilean Peso',
		symbol_native: '$',
		decimal_digits: 0,
		rounding: 0,
		code: 'CLP',
		name_plural: 'Chilean pesos',
	},
	{
		symbol: 'CN¥',
		name: 'Chinese Yuan',
		symbol_native: 'CN¥',
		decimal_digits: 2,
		rounding: 0,
		code: 'CNY',
		name_plural: 'Chinese yuan',
	},
	{
		symbol: 'CO$',
		name: 'Colombian Peso',
		symbol_native: '$',
		decimal_digits: 0,
		rounding: 0,
		code: 'COP',
		name_plural: 'Colombian pesos',
	},
	{
		symbol: '₡',
		name: 'Costa Rican Colón',
		symbol_native: '₡',
		decimal_digits: 0,
		rounding: 0,
		code: 'CRC',
		name_plural: 'Costa Rican colóns',
	},
	{
		symbol: 'CV$',
		name: 'Cape Verdean Escudo',
		symbol_native: 'CV$',
		decimal_digits: 2,
		rounding: 0,
		code: 'CVE',
		name_plural: 'Cape Verdean escudos',
	},
	{
		symbol: 'Kč',
		name: 'Czech Republic Koruna',
		symbol_native: 'Kč',
		decimal_digits: 2,
		rounding: 0,
		code: 'CZK',
		name_plural: 'Czech Republic korunas',
	},
	{
		symbol: 'Fdj',
		name: 'Djiboutian Franc',
		symbol_native: 'Fdj',
		decimal_digits: 0,
		rounding: 0,
		code: 'DJF',
		name_plural: 'Djiboutian francs',
	},
	{
		symbol: 'Dkr',
		name: 'Danish Krone',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'DKK',
		name_plural: 'Danish kroner',
	},
	{
		symbol: 'RD$',
		name: 'Dominican Peso',
		symbol_native: 'RD$',
		decimal_digits: 2,
		rounding: 0,
		code: 'DOP',
		name_plural: 'Dominican pesos',
	},
	{
		symbol: 'DA',
		name: 'Algerian Dinar',
		symbol_native: 'د.ج.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'DZD',
		name_plural: 'Algerian dinars',
	},
	{
		symbol: 'Ekr',
		name: 'Estonian Kroon',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'EEK',
		name_plural: 'Estonian kroons',
	},
	{
		symbol: 'EGP',
		name: 'Egyptian Pound',
		symbol_native: 'ج.م.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'EGP',
		name_plural: 'Egyptian pounds',
	},
	{
		symbol: 'Nfk',
		name: 'Eritrean Nakfa',
		symbol_native: 'Nfk',
		decimal_digits: 2,
		rounding: 0,
		code: 'ERN',
		name_plural: 'Eritrean nakfas',
	},
	{
		symbol: 'Br',
		name: 'Ethiopian Birr',
		symbol_native: 'Br',
		decimal_digits: 2,
		rounding: 0,
		code: 'ETB',
		name_plural: 'Ethiopian birrs',
	},
	{
		symbol: '£',
		name: 'British Pound Sterling',
		symbol_native: '£',
		decimal_digits: 2,
		rounding: 0,
		code: 'GBP',
		name_plural: 'British pounds sterling',
	},
	{
		symbol: 'GEL',
		name: 'Georgian Lari',
		symbol_native: 'GEL',
		decimal_digits: 2,
		rounding: 0,
		code: 'GEL',
		name_plural: 'Georgian laris',
	},
	{
		symbol: 'GH₵',
		name: 'Ghanaian Cedi',
		symbol_native: 'GH₵',
		decimal_digits: 2,
		rounding: 0,
		code: 'GHS',
		name_plural: 'Ghanaian cedis',
	},
	{
		symbol: 'FG',
		name: 'Guinean Franc',
		symbol_native: 'FG',
		decimal_digits: 0,
		rounding: 0,
		code: 'GNF',
		name_plural: 'Guinean francs',
	},
	{
		symbol: 'GTQ',
		name: 'Guatemalan Quetzal',
		symbol_native: 'Q',
		decimal_digits: 2,
		rounding: 0,
		code: 'GTQ',
		name_plural: 'Guatemalan quetzals',
	},
	{
		symbol: 'HK$',
		name: 'Hong Kong Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'HKD',
		name_plural: 'Hong Kong dollars',
	},
	{
		symbol: 'HNL',
		name: 'Honduran Lempira',
		symbol_native: 'L',
		decimal_digits: 2,
		rounding: 0,
		code: 'HNL',
		name_plural: 'Honduran lempiras',
	},
	{
		symbol: 'kn',
		name: 'Croatian Kuna',
		symbol_native: 'kn',
		decimal_digits: 2,
		rounding: 0,
		code: 'HRK',
		name_plural: 'Croatian kunas',
	},
	{
		symbol: 'Ft',
		name: 'Hungarian Forint',
		symbol_native: 'Ft',
		decimal_digits: 0,
		rounding: 0,
		code: 'HUF',
		name_plural: 'Hungarian forints',
	},
	{
		symbol: 'Rp',
		name: 'Indonesian Rupiah',
		symbol_native: 'Rp',
		decimal_digits: 0,
		rounding: 0,
		code: 'IDR',
		name_plural: 'Indonesian rupiahs',
	},
	{
		symbol: '₪',
		name: 'Israeli New Sheqel',
		symbol_native: '₪',
		decimal_digits: 2,
		rounding: 0,
		code: 'ILS',
		name_plural: 'Israeli new sheqels',
	},
	{
		symbol: 'Rs',
		name: 'Indian Rupee',
		symbol_native: 'টকা',
		decimal_digits: 2,
		rounding: 0,
		code: 'INR',
		name_plural: 'Indian rupees',
	},
	{
		symbol: 'IQD',
		name: 'Iraqi Dinar',
		symbol_native: 'د.ع.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'IQD',
		name_plural: 'Iraqi dinars',
	},
	{
		symbol: 'IRR',
		name: 'Iranian Rial',
		symbol_native: '﷼',
		decimal_digits: 0,
		rounding: 0,
		code: 'IRR',
		name_plural: 'Iranian rials',
	},
	{
		symbol: 'Ikr',
		name: 'Icelandic Króna',
		symbol_native: 'kr',
		decimal_digits: 0,
		rounding: 0,
		code: 'ISK',
		name_plural: 'Icelandic krónur',
	},
	{
		symbol: 'J$',
		name: 'Jamaican Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'JMD',
		name_plural: 'Jamaican dollars',
	},
	{
		symbol: 'JD',
		name: 'Jordanian Dinar',
		symbol_native: 'د.أ.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'JOD',
		name_plural: 'Jordanian dinars',
	},
	{
		symbol: '¥',
		name: 'Japanese Yen',
		symbol_native: '￥',
		decimal_digits: 0,
		rounding: 0,
		code: 'JPY',
		name_plural: 'Japanese yen',
	},
	{
		symbol: 'Ksh',
		name: 'Kenyan Shilling',
		symbol_native: 'Ksh',
		decimal_digits: 2,
		rounding: 0,
		code: 'KES',
		name_plural: 'Kenyan shillings',
	},
	{
		symbol: 'KHR',
		name: 'Cambodian Riel',
		symbol_native: '៛',
		decimal_digits: 2,
		rounding: 0,
		code: 'KHR',
		name_plural: 'Cambodian riels',
	},
	{
		symbol: 'CF',
		name: 'Comorian Franc',
		symbol_native: 'FC',
		decimal_digits: 0,
		rounding: 0,
		code: 'KMF',
		name_plural: 'Comorian francs',
	},
	{
		symbol: '₩',
		name: 'South Korean Won',
		symbol_native: '₩',
		decimal_digits: 0,
		rounding: 0,
		code: 'KRW',
		name_plural: 'South Korean won',
	},
	{
		symbol: 'KD',
		name: 'Kuwaiti Dinar',
		symbol_native: 'د.ك.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'KWD',
		name_plural: 'Kuwaiti dinars',
	},
	{
		symbol: 'KZT',
		name: 'Kazakhstani Tenge',
		symbol_native: 'тңг.',
		decimal_digits: 2,
		rounding: 0,
		code: 'KZT',
		name_plural: 'Kazakhstani tenges',
	},
	{
		symbol: 'L.L.',
		name: 'Lebanese Pound',
		symbol_native: 'ل.ل.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'LBP',
		name_plural: 'Lebanese pounds',
	},
	{
		symbol: 'SLRs',
		name: 'Sri Lankan Rupee',
		symbol_native: 'SL Re',
		decimal_digits: 2,
		rounding: 0,
		code: 'LKR',
		name_plural: 'Sri Lankan rupees',
	},
	{
		symbol: 'Lt',
		name: 'Lithuanian Litas',
		symbol_native: 'Lt',
		decimal_digits: 2,
		rounding: 0,
		code: 'LTL',
		name_plural: 'Lithuanian litai',
	},
	{
		symbol: 'Ls',
		name: 'Latvian Lats',
		symbol_native: 'Ls',
		decimal_digits: 2,
		rounding: 0,
		code: 'LVL',
		name_plural: 'Latvian lati',
	},
	{
		symbol: 'LD',
		name: 'Libyan Dinar',
		symbol_native: 'د.ل.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'LYD',
		name_plural: 'Libyan dinars',
	},
	{
		symbol: 'MAD',
		name: 'Moroccan Dirham',
		symbol_native: 'د.م.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'MAD',
		name_plural: 'Moroccan dirhams',
	},
	{
		symbol: 'MDL',
		name: 'Moldovan Leu',
		symbol_native: 'MDL',
		decimal_digits: 2,
		rounding: 0,
		code: 'MDL',
		name_plural: 'Moldovan lei',
	},
	{
		symbol: 'MGA',
		name: 'Malagasy Ariary',
		symbol_native: 'MGA',
		decimal_digits: 0,
		rounding: 0,
		code: 'MGA',
		name_plural: 'Malagasy Ariaries',
	},
	{
		symbol: 'MKD',
		name: 'Macedonian Denar',
		symbol_native: 'MKD',
		decimal_digits: 2,
		rounding: 0,
		code: 'MKD',
		name_plural: 'Macedonian denari',
	},
	{
		symbol: 'MMK',
		name: 'Myanma Kyat',
		symbol_native: 'K',
		decimal_digits: 0,
		rounding: 0,
		code: 'MMK',
		name_plural: 'Myanma kyats',
	},
	{
		symbol: 'MOP$',
		name: 'Macanese Pataca',
		symbol_native: 'MOP$',
		decimal_digits: 2,
		rounding: 0,
		code: 'MOP',
		name_plural: 'Macanese patacas',
	},
	{
		symbol: 'MURs',
		name: 'Mauritian Rupee',
		symbol_native: 'MURs',
		decimal_digits: 0,
		rounding: 0,
		code: 'MUR',
		name_plural: 'Mauritian rupees',
	},
	{
		symbol: 'MX$',
		name: 'Mexican Peso',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'MXN',
		name_plural: 'Mexican pesos',
	},
	{
		symbol: 'RM',
		name: 'Malaysian Ringgit',
		symbol_native: 'RM',
		decimal_digits: 2,
		rounding: 0,
		code: 'MYR',
		name_plural: 'Malaysian ringgits',
	},
	{
		symbol: 'MTn',
		name: 'Mozambican Metical',
		symbol_native: 'MTn',
		decimal_digits: 2,
		rounding: 0,
		code: 'MZN',
		name_plural: 'Mozambican meticals',
	},
	{
		symbol: 'N$',
		name: 'Namibian Dollar',
		symbol_native: 'N$',
		decimal_digits: 2,
		rounding: 0,
		code: 'NAD',
		name_plural: 'Namibian dollars',
	},
	{
		symbol: '₦',
		name: 'Nigerian Naira',
		symbol_native: '₦',
		decimal_digits: 2,
		rounding: 0,
		code: 'NGN',
		name_plural: 'Nigerian nairas',
	},
	{
		symbol: 'C$',
		name: 'Nicaraguan Córdoba',
		symbol_native: 'C$',
		decimal_digits: 2,
		rounding: 0,
		code: 'NIO',
		name_plural: 'Nicaraguan córdobas',
	},
	{
		symbol: 'Nkr',
		name: 'Norwegian Krone',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'NOK',
		name_plural: 'Norwegian kroner',
	},
	{
		symbol: 'NPRs',
		name: 'Nepalese Rupee',
		symbol_native: 'नेरू',
		decimal_digits: 2,
		rounding: 0,
		code: 'NPR',
		name_plural: 'Nepalese rupees',
	},
	{
		symbol: 'NZ$',
		name: 'New Zealand Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'NZD',
		name_plural: 'New Zealand dollars',
	},
	{
		symbol: 'OMR',
		name: 'Omani Rial',
		symbol_native: 'ر.ع.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'OMR',
		name_plural: 'Omani rials',
	},
	{
		symbol: 'B/.',
		name: 'Panamanian Balboa',
		symbol_native: 'B/.',
		decimal_digits: 2,
		rounding: 0,
		code: 'PAB',
		name_plural: 'Panamanian balboas',
	},
	{
		symbol: 'S/.',
		name: 'Peruvian Nuevo Sol',
		symbol_native: 'S/.',
		decimal_digits: 2,
		rounding: 0,
		code: 'PEN',
		name_plural: 'Peruvian nuevos soles',
	},
	{
		symbol: '₱',
		name: 'Philippine Peso',
		symbol_native: '₱',
		decimal_digits: 2,
		rounding: 0,
		code: 'PHP',
		name_plural: 'Philippine pesos',
	},
	{
		symbol: 'PKRs',
		name: 'Pakistani Rupee',
		symbol_native: '₨',
		decimal_digits: 0,
		rounding: 0,
		code: 'PKR',
		name_plural: 'Pakistani rupees',
	},
	{
		symbol: 'zł',
		name: 'Polish Zloty',
		symbol_native: 'zł',
		decimal_digits: 2,
		rounding: 0,
		code: 'PLN',
		name_plural: 'Polish zlotys',
	},
	{
		symbol: '₲',
		name: 'Paraguayan Guarani',
		symbol_native: '₲',
		decimal_digits: 0,
		rounding: 0,
		code: 'PYG',
		name_plural: 'Paraguayan guaranis',
	},
	{
		symbol: 'QR',
		name: 'Qatari Rial',
		symbol_native: 'ر.ق.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'QAR',
		name_plural: 'Qatari rials',
	},
	{
		symbol: 'RON',
		name: 'Romanian Leu',
		symbol_native: 'RON',
		decimal_digits: 2,
		rounding: 0,
		code: 'RON',
		name_plural: 'Romanian lei',
	},
	{
		symbol: 'din.',
		name: 'Serbian Dinar',
		symbol_native: 'дин.',
		decimal_digits: 0,
		rounding: 0,
		code: 'RSD',
		name_plural: 'Serbian dinars',
	},
	{
		symbol: 'RUB',
		name: 'Russian Ruble',
		symbol_native: '₽.',
		decimal_digits: 2,
		rounding: 0,
		code: 'RUB',
		name_plural: 'Russian rubles',
	},
	{
		symbol: 'RWF',
		name: 'Rwandan Franc',
		symbol_native: 'FR',
		decimal_digits: 0,
		rounding: 0,
		code: 'RWF',
		name_plural: 'Rwandan francs',
	},
	{
		symbol: 'SR',
		name: 'Saudi Riyal',
		symbol_native: 'ر.س.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'SAR',
		name_plural: 'Saudi riyals',
	},
	{
		symbol: 'SDG',
		name: 'Sudanese Pound',
		symbol_native: 'SDG',
		decimal_digits: 2,
		rounding: 0,
		code: 'SDG',
		name_plural: 'Sudanese pounds',
	},
	{
		symbol: 'Skr',
		name: 'Swedish Krona',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'SEK',
		name_plural: 'Swedish kronor',
	},
	{
		symbol: 'S$',
		name: 'Singapore Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'SGD',
		name_plural: 'Singapore dollars',
	},
	{
		symbol: 'Ssh',
		name: 'Somali Shilling',
		symbol_native: 'Ssh',
		decimal_digits: 0,
		rounding: 0,
		code: 'SOS',
		name_plural: 'Somali shillings',
	},
	{
		symbol: 'SY£',
		name: 'Syrian Pound',
		symbol_native: 'ل.س.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'SYP',
		name_plural: 'Syrian pounds',
	},
	{
		symbol: '฿',
		name: 'Thai Baht',
		symbol_native: '฿',
		decimal_digits: 2,
		rounding: 0,
		code: 'THB',
		name_plural: 'Thai baht',
	},
	{
		symbol: 'DT',
		name: 'Tunisian Dinar',
		symbol_native: 'د.ت.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'TND',
		name_plural: 'Tunisian dinars',
	},
	{
		symbol: 'T$',
		name: 'Tongan Paʻanga',
		symbol_native: 'T$',
		decimal_digits: 2,
		rounding: 0,
		code: 'TOP',
		name_plural: 'Tongan paʻanga',
	},
	{
		symbol: 'TL',
		name: 'Turkish Lira',
		symbol_native: 'TL',
		decimal_digits: 2,
		rounding: 0,
		code: 'TRY',
		name_plural: 'Turkish Lira',
	},
	{
		symbol: 'TT$',
		name: 'Trinidad and Tobago Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'TTD',
		name_plural: 'Trinidad and Tobago dollars',
	},
	{
		symbol: 'NT$',
		name: 'New Taiwan Dollar',
		symbol_native: 'NT$',
		decimal_digits: 2,
		rounding: 0,
		code: 'TWD',
		name_plural: 'New Taiwan dollars',
	},
	{
		symbol: 'TSh',
		name: 'Tanzanian Shilling',
		symbol_native: 'TSh',
		decimal_digits: 0,
		rounding: 0,
		code: 'TZS',
		name_plural: 'Tanzanian shillings',
	},
	{
		symbol: '₴',
		name: 'Ukrainian Hryvnia',
		symbol_native: '₴',
		decimal_digits: 2,
		rounding: 0,
		code: 'UAH',
		name_plural: 'Ukrainian hryvnias',
	},
	{
		symbol: 'USh',
		name: 'Ugandan Shilling',
		symbol_native: 'USh',
		decimal_digits: 0,
		rounding: 0,
		code: 'UGX',
		name_plural: 'Ugandan shillings',
	},
	{
		symbol: '$U',
		name: 'Uruguayan Peso',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'UYU',
		name_plural: 'Uruguayan pesos',
	},
	{
		symbol: 'UZS',
		name: 'Uzbekistan Som',
		symbol_native: 'UZS',
		decimal_digits: 0,
		rounding: 0,
		code: 'UZS',
		name_plural: 'Uzbekistan som',
	},
	{
		symbol: 'Bs.F.',
		name: 'Venezuelan Bolívar',
		symbol_native: 'Bs.F.',
		decimal_digits: 2,
		rounding: 0,
		code: 'VEF',
		name_plural: 'Venezuelan bolívars',
	},
	{
		symbol: '₫',
		name: 'Vietnamese Dong',
		symbol_native: '₫',
		decimal_digits: 0,
		rounding: 0,
		code: 'VND',
		name_plural: 'Vietnamese dong',
	},
	{
		symbol: 'FCFA',
		name: 'CFA Franc BEAC',
		symbol_native: 'FCFA',
		decimal_digits: 0,
		rounding: 0,
		code: 'XAF',
		name_plural: 'CFA francs BEAC',
	},
	{
		symbol: 'CFA',
		name: 'CFA Franc BCEAO',
		symbol_native: 'CFA',
		decimal_digits: 0,
		rounding: 0,
		code: 'XOF',
		name_plural: 'CFA francs BCEAO',
	},
	{
		symbol: 'YR',
		name: 'Yemeni Rial',
		symbol_native: 'ر.ي.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'YER',
		name_plural: 'Yemeni rials',
	},
	{
		symbol: 'R',
		name: 'South African Rand',
		symbol_native: 'R',
		decimal_digits: 2,
		rounding: 0,
		code: 'ZAR',
		name_plural: 'South African rand',
	},
	{
		symbol: 'ZK',
		name: 'Zambian Kwacha',
		symbol_native: 'ZK',
		decimal_digits: 0,
		rounding: 0,
		code: 'ZMK',
		name_plural: 'Zambian kwachas',
	},
	{
		symbol: 'ZWL$',
		name: 'Zimbabwean Dollar',
		symbol_native: 'ZWL$',
		decimal_digits: 0,
		rounding: 0,
		code: 'ZWL',
		name_plural: 'Zimbabwean Dollar',
	},
];

export const currenciesObject = {
	USD: {
		symbol: '$',
		name: 'US Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'USD',
		name_plural: 'US dollars',
	},
	CAD: {
		symbol: 'CA$',
		name: 'Canadian Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'CAD',
		name_plural: 'Canadian dollars',
	},
	EUR: {
		symbol: '€',
		name: 'Euro',
		symbol_native: '€',
		decimal_digits: 2,
		rounding: 0,
		code: 'EUR',
		name_plural: 'euros',
	},
	AED: {
		symbol: 'AED',
		name: 'United Arab Emirates Dirham',
		symbol_native: 'د.إ.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'AED',
		name_plural: 'UAE dirhams',
	},
	AFN: {
		symbol: 'Af',
		name: 'Afghan Afghani',
		symbol_native: '؋',
		decimal_digits: 0,
		rounding: 0,
		code: 'AFN',
		name_plural: 'Afghan Afghanis',
	},
	ALL: {
		symbol: 'ALL',
		name: 'Albanian Lek',
		symbol_native: 'Lek',
		decimal_digits: 0,
		rounding: 0,
		code: 'ALL',
		name_plural: 'Albanian lekë',
	},
	AMD: {
		symbol: 'AMD',
		name: 'Armenian Dram',
		symbol_native: 'դր.',
		decimal_digits: 0,
		rounding: 0,
		code: 'AMD',
		name_plural: 'Armenian drams',
	},
	ARS: {
		symbol: 'AR$',
		name: 'Argentine Peso',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'ARS',
		name_plural: 'Argentine pesos',
	},
	AUD: {
		symbol: 'AU$',
		name: 'Australian Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'AUD',
		name_plural: 'Australian dollars',
	},
	AZN: {
		symbol: 'man.',
		name: 'Azerbaijani Manat',
		symbol_native: 'ман.',
		decimal_digits: 2,
		rounding: 0,
		code: 'AZN',
		name_plural: 'Azerbaijani manats',
	},
	BAM: {
		symbol: 'KM',
		name: 'Bosnia-Herzegovina Convertible Mark',
		symbol_native: 'KM',
		decimal_digits: 2,
		rounding: 0,
		code: 'BAM',
		name_plural: 'Bosnia-Herzegovina convertible marks',
	},
	BDT: {
		symbol: 'Tk',
		name: 'Bangladeshi Taka',
		symbol_native: '৳',
		decimal_digits: 2,
		rounding: 0,
		code: 'BDT',
		name_plural: 'Bangladeshi takas',
	},
	BGN: {
		symbol: 'BGN',
		name: 'Bulgarian Lev',
		symbol_native: 'лв.',
		decimal_digits: 2,
		rounding: 0,
		code: 'BGN',
		name_plural: 'Bulgarian leva',
	},
	BHD: {
		symbol: 'BD',
		name: 'Bahraini Dinar',
		symbol_native: 'د.ب.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'BHD',
		name_plural: 'Bahraini dinars',
	},
	BIF: {
		symbol: 'FBu',
		name: 'Burundian Franc',
		symbol_native: 'FBu',
		decimal_digits: 0,
		rounding: 0,
		code: 'BIF',
		name_plural: 'Burundian francs',
	},
	BND: {
		symbol: 'BN$',
		name: 'Brunei Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'BND',
		name_plural: 'Brunei dollars',
	},
	BOB: {
		symbol: 'Bs',
		name: 'Bolivian Boliviano',
		symbol_native: 'Bs',
		decimal_digits: 2,
		rounding: 0,
		code: 'BOB',
		name_plural: 'Bolivian bolivianos',
	},
	BRL: {
		symbol: 'R$',
		name: 'Brazilian Real',
		symbol_native: 'R$',
		decimal_digits: 2,
		rounding: 0,
		code: 'BRL',
		name_plural: 'Brazilian reals',
	},
	BWP: {
		symbol: 'BWP',
		name: 'Botswanan Pula',
		symbol_native: 'P',
		decimal_digits: 2,
		rounding: 0,
		code: 'BWP',
		name_plural: 'Botswanan pulas',
	},
	BYN: {
		symbol: 'Br',
		name: 'Belarusian Ruble',
		symbol_native: 'руб.',
		decimal_digits: 2,
		rounding: 0,
		code: 'BYN',
		name_plural: 'Belarusian rubles',
	},
	BZD: {
		symbol: 'BZ$',
		name: 'Belize Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'BZD',
		name_plural: 'Belize dollars',
	},
	CDF: {
		symbol: 'CDF',
		name: 'Congolese Franc',
		symbol_native: 'FrCD',
		decimal_digits: 2,
		rounding: 0,
		code: 'CDF',
		name_plural: 'Congolese francs',
	},
	CHF: {
		symbol: 'CHF',
		name: 'Swiss Franc',
		symbol_native: 'CHF',
		decimal_digits: 2,
		rounding: 0.05,
		code: 'CHF',
		name_plural: 'Swiss francs',
	},
	CLP: {
		symbol: 'CL$',
		name: 'Chilean Peso',
		symbol_native: '$',
		decimal_digits: 0,
		rounding: 0,
		code: 'CLP',
		name_plural: 'Chilean pesos',
	},
	CNY: {
		symbol: 'CN¥',
		name: 'Chinese Yuan',
		symbol_native: 'CN¥',
		decimal_digits: 2,
		rounding: 0,
		code: 'CNY',
		name_plural: 'Chinese yuan',
	},
	COP: {
		symbol: 'CO$',
		name: 'Colombian Peso',
		symbol_native: '$',
		decimal_digits: 0,
		rounding: 0,
		code: 'COP',
		name_plural: 'Colombian pesos',
	},
	CRC: {
		symbol: '₡',
		name: 'Costa Rican Colón',
		symbol_native: '₡',
		decimal_digits: 0,
		rounding: 0,
		code: 'CRC',
		name_plural: 'Costa Rican colóns',
	},
	CVE: {
		symbol: 'CV$',
		name: 'Cape Verdean Escudo',
		symbol_native: 'CV$',
		decimal_digits: 2,
		rounding: 0,
		code: 'CVE',
		name_plural: 'Cape Verdean escudos',
	},
	CZK: {
		symbol: 'Kč',
		name: 'Czech Republic Koruna',
		symbol_native: 'Kč',
		decimal_digits: 2,
		rounding: 0,
		code: 'CZK',
		name_plural: 'Czech Republic korunas',
	},
	DJF: {
		symbol: 'Fdj',
		name: 'Djiboutian Franc',
		symbol_native: 'Fdj',
		decimal_digits: 0,
		rounding: 0,
		code: 'DJF',
		name_plural: 'Djiboutian francs',
	},
	DKK: {
		symbol: 'Dkr',
		name: 'Danish Krone',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'DKK',
		name_plural: 'Danish kroner',
	},
	DOP: {
		symbol: 'RD$',
		name: 'Dominican Peso',
		symbol_native: 'RD$',
		decimal_digits: 2,
		rounding: 0,
		code: 'DOP',
		name_plural: 'Dominican pesos',
	},
	DZD: {
		symbol: 'DA',
		name: 'Algerian Dinar',
		symbol_native: 'د.ج.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'DZD',
		name_plural: 'Algerian dinars',
	},
	EEK: {
		symbol: 'Ekr',
		name: 'Estonian Kroon',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'EEK',
		name_plural: 'Estonian kroons',
	},
	EGP: {
		symbol: 'EGP',
		name: 'Egyptian Pound',
		symbol_native: 'ج.م.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'EGP',
		name_plural: 'Egyptian pounds',
	},
	ERN: {
		symbol: 'Nfk',
		name: 'Eritrean Nakfa',
		symbol_native: 'Nfk',
		decimal_digits: 2,
		rounding: 0,
		code: 'ERN',
		name_plural: 'Eritrean nakfas',
	},
	ETB: {
		symbol: 'Br',
		name: 'Ethiopian Birr',
		symbol_native: 'Br',
		decimal_digits: 2,
		rounding: 0,
		code: 'ETB',
		name_plural: 'Ethiopian birrs',
	},
	GBP: {
		symbol: '£',
		name: 'British Pound Sterling',
		symbol_native: '£',
		decimal_digits: 2,
		rounding: 0,
		code: 'GBP',
		name_plural: 'British pounds sterling',
	},
	GEL: {
		symbol: 'GEL',
		name: 'Georgian Lari',
		symbol_native: 'GEL',
		decimal_digits: 2,
		rounding: 0,
		code: 'GEL',
		name_plural: 'Georgian laris',
	},
	GHS: {
		symbol: 'GH₵',
		name: 'Ghanaian Cedi',
		symbol_native: 'GH₵',
		decimal_digits: 2,
		rounding: 0,
		code: 'GHS',
		name_plural: 'Ghanaian cedis',
	},
	GNF: {
		symbol: 'FG',
		name: 'Guinean Franc',
		symbol_native: 'FG',
		decimal_digits: 0,
		rounding: 0,
		code: 'GNF',
		name_plural: 'Guinean francs',
	},
	GTQ: {
		symbol: 'GTQ',
		name: 'Guatemalan Quetzal',
		symbol_native: 'Q',
		decimal_digits: 2,
		rounding: 0,
		code: 'GTQ',
		name_plural: 'Guatemalan quetzals',
	},
	HKD: {
		symbol: 'HK$',
		name: 'Hong Kong Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'HKD',
		name_plural: 'Hong Kong dollars',
	},
	HNL: {
		symbol: 'HNL',
		name: 'Honduran Lempira',
		symbol_native: 'L',
		decimal_digits: 2,
		rounding: 0,
		code: 'HNL',
		name_plural: 'Honduran lempiras',
	},
	HRK: {
		symbol: 'kn',
		name: 'Croatian Kuna',
		symbol_native: 'kn',
		decimal_digits: 2,
		rounding: 0,
		code: 'HRK',
		name_plural: 'Croatian kunas',
	},
	HUF: {
		symbol: 'Ft',
		name: 'Hungarian Forint',
		symbol_native: 'Ft',
		decimal_digits: 0,
		rounding: 0,
		code: 'HUF',
		name_plural: 'Hungarian forints',
	},
	IDR: {
		symbol: 'Rp',
		name: 'Indonesian Rupiah',
		symbol_native: 'Rp',
		decimal_digits: 0,
		rounding: 0,
		code: 'IDR',
		name_plural: 'Indonesian rupiahs',
	},
	ILS: {
		symbol: '₪',
		name: 'Israeli New Sheqel',
		symbol_native: '₪',
		decimal_digits: 2,
		rounding: 0,
		code: 'ILS',
		name_plural: 'Israeli new sheqels',
	},
	INR: {
		symbol: 'Rs',
		name: 'Indian Rupee',
		symbol_native: 'টকা',
		decimal_digits: 2,
		rounding: 0,
		code: 'INR',
		name_plural: 'Indian rupees',
	},
	IQD: {
		symbol: 'IQD',
		name: 'Iraqi Dinar',
		symbol_native: 'د.ع.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'IQD',
		name_plural: 'Iraqi dinars',
	},
	IRR: {
		symbol: 'IRR',
		name: 'Iranian Rial',
		symbol_native: '﷼',
		decimal_digits: 0,
		rounding: 0,
		code: 'IRR',
		name_plural: 'Iranian rials',
	},
	ISK: {
		symbol: 'Ikr',
		name: 'Icelandic Króna',
		symbol_native: 'kr',
		decimal_digits: 0,
		rounding: 0,
		code: 'ISK',
		name_plural: 'Icelandic krónur',
	},
	JMD: {
		symbol: 'J$',
		name: 'Jamaican Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'JMD',
		name_plural: 'Jamaican dollars',
	},
	JOD: {
		symbol: 'JD',
		name: 'Jordanian Dinar',
		symbol_native: 'د.أ.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'JOD',
		name_plural: 'Jordanian dinars',
	},
	JPY: {
		symbol: '¥',
		name: 'Japanese Yen',
		symbol_native: '￥',
		decimal_digits: 0,
		rounding: 0,
		code: 'JPY',
		name_plural: 'Japanese yen',
	},
	KES: {
		symbol: 'Ksh',
		name: 'Kenyan Shilling',
		symbol_native: 'Ksh',
		decimal_digits: 2,
		rounding: 0,
		code: 'KES',
		name_plural: 'Kenyan shillings',
	},
	KHR: {
		symbol: 'KHR',
		name: 'Cambodian Riel',
		symbol_native: '៛',
		decimal_digits: 2,
		rounding: 0,
		code: 'KHR',
		name_plural: 'Cambodian riels',
	},
	KMF: {
		symbol: 'CF',
		name: 'Comorian Franc',
		symbol_native: 'FC',
		decimal_digits: 0,
		rounding: 0,
		code: 'KMF',
		name_plural: 'Comorian francs',
	},
	KRW: {
		symbol: '₩',
		name: 'South Korean Won',
		symbol_native: '₩',
		decimal_digits: 0,
		rounding: 0,
		code: 'KRW',
		name_plural: 'South Korean won',
	},
	KWD: {
		symbol: 'KD',
		name: 'Kuwaiti Dinar',
		symbol_native: 'د.ك.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'KWD',
		name_plural: 'Kuwaiti dinars',
	},
	KZT: {
		symbol: 'KZT',
		name: 'Kazakhstani Tenge',
		symbol_native: 'тңг.',
		decimal_digits: 2,
		rounding: 0,
		code: 'KZT',
		name_plural: 'Kazakhstani tenges',
	},
	LBP: {
		symbol: 'L.L.',
		name: 'Lebanese Pound',
		symbol_native: 'ل.ل.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'LBP',
		name_plural: 'Lebanese pounds',
	},
	LKR: {
		symbol: 'SLRs',
		name: 'Sri Lankan Rupee',
		symbol_native: 'SL Re',
		decimal_digits: 2,
		rounding: 0,
		code: 'LKR',
		name_plural: 'Sri Lankan rupees',
	},
	LTL: {
		symbol: 'Lt',
		name: 'Lithuanian Litas',
		symbol_native: 'Lt',
		decimal_digits: 2,
		rounding: 0,
		code: 'LTL',
		name_plural: 'Lithuanian litai',
	},
	LVL: {
		symbol: 'Ls',
		name: 'Latvian Lats',
		symbol_native: 'Ls',
		decimal_digits: 2,
		rounding: 0,
		code: 'LVL',
		name_plural: 'Latvian lati',
	},
	LYD: {
		symbol: 'LD',
		name: 'Libyan Dinar',
		symbol_native: 'د.ل.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'LYD',
		name_plural: 'Libyan dinars',
	},
	MAD: {
		symbol: 'MAD',
		name: 'Moroccan Dirham',
		symbol_native: 'د.م.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'MAD',
		name_plural: 'Moroccan dirhams',
	},
	MDL: {
		symbol: 'MDL',
		name: 'Moldovan Leu',
		symbol_native: 'MDL',
		decimal_digits: 2,
		rounding: 0,
		code: 'MDL',
		name_plural: 'Moldovan lei',
	},
	MGA: {
		symbol: 'MGA',
		name: 'Malagasy Ariary',
		symbol_native: 'MGA',
		decimal_digits: 0,
		rounding: 0,
		code: 'MGA',
		name_plural: 'Malagasy Ariaries',
	},
	MKD: {
		symbol: 'MKD',
		name: 'Macedonian Denar',
		symbol_native: 'MKD',
		decimal_digits: 2,
		rounding: 0,
		code: 'MKD',
		name_plural: 'Macedonian denari',
	},
	MMK: {
		symbol: 'MMK',
		name: 'Myanma Kyat',
		symbol_native: 'K',
		decimal_digits: 0,
		rounding: 0,
		code: 'MMK',
		name_plural: 'Myanma kyats',
	},
	MOP: {
		symbol: 'MOP$',
		name: 'Macanese Pataca',
		symbol_native: 'MOP$',
		decimal_digits: 2,
		rounding: 0,
		code: 'MOP',
		name_plural: 'Macanese patacas',
	},
	MUR: {
		symbol: 'MURs',
		name: 'Mauritian Rupee',
		symbol_native: 'MURs',
		decimal_digits: 0,
		rounding: 0,
		code: 'MUR',
		name_plural: 'Mauritian rupees',
	},
	MXN: {
		symbol: 'MX$',
		name: 'Mexican Peso',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'MXN',
		name_plural: 'Mexican pesos',
	},
	MYR: {
		symbol: 'RM',
		name: 'Malaysian Ringgit',
		symbol_native: 'RM',
		decimal_digits: 2,
		rounding: 0,
		code: 'MYR',
		name_plural: 'Malaysian ringgits',
	},
	MZN: {
		symbol: 'MTn',
		name: 'Mozambican Metical',
		symbol_native: 'MTn',
		decimal_digits: 2,
		rounding: 0,
		code: 'MZN',
		name_plural: 'Mozambican meticals',
	},
	NAD: {
		symbol: 'N$',
		name: 'Namibian Dollar',
		symbol_native: 'N$',
		decimal_digits: 2,
		rounding: 0,
		code: 'NAD',
		name_plural: 'Namibian dollars',
	},
	NGN: {
		symbol: '₦',
		name: 'Nigerian Naira',
		symbol_native: '₦',
		decimal_digits: 2,
		rounding: 0,
		code: 'NGN',
		name_plural: 'Nigerian nairas',
	},
	NIO: {
		symbol: 'C$',
		name: 'Nicaraguan Córdoba',
		symbol_native: 'C$',
		decimal_digits: 2,
		rounding: 0,
		code: 'NIO',
		name_plural: 'Nicaraguan córdobas',
	},
	NOK: {
		symbol: 'Nkr',
		name: 'Norwegian Krone',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'NOK',
		name_plural: 'Norwegian kroner',
	},
	NPR: {
		symbol: 'NPRs',
		name: 'Nepalese Rupee',
		symbol_native: 'नेरू',
		decimal_digits: 2,
		rounding: 0,
		code: 'NPR',
		name_plural: 'Nepalese rupees',
	},
	NZD: {
		symbol: 'NZ$',
		name: 'New Zealand Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'NZD',
		name_plural: 'New Zealand dollars',
	},
	OMR: {
		symbol: 'OMR',
		name: 'Omani Rial',
		symbol_native: 'ر.ع.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'OMR',
		name_plural: 'Omani rials',
	},
	PAB: {
		symbol: 'B/.',
		name: 'Panamanian Balboa',
		symbol_native: 'B/.',
		decimal_digits: 2,
		rounding: 0,
		code: 'PAB',
		name_plural: 'Panamanian balboas',
	},
	PEN: {
		symbol: 'S/.',
		name: 'Peruvian Nuevo Sol',
		symbol_native: 'S/.',
		decimal_digits: 2,
		rounding: 0,
		code: 'PEN',
		name_plural: 'Peruvian nuevos soles',
	},
	PHP: {
		symbol: '₱',
		name: 'Philippine Peso',
		symbol_native: '₱',
		decimal_digits: 2,
		rounding: 0,
		code: 'PHP',
		name_plural: 'Philippine pesos',
	},
	PKR: {
		symbol: 'PKRs',
		name: 'Pakistani Rupee',
		symbol_native: '₨',
		decimal_digits: 0,
		rounding: 0,
		code: 'PKR',
		name_plural: 'Pakistani rupees',
	},
	PLN: {
		symbol: 'zł',
		name: 'Polish Zloty',
		symbol_native: 'zł',
		decimal_digits: 2,
		rounding: 0,
		code: 'PLN',
		name_plural: 'Polish zlotys',
	},
	PYG: {
		symbol: '₲',
		name: 'Paraguayan Guarani',
		symbol_native: '₲',
		decimal_digits: 0,
		rounding: 0,
		code: 'PYG',
		name_plural: 'Paraguayan guaranis',
	},
	QAR: {
		symbol: 'QR',
		name: 'Qatari Rial',
		symbol_native: 'ر.ق.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'QAR',
		name_plural: 'Qatari rials',
	},
	RON: {
		symbol: 'RON',
		name: 'Romanian Leu',
		symbol_native: 'RON',
		decimal_digits: 2,
		rounding: 0,
		code: 'RON',
		name_plural: 'Romanian lei',
	},
	RSD: {
		symbol: 'din.',
		name: 'Serbian Dinar',
		symbol_native: 'дин.',
		decimal_digits: 0,
		rounding: 0,
		code: 'RSD',
		name_plural: 'Serbian dinars',
	},
	RUB: {
		symbol: 'RUB',
		name: 'Russian Ruble',
		symbol_native: '₽.',
		decimal_digits: 2,
		rounding: 0,
		code: 'RUB',
		name_plural: 'Russian rubles',
	},
	RWF: {
		symbol: 'RWF',
		name: 'Rwandan Franc',
		symbol_native: 'FR',
		decimal_digits: 0,
		rounding: 0,
		code: 'RWF',
		name_plural: 'Rwandan francs',
	},
	SAR: {
		symbol: 'SR',
		name: 'Saudi Riyal',
		symbol_native: 'ر.س.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'SAR',
		name_plural: 'Saudi riyals',
	},
	SDG: {
		symbol: 'SDG',
		name: 'Sudanese Pound',
		symbol_native: 'SDG',
		decimal_digits: 2,
		rounding: 0,
		code: 'SDG',
		name_plural: 'Sudanese pounds',
	},
	SEK: {
		symbol: 'Skr',
		name: 'Swedish Krona',
		symbol_native: 'kr',
		decimal_digits: 2,
		rounding: 0,
		code: 'SEK',
		name_plural: 'Swedish kronor',
	},
	SGD: {
		symbol: 'S$',
		name: 'Singapore Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'SGD',
		name_plural: 'Singapore dollars',
	},
	SOS: {
		symbol: 'Ssh',
		name: 'Somali Shilling',
		symbol_native: 'Ssh',
		decimal_digits: 0,
		rounding: 0,
		code: 'SOS',
		name_plural: 'Somali shillings',
	},
	SYP: {
		symbol: 'SY£',
		name: 'Syrian Pound',
		symbol_native: 'ل.س.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'SYP',
		name_plural: 'Syrian pounds',
	},
	THB: {
		symbol: '฿',
		name: 'Thai Baht',
		symbol_native: '฿',
		decimal_digits: 2,
		rounding: 0,
		code: 'THB',
		name_plural: 'Thai baht',
	},
	TND: {
		symbol: 'DT',
		name: 'Tunisian Dinar',
		symbol_native: 'د.ت.‏',
		decimal_digits: 3,
		rounding: 0,
		code: 'TND',
		name_plural: 'Tunisian dinars',
	},
	TOP: {
		symbol: 'T$',
		name: 'Tongan Paʻanga',
		symbol_native: 'T$',
		decimal_digits: 2,
		rounding: 0,
		code: 'TOP',
		name_plural: 'Tongan paʻanga',
	},
	TRY: {
		symbol: 'TL',
		name: 'Turkish Lira',
		symbol_native: 'TL',
		decimal_digits: 2,
		rounding: 0,
		code: 'TRY',
		name_plural: 'Turkish Lira',
	},
	TTD: {
		symbol: 'TT$',
		name: 'Trinidad and Tobago Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'TTD',
		name_plural: 'Trinidad and Tobago dollars',
	},
	TWD: {
		symbol: 'NT$',
		name: 'New Taiwan Dollar',
		symbol_native: 'NT$',
		decimal_digits: 2,
		rounding: 0,
		code: 'TWD',
		name_plural: 'New Taiwan dollars',
	},
	TZS: {
		symbol: 'TSh',
		name: 'Tanzanian Shilling',
		symbol_native: 'TSh',
		decimal_digits: 0,
		rounding: 0,
		code: 'TZS',
		name_plural: 'Tanzanian shillings',
	},
	UAH: {
		symbol: '₴',
		name: 'Ukrainian Hryvnia',
		symbol_native: '₴',
		decimal_digits: 2,
		rounding: 0,
		code: 'UAH',
		name_plural: 'Ukrainian hryvnias',
	},
	UGX: {
		symbol: 'USh',
		name: 'Ugandan Shilling',
		symbol_native: 'USh',
		decimal_digits: 0,
		rounding: 0,
		code: 'UGX',
		name_plural: 'Ugandan shillings',
	},
	UYU: {
		symbol: '$U',
		name: 'Uruguayan Peso',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'UYU',
		name_plural: 'Uruguayan pesos',
	},
	UZS: {
		symbol: 'UZS',
		name: 'Uzbekistan Som',
		symbol_native: 'UZS',
		decimal_digits: 0,
		rounding: 0,
		code: 'UZS',
		name_plural: 'Uzbekistan som',
	},
	VEF: {
		symbol: 'Bs.F.',
		name: 'Venezuelan Bolívar',
		symbol_native: 'Bs.F.',
		decimal_digits: 2,
		rounding: 0,
		code: 'VEF',
		name_plural: 'Venezuelan bolívars',
	},
	VND: {
		symbol: '₫',
		name: 'Vietnamese Dong',
		symbol_native: '₫',
		decimal_digits: 0,
		rounding: 0,
		code: 'VND',
		name_plural: 'Vietnamese dong',
	},
	XAF: {
		symbol: 'FCFA',
		name: 'CFA Franc BEAC',
		symbol_native: 'FCFA',
		decimal_digits: 0,
		rounding: 0,
		code: 'XAF',
		name_plural: 'CFA francs BEAC',
	},
	XOF: {
		symbol: 'CFA',
		name: 'CFA Franc BCEAO',
		symbol_native: 'CFA',
		decimal_digits: 0,
		rounding: 0,
		code: 'XOF',
		name_plural: 'CFA francs BCEAO',
	},
	YER: {
		symbol: 'YR',
		name: 'Yemeni Rial',
		symbol_native: 'ر.ي.‏',
		decimal_digits: 0,
		rounding: 0,
		code: 'YER',
		name_plural: 'Yemeni rials',
	},
	ZAR: {
		symbol: 'R',
		name: 'South African Rand',
		symbol_native: 'R',
		decimal_digits: 2,
		rounding: 0,
		code: 'ZAR',
		name_plural: 'South African rand',
	},
	ZMK: {
		symbol: 'ZK',
		name: 'Zambian Kwacha',
		symbol_native: 'ZK',
		decimal_digits: 0,
		rounding: 0,
		code: 'ZMK',
		name_plural: 'Zambian kwachas',
	},
	ZWL: {
		symbol: 'ZWL$',
		name: 'Zimbabwean Dollar',
		symbol_native: 'ZWL$',
		decimal_digits: 0,
		rounding: 0,
		code: 'ZWL',
		name_plural: 'Zimbabwean Dollar',
	},
};

export const timezones = [
	{
		_id: 1,
		value: 'Dateline Standard Time',
		abbr: 'DST',
		offset: -12,
		isdst: false,
		text: '(UTC-12:00) International Date Line West',
		utc: ['Etc/GMT+12'],
	},
	{
		value: 'UTC-11',
		abbr: 'U',
		offset: -11,
		isdst: false,
		text: '(UTC-11:00) Coordinated Universal Time-11',
		utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago'],
	},
	{
		value: 'Hawaiian Standard Time',
		abbr: 'HST',
		offset: -10,
		isdst: false,
		text: '(UTC-10:00) Hawaii',
		utc: [
			'Etc/GMT+10',
			'Pacific/Honolulu',
			'Pacific/Johnston',
			'Pacific/Rarotonga',
			'Pacific/Tahiti',
		],
	},
	{
		value: 'Alaskan Standard Time',
		abbr: 'AKDT',
		offset: -8,
		isdst: true,
		text: '(UTC-09:00) Alaska',
		utc: [
			'America/Anchorage',
			'America/Juneau',
			'America/Nome',
			'America/Sitka',
			'America/Yakutat',
		],
	},
	{
		value: 'Pacific Standard Time (Mexico)',
		abbr: 'PDT',
		offset: -7,
		isdst: true,
		text: '(UTC-08:00) Baja California',
		utc: ['America/Santa_Isabel'],
	},
	{
		value: 'Pacific Daylight Time',
		abbr: 'PDT',
		offset: -7,
		isdst: true,
		text: '(UTC-07:00) Pacific Time (US & Canada)',
		utc: ['America/Los_Angeles', 'America/Tijuana', 'America/Vancouver'],
	},
	{
		value: 'Pacific Standard Time',
		abbr: 'PST',
		offset: -8,
		isdst: false,
		text: '(UTC-08:00) Pacific Time (US & Canada)',
		utc: [
			'America/Los_Angeles',
			'America/Tijuana',
			'America/Vancouver',
			'PST8PDT',
		],
	},
	{
		value: 'US Mountain Standard Time',
		abbr: 'UMST',
		offset: -7,
		isdst: false,
		text: '(UTC-07:00) Arizona',
		utc: [
			'America/Creston',
			'America/Dawson',
			'America/Dawson_Creek',
			'America/Hermosillo',
			'America/Phoenix',
			'America/Whitehorse',
			'Etc/GMT+7',
		],
	},
	{
		value: 'Mountain Standard Time (Mexico)',
		abbr: 'MDT',
		offset: -6,
		isdst: true,
		text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
		utc: ['America/Chihuahua', 'America/Mazatlan'],
	},
	{
		value: 'Mountain Standard Time',
		abbr: 'MDT',
		offset: -6,
		isdst: true,
		text: '(UTC-07:00) Mountain Time (US & Canada)',
		utc: [
			'America/Boise',
			'America/Cambridge_Bay',
			'America/Denver',
			'America/Edmonton',
			'America/Inuvik',
			'America/Ojinaga',
			'America/Yellowknife',
			'MST7MDT',
		],
	},
	{
		value: 'Central America Standard Time',
		abbr: 'CAST',
		offset: -6,
		isdst: false,
		text: '(UTC-06:00) Central America',
		utc: [
			'America/Belize',
			'America/Costa_Rica',
			'America/El_Salvador',
			'America/Guatemala',
			'America/Managua',
			'America/Tegucigalpa',
			'Etc/GMT+6',
			'Pacific/Galapagos',
		],
	},
	{
		value: 'Central Standard Time',
		abbr: 'CDT',
		offset: -5,
		isdst: true,
		text: '(UTC-06:00) Central Time (US & Canada)',
		utc: [
			'America/Chicago',
			'America/Indiana/Knox',
			'America/Indiana/Tell_City',
			'America/Matamoros',
			'America/Menominee',
			'America/North_Dakota/Beulah',
			'America/North_Dakota/Center',
			'America/North_Dakota/New_Salem',
			'America/Rainy_River',
			'America/Rankin_Inlet',
			'America/Resolute',
			'America/Winnipeg',
			'CST6CDT',
		],
	},
	{
		value: 'Central Standard Time (Mexico)',
		abbr: 'CDT',
		offset: -5,
		isdst: true,
		text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
		utc: [
			'America/Bahia_Banderas',
			'America/Cancun',
			'America/Merida',
			'America/Mexico_City',
			'America/Monterrey',
		],
	},
	{
		value: 'Canada Central Standard Time',
		abbr: 'CCST',
		offset: -6,
		isdst: false,
		text: '(UTC-06:00) Saskatchewan',
		utc: ['America/Regina', 'America/Swift_Current'],
	},
	{
		value: 'SA Pacific Standard Time',
		abbr: 'SPST',
		offset: -5,
		isdst: false,
		text: '(UTC-05:00) Bogota, Lima, Quito',
		utc: [
			'America/Bogota',
			'America/Cayman',
			'America/Coral_Harbour',
			'America/Eirunepe',
			'America/Guayaquil',
			'America/Jamaica',
			'America/Lima',
			'America/Panama',
			'America/Rio_Branco',
			'Etc/GMT+5',
		],
	},
	{
		value: 'Eastern Standard Time',
		abbr: 'EDT',
		offset: -4,
		isdst: true,
		text: '(UTC-05:00) Eastern Time (US & Canada)',
		utc: [
			'America/Detroit',
			'America/Havana',
			'America/Indiana/Petersburg',
			'America/Indiana/Vincennes',
			'America/Indiana/Winamac',
			'America/Iqaluit',
			'America/Kentucky/Monticello',
			'America/Louisville',
			'America/Montreal',
			'America/Nassau',
			'America/New_York',
			'America/Nipigon',
			'America/Pangnirtung',
			'America/Port-au-Prince',
			'America/Thunder_Bay',
			'America/Toronto',
			'EST5EDT',
		],
	},
	{
		value: 'US Eastern Standard Time',
		abbr: 'UEDT',
		offset: -4,
		isdst: true,
		text: '(UTC-05:00) Indiana (East)',
		utc: [
			'America/Indiana/Marengo',
			'America/Indiana/Vevay',
			'America/Indianapolis',
		],
	},
	{
		value: 'Venezuela Standard Time',
		abbr: 'VST',
		offset: -4.5,
		isdst: false,
		text: '(UTC-04:30) Caracas',
		utc: ['America/Caracas'],
	},
	{
		value: 'Paraguay Standard Time',
		abbr: 'PYT',
		offset: -4,
		isdst: false,
		text: '(UTC-04:00) Asuncion',
		utc: ['America/Asuncion'],
	},
	{
		value: 'Atlantic Standard Time',
		abbr: 'ADT',
		offset: -3,
		isdst: true,
		text: '(UTC-04:00) Atlantic Time (Canada)',
		utc: [
			'America/Glace_Bay',
			'America/Goose_Bay',
			'America/Halifax',
			'America/Moncton',
			'America/Thule',
			'Atlantic/Bermuda',
		],
	},
	{
		value: 'Central Brazilian Standard Time',
		abbr: 'CBST',
		offset: -4,
		isdst: false,
		text: '(UTC-04:00) Cuiaba',
		utc: ['America/Campo_Grande', 'America/Cuiaba'],
	},
	{
		value: 'SA Western Standard Time',
		abbr: 'SWST',
		offset: -4,
		isdst: false,
		text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
		utc: [
			'America/Anguilla',
			'America/Antigua',
			'America/Aruba',
			'America/Barbados',
			'America/Blanc-Sablon',
			'America/Boa_Vista',
			'America/Curacao',
			'America/Dominica',
			'America/Grand_Turk',
			'America/Grenada',
			'America/Guadeloupe',
			'America/Guyana',
			'America/Kralendijk',
			'America/La_Paz',
			'America/Lower_Princes',
			'America/Manaus',
			'America/Marigot',
			'America/Martinique',
			'America/Montserrat',
			'America/Port_of_Spain',
			'America/Porto_Velho',
			'America/Puerto_Rico',
			'America/Santo_Domingo',
			'America/St_Barthelemy',
			'America/St_Kitts',
			'America/St_Lucia',
			'America/St_Thomas',
			'America/St_Vincent',
			'America/Tortola',
			'Etc/GMT+4',
		],
	},
	{
		value: 'Pacific SA Standard Time',
		abbr: 'PSST',
		offset: -4,
		isdst: false,
		text: '(UTC-04:00) Santiago',
		utc: ['America/Santiago', 'Antarctica/Palmer'],
	},
	{
		value: 'Newfoundland Standard Time',
		abbr: 'NDT',
		offset: -2.5,
		isdst: true,
		text: '(UTC-03:30) Newfoundland',
		utc: ['America/St_Johns'],
	},
	{
		value: 'E. South America Standard Time',
		abbr: 'ESAST',
		offset: -3,
		isdst: false,
		text: '(UTC-03:00) Brasilia',
		utc: ['America/Sao_Paulo'],
	},
	{
		value: 'Argentina Standard Time',
		abbr: 'AST',
		offset: -3,
		isdst: false,
		text: '(UTC-03:00) Buenos Aires',
		utc: [
			'America/Argentina/La_Rioja',
			'America/Argentina/Rio_Gallegos',
			'America/Argentina/Salta',
			'America/Argentina/San_Juan',
			'America/Argentina/San_Luis',
			'America/Argentina/Tucuman',
			'America/Argentina/Ushuaia',
			'America/Buenos_Aires',
			'America/Catamarca',
			'America/Cordoba',
			'America/Jujuy',
			'America/Mendoza',
		],
	},
	{
		value: 'SA Eastern Standard Time',
		abbr: 'SEST',
		offset: -3,
		isdst: false,
		text: '(UTC-03:00) Cayenne, Fortaleza',
		utc: [
			'America/Araguaina',
			'America/Belem',
			'America/Cayenne',
			'America/Fortaleza',
			'America/Maceio',
			'America/Paramaribo',
			'America/Recife',
			'America/Santarem',
			'Antarctica/Rothera',
			'Atlantic/Stanley',
			'Etc/GMT+3',
		],
	},
	{
		value: 'Greenland Standard Time',
		abbr: 'GDT',
		offset: -3,
		isdst: true,
		text: '(UTC-03:00) Greenland',
		utc: ['America/Godthab'],
	},
	{
		value: 'Montevideo Standard Time',
		abbr: 'MST',
		offset: -3,
		isdst: false,
		text: '(UTC-03:00) Montevideo',
		utc: ['America/Montevideo'],
	},
	{
		value: 'Bahia Standard Time',
		abbr: 'BST',
		offset: -3,
		isdst: false,
		text: '(UTC-03:00) Salvador',
		utc: ['America/Bahia'],
	},
	{
		value: 'UTC-02',
		abbr: 'U',
		offset: -2,
		isdst: false,
		text: '(UTC-02:00) Coordinated Universal Time-02',
		utc: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
	},
	{
		value: 'Mid-Atlantic Standard Time',
		abbr: 'MDT',
		offset: -1,
		isdst: true,
		text: '(UTC-02:00) Mid-Atlantic - Old',
		utc: [],
	},
	{
		value: 'Azores Standard Time',
		abbr: 'ADT',
		offset: 0,
		isdst: true,
		text: '(UTC-01:00) Azores',
		utc: ['America/Scoresbysund', 'Atlantic/Azores'],
	},
	{
		value: 'Cape Verde Standard Time',
		abbr: 'CVST',
		offset: -1,
		isdst: false,
		text: '(UTC-01:00) Cape Verde Is.',
		utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
	},
	{
		value: 'Morocco Standard Time',
		abbr: 'MDT',
		offset: 1,
		isdst: true,
		text: '(UTC) Casablanca',
		utc: ['Africa/Casablanca', 'Africa/El_Aaiun'],
	},
	{
		value: 'UTC',
		abbr: 'UTC',
		offset: 0,
		isdst: false,
		text: '(UTC) Coordinated Universal Time',
		utc: ['America/Danmarkshavn', 'Etc/GMT'],
	},
	{
		value: 'GMT Standard Time',
		abbr: 'GMT',
		offset: 0,
		isdst: false,
		text: '(UTC) Edinburgh, London',
		utc: [
			'Europe/Isle_of_Man',
			'Europe/Guernsey',
			'Europe/Jersey',
			'Europe/London',
		],
	},
	{
		value: 'British Summer Time',
		abbr: 'BST',
		offset: 1,
		isdst: true,
		text: '(UTC+01:00) Edinburgh, London',
		utc: [
			'Europe/Isle_of_Man',
			'Europe/Guernsey',
			'Europe/Jersey',
			'Europe/London',
		],
	},
	{
		value: 'GMT Standard Time',
		abbr: 'GDT',
		offset: 1,
		isdst: true,
		text: '(UTC) Dublin, Lisbon',
		utc: [
			'Atlantic/Canary',
			'Atlantic/Faeroe',
			'Atlantic/Madeira',
			'Europe/Dublin',
			'Europe/Lisbon',
		],
	},
	{
		value: 'Greenwich Standard Time',
		abbr: 'GST',
		offset: 0,
		isdst: false,
		text: '(UTC) Monrovia, Reykjavik',
		utc: [
			'Africa/Abidjan',
			'Africa/Accra',
			'Africa/Bamako',
			'Africa/Banjul',
			'Africa/Bissau',
			'Africa/Conakry',
			'Africa/Dakar',
			'Africa/Freetown',
			'Africa/Lome',
			'Africa/Monrovia',
			'Africa/Nouakchott',
			'Africa/Ouagadougou',
			'Africa/Sao_Tome',
			'Atlantic/Reykjavik',
			'Atlantic/St_Helena',
		],
	},
	{
		value: 'W. Europe Standard Time',
		abbr: 'WEDT',
		offset: 2,
		isdst: true,
		text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		utc: [
			'Arctic/Longyearbyen',
			'Europe/Amsterdam',
			'Europe/Andorra',
			'Europe/Berlin',
			'Europe/Busingen',
			'Europe/Gibraltar',
			'Europe/Luxembourg',
			'Europe/Malta',
			'Europe/Monaco',
			'Europe/Oslo',
			'Europe/Rome',
			'Europe/San_Marino',
			'Europe/Stockholm',
			'Europe/Vaduz',
			'Europe/Vatican',
			'Europe/Vienna',
			'Europe/Zurich',
		],
	},
	{
		value: 'Central Europe Standard Time',
		abbr: 'CEDT',
		offset: 2,
		isdst: true,
		text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		utc: [
			'Europe/Belgrade',
			'Europe/Bratislava',
			'Europe/Budapest',
			'Europe/Ljubljana',
			'Europe/Podgorica',
			'Europe/Prague',
			'Europe/Tirane',
		],
	},
	{
		value: 'Romance Standard Time',
		abbr: 'RDT',
		offset: 2,
		isdst: true,
		text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
		utc: [
			'Africa/Ceuta',
			'Europe/Brussels',
			'Europe/Copenhagen',
			'Europe/Madrid',
			'Europe/Paris',
		],
	},
	{
		value: 'Central European Standard Time',
		abbr: 'CEDT',
		offset: 2,
		isdst: true,
		text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
		utc: ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb'],
	},
	{
		value: 'W. Central Africa Standard Time',
		abbr: 'WCAST',
		offset: 1,
		isdst: false,
		text: '(UTC+01:00) West Central Africa',
		utc: [
			'Africa/Algiers',
			'Africa/Bangui',
			'Africa/Brazzaville',
			'Africa/Douala',
			'Africa/Kinshasa',
			'Africa/Lagos',
			'Africa/Libreville',
			'Africa/Luanda',
			'Africa/Malabo',
			'Africa/Ndjamena',
			'Africa/Niamey',
			'Africa/Porto-Novo',
			'Africa/Tunis',
			'Etc/GMT-1',
		],
	},
	{
		value: 'Namibia Standard Time',
		abbr: 'NST',
		offset: 1,
		isdst: false,
		text: '(UTC+01:00) Windhoek',
		utc: ['Africa/Windhoek'],
	},
	{
		value: 'GTB Standard Time',
		abbr: 'GDT',
		offset: 3,
		isdst: true,
		text: '(UTC+02:00) Athens, Bucharest',
		utc: [
			'Asia/Nicosia',
			'Europe/Athens',
			'Europe/Bucharest',
			'Europe/Chisinau',
		],
	},
	{
		value: 'Middle East Standard Time',
		abbr: 'MEDT',
		offset: 3,
		isdst: true,
		text: '(UTC+02:00) Beirut',
		utc: ['Asia/Beirut'],
	},
	{
		value: 'Egypt Standard Time',
		abbr: 'EST',
		offset: 2,
		isdst: false,
		text: '(UTC+02:00) Cairo',
		utc: ['Africa/Cairo'],
	},
	{
		value: 'Syria Standard Time',
		abbr: 'SDT',
		offset: 3,
		isdst: true,
		text: '(UTC+02:00) Damascus',
		utc: ['Asia/Damascus'],
	},
	{
		value: 'E. Europe Standard Time',
		abbr: 'EEDT',
		offset: 3,
		isdst: true,
		text: '(UTC+02:00) E. Europe',
		utc: [
			'Asia/Nicosia',
			'Europe/Athens',
			'Europe/Bucharest',
			'Europe/Chisinau',
			'Europe/Helsinki',
			'Europe/Kiev',
			'Europe/Mariehamn',
			'Europe/Nicosia',
			'Europe/Riga',
			'Europe/Sofia',
			'Europe/Tallinn',
			'Europe/Uzhgorod',
			'Europe/Vilnius',
			'Europe/Zaporozhye',
		],
	},
	{
		value: 'South Africa Standard Time',
		abbr: 'SAST',
		offset: 2,
		isdst: false,
		text: '(UTC+02:00) Harare, Pretoria',
		utc: [
			'Africa/Blantyre',
			'Africa/Bujumbura',
			'Africa/Gaborone',
			'Africa/Harare',
			'Africa/Johannesburg',
			'Africa/Kigali',
			'Africa/Lubumbashi',
			'Africa/Lusaka',
			'Africa/Maputo',
			'Africa/Maseru',
			'Africa/Mbabane',
			'Etc/GMT-2',
		],
	},
	{
		value: 'FLE Standard Time',
		abbr: 'FDT',
		offset: 3,
		isdst: true,
		text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
		utc: [
			'Europe/Helsinki',
			'Europe/Kiev',
			'Europe/Mariehamn',
			'Europe/Riga',
			'Europe/Sofia',
			'Europe/Tallinn',
			'Europe/Uzhgorod',
			'Europe/Vilnius',
			'Europe/Zaporozhye',
		],
	},
	{
		value: 'Turkey Standard Time',
		abbr: 'TDT',
		offset: 3,
		isdst: false,
		text: '(UTC+03:00) Istanbul',
		utc: ['Europe/Istanbul'],
	},
	{
		value: 'Israel Standard Time',
		abbr: 'JDT',
		offset: 3,
		isdst: true,
		text: '(UTC+02:00) Jerusalem',
		utc: ['Asia/Jerusalem'],
	},
	{
		value: 'Libya Standard Time',
		abbr: 'LST',
		offset: 2,
		isdst: false,
		text: '(UTC+02:00) Tripoli',
		utc: ['Africa/Tripoli'],
	},
	{
		value: 'Jordan Standard Time',
		abbr: 'JST',
		offset: 3,
		isdst: false,
		text: '(UTC+03:00) Amman',
		utc: ['Asia/Amman'],
	},
	{
		value: 'Arabic Standard Time',
		abbr: 'AST',
		offset: 3,
		isdst: false,
		text: '(UTC+03:00) Baghdad',
		utc: ['Asia/Baghdad'],
	},
	{
		value: 'Kaliningrad Standard Time',
		abbr: 'KST',
		offset: 3,
		isdst: false,
		text: '(UTC+02:00) Kaliningrad',
		utc: ['Europe/Kaliningrad'],
	},
	{
		value: 'Arab Standard Time',
		abbr: 'AST',
		offset: 3,
		isdst: false,
		text: '(UTC+03:00) Kuwait, Riyadh',
		utc: [
			'Asia/Aden',
			'Asia/Bahrain',
			'Asia/Kuwait',
			'Asia/Qatar',
			'Asia/Riyadh',
		],
	},
	{
		value: 'E. Africa Standard Time',
		abbr: 'EAST',
		offset: 3,
		isdst: false,
		text: '(UTC+03:00) Nairobi',
		utc: [
			'Africa/Addis_Ababa',
			'Africa/Asmera',
			'Africa/Dar_es_Salaam',
			'Africa/Djibouti',
			'Africa/Juba',
			'Africa/Kampala',
			'Africa/Khartoum',
			'Africa/Mogadishu',
			'Africa/Nairobi',
			'Antarctica/Syowa',
			'Etc/GMT-3',
			'Indian/Antananarivo',
			'Indian/Comoro',
			'Indian/Mayotte',
		],
	},
	{
		value: 'Moscow Standard Time',
		abbr: 'MSK',
		offset: 3,
		isdst: false,
		text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
		utc: [
			'Europe/Kirov',
			'Europe/Moscow',
			'Europe/Simferopol',
			'Europe/Volgograd',
			'Europe/Minsk',
		],
	},
	{
		value: 'Samara Time',
		abbr: 'SAMT',
		offset: 4,
		isdst: false,
		text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
		utc: ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk'],
	},
	{
		value: 'Iran Standard Time',
		abbr: 'IDT',
		offset: 4.5,
		isdst: true,
		text: '(UTC+03:30) Tehran',
		utc: ['Asia/Tehran'],
	},
	{
		value: 'Arabian Standard Time',
		abbr: 'AST',
		offset: 4,
		isdst: false,
		text: '(UTC+04:00) Abu Dhabi, Muscat',
		utc: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
	},
	{
		value: 'Azerbaijan Standard Time',
		abbr: 'ADT',
		offset: 5,
		isdst: true,
		text: '(UTC+04:00) Baku',
		utc: ['Asia/Baku'],
	},
	{
		value: 'Mauritius Standard Time',
		abbr: 'MST',
		offset: 4,
		isdst: false,
		text: '(UTC+04:00) Port Louis',
		utc: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
	},
	{
		value: 'Georgian Standard Time',
		abbr: 'GET',
		offset: 4,
		isdst: false,
		text: '(UTC+04:00) Tbilisi',
		utc: ['Asia/Tbilisi'],
	},
	{
		value: 'Caucasus Standard Time',
		abbr: 'CST',
		offset: 4,
		isdst: false,
		text: '(UTC+04:00) Yerevan',
		utc: ['Asia/Yerevan'],
	},
	{
		value: 'Afghanistan Standard Time',
		abbr: 'AST',
		offset: 4.5,
		isdst: false,
		text: '(UTC+04:30) Kabul',
		utc: ['Asia/Kabul'],
	},
	{
		value: 'West Asia Standard Time',
		abbr: 'WAST',
		offset: 5,
		isdst: false,
		text: '(UTC+05:00) Ashgabat, Tashkent',
		utc: [
			'Antarctica/Mawson',
			'Asia/Aqtau',
			'Asia/Aqtobe',
			'Asia/Ashgabat',
			'Asia/Dushanbe',
			'Asia/Oral',
			'Asia/Samarkand',
			'Asia/Tashkent',
			'Etc/GMT-5',
			'Indian/Kerguelen',
			'Indian/Maldives',
		],
	},
	{
		value: 'Yekaterinburg Time',
		abbr: 'YEKT',
		offset: 5,
		isdst: false,
		text: '(UTC+05:00) Yekaterinburg',
		utc: ['Asia/Yekaterinburg'],
	},
	{
		value: 'Pakistan Standard Time',
		abbr: 'PKT',
		offset: 5,
		isdst: false,
		text: '(UTC+05:00) Islamabad, Karachi',
		utc: ['Asia/Karachi'],
	},
	{
		value: 'India Standard Time',
		abbr: 'IST',
		offset: 5.5,
		isdst: false,
		text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
		utc: ['Asia/Kolkata', 'Asia/Calcutta'],
	},
	{
		value: 'Sri Lanka Standard Time',
		abbr: 'SLST',
		offset: 5.5,
		isdst: false,
		text: '(UTC+05:30) Sri Jayawardenepura',
		utc: ['Asia/Colombo'],
	},
	{
		value: 'Nepal Standard Time',
		abbr: 'NST',
		offset: 5.75,
		isdst: false,
		text: '(UTC+05:45) Kathmandu',
		utc: ['Asia/Kathmandu'],
	},
	{
		value: 'Central Asia Standard Time',
		abbr: 'CAST',
		offset: 6,
		isdst: false,
		text: '(UTC+06:00) Nur-Sultan (Astana)',
		utc: [
			'Antarctica/Vostok',
			'Asia/Almaty',
			'Asia/Bishkek',
			'Asia/Qyzylorda',
			'Asia/Urumqi',
			'Etc/GMT-6',
			'Indian/Chagos',
		],
	},
	{
		value: 'Bangladesh Standard Time',
		abbr: 'BST',
		offset: 6,
		isdst: false,
		text: '(UTC+06:00) Dhaka',
		utc: ['Asia/Dhaka', 'Asia/Thimphu'],
	},
	{
		value: 'Myanmar Standard Time',
		abbr: 'MST',
		offset: 6.5,
		isdst: false,
		text: '(UTC+06:30) Yangon (Rangoon)',
		utc: ['Asia/Rangoon', 'Indian/Cocos'],
	},
	{
		value: 'SE Asia Standard Time',
		abbr: 'SAST',
		offset: 7,
		isdst: false,
		text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
		utc: [
			'Antarctica/Davis',
			'Asia/Bangkok',
			'Asia/Hovd',
			'Asia/Jakarta',
			'Asia/Phnom_Penh',
			'Asia/Pontianak',
			'Asia/Saigon',
			'Asia/Vientiane',
			'Etc/GMT-7',
			'Indian/Christmas',
		],
	},
	{
		value: 'N. Central Asia Standard Time',
		abbr: 'NCAST',
		offset: 7,
		isdst: false,
		text: '(UTC+07:00) Novosibirsk',
		utc: ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk'],
	},
	{
		value: 'China Standard Time',
		abbr: 'CST',
		offset: 8,
		isdst: false,
		text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
		utc: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
	},
	{
		value: 'North Asia Standard Time',
		abbr: 'NAST',
		offset: 8,
		isdst: false,
		text: '(UTC+08:00) Krasnoyarsk',
		utc: ['Asia/Krasnoyarsk'],
	},
	{
		value: 'Singapore Standard Time',
		abbr: 'MPST',
		offset: 8,
		isdst: false,
		text: '(UTC+08:00) Kuala Lumpur, Singapore',
		utc: [
			'Asia/Brunei',
			'Asia/Kuala_Lumpur',
			'Asia/Kuching',
			'Asia/Makassar',
			'Asia/Manila',
			'Asia/Singapore',
			'Etc/GMT-8',
		],
	},
	{
		value: 'W. Australia Standard Time',
		abbr: 'WAST',
		offset: 8,
		isdst: false,
		text: '(UTC+08:00) Perth',
		utc: ['Antarctica/Casey', 'Australia/Perth'],
	},
	{
		value: 'Taipei Standard Time',
		abbr: 'TST',
		offset: 8,
		isdst: false,
		text: '(UTC+08:00) Taipei',
		utc: ['Asia/Taipei'],
	},
	{
		value: 'Ulaanbaatar Standard Time',
		abbr: 'UST',
		offset: 8,
		isdst: false,
		text: '(UTC+08:00) Ulaanbaatar',
		utc: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
	},
	{
		value: 'North Asia East Standard Time',
		abbr: 'NAEST',
		offset: 8,
		isdst: false,
		text: '(UTC+08:00) Irkutsk',
		utc: ['Asia/Irkutsk'],
	},
	{
		value: 'Japan Standard Time',
		abbr: 'JST',
		offset: 9,
		isdst: false,
		text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
		utc: [
			'Asia/Dili',
			'Asia/Jayapura',
			'Asia/Tokyo',
			'Etc/GMT-9',
			'Pacific/Palau',
		],
	},
	{
		value: 'Korea Standard Time',
		abbr: 'KST',
		offset: 9,
		isdst: false,
		text: '(UTC+09:00) Seoul',
		utc: ['Asia/Pyongyang', 'Asia/Seoul'],
	},
	{
		value: 'Cen. Australia Standard Time',
		abbr: 'CAST',
		offset: 9.5,
		isdst: false,
		text: '(UTC+09:30) Adelaide',
		utc: ['Australia/Adelaide', 'Australia/Broken_Hill'],
	},
	{
		value: 'AUS Central Standard Time',
		abbr: 'ACST',
		offset: 9.5,
		isdst: false,
		text: '(UTC+09:30) Darwin',
		utc: ['Australia/Darwin'],
	},
	{
		value: 'E. Australia Standard Time',
		abbr: 'EAST',
		offset: 10,
		isdst: false,
		text: '(UTC+10:00) Brisbane',
		utc: ['Australia/Brisbane', 'Australia/Lindeman'],
	},
	{
		value: 'AUS Eastern Standard Time',
		abbr: 'AEST',
		offset: 10,
		isdst: false,
		text: '(UTC+10:00) Canberra, Melbourne, Sydney',
		utc: ['Australia/Melbourne', 'Australia/Sydney'],
	},
	{
		value: 'West Pacific Standard Time',
		abbr: 'WPST',
		offset: 10,
		isdst: false,
		text: '(UTC+10:00) Guam, Port Moresby',
		utc: [
			'Antarctica/DumontDUrville',
			'Etc/GMT-10',
			'Pacific/Guam',
			'Pacific/Port_Moresby',
			'Pacific/Saipan',
			'Pacific/Truk',
		],
	},
	{
		value: 'Tasmania Standard Time',
		abbr: 'TST',
		offset: 10,
		isdst: false,
		text: '(UTC+10:00) Hobart',
		utc: ['Australia/Currie', 'Australia/Hobart'],
	},
	{
		value: 'Yakutsk Standard Time',
		abbr: 'YST',
		offset: 9,
		isdst: false,
		text: '(UTC+09:00) Yakutsk',
		utc: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
	},
	{
		value: 'Central Pacific Standard Time',
		abbr: 'CPST',
		offset: 11,
		isdst: false,
		text: '(UTC+11:00) Solomon Is., New Caledonia',
		utc: [
			'Antarctica/Macquarie',
			'Etc/GMT-11',
			'Pacific/Efate',
			'Pacific/Guadalcanal',
			'Pacific/Kosrae',
			'Pacific/Noumea',
			'Pacific/Ponape',
		],
	},
	{
		value: 'Vladivostok Standard Time',
		abbr: 'VST',
		offset: 11,
		isdst: false,
		text: '(UTC+11:00) Vladivostok',
		utc: ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok'],
	},
	{
		value: 'New Zealand Standard Time',
		abbr: 'NZST',
		offset: 12,
		isdst: false,
		text: '(UTC+12:00) Auckland, Wellington',
		utc: ['Antarctica/McMurdo', 'Pacific/Auckland'],
	},
	{
		value: 'UTC+12',
		abbr: 'U',
		offset: 12,
		isdst: false,
		text: '(UTC+12:00) Coordinated Universal Time+12',
		utc: [
			'Etc/GMT-12',
			'Pacific/Funafuti',
			'Pacific/Kwajalein',
			'Pacific/Majuro',
			'Pacific/Nauru',
			'Pacific/Tarawa',
			'Pacific/Wake',
			'Pacific/Wallis',
		],
	},
	{
		value: 'Fiji Standard Time',
		abbr: 'FST',
		offset: 12,
		isdst: false,
		text: '(UTC+12:00) Fiji',
		utc: ['Pacific/Fiji'],
	},
	{
		value: 'Magadan Standard Time',
		abbr: 'MST',
		offset: 12,
		isdst: false,
		text: '(UTC+12:00) Magadan',
		utc: [
			'Asia/Anadyr',
			'Asia/Kamchatka',
			'Asia/Magadan',
			'Asia/Srednekolymsk',
		],
	},
	{
		value: 'Kamchatka Standard Time',
		abbr: 'KDT',
		offset: 13,
		isdst: true,
		text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
		utc: ['Asia/Kamchatka'],
	},
	{
		value: 'Tonga Standard Time',
		abbr: 'TST',
		offset: 13,
		isdst: false,
		text: "(UTC+13:00) Nuku'alofa",
		utc: [
			'Etc/GMT-13',
			'Pacific/Enderbury',
			'Pacific/Fakaofo',
			'Pacific/Tongatapu',
		],
	},
	{
		value: 'Samoa Standard Time',
		abbr: 'SST',
		offset: 13,
		isdst: false,
		text: '(UTC+13:00) Samoa',
		utc: ['Pacific/Apia'],
	},
];
