import { Button } from '@mui/material';

interface ButtonWithIconProps {
	icon: Function;
	text: string;
	onClick: Function;
	color: string;
	isActive: boolean;
	width: any;
	disabled?: boolean;
}

const ButtonWithIcon = ({
	icon,
	text,
	onClick,
	color,
	isActive,
	width,
	disabled,
}: ButtonWithIconProps) => {
	return (
		<Button
			variant={'outlined'}
			onClick={() => onClick()}
			disabled={disabled ? true : false}
			style={{
				borderColor: isActive ? 'white' : 'gray',
				backgroundColor: isActive ? color : 'white',
				color: isActive ? 'white' : '#000',
				margin: 10,
				padding: 10,
				// minWidth: 200,
				// width: width ? width : 'auto',
				width: width ? width : '250px',
				borderRadius: 15,
			}}
			// className={classes.button}
			startIcon={icon ? icon() : null}
		>
			{text}
		</Button>
	);
};

export default ButtonWithIcon;
