import {
	detailsInitalized,
	linksInitalized,
	locationInitialized,
	imagesInitialized,
} from '@toriosoftware/torioshared/dist/detailedPlace';
import { sendAPI } from './helper';

export const getDetails = async (history, state) => {
	let result = await getDetailedPlace();

	const details = detailsInitalized;
	const links = linksInitalized;
	const location = locationInitialized;
	const images = imagesInitialized;
	// let mainImageNumber = '1';

	if (result.success) {
		details.name = result.item.name;
		details.description = result.item.description;
		details.isActive = result.item.isActive;
		details.isFoodService = result.item.isFoodService;
		details.foodDescription = result.item.foodDescription;
		details.foodDescriptionis = result.item.foodDescriptionis;
		details.musicgenre = result.item.musicgenre;
		details.musicgenreis = result.item.musicgenreis;
		details.oneLineris = result.item.oneLineris;
		details.oneLiner = result.item.oneLiner;
		details.country = result.item.country;
		details.dogFriendly = result.item.dogFriendly;
		details.countryis = result.item.countryis;
		details.genre = result.item.genre;
		details.noEmails = result.item.noEmails;
		details.hideTime = result.item.hideTime;
		details.hideRating = result.item.hideRating;
		details.awards = result.item.awards;
		details.descriptionis = result.item.descriptionis;
		details.openingTimes = result.item.openingTimes;
		details.openingTimesis = result.item.openingTimesis;
		details.dresscode = result.item.dresscode;
		details.dresscodeis = result.item.dresscodeis;
		details.translation = result.item.translation;

		links['facebookLink'] = result.item.facebookLink;
		links['instagramLink'] = result.item.instagramLink;
		links['websiteLink'] = result.item.websiteLink;
		links['foodMenu'] = result.item.foodMenu;
		links['drinkMenu'] = result.item.drinkMenu;

		location.address = result.item.address;
		location.lat = result.item.lat;
		location.long = result.item.long;

		images.imageLinks = result.imageLinks;
		images.mainImageLink = result.mainImageLink;
		images.mainImageNumber = result.mainImageNumber;
		images.verticalAlign = result.verticalAlign;

		if (
			result.item &&
			result.item.placeFeatures &&
			result.item.placeFeatures.length > 0
		) {
			let placeFeatures = result.item.placeFeatures.map((feature) => {
				return feature.englishName;
			});
			details.features = placeFeatures;
		}
		const allFeatures = result.allFeatures;
		return {
			details,
			allFeatures,
			links,
			location,
			images,
			csrfToken: result.csrfToken,
		};
	} else {
		state.openModal('Error', 'You do not have access to this area. ', 'error');
		history('/admin');
		return {};
	}
};

export const getDetailedPlace = async () => {
	const result = await sendAPI(
		'detailedPlace/getDetailedPlace/',
		{
			shouldGetAllFeatures: true,
		},
		false,
		null,
		null,
		null,
		null,
		true
	);
	if (result) {
		return result;
	} else {
		return {
			success: false,
		};
	}
};

export const saveDetailedPlaceInfo = async (
	info,
	selectedFeatures,
	openModal
) => {
	if (info) {
		return;

		info['images'] = null;
		info['mainImage'] = null;
		const result = await sendAPI(
			'detailedPlace/saveDetailedPlace/',
			{
				...info,
				selectedFeatures: selectedFeatures,
			},
			openModal,
			openModal
		);
		if (result) {
			return result;
		} else {
			return {};
		}
	}
};

export const saveLinks = async (info, openModal) => {
	const result = await sendAPI(
		'detailedPlace/saveLinks/',
		{ ...info },
		openModal,
		openModal
	);
	if (result) {
		return result;
	} else {
		return {};
	}
};

export const saveImages = async (info, openModal) => {
	const result = await sendAPI(
		'detailedPlace/saveImages/',
		info,
		openModal,
		openModal
	);
	if (result && result.success) {
		// window.location.reload();
	}
};
