import {
	getFlowColumns,
	getFlowmeterColumns,
} from '../../business/tableHelper';
import PageContainer from '../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';

const Flowmeters = ({ user }: any) => {
	return (
		<PageContainer header={'Flow meters'}>
			<React.Fragment>
				<ManageItems
					header={'Flow meters'}
					key={111}
					getData={{
						url: 'flowmeters/flows',
						body: {},
					}}
					buttonText={'Add flow meter'}
					// includeContainer={showPageContainer}
					redirectLink={ROUTE_TYPES[ROUTE_NAMES.flowmeter].route}
					columns={getFlowmeterColumns()}
					noItemsText={'No flow meters have been added.'}
				/>
			</React.Fragment>
		</PageContainer>
	);
};

export default Flowmeters;
