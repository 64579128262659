import { useDiscountTimeMutation, useManageDiscountTimeMutation } from '../../store/api/api';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	discountTimeInterface,
	discountTimeSchema,
	discountTimeInitialized,
} from '@toriosoftware/torioshared/dist/discountTime/DiscountTime';
import MainInputController from '../../components/inputs/MainInputController';
import FormContainer from '../../components/containers/FormContainer';
import PageContainer from '../../components/containers/PageContainer';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	IMAGE_TYPES,
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import MainSwitchController from '../../components/switch/MainSwitchController';
import SubHeader from '../../components/texts/SubHeader';
import MainSwitch from '../../components/switch/MainSwitch';
import React, { useEffect } from 'react';
import MainImageLinksController from '../../components/images/MainImageLinksController';
import MainButton from '../../components/buttons/MainButton';
import LineContainer from '../../components/lines/LineContainer';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const DiscountTime = ({ user }) => {
	// State
	const [discountTime] = useDiscountTimeMutation();
	const [manageDiscountTime] = useManageDiscountTimeMutation();
	const [timesToUse, setTimesToUse] = React.useState([]);
	const [snackOpen, setSnackOpen] = React.useState(false);
	const [notAgain, setNotAgain] = React.useState(false);
	const [snackTimesOpen, setSnackTimesOpen] = React.useState(false);

	// POST DATA
	const {
		formState: { isDirty, errors },
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
	} = useForm<discountTimeInterface>({
		defaultValues: discountTimeInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(discountTimeSchema),
	});

	const from = watch('from');
	const to = watch('to');
	const times = watch('times');

	// GET DATA
	const {
		modelState,
		setIsLoaded,
		isLoaded,
		history,
		isNew,
		id,
		data,
		csrfToken,
	} = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.discounttime, user)?.data?.get(discountTime, reset),
		],
		user: user,
	});

	useEffect(() => {
		if(times && times.length > 0 && isLoaded && !notAgain) {
			setTimesToUse(times)
			setNotAgain(true)
		}
	}, [times, isLoaded]);

	const addTimes = () => {
		console.log("errors: ", errors);
		if(from && to && from.length === 5 && to.length === 5) {
			let canContinue = true;
			if(errors && Object.keys(errors).length > 0) {
				for(let key in errors) {
					if(key === 'from' || key === 'to') {
						canContinue = false;
						break;
					}
				}
			}

			if(canContinue) {
				const newTimesArray = [...timesToUse, { from: from, to: to }];
				setTimesToUse(newTimesArray);
				setValue('times', newTimesArray);
				setValue('from', '');
				setValue('to', '');
			} else {
				setSnackTimesOpen(true);
			}
		} else {
			setSnackTimesOpen(true);
		}
		// setTimesToUse([...timesToUse, { from: '', to: '' }]);
	}

	// const imageLink = watch('imageLink');

	return (
		<PageContainer breadPlaceholder={'Discount time'} header={'Discount time'}>
			<FormContainer
				csrfToken={csrfToken}
				isDirty={isDirty}
				modal={modelState}
				handleSubmit={handleSubmit}
				showBack={true}
				onSubmitFunction={manageDiscountTime}
				onRemoveFunction={manageDiscountTime}
				disabled={(times?.length === 0)}
				linkAfterDelete={'discounttimes'}
				title={'Discount time'}
				linkAfterSuccess={isNew ? ROUTE_TYPES[ROUTE_NAMES.discounttimes].route : ''}
				customData={{ id }}
				isLoaded={isLoaded}
				goBackDepth={0}
				history={history}
				hideRemove={isNew ? true : false}
			>

				<MainInputController
					control={control}
					label={'Name (not visible to customers)'}
					identifier={'name'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Description (not visible to customers)'}
					rows={'2'}
					identifier={'description'}
					md={12}
				/>

				
				<MainInputController
					control={control}
					label={'Display discount text'}
					rows={'2'}
					identifier={'discountText'}
					md={12}
				/>


				{/* <MainSwitch
					key={523421}
					isBig={true}
					value={isPercentage}
					label={'Is percentage discount'}
					onChange={(e) => {
						let newPercentage = !isPercentage;
						setIsPercentage(newPercentage);
						setValue('percentage', 0);
						setValue('amount', 0);
						setValue('originalAmount', 0);
					}}
					identifier={'isPercentage'}
				/>
				
				<MainInputController
					control={control}
					label={'Discount percentage'}
					type={'number'}
					hideInput={!isPercentage}
					identifier={'percentage'}
					md={12}
					options={{
						max: 100,
						min: 0,
					}}
					endText={'%'}
				/>

				<MainInputController
					control={control}
					label={'Old price'}
					hideInput={isPercentage}
					type={'number'}
					identifier={'originalAmount'}
					md={6}
					options={{
						max: 30000,
						min: 0,
					}}
					endText={' kr'}
				/>
				
				<MainInputController
					control={control}
					label={'New price'}
					hideInput={isPercentage}
					type={'number'}
					identifier={'amount'}
					md={6}
					options={{
						max: 30000,
						min: 0,
					}}
					endText={' kr'}
				/>

				<MainSwitchController
					key={21412}
					control={control}
					label={'Display below main card in Barhopp'}
					md={12}
					identifier={'isMain'}
				/>

				<SubHeader text={'Is available on'} />

				<MainInputController
					control={control}
					label={'From'}
					type={'text'}
					identifier={'from'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'To'}
					type={'text'}
					identifier={'to'}
					md={6}
				/> */}

				<SubHeader text={'Days discount is active'} />

				{
					['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
						return(
							<MainSwitchController
								key={index}
								control={control}
								label={day}
								md={3}
								identifier={day.toLowerCase() }
							/>
						)
					})
				}


				<SubHeader text={'Set times for discount'} />
	

				<MainInputController
					control={control}
					label={'From'}
					type={'text'}
					identifier={'from'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'To'}
					type={'text'}
					identifier={'to'}
					md={6}
				/>

				<LineContainer>
					<MainButton
						// disabled={!isDirty}
						disabled={false}
						onClick={() => {
							addTimes();
						}}
						isSubmit={false}
						// disabled={false}
						text={'Add time'}
						style={{color: 'black'}}
						variant={'outlined'}
						color={'primary'}
					/>
				</LineContainer>

				<SubHeader text={'Times discount is active'} />

				<LineContainer>
					{
						timesToUse && timesToUse.length > 0 ?
						timesToUse.map((time, index) => {
							return(
								<div style={{display: 'inline', borderRadius: 15, padding: 10, marginRight: 10, marginBottom: 10, backgroundColor: 'lightgray'}}>
									<span>{time.from} - {time.to}</span>
									<span style={{fontWeight: 'bold', marginLeft: 12, cursor: 'pointer'}} onClick={() => {
										let newTimes = [...timesToUse];
										newTimes.splice(index, 1);
										setTimesToUse(newTimes);
										setValue('times', newTimes);
										setSnackOpen(true);
									}}>X</span>
								</div>
							)
						})
						:
						<span>No times added yet. </span>
					}
				</LineContainer>

				<Snackbar open={snackOpen} autoHideDuration={4000} onClose={() => {
					setSnackOpen(false);
				}}>
					<Alert onClose={() => {
						setSnackOpen(false);
					}} severity="info" sx={{ width: '100%' }}>
						Changes to discount times has not been saved. 
					</Alert>
				</Snackbar>

				
				<Snackbar open={snackTimesOpen} autoHideDuration={4000} onClose={() => {
					setSnackTimesOpen(false);
				}}>
					<Alert onClose={() => {
						setSnackTimesOpen(false);
					}} severity="error" sx={{ width: '100%' }}>
						You need to specify from and to date with correct time format (HH:MM).
					</Alert>
				</Snackbar>

				<LineContainer>
					<Alert severity='info' sx={{ width: '100%', marginTop: 4 }}>
						You specify discounted price in drink.
					</Alert>
				</LineContainer>
				{
					times && times.length > 0 ? null :
					<LineContainer>
						<Alert severity='error' sx={{ width: '100%', marginBottom: 1 }}>
							You need to add at least one time by pressing the 'Add time' button.
						</Alert>
					</LineContainer>
				}

				{/* <LineContainer>
					<p style={{ fontWeight: 400, fontSize: 18 }}>You set discount price inside relevant drinks. </p>
				</LineContainer> */}
				
			</FormContainer>
		</PageContainer>
	);
};

export default DiscountTime;
