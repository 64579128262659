import { Grid, GridSize, Typography } from '@mui/material';
import PanelContainer from '../containers/PanelContainer';

interface SmallDisplayCardProps {
	header: string;
	text: string;
	md: GridSize;
}

const SmallDisplayCard = ({ header, text, md }: SmallDisplayCardProps) => {
	return (
		<PanelContainer md={md} header={header}>
			<Grid sm={12} xs={12} style={{ width: '100%', paddingBottom: 15 }} item>
				<div></div>
				<Typography
					textAlign={'center'}
					variant={'body1'}
					fontSize={20}
					fontWeight={400}
					margin={'auto'}
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{text}
				</Typography>
			</Grid>
		</PanelContainer>
	);
};

export default SmallDisplayCard;
