import React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import { formControlStyles } from '../../styles/materialStyles';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Info from '@mui/icons-material/Info';

const MainSwitch = ({
	identifier,
	onChange,
	value,
	hide,
	label,
	isSmall,
	about,
	isBig,
}: {
	identifier: string;
	onChange: Function;
	value: any;
	label: string;
	isSmall?: boolean;
	about?: string;
	hide?: boolean;
	isBig?: boolean;
}) => {
	// const [checked, setChecked] = React.useState(false);

	// const toggleChecked = () => {
	// 	setChecked((prev) => !prev);
	// };
	if (hide) return null;

	const getValue = () => {
		if (value) {
			return true;
		}
		return false;
	};

	return (
		<Grid
			item
			xs={12}
			md={isBig ? 12 : (isSmall ? 4 : 6)}
			style={{ display: 'flex', alignItems: 'center' }}
		>
			<FormControl
				// fullWidth={true}
				className={clsx(formControlStyles().root)}
				// style={{ width: '98%' }}
			>
				<FormControlLabel
					control={
						<Switch
							color={'primary'}
							checked={getValue()}
							onChange={() => {
								onChange(identifier, !getValue());
							}}

							// toggleChecked={toggleChecked()}
						/>
					}
					label={label}
				/>
			</FormControl>
			{about ? (
				<Tooltip style={{ cursor: 'pointer', fontSize: 24 }} title={about}>
					<Info
						style={{
							color: '#111',
							fontSize: 24,
							marginRight: -3,
						}}
					/>
				</Tooltip>
			) : null}
		</Grid>
	);
};

export default MainSwitch;

// {/* <p
// 					style={{
// 						color: 'gray',
// 						fontWeight: 600,
// 						cursor: 'pointer',
// 						marginBottom: 0,
// 					}}
// 				>
// 					What is this?
// 				</p> */}
// 				{/* <p style={{ color: 'gray' }}>{about}</p> */}
