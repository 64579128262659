import clsx from 'clsx';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Grid, GridSize, IconButton } from '@mui/material';
import { formControlStyles } from '../../styles/materialStyles';
import { useForm, Controller } from 'react-hook-form';

interface OptionsProps {
	min?: number;
	max?: number;
}

interface InputMainProps {
	label: string;
	identifier: string;
	// value: string;
	maxlength?: number;
	md?: GridSize;
	type?: string;
	endText?: string;
	helperText?: string;
	helperTextDescribeBy?: string;
	options?: OptionsProps;
	rows?: string;
	disabled?: boolean;
	isPassword?: boolean;
	onIconPressed?: Function;
	placeholder?: string;
	placeDecimals?: number;
	hideInput?: boolean;
	control: any;
}

const MainInputController = ({
	label,
	identifier,
	maxlength,
	options,
	type,
	helperText,
	helperTextDescribeBy,
	onIconPressed,
	endText,
	rows,
	md,
	disabled,
	isPassword,
	placeholder,
	hideInput,
	placeDecimals,
	control,
}: InputMainProps) => {
	const numberValidator = (value) => {
		let valueNumber = parseInt(value);
		if (options) {
			const { min, max } = options;
			if (valueNumber < min) {
				valueNumber = min;
			} else if (valueNumber > max) {
				return false;
				// valueNumber = max;
			}
			if (!valueNumber) {
				valueNumber = 0;
			}
		}
		if (!valueNumber) {
			valueNumber = 0;
		}
		return JSON.stringify(valueNumber);
	};

	const getErrors = (errors, keyToGet = 'message') => {
		if (errors[identifier] && errors[identifier][keyToGet]) {
			return errors[identifier][keyToGet];
		}
		return '';
	};

	return (
		<Controller
			control={control}
			name={identifier}
			render={({
				field: { onChange, onBlur, value, ref },
				fieldState: { invalid, isTouched, isDirty, error },
				formState: { errors },
			}) => {
				let errorMessage = getErrors(errors);

				return (
					<Grid
						style={hideInput ? { display: 'none' } : {}}
						item
						xs={12}
						md={md}
					>
						<FormControl
							fullWidth={true}
							// style={{ width: '100%' }}
							className={clsx(formControlStyles().root)}
						>
							<TextField
								// id="standard-adornment-weight"
								// maxLength={maxlength ? maxlength : null}
								value={value}
								inputRef={ref}
								style={{ backgroundColor: 'white' }}
								variant={'outlined'}
								label={label}
								error={errorMessage ? true : false}
								onBlur={onBlur}
								helperText={errorMessage}
								onChange={onChange}
								// onChange={onChangeInput}
								placeholder={placeholder ? placeholder : ''}
								disabled={disabled ? true : false}
								type={isPassword ? 'password' : 'text'}
								rows={rows ? rows : ''}
								// error={error ? error : false}
								multiline={rows ? true : false}
								InputProps={
									endText
										? {
												endAdornment: (
													<InputAdornment position="start">
														{endText}
													</InputAdornment>
												),
										  }
										: onIconPressed
										? {
												endAdornment: (
													<IconButton
														aria-label="edit"
														onClick={() => onIconPressed && onIconPressed()}
													>
														<EditIcon />
													</IconButton>
												),
										  }
										: null
								}
								aria-describedby={
									helperTextDescribeBy ? helperTextDescribeBy : ''
								}
								inputProps={{
									'aria-label': label,
									maxLength: maxlength,
								}}
							/>

							{helperText ? (
								<FormHelperText id={helperTextDescribeBy}>
									{helperText}
								</FormHelperText>
							) : null}
						</FormControl>
					</Grid>
				);
			}}
		/>
	);
};

// {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
export default MainInputController;
