import moment from 'moment';
import Schedule from '@mui/icons-material/Schedule';
import Fingerprint from '@mui/icons-material/Fingerprint';
import { Grid } from '@mui/material';

const SmallTitleText = ({ accessLog }) => {
	let smallIcon = {
		fontSize: 14,
		color: 'gray',
		marginRight: 4,
	};

	const grayBelowHeading = (text, icon) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					lineHeight: '0px',
					marginTop: 0,
					marginBottom: 0,
				}}
			>
				{icon ? icon() : null}
				<p style={{ color: 'gray' }}>{text}</p>
			</div>
		);
	};

	return (
		<Grid item style={{ marginTop: -20 }}>
			{grayBelowHeading(
				accessLog['localTimestamp']
					? moment.unix(accessLog['localTimestamp']).format('DD-MM-YYYY HH:mm')
					: 'Unknown',
				() => (
					<Schedule style={smallIcon} />
				)
			)}
			{grayBelowHeading(accessLog['guid'] ? accessLog['guid'] : 'No ID', () => (
				<Fingerprint style={smallIcon} />
			))}
		</Grid>
	);
};

export default SmallTitleText;
