export const getEventByDateKey = (
	selectedDatePara,
	allEventsUsed,
	allEventEntriesUsed
) => {
	// Leita í öllum events
	for (let i = 0; i < allEventEntriesUsed.length; i++) {
		// Finna event sem er notað
		let event = allEventEntriesUsed[i];
		if (event && event.length === 2) {
			// Passa að eventið sem er notað
			if (event[0] === selectedDatePara) {
				let mainEvent = allEventsUsed.find((mainEvent) => {
					if (mainEvent._id === event[1]) {
						return true;
					}
					return false;
				});
				if (mainEvent) {
					return mainEvent;
				}
				return;
			}
		}
	}
	return {};
};
