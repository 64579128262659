import { Grid, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import MainContainer from '../containers/MainContainer';
import PanelContainer from '../containers/PanelContainer';
// import howTo from './../../assets/howto.png';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

interface HelpCardInterface {
	title: string;
	texts: Array<string>;
	link: string;
	seconds?: number;
}

const HelpCard = ({ title, texts, link, seconds }: HelpCardInterface) => {
	return (
		<MainContainer direction="row" history={''}>
			<PanelContainer
				style={{
					borderColor: '#ccc',
					borderWidth: 8,
					borderStyle: 'solid',
				}}
				isLoaded={true}
				header={title}
			>
				<Grid
					style={{
						alignItems: 'center',
						display: 'flex',
					}}
					sx={{
						flexDirection: {
							md: 'row',
							sm: 'column',
							xs: 'column',
						},
					}}
				>
					<div style={{ width: 70, paddingRight: 21, marginLeft: 15 }}>
						<InfoIcon style={{ width: 50, height: 50 }} />
					</div>
					<div>
						{texts &&
							texts.map((text, index) => {
								return (
									<Grid
										key={index}
										item
										sx={{
											marginTop: 2,
											width: {
												md: '70%',
												sm: '100%',
												xs: '100%',
											},
										}}
									>
										<Typography
											sx={{ mt: 4, mb: 2 }}
											variant="body1"
											style={{ fontWeight: 'bold' }}
											component="span"
										>
											{index + 1}.{' '}
										</Typography>
										<Typography
											sx={{
												mt: 4,
												mb: 2,
												// flexDirection: {
												// 	width: '70%',
												// 	sm: '100%',
												// 	xs: '100%',
												// },
											}}
											// style={{ maxWidth: '70%' }}
											variant="body1"
											component="span"
										>
											{text}
										</Typography>
									</Grid>
								);
							})}
					</div>
					<Grid
						sx={{
							marginTop: {
								md: 0,
								sm: 4,
							},
						}}
					>
						<Button
							startIcon={<PlayCircleIcon style={{ color: 'white' }} />}
							target="_blank"
							href={link}
							variant="contained"
							style={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
								width: 228,
								height: 72,
							}}
						>
							<Typography variant="body1" style={{ color: 'white' }}>
								See video
							</Typography>
						</Button>
						{seconds && (
							<Typography
								variant="body1"
								style={{ color: '#ccc', fontSize: 12, float: 'right' }}
							>
								~ {seconds} seconds
							</Typography>
						)}
					</Grid>
				</Grid>
			</PanelContainer>
		</MainContainer>
	);
};

export default HelpCard;
