import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { initializeStore } from '@toriosoftware/torioshared/dist/helpers';
import { AUTH_TYPES } from '@toriosoftware/torioshared/dist/config';
import { useGetCanCreateMutation } from '../../store/api/api';

interface ManageItemsProps {
	isLoadedInitial?: boolean;
	retrieveData?: Array<RetrieveDataProps>;
	user?: any;
	idToUse?: string;
	dontInitialize?: boolean;
}

interface RetrieveDataProps {
	mutation: any;
	parameters?: any;
	dataKey?: string;
	reset?: any;
	initStructure?: any;
	role?: string;
	subscriptions?: Array<string>;
	goBackIfNotAllowed?: boolean;
}

const useManageHook = ({
	isLoadedInitial = false,
	retrieveData,
	dontInitialize,
	user,
}: ManageItemsProps) => {
	//@ts-ignore
	const [state] = useContext(Context);
	const [isLoaded, setIsLoaded] = React.useState(isLoadedInitial);
	const history = useHistoryNav();
	const [isNew, setIsNew] = React.useState(true);
	const [csrfToken, setCsrfToken] = React.useState('');
	const [serverData, setServerData] = useState({});
	const [id, setId] = React.useState('0');
	const params = useParams<any>();
	const [getCanCreate] = useGetCanCreateMutation();

	const handleData = async (retrieveDataPara) => {
		if (retrieveDataPara.length > 0) {
			let serverDataTemp = {};
			for (let i = 0; i < retrieveDataPara.length; i++) {
				let item = retrieveDataPara[i];
				if (item.mutation) {
					console.log('ITEM ', item);
					let parameters = item.parameters ? item.parameters : {};
					if (!parameters.id) {
						parameters.id = params.id;
					} else {
						setId(parameters.id);
					}
					parameters.modal = state;

					let isRole = AUTH_TYPES.role(user, item.role);
					if (isRole) {
						//@ts-ignore
						let isSubscription = AUTH_TYPES.subscriptions(
							user,
							item.subscriptions
						);
						if (isSubscription) {
							let { data } = await item.mutation(parameters);
							if (data && data.success) {
								if (data.csrfToken) {
									setCsrfToken(data.csrfToken);
								}
								if (item.dataKey) {
									const dataKey = item.dataKey;
									serverDataTemp[dataKey] = data;
									if (item.reset) {
										const reset = item.reset;
										let dataToSet = data[dataKey];
										if (dataToSet && item.initStructure) {
											let cleanDataToSet = initializeStore(
												dataToSet,
												item.initStructure
											);
											reset(cleanDataToSet);
										} else {
											state.openModal(
												'Error',
												'Could not find data. ',
												'error'
											);
											history(-1);
										}
									}
								}
							} else {
								if (item.goBackIfNotAllowed) {
									history(-1);
								}
							}
						} else {
							if (item.goBackIfNotAllowed) {
								history(-1);
							}
						}
					} else {
						if (item.goBackIfNotAllowed) {
							history(-1);
						}
					}
				} else {
				}
			}
			setServerData(serverDataTemp);
			setIsLoaded(true);
		} else {
			setIsLoaded(true);
		}
	};

	const handleNewData = async (retrieveData) => {
		for (let i = 0; i < retrieveData.length; i++) {
			let item = retrieveData[i];
			if (item && item.initStructure) {
				let cleanDataToSet = initializeStore({}, item.initStructure);
				item.reset(cleanDataToSet);
			}
		}
		setIsLoaded(true);
		handleToken();
	};

	const handleToken = async () => {
		let canCreate = await getCanCreate({ type: '' }).unwrap();
		if (canCreate && canCreate.success) {
			if (canCreate.csrfToken) {
				setCsrfToken(canCreate.csrfToken);
			}
		}
	};

	React.useEffect(() => {
		if (dontInitialize) {
			return;
		}
		if (params.id !== '0') {
			setId(params.id);
			setIsNew(false);
			handleData(retrieveData);
		} else {
			handleNewData(retrieveData);
		}
	}, []);

	return {
		modelState: state,
		setIsLoaded,
		handleData,
		isLoaded,
		history,
		isNew,
		id,
		csrfToken,
		data: serverData,
	};
};

export default useManageHook;
