import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { Context } from '../../store/modalContext/store';
import { capitalizeTxt } from '../../business/miscHelper';
import MainButton from '../../components/buttons/MainButton';
import CloseIcon from '@mui/icons-material/Close';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import APIDropdown from '../../components/dropdowns/APIDropdown';
import useItems from '../../hooks/useItems';
import MainDropdown from '../../components/dropdowns/MainDropdown';
import LineContainer from '../../components/lines/LineContainer';

const DiscountItems = ({
	discounts,
	typePara,
	getItemsUrl,
	createDropdownFunction,
	control,
	drink,
	setValue,
	customButtonText,
	noDiscountText,
	id,
	label,
}) => {
	//@ts-ignore
	const [state] = useContext(Context);
	const [selectedDiscount, setSelectedDiscount] = React.useState('');

	const [items, item, dropdown] = useItems({
		id: '',
		dropdownFunction: createDropdownFunction.bind(this),
		useDropdown: true,
		url: getItemsUrl,
	});

	const onAddDiscount = (selectedDiscountPara) => {
		if (selectedDiscountPara) {
			console.log("discounts: ", discounts);
			// Check if discount has been added
			if(discounts && discounts.length > 0) {
				let discountExists = discounts.find((discount) => discount._id === selectedDiscountPara);
				if(discountExists) {
					state.openModal(
						'Warning',
						'You have already added this discount. ',
						'warning'
					);
					return;
				}
			}

			if(!discounts || (discounts && discounts.length === 0)) {
				discounts = [];
			}

			let newDiscounts = [...discounts];
			newDiscounts.push(selectedDiscountPara);
			console.log("newDiscounts: ", id, newDiscounts);
			setValue(id, newDiscounts);

		} else {
			state.openModal(
				'Warning',
				'You have to select the discount you want to add first. ',
				'warning'
			);
		}
	};

	const onRemoveDiscount = (discountId) => {
		if (discountId) {

			let newDiscounts = [...discounts];
			let index = newDiscounts.indexOf(discountId);
			if (index > -1) {
				newDiscounts.splice(index, 1);
			}
			console.log("newDiscounts: ", id, newDiscounts);
			setValue(id, newDiscounts);

			// newDiscounts.push(selectedDiscountPara);
			// console.log("newDiscounts: ", id, newDiscounts);
			// setValue(id, newDiscounts);
		}
	};

	return (
		<div style={{ width: '100%' }}>
			<MainDropdown
				label={label}
				size={12}
				options={dropdown}
				optionsValueKey={'_id'}
				optionsTextKey={'text'}
				id={id}
				value={selectedDiscount}
				onChange={(e) => {
					setSelectedDiscount(e.target.value);
				}}
				// identifier={id}
				isWithLink={true}
				link={'/admin/discounttimes'}
				// md={12}
			/>

			<MainButton
				text={customButtonText ? customButtonText : 'Add discount to drink'}
				removePadding={true}
				variant='outlined'
				style={{color: 'black'}}
				onClick={() => {
					onAddDiscount(selectedDiscount);
				}}
			/>

			
			{/* <LineContainer>
				<span style={{fontWeight: 'bold', fontSize: 16, marginTop: 20}}>Discounts are available on these times:</span>
			</LineContainer> */}

			{discounts && discounts.length > 0 ? (
				<Grid
					spacing={2}
					md={12}
					container
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: 20,
						marginBottom: 20,
					}}
				>
					{discounts && discounts.length > 0 ? (
						discounts.map((discountId, index) => {
							let discountedItem = null;
							discountedItem = items?.find((i) => discountId === i._id);


							// if (typePara === 'customDiscounts') {
							// } else if (typePara === 'repeatedDiscounts') {
							// 	discountedItem = discountRepeatItems.find(
							// 		(i) => discountId === i._id
							// 	);
							// }

							if (discountedItem) {

								let discountDays = {
									monday: discountedItem.monday,
									tuesday: discountedItem.tuesday,
									wednesday: discountedItem.wednesday,
									thursday: discountedItem.thursday,
									friday: discountedItem.friday,
									saturday: discountedItem.saturday,
									sunday: discountedItem.sunday,
								}


								return (
									<Grid style={{ width: '100%' }} item md={6} key={index}>
										<div
											style={{
												display: 'flex',
												border: '1px solid lightgray',
												padding: 25,
												borderRadius: 5,
												paddingTop: 34,
												position: 'relative',
												minHeight: 270,
												flexDirection: 'column',
												width: '100%',
												justifyContent: 'space-between',
												backgroundColor: '#f8f8f8',
											}}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<div
													style={{
														position: 'absolute',
														top: 0,
														left: 0,
														backgroundColor: 'gray',
														color: 'white',
														fontSize: 12,
														borderBottomRightRadius: 3,
														padding: 4,
													}}
												>
													Discount time
												</div>
												<div
													style={{
														position: 'absolute',
														top: 5,
														right: 5,
														color: 'gray',
														fontSize: 12,
														borderBottomRightRadius: 3,
														padding: 4,
														cursor: 'pointer',
													}}
													onClick={() => {
														onRemoveDiscount(discountId);
													}}
												>
													<CloseIcon style={{ color: 'gray', fontSize: 16 }} />
												</div>
												<div>
													<h4
														style={{
															fontWeight: 700,
															fontSize: 20,
															marginBottom: 0,
															marginTop: 0,
														}}
													>
														{discountedItem.name}
													</h4>
													<p>
														{discountedItem.description}
													</p>
													<p
														style={{
															marginBottom: 0,
															marginTop: 0,
															fontSize: 19,
														}}
													>
														{discountedItem.discountText}
													</p>

													<div style={{width: '100%'}}>
														<p>Times:</p>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															flexWrap: 'wrap',
														}}
													>
														{
															discountedItem && 
															discountedItem.times &&
															  discountedItem.times.length > 0
																? discountedItem.times.map((value, index) => {
																		return (
																			<div
																				key={index}
																				style={{
																					color: 'white',
																					backgroundColor: '#222',
																					borderRadius: 15,
																					padding: 10,
																					marginRight: 10,
																					marginTop: 4,
																				}}
																			>
																				{value.from} - {value.to}
																			</div>
																		);
																  })
																: null
														}
														<div style={{width: '100%'}}>
															<p>Days:</p>
														</div>

															{ 
																Object.keys(discountDays).map(
																	(key, index) => {
																		let isSelected = discountDays[key];
																		return (
																			<div
																				key={index}
																				style={{
																					backgroundColor: isSelected
																						? '#222'
																						: 'white',
																					color: isSelected ? 'white' : '#222',
																					border: '1px solid #222',
																					borderRadius: 15,
																					padding: 10,
																					marginRight: 10,
																					marginTop: 4,
																				}}
																			>
																				{capitalizeTxt(key)}
																			</div>
																		);
																})
															}
													</div>
												</div>
											</div>
											<div style={{width: '100%', marginTop: 20}}>

											<MainButton
												text={'Edit'}
												onClick={() => {
													const win = window.open(
														'/admin/discounttimes/' + discountId,
														'_blank'
													);
													win.focus();
												}}
											/>
											</div>
										</div>
									</Grid>
								);
							} else {
								return <p style={{textAlign: 'center', margin: 'auto', marginTop: 20 }}>
										Discounts not found. 
								</p>
							}
						})
					) : (
							<p style={{ marginTop: 20, textAlign: 'center' }}>
								No discounts found.{' '}
							</p>
					)}
				</Grid>
			) : (
				<Grid md={12} container style={{ marginLeft: 10 }}>
					<p
						style={{
							color: 'gray',
							textAlign: 'center',
							margin: 'auto',
							marginTop: 35,
							marginBottom: 35,
						}}
					>
						{noDiscountText}
					</p>
				</Grid>
			)}
		</div>
	);
};

export default DiscountItems;
