"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMAGE_TYPES = exports.SIDEBAR_TYPES = exports.ROUTE_TYPES = exports.ROUTE_NAMES = exports.AUTH_TYPES = exports.middlewareTypes = exports.APP_TYPES = void 0;
const iconStyle = { fontSize: 80 };
exports.APP_TYPES = {
    session: {
        auth: {
            loggedIn: 'loggedIn',
            // NotFullAuth: 'NotFullAuth',
            // FullAuth: 'FullAuth',
            // PlaceAuth: 'PlaceAuth',
        },
        authType: {
            Token: 'Token',
            Cookie: 'Cookie',
            CookieUser: 'CookieUser',
        },
        loginType: {
            Email: 'Email',
            Phone: 'Phone',
            PhoneCert: 'PhoneCert',
        },
        providers: {
            None: 'None',
            Google: 'Google',
            Facebook: 'Facebook',
        },
    },
    access: {
        systemRole: {
            Admin: 'Admin',
            Place: 'Place',
            User: 'User',
        },
        role: {
            'Place admin': 'Place admin',
            'Place user': 'Place user',
            User: 'User',
        },
    },
    platform: {
        Barhopp: 'Barhopp',
        TorioApp: 'TorioApp',
        TorioWeb: 'TorioWeb',
    },
    subscriptions: {
        All: 'All',
        TorioTap: 'TorioTap',
        Barhopp: 'Barhopp',
        TorioDrinks: 'TorioDrinks',
        Kegs: 'Kegs',
    },
    currencies: {
        ISK: 'ISK',
    },
    flowoptions: {
        TimedFlow: 'TimedFlow',
        ContinuousFlow: 'ContinuousFlow',
        FixedFlow: 'FixedFlow',
    },
    paymentArtifacts: {
        Pos: 'Pos',
        QRDynamic: 'QRDynamic',
        QRStatic: 'QRStatic',
    },
    paymentProviders: {
        Rapyd: 'Rapyd',
        Valitor: 'Valitor',
        Teya: 'Teya',
    },
};
exports.middlewareTypes = {
    auth: 'auth',
    userFullAuth: 'userFullAuth',
    companyfullAuth: 'companyfullAuth',
    role: 'role',
    products: 'products',
    subscriptions: 'subscriptions',
    isPlaceAuth: 'isPlaceAuth',
    isPlaceFullAuth: 'isPlaceFullAuth',
    isPlaceAuthEmailConfirmed: 'isPlaceAuthEmailConfirmed',
    isPlaceAuthWithoutPlace: 'isPlaceAuthWithoutPlace',
};
// For the front end
exports.AUTH_TYPES = {
    companyAuth: {
        isFirstAuth: function (user) {
            return (user.sessionType === exports.APP_TYPES.session.authType.Cookie &&
                user._id &&
                user.loggedIn);
        },
        isAuthAllowed: function (user) {
            return ((user.places &&
                user.places.length > 0 &&
                user.systemRole === 'Place') ||
                user.systemRole === 'Admin');
        },
        isAuthConfirmed: function (user) {
            if (user.loginType === exports.APP_TYPES.session.loginType.Phone) {
                if (user.isEmailConfirmed) {
                    if (user.phone && user.countryPhoneCode && user.isPhoneConfirmed) {
                        return true;
                    }
                }
            }
            else if (user.isEmailConfirmed &&
                user.loginType === exports.APP_TYPES.session.loginType.Email) {
                return true;
            }
            return false;
        },
        isPlaceSelected: function (user) {
            if (user.place) {
                return true;
            }
            return false;
        },
    },
    role: function (user, viewRole) {
        if (user && viewRole) {
            if (user.systemRole === exports.APP_TYPES.access.systemRole.Admin) {
                return true;
            }
            else if (viewRole === 'Admin' || viewRole === 'admin') {
                return false;
            }
            if (viewRole === exports.APP_TYPES.access.role['Place admin']) {
                return user.role === exports.APP_TYPES.access.role['Place admin'];
            }
            else if (viewRole === exports.APP_TYPES.access.role['Place user']) {
                return (user.role === exports.APP_TYPES.access.role['Place admin'] ||
                    user.role === exports.APP_TYPES.access.role['Place user']);
            }
        }
        return false;
    },
    subscriptions: function (user, allowedSubscriptions) {
        if (allowedSubscriptions && allowedSubscriptions.length > 0) {
            if (allowedSubscriptions[0] === exports.APP_TYPES.subscriptions.All) {
                return true;
            }
            for (let i = 0; i < allowedSubscriptions.length; i++) {
                let subscription = allowedSubscriptions[i];
                if (user.subscription.includes(subscription)) {
                    return true;
                }
            }
        }
        return false;
    },
    platform: function (user, allowedPlatforms) {
        if (!allowedPlatforms ||
            (allowedPlatforms && allowedPlatforms.length === 0) ||
            (allowedPlatforms.length > 0 && allowedPlatforms.includes(user.platform))) {
            return true;
        }
        else {
            return false;
        }
    },
};
exports.ROUTE_NAMES = {
    paymentdetails: 'paymentdetails',
    flowmeters: 'flowmeters',
    flowmeter: 'flowmeter',
    useraccess: 'useraccess',
    flows: 'flows',
    flow: 'flow',
    place: 'place',
    placeusers: 'placeusers',
    placeuser: 'placeuser',
    profile: 'profile',
    happyhour: 'happyhour',
    sales: 'sales',
    discounts: 'discounts',
    discounttimes: 'discounttimes',
    discounttime: 'discounttime',
    misc: 'misc',
    kegs: 'kegs',
    keg: 'keg',
    kegchanges: 'kegchanges',
    kegchange: 'kegchange',
    drinks: 'drinks',
    drink: 'drink',
    devices: 'devices',
    device: 'device',
    scan: 'scan',
    detailedplace: 'detailedplace',
    changeplace: 'changeplace',
    scanadmin: 'scanadmin',
    events: 'events',
    event: 'event',
    joinplace: 'joinplace',
    createplace: 'createplace',
    menu: 'menu',
    taps: 'taps',
    tap: 'tap',
    createqr: 'createqr',
    cart: 'cart',
    accesslog: 'accesslog',
    accesslogs: 'accesslogs',
    monitortaps: 'monitortaps',
    gpios: 'gpios',
    gpio: 'gpio',
    creditreceipts: 'creditreceipts',
    creditreceipt: 'creditreceipt',
    coupons: 'coupons',
    coupon: 'coupon',
};
exports.ROUTE_TYPES = {
    [exports.ROUTE_NAMES.paymentdetails]: {
        id: exports.ROUTE_NAMES.paymentdetails,
        role: exports.APP_TYPES.access.systemRole.Admin,
        route: 'paymentdetails',
        itemRoute: 'Payment details',
        getRoute: 'paymentdetails',
        name: 'Payment details',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
    },
    [exports.ROUTE_NAMES.creditreceipts]: {
        id: exports.ROUTE_NAMES.creditreceipts,
        role: exports.APP_TYPES.access.role['Place user'],
        route: 'creditreceipts',
        itemRoute: 'Receipt',
        getRoute: 'logs/getUserReceiptsCompany',
        name: 'Receipts',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.creditreceipt]: {
        id: exports.ROUTE_NAMES.creditreceipt,
        role: exports.APP_TYPES.access.role['Place user'],
        route: 'creditreceipts/:id',
        name: 'Receipt',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.flows]: {
        id: exports.ROUTE_NAMES.flows,
        role: exports.APP_TYPES.access.systemRole.Admin,
        route: 'flows',
        name: 'Flows',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.flow]: {
        id: exports.ROUTE_NAMES.flow,
        role: exports.APP_TYPES.access.systemRole.Admin,
        route: 'flows/:id',
        name: 'Flow',
        itemRoute: 'Flow',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.coupons]: {
        id: exports.ROUTE_NAMES.coupons,
        role: exports.APP_TYPES.access.systemRole.Place,
        route: 'coupons',
        name: 'Coupons',
        itemRoute: 'Coupon',
        subscriptions: [exports.APP_TYPES.subscriptions.Barhopp],
    },
    [exports.ROUTE_NAMES.coupon]: {
        id: exports.ROUTE_NAMES.coupon,
        role: exports.APP_TYPES.access.systemRole.Place,
        route: 'coupons/:id',
        name: 'Coupon',
        itemRoute: 'Coupon',
        subscriptions: [exports.APP_TYPES.subscriptions.Barhopp],
    },
    [exports.ROUTE_NAMES.discounttimes]: {
        id: exports.ROUTE_NAMES.discounttimes,
        role: exports.APP_TYPES.access.systemRole.Place,
        route: 'discounttimes',
        name: 'Discount times',
        itemRoute: 'Discount time',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.discounttime]: {
        id: exports.ROUTE_NAMES.discounttime,
        role: exports.APP_TYPES.access.systemRole.Place,
        route: 'discounttimes/:id',
        name: 'Discount time',
        itemRoute: 'Discount time',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.useraccess]: {
        id: exports.ROUTE_NAMES.useraccess,
        role: exports.APP_TYPES.access.role['Place admin'],
        route: 'useraccess',
        name: 'User access',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.flowmeters]: {
        id: exports.ROUTE_NAMES.flowmeters,
        role: exports.APP_TYPES.access.systemRole.Admin,
        route: 'flowmeters',
        name: 'Flowmeters',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.flowmeter]: {
        id: exports.ROUTE_NAMES.flowmeter,
        role: exports.APP_TYPES.access.systemRole.Admin,
        route: 'flowmeters/:id',
        name: 'Flowmeter',
        itemRoute: 'Flowmeter',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.place]: {
        id: exports.ROUTE_NAMES.place,
        role: exports.APP_TYPES.access.role['Place admin'],
        route: 'place',
        name: 'Place',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
    },
    [exports.ROUTE_NAMES.placeusers]: {
        id: exports.ROUTE_NAMES.placeusers,
        role: exports.APP_TYPES.access.role['Place admin'],
        route: 'place/placeusers',
        name: 'Place users',
        itemRoute: 'Place user',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
    },
    [exports.ROUTE_NAMES.placeuser]: {
        id: exports.ROUTE_NAMES.placeuser,
        route: 'place/placeusers/:id',
        name: 'Place user',
        itemRoute: 'Place user',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
        role: exports.APP_TYPES.access.role['Place admin'],
    },
    [exports.ROUTE_NAMES.profile]: {
        id: exports.ROUTE_NAMES.profile,
        route: 'profile',
        name: 'Profile',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.kegs]: {
        id: exports.ROUTE_NAMES.kegs,
        route: 'kegs',
        name: 'Kegs',
        itemRoute: 'item',
        subscriptions: [exports.APP_TYPES.subscriptions.Kegs],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.keg]: {
        id: exports.ROUTE_NAMES.keg,
        route: 'kegs/:id',
        name: 'Kegs',
        subscriptions: [exports.APP_TYPES.subscriptions.Kegs],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.kegchanges]: {
        id: exports.ROUTE_NAMES.kegchanges,
        route: 'kegchanges',
        name: 'Keg changes',
        itemRoute: 'item',
        subscriptions: [exports.APP_TYPES.subscriptions.Kegs],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.sales]: {
        id: exports.ROUTE_NAMES.sales,
        route: 'sales',
        name: 'Sales',
        itemRoute: 'item',
        subscriptions: [exports.APP_TYPES.subscriptions.Kegs],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.sale]: {
        id: exports.ROUTE_NAMES.sale,
        route: 'sales',
        name: 'Sales',
        itemRoute: 'item/:id',
        subscriptions: [exports.APP_TYPES.subscriptions.Kegs],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.kegchange]: {
        id: exports.ROUTE_NAMES.kegchange,
        route: 'kegchanges/:id',
        name: 'Keg change',
        subscriptions: [exports.APP_TYPES.subscriptions.Kegs],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.discounts]: {
        id: exports.ROUTE_NAMES.discounts,
        route: 'discounts',
        name: 'Manage discounts',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.misc]: {
        id: exports.ROUTE_NAMES.misc,
        subscriptions: [exports.APP_TYPES.subscriptions.All],
        role: exports.APP_TYPES.access.systemRole['Admin'],
        route: 'misc',
        name: 'Misc',
    },
    [exports.ROUTE_NAMES.drinks]: {
        id: exports.ROUTE_NAMES.drinks,
        itemRoute: 'Drink',
        subscriptions: [
            exports.APP_TYPES.subscriptions.TorioTap,
            exports.APP_TYPES.subscriptions.Kegs,
        ],
        role: exports.APP_TYPES.access.role['Place user'],
        route: 'drinks',
        name: 'Drinks',
    },
    [exports.ROUTE_NAMES.drink]: {
        id: exports.ROUTE_NAMES.drink,
        subscriptions: [
            exports.APP_TYPES.subscriptions.TorioTap,
            exports.APP_TYPES.subscriptions.Kegs,
        ],
        role: exports.APP_TYPES.access.role['Place user'],
        route: 'drinks/:id',
        name: 'Drink',
    },
    [exports.ROUTE_NAMES.gpios]: {
        id: exports.ROUTE_NAMES.gpios,
        route: 'gpios',
        name: 'GPIOs',
        role: exports.APP_TYPES.access.systemRole['Admin'],
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.gpio]: {
        id: exports.ROUTE_NAMES.gpio,
        route: 'gpios/:id',
        name: 'GPIO',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.systemRole['Admin'],
    },
    [exports.ROUTE_NAMES.devices]: {
        id: exports.ROUTE_NAMES.devices,
        route: 'devices',
        name: 'Devices',
        getRoute: 'devices/devices',
        role: exports.APP_TYPES.access.role['Place user'],
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
    },
    [exports.ROUTE_NAMES.device]: {
        id: exports.ROUTE_NAMES.device,
        route: 'devices/:id',
        name: 'Device',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.detailedplace]: {
        id: exports.ROUTE_NAMES.detailedplace,
        route: 'detailedplace',
        name: 'Place information',
        subscriptions: [exports.APP_TYPES.subscriptions.Barhopp],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.scanadmin]: {
        id: exports.ROUTE_NAMES.scanadmin,
        route: 'scanadmin',
        name: 'Scan',
        itemRoute: 'Scan',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.events]: {
        id: exports.ROUTE_NAMES.events,
        route: 'events',
        name: 'Events',
        itemRoute: 'Event',
        subscriptions: [exports.APP_TYPES.subscriptions.Barhopp],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.event]: {
        id: exports.ROUTE_NAMES.event,
        route: 'events/:id',
        name: 'Events',
        subscriptions: [exports.APP_TYPES.subscriptions.Barhopp],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.happyhour]: {
        id: exports.ROUTE_NAMES.happyhour,
        route: 'happyhour',
        name: 'Happy hour',
        subscriptions: [exports.APP_TYPES.subscriptions.Barhopp],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.joinplace]: {
        id: exports.ROUTE_NAMES.joinplace,
        route: 'joinplace',
        name: 'Join place',
        itemRoute: 'Invite',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.changeplace]: {
        id: exports.ROUTE_NAMES.changeplace,
        route: 'changeplace',
        name: 'Change place',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.createplace]: {
        id: exports.ROUTE_NAMES.createplace,
        route: 'createplace',
        name: 'Create company',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
        role: exports.APP_TYPES.access.systemRole['Admin'],
    },
    [exports.ROUTE_NAMES.scan]: {
        id: exports.ROUTE_NAMES.scan,
        route: 'scan',
        name: 'Manage scan',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.systemRole['Admin'],
    },
    [exports.ROUTE_NAMES.menu]: {
        id: exports.ROUTE_NAMES.menu,
        route: '',
        name: 'Manage',
        subscriptions: [exports.APP_TYPES.subscriptions.All],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.taps]: {
        id: exports.ROUTE_NAMES.taps,
        route: 'taps',
        itemRoute: 'Tap',
        getRoute: 'taps/taps',
        name: 'Taps',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
        subRoles: [exports.APP_TYPES.access.role['Cleaner']],
    },
    [exports.ROUTE_NAMES.tap]: {
        id: exports.ROUTE_NAMES.tap,
        route: 'taps/:id',
        name: 'Tap',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
        subRoles: [exports.APP_TYPES.access.role['Cleaner']],
    },
    [exports.ROUTE_NAMES.accesslogs]: {
        id: exports.ROUTE_NAMES.accesslogs,
        route: 'accesslogs',
        itemRoute: 'Access log',
        name: 'Tap access',
        getRoute: 'logs/getAccessLogs',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.accesslog]: {
        id: exports.ROUTE_NAMES.accesslog,
        route: 'accesslogs/:id',
        name: 'Tap access',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.createqr]: {
        id: exports.ROUTE_NAMES.createqr,
        route: 'taps/createqr/:id',
        name: 'Create QR code',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.cart]: {
        id: exports.ROUTE_NAMES.cart,
        route: 'qr/:type/:placeId/:qrId',
        name: 'Create QR code',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.scan]: {
        id: exports.ROUTE_NAMES.scan,
        route: 'qr/:type/:placeId/:qrId',
        name: 'Create QR code',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
    [exports.ROUTE_NAMES.monitortaps]: {
        id: exports.ROUTE_NAMES.monitortaps,
        route: 'monitortaps',
        name: 'Monitor taps',
        subscriptions: [exports.APP_TYPES.subscriptions.TorioTap],
        role: exports.APP_TYPES.access.role['Place user'],
    },
};
exports.SIDEBAR_TYPES = {
    FIRST: [exports.ROUTE_TYPES.menu],
    [exports.APP_TYPES.subscriptions.TorioTap]: [
        exports.ROUTE_TYPES.creditreceipts,
        exports.ROUTE_TYPES.useraccess,
        exports.ROUTE_TYPES.accesslogs,
        exports.ROUTE_TYPES.taps,
        exports.ROUTE_TYPES.drinks,
        exports.ROUTE_TYPES.discounttimes,
        exports.ROUTE_TYPES.discounts,
        exports.ROUTE_TYPES.devices,
        exports.ROUTE_TYPES.gpios,
        exports.ROUTE_TYPES.scanadmin,
        exports.ROUTE_TYPES.flows,
        exports.ROUTE_TYPES.flowmeters,
    ],
    [exports.APP_TYPES.subscriptions.Kegs]: [exports.ROUTE_TYPES.kegs, exports.ROUTE_TYPES.sales],
    [exports.APP_TYPES.subscriptions.Barhopp]: [
        exports.ROUTE_TYPES.detailedplace,
        exports.ROUTE_TYPES.happyhour,
        exports.ROUTE_TYPES.events,
        exports.ROUTE_TYPES.coupons,
    ],
    [exports.APP_TYPES.subscriptions.All]: [
        exports.ROUTE_TYPES.paymentdetails,
        exports.ROUTE_TYPES.place,
        exports.ROUTE_TYPES.profile,
        exports.ROUTE_TYPES.changeplace,
        exports.ROUTE_TYPES.createplace,
    ],
};
exports.IMAGE_TYPES = {
    detailedPlace: {
        maxSize: 1.3,
        compression: 1,
        maxCount: 5,
    },
    detailedPlaceMain: {
        maxSize: 1.3,
        compression: 0.8,
        maxCount: 1,
    },
    event: {
        maxSize: 1.4,
        maxCount: 1,
        compression: 0.8,
    },
    coupon: {
        maxSize: 1,
        compression: 0.5,
        maxCount: 1,
    },
    drinkHighQuality: {
        maxSize: 1.3,
        maxCount: 1,
        compression: 1.2,
    },
    drinks: {
        maxSize: 0.95,
        maxCount: 5,
        compression: 0.7,
        path: 'drinks/images',
    },
    drink: {
        maxSize: 1.9,
        maxCount: 1,
        compression: 1.2,
        path: 'drinks/image',
    },
    clearDrink: {
        maxSize: 1.9,
        maxCount: 1,
        compression: 1.2,
        path: 'drinks/clearImage',
    },
};
// event: {
//     maxSize: 0.38,
//     maxCount: 1,
//     compression: 0.15,
// },
