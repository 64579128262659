import { useParams } from 'react-router-dom';
import PageContainer from '../../components/containers/PageContainer';
import Keg from './Keg';
import KegChange from '../KegChanges/KegChange';
import KegChanges from '../KegChanges/KegChanges';
import React from 'react';

function KegPageContainer({ user }: any) {
	const params = useParams<any>();
	const [drinkName, setDrinkName] = React.useState<any>(null);
	const [keg, setKeg] = React.useState<any>(null);
	console.log('params: ', params.id);
	return (
		<PageContainer header={'Kegs'}>
			<Keg
				user={user}
				kegId={params.id}
				setKeg={setKeg}
				setDrinkName={setDrinkName}
				drinkName={drinkName}
			/>
			{params.id ? (
				<KegChanges
					user={user}
					drinkName={drinkName}
					kegId={params.id}
					keg={keg}
				/>
			) : null}
		</PageContainer>
	);
}

export default KegPageContainer;
