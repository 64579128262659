import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import { io } from 'socket.io-client';
import { sendAPI } from '../../api/helper';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import { TAP_STATES } from '../HomeMain/Constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccordionTapStatus from '../Taps/AccordionTapStatus';
import MonitorSection from './MonitorSection';
import RadioController from '../../components/radio/RadioController';
import AppRadioGroup from '../../components/radio/AppRadioGroup';
import { redirectToItemNewTab } from '../../api/helper';
import KegIcon from '../Kegs/KegIcon';
import MontorHandleOpen from './MontorHandleOpen';
import HandleManual from './HandleManual';

interface MonitorTapInterface {
	user: any;
	tapId: any;
	md: any;
	onClick?: any;
}

const MonitorTap = ({ user, tapId, md, onClick }: MonitorTapInterface) => {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const [tap, setTap] = React.useState<any>({});
	const [drink, setDrink] = React.useState<any>({});
	const [accessLog, setAccessLog] = React.useState<any>({});
	const [keg, setKeg] = React.useState<any>({});
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [socketId, setSocketId] = React.useState<any>(null);
	const [socketStored, setSocketStored] = React.useState<any>(null);

	React.useEffect(() => {
		// const socket = io('http://192.168.1.242:3000');
		let socket;

		if (process.env.NODE_ENV !== 'development') {
			socket = io('https://admin.torio.is', { secure: true });
			// socket = io('https://admin.torio.is');
		} else {
			socket = io();
		}

		socket.on('connect', () => {
			setSocketId(socket.id);
		});

		socket.on('disconnect', () => {
			console.log('Disconnected');
		});

		let tapIdTouse = 'tap' + tapId;
		socket.on(tapIdTouse, (data) => {
			console.log(
				'data',
				data,
				'tapIdTouse',
				tapIdTouse,
				'tapId',
				tapId,
				'socketId',
				socketId,
				'socketStored',
				socketStored
			);
			if (data?.tap !== undefined) {
				setTap(data.tap);
			}
			if (data?.keg !== undefined) {
				setKeg(data.keg);
			}
			if (data?.drink !== undefined) {
				setDrink(data.drink);
			}
			if (data?.accessLog !== undefined) {
				setAccessLog(data.accessLog);
			}
		});

		setSocketStored(socket);
		getData();

		return () => {
			if (socketStored) {
				console.log('scoket disconenct');
				socketStored.disconnect();
			}
			socket.disconnect();
		};
	}, []);

	const flowType =
		tap?.flowType === 'Manual'
			? 'Manual'
			: tap?.flowStepEnum
			? tap.flowStepEnum
			: 'Unknown';

	const getKegData = () => {
		if (keg && keg['ml'] !== undefined && keg['totalMl'] !== undefined) {
			return keg['ml']
				? keg['ml'] + ' ml '
				: 'Not known' + ' ml / ' + keg['totalMl']
				? keg['totalMl'] + ' ml'
				: 'Not known' + ' ml';
		} else {
			return '';
		}
	};

	const getData = async () => {
		setTimeout(() => {
			sendAPI('taps/tapmonitor/' + tapId, {}, null, null);
		}, 1500);
	};

	console.log('flowType: ', flowType);

	console.log('tapppp: ', tap);

	return (
		<PanelContainer
			md={md}
			style={{
				backgroundColor:
					flowType === 'Manual'
						? 'white'
						: TAP_STATES[tap.flowStepEnum]?.color
						? TAP_STATES[tap.flowStepEnum]?.color
						: 'white',
			}}
			header={'Tap ' + (tap.tapNumber ? tap.tapNumber : '')}
		>
			<Grid sm={12} xs={12} style={{ width: '100%', paddingBottom: 15 }} item>
				<div></div>
				<Typography
					textAlign={'center'}
					variant={'body1'}
					fontSize={20}
					fontWeight={'bold'}
					margin={'auto'}
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{flowType}
				</Typography>

				{flowType !== 'Unknown' &&
				tap?.flowStepEnum === 'ERROR' &&
				tap.errorMessage ? (
					<div>
						<Typography
							style={{
								textAlign: 'center',
								color: 'red',
							}}
						>
							{tap.errorMessage}
						</Typography>
						<Typography
							style={{
								textAlign: 'center',
								color: 'red',
							}}
						>
							Restart tap to continue
						</Typography>
					</div>
				) : null}

				{tap?.flowStepEnum === 'MANUAL' ? (
					<Typography
						style={{
							textAlign: 'center',
							color: tap.solenoidOn ? 'red' : 'gray',
						}}
					>
						{tap.solenoidOn
							? 'Tap is open to everyone without scanning.'
							: 'Tap is closed for everyone and nobody can use it. '}
					</Typography>
				) : null}

				<MonitorSection
					image={
						drink?.imageClearLink?.link
							? drink?.imageClearLink?.link
							: drink?.image?.link
							? drink?.image?.link
							: null
					}
					header={drink?.name}
					buttons={[
						{
							text: 'View',
							onClick: redirectToItemNewTab('/drinks/', drink?._id),
						},
					]}
					list={[
						{
							label: 'Price',
							text: drink?.price300Ml
								? `${drink?.price300Ml} kr p/330ml`
								: null,
						},
						{
							label: 'ABV',
							text: drink?.alc ? `${drink?.alc}%` : null,
						},
						{
							label: 'Type',
							text: drink?.type ? `${drink?.type}` : null,
						},
					]}
				/>

				{accessLog ? (
					<MonitorSection
						image={null}
						header={'User'}
						list={[
							{
								label: 'Usage',
								text: 'Tap is being accessed by a user',
							},
							// {
							// 	label: 'Is free',
							// 	text: accessLog?.isFree ? 'Tap is free' : 'Not free',
							// },
						]}
					/>
				) : null}

				{keg && false ? (
					<MonitorSection
						image={null}
						iconComponent={() => (
							<KegIcon totalMl={keg['totalMl']} ml={keg['ml']} />
						)}
						header={'Keg'}
						list={[
							{
								label: 'serial',
								text: keg['serial'],
							},
							{
								label: 'Ml status',
								text: getKegData(),
							},
						]}
						buttons={[
							{
								text: 'View',
								onClick: redirectToItemNewTab('/kegs/', keg?._id),
							},
						]}
					/>
				) : null}

				{tap.flowType === 'Manual' ? (
					<HandleManual modalState={modalState} tap={tap} />
				) : (
					<MontorHandleOpen
						onClick={onClick}
						modalState={modalState}
						tap={tap}
					/>
				)}

				<div style={{ marginTop: 29 }}>
					<IconButton
						color="inherit"
						style={{
							borderRadius: 15,
						}}
						aria-label="open drawer"
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						edge="start"
					>
						{isOpen ? (
							<KeyboardArrowUpIcon
								style={{
									color: '#111',
								}}
							/>
						) : (
							<KeyboardArrowDownIcon
								style={{
									color: '#111',
								}}
							/>
						)}
						{isOpen ? (
							<Typography>Hide problem solver sheet</Typography>
						) : (
							<Typography>Show problem solver sheet</Typography>
						)}
					</IconButton>
				</div>

				{isOpen ? <AccordionTapStatus tapId={tap?._id} /> : null}
			</Grid>
		</PanelContainer>
	);
};

export default MonitorTap;
