import { getTapColumns } from './../../business/tableHelper';
import PageContainer from './../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import Tap from './Tap';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import { useHistoryNav } from '../../hooks/useHistoryNav';

interface PlaceUserProps {
	user: any;
}

const Taps = ({ user }: PlaceUserProps) => {
	const navigate = useHistoryNav();

	return (
		<React.Fragment>
			<ManageItems
				customButtons={[
					{
						text: 'View live tap monitor',
						onClick: () => {
							navigate('/admin/monitortaps');
						},
					},
				]}
				header={'Taps'}
				key={1920}
				getData={{
					url: ROUTE_TYPES[ROUTE_NAMES.taps].getRoute,
					// url: 'taps/taps', // ROUTE_TYPES[ROUTE_NAMES.taps].getRoute,
					body: {},
				}}
				redirectLink={ROUTE_TYPES[ROUTE_NAMES.tap].route}
				columns={getTapColumns()}
				noItemsText={'No taps have been added.'}
				includeContainer={true}
				buttonText={'Create tap'}
			/>
		</React.Fragment>
	);
};

export default Taps;
