export const capitalizeTxt = (txt) => {
	return txt.charAt(0).toUpperCase() + txt.slice(1);
};

export const findValueByKey = (arr, key, valueToFind) => {
	return arr.find((value) => {
		if (value) {
			return value[key] === valueToFind;
		}
	});
};
