import FormControl from '@mui/material/FormControl';
import { formControlStyles } from '../../styles/materialStyles';
import {
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
} from '@mui/material';

const AppRadioGroup = ({
	md,
	identifier,
	label,
	onChange,
	radios,
	value,
	helperText,
}: any) => {
	return (
		<Grid item xs={12} md={md ? md : 12}>
			<FormLabel id={identifier}>{label}</FormLabel>
			<FormControl
				fullWidth={true}
				className={formControlStyles().root}
				variant="outlined"
				onChange={onChange}
				// style={{ width: '90%' }}
			>
				<RadioGroup
					// {...field}
					// value={value}
					row
					aria-labelledby={identifier + '-label'}
					name={identifier}
				>
					{radios.map((radio, index) => {
						return (
							<FormControlLabel
								value={radio.value}
								key={index}
								control={<Radio checked={value === radio.value} />}
								label={radio.label}
							/>
						);
					})}
				</RadioGroup>
			</FormControl>
			<FormHelperText>{helperText}</FormHelperText>
		</Grid>
	);
};

export default AppRadioGroup;
