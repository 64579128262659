import { getCouponColumns } from '../../business/tableHelper';
import PageContainer from '../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';


const Coupons = ({ user }: any) => {

	return (
		<PageContainer header={'Coupons'}>
			<React.Fragment>
				<ManageItems
					header={'Coupons'}
					key={112}
					getData={{
						url: 'coupons/coupons',
						body: {},
					}}
					buttonText={'Add coupon'}
					// includeContainer={showPageContainer}
					redirectLink={ROUTE_TYPES[ROUTE_NAMES.coupon].route}
					columns={getCouponColumns()}
					noItemsText={'No coupons have been added.'}
				/>
			</React.Fragment>
		</PageContainer>
	);
};

export default Coupons;
