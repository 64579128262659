import { currenciesObject } from '../data/data';

export const getCurrencyType = (place) => {
	if (place && place['currency']) {
		let compCurrency = place['currency'];
		return ` ${currenciesObject[compCurrency].symbol_native} (${currenciesObject[compCurrency].code})`;
	} else {
		return '';
	}
};

export const getCurrencyTypeByCode = (currency) => {
	if (currency) {
		return ` ${currenciesObject[currency].symbol_native} (${currenciesObject[currency].code})`;
	} else {
		return '';
	}
};

export const getCurrencyAppend = (placeCurrency, currencyAppend) => {
	if (placeCurrency && currencyAppend) {
		return currencyAppend + ` (${placeCurrency})`;
	}
	return '';
};

export function addThousandSeparator(number) {
	// Convert the number to a string
	var numStr = number.toString();

	// Regular expression to insert '.' every three digits from the end
	return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
