import { styled } from '@mui/material';
import message from './../../assets/message.png';

const LoginInputField = ({
	value,
	isDisabled,
	withEnvelope,
	placeholder,
	onChange,
}) => {
	return (
		<div
			style={{ marginBottom: 40 }}
			className="login-main-wrapper_input-wrapper"
		>
			{withEnvelope ? (
				<img
					style={{ width: 24 }}
					src={message}
					alt="message-icon"
					className="img-fluid img-fluid-icon"
				/>
			) : null}
			<input
				style={{
					marginTop: 3,
					position: 'relative',
				}}
				value={value}
				disabled={isDisabled}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				type="text"
				placeholder={placeholder}
			/>
		</div>
	);
};

export default LoginInputField;
