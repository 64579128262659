import React, { useContext, useEffect } from 'react';
import LineContainer from '../../components/lines/LineContainer';
import { Context } from '../../store/modalContext/store';
import MainButton from '../../components/buttons/MainButton';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import SubHeader from '../../components/texts/SubHeader';
import PageContainer from '../../components/containers/PageContainer';
import MainSwitch from '../../components/switch/MainSwitch';
import MainInput from '../../components/inputs/MainInput';

import toriologo from '../../assets/toriologo.png';
import TextField from '@mui/material/TextField';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { formControlStyles } from '../../styles/materialStyles';
import {
	useConfirmRegisterMutation,
	useConfirmRegisterEmailMutation,
	useGetRegisteredPlaceMutation,
	useCreateRegisteredPlaceMutation,
} from '../../store/api/api';
import { useParams } from 'react-router-dom';
import { onObjectChange } from '../../api/helper';
import GoogleConnect from '../GoogleConnect/GoogleConnect';

const RegisterPlace = ({ user }) => {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [sendEmails, setSendEmails] = React.useState(false);
	const [showGoogleLat, setShowGoogleLat] = React.useState(true);
	const [selectedGoogleId, setSelectedGoogleId] = React.useState('');


	const [registeredPlace, setRegisteredPlace] = React.useState({});
	const [getRegisteredPlace] = useGetRegisteredPlaceMutation();
	const [createRegisteredPlace] = useCreateRegisteredPlaceMutation();

	const { id } = useParams();

	const getData = async (idParams) => {
		let result = await getRegisteredPlace({ id: idParams }).unwrap();
		if (result.success) {
			setRegisteredPlace(result.item);
		}
	};

	useEffect(() => {
		if (id) {
			getData(id);
		}
	}, [id]);

	useEffect(() => {
		if (!registeredPlace['subscription']) {
			onValueChange('subscription', 'Barhopp');
		}
	}, []);

	const onValueChange = (key, value) => {
		onObjectChange(registeredPlace, key, value, setRegisteredPlace.bind(this));
	};

	return (
		<PageContainer header={'User '}>
			<MainContainer isLoaded={true}>
				<PanelContainer header={'Register place'}>
					<SubHeader text={'Place'} />

					{[
						{
							label: 'Place name',
							name: 'companyName',
							size: 12,
						},
						{
							label: 'One liner',
							name: 'oneLiner',
							size: 12,
						},
						{
							label: 'Your name',
							name: 'name',
							size: 12,
						},
						{
							label: 'Company email',
							name: 'email',
							size: 6,
						},
						{
							label: 'Login email',
							name: 'loginEmail',
							size: 6,
						},
						{
							label: 'Facebook email',
							name: 'facebookEmail',
							size: 6,
						},
						{
							label: 'Google email',
							name: 'googleEmail',
							size: 6,
						},
						{
							label: 'Email confirmed',
							name: 'emailConfirmed',
							size: 6,
							disabled: true,
						},
						{
							label: 'Place added',
							name: 'placeAdded',
							disabled: true,
							size: 6,
						},
						{
							label: 'Long',
							name: 'long',
							size: 6,
							disabled: showGoogleLat,
						},
						{
							label: 'Lat',
							name: 'lat',
							size: 6,
							disabled: showGoogleLat,
						},
					].map((value, index) => {
						return (
							<MainInput
								label={value.label}
								type={'text'}
								value={
									registeredPlace[value.name] ? registeredPlace[value.name] : ''
								}
								md={value.size}
								disabled={value.disabled ? true : false}
								identifier={value.name}
								onChange={(key, value) => {
									onValueChange(key, value);
								}}
							/>
						);
					})}

					<MainSwitch
						label={'Use Google places'}
						value={showGoogleLat}
						identifier={'longlat'}
						onChange={(identifier, newVal) => {
							setShowGoogleLat(newVal);
						}}
					/>

					<MainSwitch
						label={'Send emails'}
						value={sendEmails}
						identifier={'sendEmails'}
						onChange={(identifier, newVal) => {
							setSendEmails(newVal);
						}}
					/>

					<SubHeader text={'Company settings'} />

					<MainInput
						label={'Subscription'}
						type={'text'}
						value={registeredPlace['subscription']}
						md={12}
						identifier={'subscription'}
						onChange={(key, value) => {
							onValueChange(key, value);
						}}
					/>
				</PanelContainer>
			</MainContainer>

			{showGoogleLat ? (
				<GoogleConnect
					isMinified={true}
					user={user}
					setSelectedId={setSelectedGoogleId}
				/>
			) : null}

			<MainContainer isLoaded={true}>
				<PanelContainer header={'Create'}>
					<LineContainer>
						<MainButton
							onClick={async () => {
								if (window.confirm('Do you want to add create this account')) {
									let res = await createRegisteredPlace({
										...registeredPlace,
										selectedGoogleId: selectedGoogleId,
										sendEmails: sendEmails,
										modal: modalState,
									}).unwrap();

									if (res.success) {
										window.history.back();
									}
								}
							}}
							text={'Create account'}
							color={'primary'}
						/>
					</LineContainer>
				</PanelContainer>
			</MainContainer>
		</PageContainer>
	);
};

export default RegisterPlace;
