export const showFeedback = (accessLog, role, status) => {
	if (accessLog) {
		if (accessLog['feedback']) {
			if (role === 'Admin') {
				return true; // Admin can see submitted feedbacks
			}
		} else {
			return true; //Logged in as user that hasnt submitted
		}
	}
	return false;
};

export const showErrorPane = (role, status) => {
	if (role === 'Admin' || role === 'Place admin') {
		return true;
	}
};
