import moment from 'moment';
import Schedule from '@mui/icons-material/Schedule';
import Fingerprint from '@mui/icons-material/Fingerprint';
import { Grid } from '@mui/material';
import {
	LinearGradientCircle,
	LinearGradientLine,
} from '../../../styles/styledComponents';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	line: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: 20,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			marginTop: 7,
		},
	},
	iconTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		alignItems: 'center',
		width: '74px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			width: '100%',
		},
	},
	textContainer: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			width: '100%',
		},
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: 3,
		marginTop: 10,
		color: '#333',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			width: '',
		},
	},
	smallText: {
		color: 'gray',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
		},
	},
	smallTextContainer: {
		marginLeft: 40,
		width: '60%',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			marginLeft: 'auto',
			margin: 'auto',
		},
	},
	badgeContainer: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			marginTop: 8,
		},
	},
	iconContainer: {
		width: 80,
		height: 80,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: 'white',
		zIndex: 80,
		borderRadius: 80,
		[theme.breakpoints.down('md')]: {
			margin: 'auto',
			marginTop: 8,
		},
	},
}));

const EntryInfo = ({
	label,
	log,
	icon,
	text,
	smallerText,
	badgeText,
	date,
	smallerSmallerText,
	isValid,
	fallbackMessage = 'Not enough information',
}) => {
	const classes = useStyles();

	let LinearGradientLineComponent = LinearGradientLine(
		log && log['colorOne'] ? log['colorOne'] : '#244aaa',
		log && log['colorTwo'] ? log['colorTwo'] : '#bb11aa'
	);

	let LinearGradientCircleComponent = LinearGradientCircle(
		log && log['colorOne'] ? log['colorOne'] : '#244aaa',
		log && log['colorTwo'] ? log['colorTwo'] : '#bb11aa'
	);
	return (
		<div>
			<LinearGradientLineComponent />
			<div className={classes.line}>
				<div className={classes.textContainer}>
					<div className={classes.iconTextContainer}>
						{isValid ? (
							<LinearGradientCircleComponent className={classes.iconContainer}>
								{icon('#ffffff')}
							</LinearGradientCircleComponent>
						) : (
							<div className={classes.iconContainer}>{icon('#111')}</div>
						)}
						<span style={{ fontSize: 13, marginTop: 5, textAlign: 'center' }}>
							{label}
						</span>
					</div>
					<div className={classes.smallTextContainer}>
						<p className={classes.title}>{isValid ? text : fallbackMessage}</p>
						<span className={classes.smallText}>
							{isValid ? smallerText : 'No data'}
						</span>
						{smallerSmallerText ? (
							<div>
								<span className={classes.smallText}>{smallerSmallerText}</span>
							</div>
						) : null}
					</div>
				</div>
				<div className={classes.badgeContainer}>
					<div
						style={{
							backgroundColor: isValid ? '#bff1bf' : '#f1bfbf',
							borderRadius: 12,
							padding: 8,
							textAlign: 'center',
						}}
					>
						<span
							style={{
								color: isValid ? 'green' : 'red',
								fontWeight: 800,
								flexDirection: 'column',
								justifyContent: 'center',
								display: 'flex',
								textAlign: 'center',
							}}
						>
							{isValid ? badgeText : 'Not confirmed'}
						</span>
						<span
							style={{
								color: isValid ? 'green' : 'red',
								fontSize: 13,
								fontWeight: 600,
								textAlign: 'center',
							}}
						>
							{date ? moment.unix(date).format('DD/MM/YYYY HH:mm:ss') : null}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EntryInfo;
