import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
// import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'

// import storage from 'redux-persist/lib/storage';
// Slices
// import tasksSlice from './tasksSlice'
import {userSlice} from './user/userSlice'
import {loginSlice} from './login/loginSlice'
import {api} from './api/api'

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';

// Redux persist
const persistConfig = {
  key: 'root',
  storage: storageSession,
  // whis: [loginSlice.name],
}

// Reducers
const rootReducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [loginSlice.name]: loginSlice.reducer,
  [api.reducerPath]: api.reducer,
})

const persistedConfig = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer: persistedConfig,
  middleware: (getDefaultMiddleware) => {

    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware);

    return middlewares;
  }
})

// getDefaultMiddleware({
//   serializableCheck: {
//     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//   },
// }),
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const persistor = persistStore(store);
setupListeners(store.dispatch)

export { store, persistor }
