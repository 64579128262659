const InnerDiv = (props) => {
	return (
		<div
			style={{
				paddingLeft: 15,
				paddingTop: 15,
				paddingBottom: 15,
				width: '100%',
			}}
		>
			{props.children}
		</div>
	);
};

export default InnerDiv;
