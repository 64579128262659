import { Route } from "react-router-dom";
import PageContainer from "../../components/containers/PageContainer";
import React from "react";
import { getDiscountColumns, getDiscountTimesColumns } from "../../business/tableHelper";
import ManageItems from "../../components/tables/ManageItems";

const ManageDiscounts = ({user}: any) => {
    return (<PageContainer header={'Discounts'}>
            <React.Fragment>
                <ManageItems
                    header={'Discount times'}
                    buttonText={'Add discount time'}
                    key={3}
                    getData={{
                        url: 'discounts/discounttimes',
                        body: {},
                    }}
                    columns={getDiscountTimesColumns()}
                    redirectLink={'discounttimes/:id'}
                    noItemsText={'No discount times / happy hours have been added.'} />
            </React.Fragment>
        </PageContainer>)

}

export default ManageDiscounts;