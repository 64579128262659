import { getLogsColumns } from '../../business/tableHelper';
// import { getUserPlaceColumns } from './business/tableHelper';
import PageContainer from '../../components/containers/PageContainer';
import ManageItems from '../../components/tables/ManageItems';
import Receipt from './Receipt';
import React from 'react';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import moment from 'moment';

interface PlaceUserProps {
	user: any;
}

const Receipts = ({ user }: PlaceUserProps) => {
	console.log('user: ', ROUTE_TYPES[ROUTE_NAMES.accesslogs].getRoute);

	return (
		<React.Fragment>
			<ManageItems
				header={'Tap access'}
				hideNewButton={true}
				key={1920}
				showFilter={true}
				getData={{
					url: ROUTE_TYPES[ROUTE_NAMES.accesslogs].getRoute,
					body: {
						from: moment().subtract('4', 'days'),
						to: moment(),
					},
				}}
				redirectLink={ROUTE_TYPES[ROUTE_NAMES.accesslog].route}
				columns={getLogsColumns(user.systemRole)}
				noItemsText={'No logs have been added.'}
				includeContainer={true}
			/>
		</React.Fragment>
	);
};

export default Receipts;
