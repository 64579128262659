import React, { useContext } from 'react';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import { io } from 'socket.io-client';
import { sendAPI } from '../../api/helper';
import { useGetTapIdsQuery } from '../../store/api/api';
import { Context } from '../../store/modalContext/store';
import MonitorTap from '../MonitorTaps/MonitorTap';
import PageContainer from '../../components/containers/PageContainer';

const MonitorTaps = ({ user }) => {
	//@ts-ignore
	const [modal] = useContext(Context);

	const { data, isLoading } = useGetTapIdsQuery({ modal: modal, test: 1 });

	// React.useEffect(() => {}, []);

	return (
		<PageContainer breadPlaceholder={'Monitor tap'} header={'Monitor taps'}>
			<MainContainer isLoaded={true}>
				{!isLoading &&
					data &&
					data.ids &&
					data.ids.map((idObject, index) => {
						let id = idObject._id;
						return <MonitorTap key={index} user={user} tapId={id} md={6} />;
					})}
			</MainContainer>
		</PageContainer>
	);
};

export default MonitorTaps;
