import { Component } from 'react';
import ImageCompression from 'browser-image-compression';
import './App.css';
import { Grid } from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';

interface FileUploadProps {
	handleFile: Function;
	initialFile: string;
	text: string;
	filesLimit?: number;
	minImageSize?: number;
}
// (JSX attribute) onChange?: (files: File[

class FileUpload extends Component<
	FileUploadProps,
	{
		isLoaded: boolean;
		initialFile: Array<File>;
		filesLimit?: number;
		minImageSize?: number;
	}
> {
	constructor(props) {
		super(props);
		this.state = {
			initialFile: [],
			isLoaded: false,
		};
	}

	componentDidMount = async () => {
		await this.initialize(this.props.initialFile);
		if (this.props.initialFile) {
			// let initialFileArr = [];
			// initialFileArr.push(this.props.initialFile);
			// this.setState({
			// 	initialFile: initialFileArr,
			// });
		}
	};

	handleFileDrop = async (files) => {
		if (files && files.length > 0) {
			let image = files[0];
			const options = {
				maxWidthOrHeight: 500,
				useWebWorker: true,
				maxSizeMB: this.props.minImageSize ? this.props.minImageSize : 0.25, // (default: Number.POSITIVE_INFINITY)
				// maxWidthOrHeight: 500, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
			};
			console.log('options: ', options);
			let compressedImage = await ImageCompression(image, options);
			console.log('compressed image: ', compressedImage);
			if (compressedImage) {
				const reader = new FileReader();
				reader.readAsDataURL(compressedImage);
				reader.onload = (e) => {
					if (e.target.result) {
						this.props.handleFile(e.target.result);
					}
				};
			}
		} else {
			this.props.handleFile('');
		}
	};

	initialize = async (base64) => {
		if (base64) {
			try {
				fetch(base64)
					.then((res) => res.blob())
					.then((blob) => {
						if (blob) {
							const file = new File([blob], 'File', {
								type: blob.type,
							});
							this.setState({
								initialFile: [file],
							});
						}
						this.setIsLoaded();
					});
			} catch (err) {
				this.setIsLoaded();
			}
		} else {
			this.setIsLoaded();
		}
	};

	setIsLoaded = () => {
		this.setState({
			isLoaded: true,
		});
	};

	render() {
		// if (!this.state.isLoaded) return <div></div>;
		if (!this.state.isLoaded) return <div></div>;
		return (
			<Grid style={{ width: '100%' }} item md={12}>
				{/* https://yuvaleros.github.io/material-ui-dropzone/ */}
				<DropzoneArea
					onChange={this.handleFileDrop}
					acceptedFiles={['image/jpeg', 'image/png']}
					filesLimit={this.props.filesLimit ? this.props.filesLimit : 1}
					dropzoneText={this.props.text}
					// multiple={false}
					initialFiles={this.state.initialFile}
					// maxFileSize={2000000}
					maxFileSize={9000000}
					dropzoneClass="dropzone"
					dropzoneParagraphClass="dropzone-text"
					classes={{}}
					// classes={styles.root}
					// dropzoneClass={styles.root}
					// classes={{}}
					// styles={{
					//     dropzone: { width: 400, height: 200 },
					//     dropzoneActive: { borderColor: 'green' },
					//   }}
					// initialFiles={[this.props.initialFile]}
					// initialFiles
					// Icon={<EditIcon />}
				/>
			</Grid>
		);
	}
}

export default FileUpload;

// ...this.props.initialFiles
