import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import Header from '../../components/texts/Header';
import SingleAccordion from '../../components/Accordion/SingleAccordion';
import { Grid } from '@mui/material';
import {
	sendInformationBack,
	resetGPIOData,
	checkDeviceConnection,
	getQueueDetails,
} from '../../api/getData';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		marginTop: 30,
	},
}));

export default function AccordionTapStatus({ tapId }) {
	//@ts-ignore
	const [state] = useContext(Context);
	const classes = useStyles();

	async function sendInformationBackSubmit() {
		await sendInformationBack(tapId, state, window);
	}

	async function resetGPIODataSubmit() {
		await resetGPIOData(tapId, state, window);
	}

	async function checkDeviceConnectionSubmit() {
		await checkDeviceConnection(tapId, state, window);
	}

	return (
		<Grid md={12} style={{ maxWidth: '100%' }} item className={classes.root}>
			<p style={{ color: 'gray', marginTop: -5 }}>
				If there are problems with the tap, then you can solve most problems
				with the options below.
			</p>
			<SingleAccordion
				header={'Cannot connect to the tap device'}
				text={
					'Make sure that your internet connection is working. If this problem does not go away by itself, then please contact us at torio@torio.is.'
				}
				buttonText={'Check if connection is working'}
				onClick={checkDeviceConnectionSubmit}
			/>

			<SingleAccordion
				header={'Cannot send data back from tap device'}
				text={`If the tap device cannot send data back to our servers, then something is wrong.
                    This can happen if the network connection suddenly goes out.
                    The tap device will try to resend the data back to our servers. You can force the servers to resend the data by clicking the button below.
                    If that doesn't work, the we recommend looking at the solutions below.
                    `}
				buttonText={'Force device to send data back to Torio servers'}
				onClick={sendInformationBackSubmit}
			/>
			<SingleAccordion
				header={'Tap device cannot be used and data needs to be resetted'}
				text={`The tap should not go into a state where this tap device needs to be resetted. We therefore suggest that you let us know when this happens so we can investigate the issue.
                    You can reset the tap device.`}
				buttonText={'Reset this tap device'}
				onClick={resetGPIODataSubmit}
			/>
		</Grid>
	);
}
