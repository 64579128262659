import Grid from '@mui/material/Grid';
import Times from '../../components/inputs/Times';
import MainInput from '../../components/inputs/MainInput';
import MainSwitch from '../../components/switch/MainSwitch';
import SubHeader from '../../components/texts/SubHeader';
import InnerDiv from '../../components/containers/InnerDiv';
import MainDropdown from '../../components/dropdowns/MainDropdown';

const HappyhourItem = ({
	item,
	onValueChange,
	dayText,
	onCopyEntry,
	currencyAppend,
}) => {
	let dayTextLower = dayText.toLowerCase();

	return (
		<InnerDiv>
			<SubHeader text={dayText} />
			<Grid style={{ marginBottom: 15 }} container spacing={2}>
				<MainSwitch
					label={'Is happy hour'}
					value={item['isHappyHour'] ? item['isHappyHour'] : false}
					identifier={'isHappyHour'}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				{item['isHappyHour'] ? (
					<MainSwitch
						label={'Is happy hour text'}
						about={
							'If checked, a text will be displayed instead of the times like "2 f 1 of beers"'
						}
						value={item['isHappyHourText'] ? item['isHappyHourText'] : false}
						identifier={'isHappyHourText'}
						onChange={(key, value) => {
							onValueChange(key, value, dayTextLower);
						}}
					/>
				) : null}
			</Grid>

			<Grid container spacing={2}>
				{item['isHappyHour'] ? (
					<Times
						onValueChange={(key, value) => {
							onValueChange(key, value, dayTextLower);
						}}
						item={item}
					/>
				) : null}
				<MainInput
					label={'Beer price'}
					type={'number'}
					hideInput={!item['isHappyHour'] || item['isHappyHourText']}
					options={{
						max: 10000,
						min: 0,
					}}
					md={4}
					value={item['drink'] ? item['drink'] : ''}
					identifier={'drink'}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
					endText={currencyAppend}
				/>
				<MainInput
					label={'Wine price'}
					hideInput={!item['isHappyHour'] || item['isHappyHourText']}
					type={'number'}
					options={{
						max: 10000,
						min: 0,
					}}
					md={4}
					value={item['wine'] ? item['wine'] : ''}
					identifier={'wine'}
					endText={currencyAppend}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				<MainInput
					label={'Cocktail price'}
					type={'number'}
					options={{
						max: 10000,
						min: 0,
					}}
					md={4}
					hideInput={!item['isHappyHour'] || item['isHappyHourText']}
					value={item['cocktail'] ? item['cocktail'] : ''}
					identifier={'cocktail'}
					endText={currencyAppend}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				<MainInput
					label={'Shot price'}
					type={'number'}
					options={{
						max: 10000,
						min: 0,
					}}
					md={4}
					value={item['shot'] ? item['shot'] : ''}
					hideInput={!item['isHappyHour'] || item['isHappyHourText']}
					identifier={'shot'}
					endText={currencyAppend}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				<MainInput
					label={'Whiskey price'}
					type={'number'}
					options={{
						max: 10000,
						min: 0,
					}}
					md={4}
					hideInput={!item['isHappyHour'] || item['isHappyHourText']}
					value={item['whiskey'] ? item['whiskey'] : ''}
					endText={currencyAppend}
					identifier={'whiskey'}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				<MainInput
					label={'Champagne price'}
					type={'number'}
					options={{
						max: 10000,
						min: 0,
					}}
					md={4}
					hideInput={!item['isHappyHour'] || item['isHappyHourText']}
					value={item['champagne'] ? item['champagne'] : ''}
					endText={currencyAppend}
					identifier={'champagne'}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				<MainInput
					label={'Happy text (English)'}
					md={12}
					hideInput={!item['isHappyHour'] || !item['isHappyHourText']}
					value={item['happyText'] ? item['happyText'] : ''}
					endText={currencyAppend}
					identifier={'happyText'}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				<MainInput
					label={'Happy text (Icelandic)'}
					md={12}
					hideInput={!item['isHappyHour'] || !item['isHappyHourText']}
					value={item['happyTextis'] ? item['happyTextis'] : ''}
					endText={currencyAppend}
					identifier={'happyTextis'}
					onChange={(key, value) => {
						onValueChange(key, value, dayTextLower);
					}}
				/>
				<MainDropdown
					label={'Copy this entry to other dates'}
					value={'none'}
					options={[
						{
							_id: 'none',
							text:
								'Do you want to copy the ' + dayText + ' entry to other dates?',
						},
						{
							_id: 'monday',
							text: 'Copy this entry to Monday',
						},
						{
							_id: 'tuesday',
							text: 'Copy this entry to Tuesday',
						},
						{
							_id: 'wednesday',
							text: 'Copy this entry to Wednesday',
						},
						{
							_id: 'thursday',
							text: 'Copy this entry to Thursday',
						},
						{
							_id: 'friday',
							text: 'Copy this entry to Friday',
						},
						{
							_id: 'saturday',
							text: 'Copy this entry to Saturday',
						},
						{
							_id: 'sunday',
							text: 'Copy this entry to Sunday',
						},
						{
							_id: 'all',
							text: 'Copy this entry to all dates',
						},
					]}
					optionsValueKey={'_id'}
					style={{
						borderColor: 'green',
						borderWidth: 1,
						borderRadius: 5,
						fontWeight: 'bold',
					}}
					optionsTextKey={'text'}
					id={'copyentry' + dayText}
					size={12}
					onChange={(e: any) => {
						onCopyEntry(dayTextLower, e.target.value);
					}}
				/>
			</Grid>
		</InnerDiv>
	);
};

export default HappyhourItem;
