import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import { sendAPI } from '../../api/helper';
import { Routes, useLocation, Route, Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import MainButton from '../buttons/MainButton';
import MainContainer from '../containers/MainContainer';
import PanelContainer from '../containers/PanelContainer';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import DateTimePickerMain from '../dropdowns/DateTimePickerMain';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import PageContainer from '../containers/PageContainer';
import { ContainerPaddingDiv } from '../../styles/styledComponents';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface ManageItemsProps {
	getData: {
		url: string;
		body: any;
	};
	columns: any;
	noItemsText: string;
	redirectLink?: string;
	manageComponent?: Function;
	header?: string;
	noDoubleClick?: boolean;
	hideNewButton?: boolean;
	showFilter?: boolean;
	showCustomButtonAlsoOnEmpty?: boolean;
	role?: string;
	customButton?: any;
	customRedirect?: string;
	customRoute?: string;
	usePushWithCustomRoute?: boolean;
	noPointer?: boolean;
	includeContainer?: boolean;
	buttonText?: string;
	customClickFunction?: Function;
	customButtons?: any;
}

// customRedirect,
// 	customRoute,
// 	usePushWithCustomRoute,
// 	role,

function ManageItems({
	getData,
	columns,
	noItemsText,
	customClickFunction,
	noDoubleClick,
	header,
	includeContainer,
	manageComponent,
	hideNewButton,
	customButtons,
	showFilter,
	customButton,
	showCustomButtonAlsoOnEmpty,
	redirectLink,
	noPointer,
	buttonText,
}: ManageItemsProps) {
	//@ts-ignore
	const [state] = useContext(Context);
	const [items, setItems] = React.useState([]);
	const [selectedItems, setSelectedItems] = React.useState([]);
	const location = useLocation();
	let route = location.pathname;
	const history = useHistoryNav(); //<History<LocationState>>();
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [from, setFrom] = React.useState(null);
	const [to, setTo] = React.useState(null);

	React.useEffect(() => {
		setFrom(getData.body.from ? getData.body.from : null);
		setTo(getData.body.to ? getData.body.to : null);
		getItems(getData.body.from, getData.body.to);
		if (columns[columns.length - 1]?.field !== 'none' && !noDoubleClick) {
			//@eslint-disable-next-line react-hooks/exhaustive-deps
			columns.push({
				field: 'none',
				headerName: 'See more',
				width: 120,
				// disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => (
					<div
						style={{ width: '100%' }}
						onClick={() => navigateToItem(params.id)}
					>
						<span className="table__link">
							<ArrowForwardIcon style={{ marginTop: 10 }} />
						</span>
					</div>
				),
			});
		}
	}, []);

	const onFromChange = (date) => {
		console.log('onFromChange', date);
		let dateAfterChange = moment(date).format('DD-MM-YYYY');
		console.log('date after change: ', dateAfterChange);
		reactLocalStorage.set('from', dateAfterChange);
		setFrom(date);
	};

	const onToChange = (date) => {
		let dateAfterChange = moment(date).format('DD-MM-YYYY');
		reactLocalStorage.set('to', dateAfterChange);
		setTo(date);
	};

	async function getItems(fromPara, toPara) {
		// from: fromPara ? fromPara : from, to: toPara ? toPara : to,
		const result = await sendAPI(
			getData.url,
			{ ...getData?.body },
			state.openModal
		);
		if (result && result.items) {
			setItems(result.items);
			setIsLoaded(true);
		}
	}

	const navigateToItem = (id): any => {
		if (redirectLink) {
			redirectLink = '/admin/' + redirectLink.replace(':id', id);
			history(redirectLink);
			// history(redirectLink);
		}
		// if (customRedirect) {
		// 	if (usePushWithCustomRoute) {
		// 		history(route + '/' + customRedirect + '/' + id);
		// 	} else {
		// 		history(customRedirect + '/' + id);
		// 	}
		// } else {
		// 	history(route + '/' + id);
		// }
	};

	if (!isLoaded && !from && !to) return <div></div>;

	const data = (
		<MainContainer isLoaded={isLoaded}>
			<PanelContainer header={header}>
				<ContainerPaddingDiv style={{ width: '100%' }}>
					{showFilter ? (
						<Grid
							spacing={2}
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginBottom: 40,
							}}
						>
							<div style={{ margin: 10 }}>
								<DateTimePickerMain
									label={'From'}
									date={from}
									onChange={(date): any => {
										onFromChange(date);
									}}
								/>
							</div>

							<div style={{ margin: 10 }}>
								<DateTimePickerMain
									label={'To'}
									date={to}
									onChange={(date): any => {
										onToChange(date);
									}}
								/>
							</div>
							<div style={{ marginLeft: 10, marginTop: 10, display: 'flex ' }}>
								<MainButton
									onClick={async () => {
										setIsLoaded(false);
										getItems(null, null);
									}}
									text={'Search'}
									color={'primary'}
								/>
								<MainButton
									onClick={async () => {
										setIsLoaded(false);
										reactLocalStorage.set('from', '');
										reactLocalStorage.set('to', '');
										window.location.reload();
									}}
									text={'Reset filters'}
									color={'default'}
								/>
							</div>
						</Grid>
					) : null}
					<Grid
						// spacing={3}
						container
						direction="row"
						alignItems="center"
					>
						{items && items.length > 0 ? (
							<div
								style={{
									width: '100%',
									backgroundColor: 'white',
									cursor: noPointer ? 'default' : 'pointer',
								}}
							>
								<DataGrid
									rows={items}
									autoPageSize={false}
									columns={columns}
									getRowId={(row) => row._id}
									pageSize={20}
									checkboxSelection={false}
									autoHeight={true}
									//@ts-expect-error
									onRowSelected={(param: any) => {
										const itemId = param.data._id;
										if (itemId) {
											if (param.data) {
												if (param.isSelected) {
													setSelectedItems([...selectedItems, itemId]);
												} else {
													const newSelectedItems = selectedItems.filter((i) => {
														return i !== itemId;
													});
													setSelectedItems(newSelectedItems);
												}
											}
										}
									}}
									onCellDoubleClick={(param, event) => {
										if (!noDoubleClick) {
											navigateToItem(param.id);
										} else {
											customClickFunction(param.id);
										}
									}}
								/>
							</div>
						) : (
							<Grid md={12}>
								<p
									style={{
										width: '100%',
										textAlign: 'center',
										margin: 'auto',
										marginTop: 30,
									}}
								>
									{noItemsText}
								</p>
								{showCustomButtonAlsoOnEmpty &&
								customButton &&
								customButton.text ? (
									<Grid md={12} style={{ width: '100%' }}>
										<MainButton
											onClick={customButton.onClick}
											text={customButton.text}
											color={'default'}
										/>
									</Grid>
								) : null}
							</Grid>
						)}
						<Grid md={12} style={{ width: '100%' }}>
							{customButton && customButton.text ? (
								<MainButton
									onClick={customButton.onClick}
									text={customButton.text}
									color={'primary'}
								/>
							) : null}

							{!hideNewButton ? (
								customButton && items && items.length > 0 ? (
									<div></div>
								) : !customButton ? (
									<MainButton
										onClick={() => {
											console.log('route 11: ', customClickFunction);
											if (customClickFunction) {
												customClickFunction('0');
											} else {
												navigateToItem('0');
											}
											// if (customRoute) {
											// 	if (usePushWithCustomRoute) {
											// 		history(route + '/' + customRoute + '/0');
											// 	} else {
											// 		history(customRoute);
											// 	}
											// } else {
											// 	history(route + '/0');
											// }
										}}
										text={buttonText ? buttonText : 'Create new'}
										color={'primary'}
									/>
								) : null
							) : null}
						</Grid>

						{customButtons && customButtons.length > 0
							? customButtons.map((button, index) => {
									return (
										<MainButton
											onClick={button.onClick}
											text={button.text}
											color={'primary'}
										/>
									);
							  })
							: null}
					</Grid>
				</ContainerPaddingDiv>
			</PanelContainer>
		</MainContainer>
	);

	if (includeContainer) {
		return <PageContainer header={header}>{data}</PageContainer>;
	} else {
		return data;
	}
}

export default ManageItems;

// <Routes>
// {/* <Route path={`${route}/:id`}>{manageComponent()}</Route> */}
// {/* <Route path={route}> */}
// useEvent
// ) => {
// 	if (param.field === '__check__') {
// 		if (
// 			$(param.element)
// 				.find(':checkbox')
// 				.is(':checked')
// 		) {
// 			const newSelectedItems = [];
// 			items.forEach((value) => {
// 				newSelectedItems.push(value._id);
// 			});
// 			setSelectedItems(newSelectedItems);
// 		} else {
// 			setSelectedItems([]);
// 		}
// 	}
// }}
// filterModel={{
// 	items: [
// 		{
// 			columnField: 'ml',
// 			operatorValue: 'equals',
// 			value: '0',
// 		},
// 	],
// }}
