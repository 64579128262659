import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import OpacityIcon from '@mui/icons-material/Opacity';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import CropDinIcon from '@mui/icons-material/CropDin';
import ListIcon from '@mui/icons-material/List';
import MemoryIcon from '@mui/icons-material/Memory';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import DiscountIcon from '@mui/icons-material/Discount';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PaidIcon from '@mui/icons-material/Paid';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import WavesIcon from '@mui/icons-material/Waves';
import ReceiptIcon from '@mui/icons-material/Receipt';

import SupportIcon from '@mui/icons-material/Support';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SelectPlace from '../screens/Login/SelectPlace';
import MenuSelect from '../components/menu/MenuSelect';

import JoinPlace from '../screens/Login/JoinPlace';
import PlaceUsers from '../screens/PlaceUsers/PlaceUsers';
import PlaceUser from '../screens/PlaceUsers/PlaceUser';
import Event from '../screens/Events/Event';
import ManageTable from '../components/tables/ManageTable';
import Misc from '../screens/Misc/Misc';
import HappyHour from '../screens/HappyHour/HappyHour';
import ManageTap from '../screens/Taps/Tap';
import Events from '../screens/Events/Events';
import CreatePlace from '../screens/Place/CreatePlace';
import PrintTapQR from '../screens/Taps/PrintTapQR';
// import ManageGPIO from '../screens/GPIO/ManageGPIO';
import Profile from '../screens/Profile/Profile';
import ManageDiscount from '../screens/Discounts/DiscountTime';
import Place from '../screens/Place/Place';
import {
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import { eventInitalized } from '@toriosoftware/torioshared/dist/event';
import { flowmeterInitialized } from '@toriosoftware/torioshared/dist/flowmeter/Flowmeter';
import { deviceInitialized } from '@toriosoftware/torioshared/dist/devices/Device';
import { placeInitalized } from '@toriosoftware/torioshared/dist/place';
import { placeUserInitialized } from '@toriosoftware/torioshared/dist/placeUser';
import { profileInitalized } from '@toriosoftware/torioshared/dist/profile';
import { TapInitialized } from '@toriosoftware/torioshared/dist/taps';
import { DrinkInitialized } from '@toriosoftware/torioshared/dist/drinks';
import { flowInitialized } from '@toriosoftware/torioshared/dist/flow/Flow';
import { couponInitialized } from '@toriosoftware/torioshared/dist/coupon/Coupon';
import { discountTimeInitialized } from '@toriosoftware/torioshared/dist/discountTime/DiscountTime';
import { paymentDetailsInitalized } from '@toriosoftware/torioshared/dist/paymentDetails';
import { KegInitialized } from '@toriosoftware/torioshared/dist/kegs';
import { KegChangeInitialized } from '@toriosoftware/torioshared/dist/kegchanges';

const iconStyle = { fontSize: 80 };

export const SIZES = {
	CONTENT_WIDTH: 1200,
};

const COMPONENT_THEMES = {
	menu: {
		icon: (withStyles, color) => (
			<BorderAllIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		exludedFromPanel: true,
		color: '#5122aa',
	},
	useraccess: {
		icon: (withStyles, color) => (
			<AddCircleOutline
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#5eadcb',
		description: 'Manage user access to Torio Tap machines. ',
	},
	creditreceipts: {
		icon: (withStyles, color) => (
			<ReceiptIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#fffdc7a8',
		description: 'Receipt list for the Torio dispensers. ',
	},
	accesslogs: {
		icon: (withStyles, color) => (
			<ListIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#52d956',
		description: 'Access logs from your taps. ',
	},
	taps: {
		icon: (withStyles, color) => (
			<OpacityIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#83d7ac',
		description: 'Tap settings. Turn taps on and off and manage their health.',
	},
	drinks: {
		icon: (withStyles, color) => (
			<LocalDrinkIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#b7a1ef',
		description: 'Add the drinks from your menu. ',
	},
	kegs: {
		icon: (withStyles, color) => (
			<CropDinIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description:
			'Manage your kegs and fluid containers. See their content and status. ',
		color: '#64dde3',
	},
	sales: {
		icon: (withStyles, color) => (
			<PublishedWithChangesIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Manage changes in your keg inventory. ',
		color: '#24ade3',
	},
	kegchanges: {
		icon: (withStyles, color) => (
			<PublishedWithChangesIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Manage changes in your keg inventory. ',
		color: '#24ade3',
	},
	discounts: {
		icon: (withStyles, color) => (
			<MoneyOffIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#fffe80',
		description:
			'Set daily discounts, happy hour and special event discounts. ',
	},
	events: {
		icon: (withStyles, color) => (
			<EventIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#ff6565',
		description: 'Let people know what is going on in your place. ',
	},
	scan: {
		icon: (withStyles, color) => (
			<QrCodeScannerIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#ff6565',
		description: 'Log into tap. ',
	},
	happyhour: {
		icon: (withStyles, color) => (
			<LocalBarIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#ffcc68',
		description: 'Happy hour details. ',
	},
	detailedplace: {
		icon: (withStyles, color) => (
			<InfoIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		color: '#51adff',
		description: 'Information about the place. ',
	},
	profile: {
		icon: (withStyles, color) => (
			<AccountCircleIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Your account details.  ',
		color: '#d4d4d4',
	},
	place: {
		icon: (withStyles, color) => (
			<BusinessIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description:
			'Manage your place and add / remove users that can manage this place.',
		color: '#d4d4d4',
	},
	changeplace: {
		icon: (withStyles, color) => (
			<CompareArrowsIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Manage another place.',
		color: '#d4d4d4',
	},
	paymentdetails: {
		icon: (withStyles, color) => (
			<PaidIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Payment details.',
		color: '#d4d4d4',
	},
	gpios: {
		icon: (withStyles, color) => (
			<MemoryIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Manage your gpios.',
		color: '#e4b2a1',
	},
	devices: {
		icon: (withStyles, color) => (
			<MemoryIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Manage your devices.',
		color: '#ffcc95',
	},
	users: {
		icon: (withStyles, color) => (
			<MemoryIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Manage users. ',
		color: '#e4b2a1',
	},
	help: {
		icon: (withStyles, color) => (
			<SupportIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Misc help. ',
		color: '#e4b2a1',
	},
	createplace: {
		icon: (withStyles, color) => (
			<AddCircleOutline
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Create place. ',
		color: '#e4b2a1',
	},
	flowmeters: {
		icon: (withStyles, color) => (
			<WavesIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Create flowmeter. ',
		color: '#e4b2a1',
	},
	flows: {
		icon: (withStyles, color) => (
			<AccountTreeIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Flows. ',
		color: '#e4b2a1',
	},
	coupons: {
		icon: (withStyles, color) => (
			<DiscountIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Coupons and discounts on certain things. ',
		color: '#e985e5',
	},
	discounttimes: {
		icon: (withStyles, color) => (
			<WatchLaterIcon
				style={withStyles ? iconStyle : color ? { color: color } : {}}
			/>
		),
		description: 'Discount times and happy hour times for Torio Tap. ',
		color: '#5cbce4',
	},
};

interface ManageItemsProps {
	isLoadedInitial?: boolean;
	retrieveData?: Array<RetrieveDataProps>;
	user?: any;
}

interface RetrieveDataProps {
	mutation: any;
	parameters?: any;
	dataKey?: string;
	reset?: any;
	initStructure?: any;
	role?: string;
	subscriptions?: Array<string>;
	goBackIfNotAllowed?: boolean;
	id?: string;
}

export const GET_ROUTE_COMPONENT = (routeKey, user) => {
	const ROUTE_COMPONENTS = {
		[ROUTE_NAMES.menu]: {
			component: () => <MenuSelect user={user} />,
			theme: COMPONENT_THEMES.menu,
		},
		[ROUTE_NAMES.useraccess]: {
			theme: COMPONENT_THEMES.useraccess,
			data: {},
		},
		[ROUTE_NAMES.place]: {
			component: () => <Place user={user} />,
			theme: COMPONENT_THEMES.place,
			data: {
				get: (mutation, reset) => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'place',
						role: ROUTE_TYPES[ROUTE_NAMES.place].role,
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.place]?.subscriptions,
						// goBackIfNotAllowed: true,
						initStructure: placeInitalized,
					};
				},
			},
		},
		[ROUTE_NAMES.placeusers]: {
			component: () => (
				<PlaceUsers
					redirectToItem={''}
					placeName={user?.placeName}
					user={user}
					showPageContainer={true}
					header={'Place users'}
				/>
			),
			theme: COMPONENT_THEMES.users,
		},
		[ROUTE_NAMES.placeuser]: {
			component: () => <PlaceUser user={user} />,
			theme: COMPONENT_THEMES.users,
			data: {
				get: (mutation, reset) => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'user',
						role: ROUTE_TYPES[ROUTE_NAMES.placeuser].role,
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.placeuser].subscriptions,
						goBackIfNotAllowed: true,
						initStructure: placeUserInitialized,
					};
				},
			},
		},
		[ROUTE_NAMES.taps]: {
			theme: COMPONENT_THEMES.taps,
		},
		[ROUTE_NAMES.tap]: {
			theme: COMPONENT_THEMES.taps,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'tap',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.tap].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.tap].role,
						goBackIfNotAllowed: true,
						initStructure: TapInitialized,
					};
				},
			},
		},
		[ROUTE_NAMES.sales]: {
			theme: COMPONENT_THEMES.sales,
		},
		[ROUTE_NAMES.kegchange]: {
			theme: COMPONENT_THEMES.kegchanges,
			data: {
				get: (mutation, reset = null, id = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.kegchanges].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.kegchanges].role,
						goBackIfNotAllowed: false,
						initStructure: KegChangeInitialized,
						parameters: { id: id },
					};
				},
			},
		},
		[ROUTE_NAMES.kegs]: {
			theme: COMPONENT_THEMES.kegs,
		},
		[ROUTE_NAMES.keg]: {
			theme: COMPONENT_THEMES.kegs,
			data: {
				get: (mutation, reset = null, id = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.keg].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.keg].role,
						goBackIfNotAllowed: false,
						initStructure: KegInitialized,
						parameters: { id: id },
					};
				},
			},
		},
		[ROUTE_NAMES.drinks]: {
			theme: COMPONENT_THEMES.drinks,
		},
		[ROUTE_NAMES.drink]: {
			theme: COMPONENT_THEMES.drinks,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'drink',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.drink].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.drink].role,
						goBackIfNotAllowed: true,
						initStructure: DrinkInitialized,
					};
				},
			},
		},
		[ROUTE_NAMES.discounttimes]: {
			theme: COMPONENT_THEMES.discounttimes,
		},
		[ROUTE_NAMES.discounttime]: {
			theme: COMPONENT_THEMES.discounttimes,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.discounttime].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.discounttime].role,
						goBackIfNotAllowed: true,
						initStructure: discountTimeInitialized,
					};
				},
			},
		},
		[ROUTE_NAMES.coupons]: {
			theme: COMPONENT_THEMES.coupons,
		},
		[ROUTE_NAMES.coupon]: {
			theme: COMPONENT_THEMES.coupons,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.coupon].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.coupon].role,
						goBackIfNotAllowed: true,
						initStructure: couponInitialized,
					};
				},
			},
		},
		[ROUTE_NAMES.profile]: {
			theme: COMPONENT_THEMES.profile,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'user',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.profile].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.profile].role,
						goBackIfNotAllowed: true,
						initStructure: profileInitalized,
					};
				},
			},
		},
		[ROUTE_NAMES.paymentdetails]: {
			theme: COMPONENT_THEMES.paymentdetails,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions:
							ROUTE_TYPES[ROUTE_NAMES.paymentdetails].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.paymentdetails].role,
						goBackIfNotAllowed: true,
						initStructure: paymentDetailsInitalized,
					};
				},
			},
		},
		[ROUTE_NAMES.gpios]: {
			theme: COMPONENT_THEMES.gpios,
		},
		[ROUTE_NAMES.gpio]: {
			theme: COMPONENT_THEMES.gpios,
		},
		[ROUTE_NAMES.happyhour]: {
			theme: COMPONENT_THEMES.happyhour,
		},
		[ROUTE_NAMES.detailedplace]: {
			theme: COMPONENT_THEMES.detailedplace,
		},
		[ROUTE_NAMES.accesslogs]: {
			theme: COMPONENT_THEMES.accesslogs,
		},
		[ROUTE_NAMES.managediscounts]: {
			theme: COMPONENT_THEMES.discounts,
		},
		[ROUTE_NAMES.misc]: {
			theme: COMPONENT_THEMES.help,
		},

		[ROUTE_NAMES.changeplace]: {
			theme: COMPONENT_THEMES.changeplace,
		},
		// ROUTE_NAMES[.// joinplace]: {
		// // // 	component: () => <JoinPlace  />,
		// // 	theme: COMPONENT_THEMES.createplace,
		// // },
		[ROUTE_NAMES.createplace]: {
			theme: COMPONENT_THEMES.createplace,
		},
		[ROUTE_NAMES.events]: {
			component: () => <Events user={user} />,
			theme: COMPONENT_THEMES.events,
			data: {
				get: (mutation): RetrieveDataProps => {
					return {
						mutation: mutation,
						dataKey: 'items',
						goBackIfNotAllowed: true,
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.events].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.events].role,
					};
				},
			},
		},
		[ROUTE_NAMES.event]: {
			component: () => <Event user={user} />,
			theme: COMPONENT_THEMES.events,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.event].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.event].role,
						goBackIfNotAllowed: true,
						initStructure: eventInitalized,
					};
				},
			},
		},
		[ROUTE_NAMES.flowmeters]: {
			// component: () => <Flows user={user} />,
			theme: COMPONENT_THEMES.flowmeters,
			data: {
				get: (mutation): RetrieveDataProps => {
					return {
						mutation: mutation,
						dataKey: 'items',
						goBackIfNotAllowed: true,
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.flowmeters].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.flowmeters].role,
					};
				},
			},
		},
		[ROUTE_NAMES.creditreceipts]: {
			theme: COMPONENT_THEMES.creditreceipts,
		},
		[ROUTE_NAMES.flowmeter]: {
			// component: () => <Flow user={user} />,
			theme: COMPONENT_THEMES.flowmeters,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.flowmeters].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.flowmeters].role,
						goBackIfNotAllowed: true,
						initStructure: flowmeterInitialized,
					};
				},
			},
		},
		[ROUTE_NAMES.flows]: {
			// component: () => <Flows user={user} />,
			theme: COMPONENT_THEMES.flows,
			data: {
				get: (mutation): RetrieveDataProps => {
					return {
						mutation: mutation,
						dataKey: 'items',
						goBackIfNotAllowed: true,
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.flows].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.flows].role,
					};
				},
			},
		},
		[ROUTE_NAMES.flow]: {
			// component: () => <Flow user={user} />,
			theme: COMPONENT_THEMES.flows,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.flow].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.flow].role,
						goBackIfNotAllowed: true,
						initStructure: flowInitialized,
					};
				},
			},
		},
		[ROUTE_NAMES.devices]: {
			// component: () => <Flows user={user} />,
			theme: COMPONENT_THEMES.devices,
			data: {
				get: (mutation): RetrieveDataProps => {
					return {
						mutation: mutation,
						dataKey: 'items',
						goBackIfNotAllowed: true,
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.devices].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.devices].role,
					};
				},
			},
		},
		[ROUTE_NAMES.device]: {
			// component: () => <Flow user={user} />,
			theme: COMPONENT_THEMES.devices,
			data: {
				get: (mutation, reset = null): RetrieveDataProps => {
					return {
						mutation: mutation,
						reset: reset,
						dataKey: 'item',
						subscriptions: ROUTE_TYPES[ROUTE_NAMES.device].subscriptions,
						role: ROUTE_TYPES[ROUTE_NAMES.device].role,
						goBackIfNotAllowed: true,
						initStructure: deviceInitialized,
					};
				},
			},
		},
	};

	return ROUTE_COMPONENTS[routeKey];
};
