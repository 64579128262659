"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceUrlData = exports.initializeStore = void 0;
const initializeStore = (initData, initStructure) => {
    if (initData && initStructure) {
        Object.keys(initStructure).forEach((key) => {
            initStructure[key] = initData[key];
        });
    }
    return initStructure;
};
exports.initializeStore = initializeStore;
const replaceUrlData = (url) => {
};
exports.replaceUrlData = replaceUrlData;
function capitalizeTxt(txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
}
// export const getIfUserHasSubscription = (
// 	userSubscription, // string
// 	viewSubscriptions // array of subscriptions
// ) => {
// 	if (viewSubscriptions && viewSubscriptions.length > 0) {
// 		for (let i = 0; i < viewSubscriptions.length; i++) {
// 			let viewSubscription = viewSubscriptions[i];
// 			if (viewSubscription.includes('All')) {
// 				return true;
// 			} else {
// 				if (userSubscription) {
// 					if (userSubscription.includes(viewSubscription)) {
// 						return true;
// 					} else {
// 						return false;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	return false;
// };
// export const getIsRoleAllowed = (
// 	userRole,
// 	viewRole,
// 	userSubscription,
// 	viewSubscriptions,
// 	systemRole
// ) => {
// 	if (getIfUserHasSubscription(userSubscription, viewSubscriptions)) {
// 		if (systemRole === 'Admin') {
// 			return true;
// 		} else if (viewRole === 'Place admin') {
// 			return userRole === 'Place admin';
// 		} else if (viewRole === 'Place user') {
// 			return userRole === 'Place admin' || userRole === 'Place user';
// 		}
// 	}
// 	return false;
// };
