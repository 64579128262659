import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	KegInterface,
	KegInitialized,
	KegSchema,
} from '@toriosoftware/torioshared/dist/kegs';
import useManageHook from '../../components/hooks/useManageHook';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import { ROUTE_NAMES } from '@toriosoftware/torioshared/dist/config';
import { useGetKegMutation, useManageKegMutation } from '../../store/api/api';
import FormContainer from '../../components/containers/FormContainer';
import MainInputController from '../../components/inputs/MainInputController';
import MainSwitchController from '../../components/switch/MainSwitchController';

function Keg({ user, kegId, setDrinkName, drinkName, setKeg }: any) {
	const [getKeg] = useGetKegMutation();
	const [manageKeg, { isLoading }] = useManageKegMutation();
	const [canChangeStock, setCanChangeStock] = useState(false);

	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		getFieldState,
		setFocus,
	} = useForm<KegInterface>({
		defaultValues: KegInitialized,
		shouldFocusError: true,
		mode: 'onChange',
		resolver: joiResolver(KegSchema),
	});

	const { modelState, isLoaded, history, isNew, id, data, handleData } =
		useManageHook({
			dontInitialize: true,
			isLoadedInitial: false,
			retrieveData: [
				GET_ROUTE_COMPONENT(ROUTE_NAMES.keg, user)?.data?.get(
					getKeg,
					reset,
					//@ts-ignore
					kegId ? kegId : '0'
				),
			],
			user: user,
		});

	const countInventory = watch('countInventory');

	useEffect(() => {
		//@ts-ignore
		if (data?.item?.item?.drink?.name) {
			if (setDrinkName && setKeg) {
				//@ts-ignore
				setDrinkName(data?.item?.item?.drink?.name);
				//@ts-ignore
				setKeg(data?.item?.item);
			}
		}
	}, [data]);

	useEffect(() => {
		if (kegId && kegId.length > 5) {
			handleData([
				GET_ROUTE_COMPONENT(ROUTE_NAMES.keg, user)?.data?.get(
					getKeg,
					reset,
					//@ts-ignore
					kegId
				),
			]);
		}
	}, [kegId]);

	if (!kegId) {
		return (
			<FormContainer
				modal={modelState}
				handleSubmit={handleSubmit}
				isDirty={false}
				onSubmitFunction={() => {}}
				onRemoveFunction={() => {}}
				isLoaded={true}
				customData={{ id }}
				disabled={true}
				showBack={false}
				isLoading={isLoading}
				linkAfterDelete={'kegs'}
				title={'Keg'}
				// linkAfterSuccess={
				// 	isNew ? '/admin/' + ROUTE_TYPES[ROUTE_NAMES.drinks].route : ''
				// }
				depth={0}
				history={history}
				hideRemove={true}
			>
				<div style={{ margin: 'auto' }}>
					<h3 style={{ textAlign: 'center', margin: 'auto' }}>
						Create drink first,
					</h3>
					<h3 style={{ textAlign: 'center', margin: 'auto' }}>
						then you can handle keg inventory.
					</h3>
				</div>
			</FormContainer>
		);
	}

	return (
		<FormContainer
			modal={modelState}
			handleSubmit={handleSubmit}
			isDirty={isDirty}
			onSubmitFunction={manageKeg}
			onRemoveFunction={manageKeg}
			isLoaded={isLoaded}
			customData={{ id }}
			showBack={true}
			isLoading={isLoading}
			linkAfterDelete={'kegs'}
			title={'Keg for ' + (drinkName ? drinkName : 'drink')}
			// linkAfterSuccess={
			// 	isNew ? '/admin/' + ROUTE_TYPES[ROUTE_NAMES.drinks].route : ''
			// }
			depth={0}
			history={history}
			hideRemove={isNew}
		>
			{/* <SubHeader text={'General information'} /> */}

			{/* <MainInputController
				control={control}
				label={'Keg name'}
				type={'text'}
				identifier={'name'}
				md={12}
			/> */}
			<MainInputController
				control={control}
				label={'Keg storage location'}
				type={'text'}
				identifier={'location'}
				md={12}
			/>

			<MainInputController
				control={control}
				endText={'L'}
				label={'Liters'}
				// helperText={'Lager, IPA, Soda, etc.'}
				type={'number'}
				identifier={'liters'}
				md={4}
			/>

			<MainInputController
				control={control}
				endText={'kr'}
				label={'Price'}
				// helperText={'Lager, IPA, Soda, etc.'}
				type={'number'}
				identifier={'price'}
				md={4}
			/>

			<MainInputController
				control={control}
				endText={'kr'}
				label={'Discount price'}
				// helperText={'Lager, IPA, Soda, etc.'}
				type={'number'}
				identifier={'discountPrice'}
				md={4}
			/>

			<MainSwitchController
				control={control}
				label={'Count inventory for keg'}
				md={6}
				identifier={'countInventory'}
			/>

			<MainSwitchController
				control={control}
				label={'Is it currently in stock?'}
				hideInput={countInventory}
				md={6}
				identifier={'isInStock'}
			/>
			<MainInputController
				control={control}
				label={'How many are in stock?'}
				hideInput={!countInventory}
				type={'number'}
				disabled={!canChangeStock}
				onIconPressed={() => {
					setCanChangeStock(true);
				}}
				identifier={'numberOfKegs'}
				md={6}
			/>
		</FormContainer>
	);
}

export default Keg;
