// import './App.css';
import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import './scan.css';
//@ts-ignore
import { useSelector, RootStateOrAny } from 'react-redux';
import { useDispatch } from 'react-redux';
import ScanCorners from './ScanCorners';
import BottomTabs from '../../components/tabs/BottomTabs';
import PageContainer from '../../components/containers/PageContainer';
import { useParams } from 'react-router-dom';
import MainDropdown from '../../components/dropdowns/MainDropdown';
import { devicesDropdownMaker } from '../../business/dropdownHelper';
import useItems from '../../hooks/useItems';
import MainContainer from '../../components/containers/MainContainer';
import PanelContainer from '../../components/containers/PanelContainer';
import { Alert, ButtonGroup, Grid } from '@mui/material';
import MainButton from '../../components/buttons/MainButton';
import { useHistoryNav } from '../../hooks/useHistoryNav';
import { useLoginToDeviceMutation } from '../../store/api/api';

// import { Gradient, ColoredContainer } from './styles/styledComponents';

// import ShowParticles from './ShowParticles';

function ScanAdmin({ user }) {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const params = useParams<any>();
	let token = params?.id;
	const history = useHistoryNav();
	const [loginToDevice] = useLoginToDeviceMutation();
	let defaultSelection = {
		_id: '',
		text: '',
	};

	const [selectedDevice, setSelectedDevice] = React.useState(defaultSelection);
	const [isSelected, setIsSelected] = React.useState(false);

	const [devices, device, devicesDropdown] = useItems({
		id: '',
		dropdownFunction: devicesDropdownMaker.bind(this),
		useDropdown: true,
		url: 'devices/devices',
	});

	// const [taps, tap, tapsDropdown] = useItems({
	// 	id: '',
	// 	dropdownFunction: tapsDropdownMaker.bind(this),
	// 	useDropdown: true,
	// 	url: 'taps/taps',
	// });

	React.useEffect(() => {
		if (token) {
		}
	}, [token]);

	return (
		<PageContainer header={'Tap login'}>
			<MainContainer>
				<PanelContainer isLoaded={true} header={'Login to tap'}>
					{token ? (
						isSelected ? (
							<Alert severity="success">
								You have selected a tap. Now you may press login on the tablet.
							</Alert>
						) : (
							<MainDropdown
								size={12}
								label={'Device to login to'}
								isWithLink={false}
								options={devicesDropdown}
								optionsValueKey={'_id'}
								optionsTextKey={'text'}
								id={'tap'}
								onChange={(e) => {
									if (e.target.value) {
										setSelectedDevice(e.target.value);
									} else {
										setSelectedDevice(defaultSelection);
									}
								}}
								value={selectedDevice}
							/>
						)
					) : null}

					<div style={{ padding: 15 }}>
						<ButtonGroup>
							<MainButton
								onClick={async () => {
									let loginResult = await loginToDevice({
										token,
										modal: modalState,
										deviceId: selectedDevice,
									});

									//@ts-ignore
									let { data } = loginResult;
									if (data && data.success) {
										setIsSelected(true);
									}
								}}
								text={'Login to device'}
								color={'primary'}
							/>
							<MainButton
								onClick={() => {
									history('/');
								}}
								text={'Cancel'}
								color={'default'}
							/>
						</ButtonGroup>
					</div>
				</PanelContainer>
			</MainContainer>
		</PageContainer>
	);
}

export default ScanAdmin;
