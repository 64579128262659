import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { TextField } from '@mui/material';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmHook({
	isOpen,
	onClose,
	onPress,
	text,
	title,
	leftButtonText = 'No',
	rightButtonText = 'Yes',
}) {
	return (
		<div>
			<Dialog
				open={isOpen}
				TransitionComponent={Transition}
				keepMounted
				// onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{text}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>{leftButtonText}</Button>
					<Button onClick={onPress}>{rightButtonText}</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
