import { Controller } from 'react-hook-form';
import { Grid, GridSize } from '@mui/material';
import AppRadioGroup from './AppRadioGroup';
import React from 'react';
import ConfirmDialog from '../dialogs/ConfirmDialog';

interface RadioProp {
	label: string;
	value: string;
}

interface RadioProps {
	label: string;
	identifier: string;
	radios: Array<RadioProp>;
	md?: GridSize;
	control: any;
	hide?: boolean;
	helperText?: string;
	selected?: string;
	restrictions?: any;
}

const RadioController = ({
	label,
	md,
	hide,
	identifier,
	radios,
	restrictions,
	control,
	selected,
	helperText,
}: RadioProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [message, setMessage] = React.useState(false);

	const onChangePressed = (value: string, changeField: Function) => {
		if (restrictions) {
			if (restrictions.logic()) {
				changeField(value);
			} else {
				setMessage(restrictions.failMessage);
				setIsOpen(true);
				// window.alert(restrictions.failMessage);
			}
		} else {
		}
	};

	return (
		<Grid item style={{ display: 'flex', width: '100%', lineBreak: 'strict' }}>
			<ConfirmDialog
				isOpen={isOpen}
				onPress={() => setIsOpen(false)}
				onClose={() => {}}
				text={message}
				title={'Warning'}
				leftButtonText={''}
				rightButtonText={'Ok'}
				showInput={false}
				onInputChange={null}
			/>
			<Controller
				control={control}
				name={identifier}
				render={({
					field: { onChange, onBlur, value, ref },
					fieldState: { invalid, isTouched, isDirty, error },
					formState: { errors },
				}) => {
					return (
						<AppRadioGroup
							md={md}
							identifier={identifier}
							label={label}
							onChange={(value) => onChangePressed(value, onChange)}
							radios={radios}
							value={value}
							helperText={helperText}
						/>
					);
				}}
			/>
		</Grid>
	);
};

export default RadioController;
